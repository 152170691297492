import React, { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import 'App.css';
import router from 'router';
import { UserContextProvider } from 'contexts/user';
import { notificationsContext } from 'contexts/notifications';
import { SelectedUserContextProvider } from 'contexts/selected-user';
import { ConfigProvider } from 'antd';
import { ChildUserContextProvider } from 'contexts/child-user-context';
import { GoogleOAuthProvider } from '@react-oauth/google';

/**
 * Main application component.
 * @return {React.ReactElement}
 */
function App() {
  const { contextHandler } = useContext(notificationsContext);
  return (
    <>
      {contextHandler}
      <ConfigProvider
        theme={{
          token: {
            fontFamily: 'Now',
            colorText: '#034078',
          },
        }}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <UserContextProvider>
            <SelectedUserContextProvider>
              <ChildUserContextProvider>
                <RouterProvider router={router} />
              </ChildUserContextProvider>
            </SelectedUserContextProvider>
          </UserContextProvider>
        </GoogleOAuthProvider>
      </ConfigProvider>
    </>
  );
}

export default App;
