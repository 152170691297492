import React, { useRef, useState } from 'react';
import './locationInput.css';
import getGoogleMapsApiClient from 'requests/googleApiClient';
import { Input } from 'antd';

const LocationInput = ({
  value,
  onChange,
  isEditMode,
  setPlaceDetail,
  disabled,
  size = 'middle',
}) => {
  const [suggestions, setSuggestions] = useState([]);

  // Timeout Ref to debounce the calls.
  const timeoutRef = useRef();
  const sessionTokenRef = useRef();

  const loadSuggestions = async (inputValue) => {
    clearTimeout(timeoutRef.current);

    // Validation - Not loading suggestions if the input length is not greater than 3.
    if (!inputValue || inputValue.trim().length <= 3) {
      setSuggestions([]);
      return;
    }

    // Debouncing the loading of suggestions to reduce usage of the Google API
    timeoutRef.current = setTimeout(async () => {
      const google = await getGoogleMapsApiClient();
      if (!sessionTokenRef.current) {
        sessionTokenRef.current =
          new google.maps.places.AutocompleteSessionToken();
      }

      new google.maps.places.AutocompleteService().getPlacePredictions(
        {
          input: inputValue,
          sessionToken: sessionTokenRef.current,
        },
        (predictions, status) => {
          // Validation - Showing no suggestions if the status is ZERO_RESULTS.
          if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            return;
          }
          // Validation - Showing no suggestions if the location is not found.
          if (
            status !== google.maps.places.PlacesServiceStatus.OK ||
            !predictions
          ) {
            return;
          }
          setSuggestions(predictions);
        }
      );
    }, 350);
  };

  const handleSuggestionSelected = async (suggestion) => {
    try {
      // Changing the input text to the Google suggested text.
      onChange(suggestion.description);
      // Clearing the suggestions once one is selected.
      setSuggestions([]);

      const google = await getGoogleMapsApiClient();

      // Clearing the session token.
      const sessionToken = sessionTokenRef.current;
      sessionTokenRef.current = undefined;

      const googleMapsAttributionContainer = document.getElementById(
        'googlemaps-attribution-container'
      );

      if (googleMapsAttributionContainer) {
        new google.maps.places.PlacesService(
          googleMapsAttributionContainer
        ).getDetails(
          {
            placeId: suggestion.place_id,
            fields: [
              'formatted_address',
              'name',
              'geometry.location',
              'place_id',
              'address_components',
            ],
            sessionToken,
          },
          (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              // Extracting the desired address components
              const addressComponents = place.address_components || [];
              let city = '';
              let state = '';
              let zipCode = '';
              let country = '';

              // Fetching the city, state, country, and zip code details.
              addressComponents.forEach((component) => {
                const types = component.types || [];
                if (types.includes('locality')) {
                  city = component.long_name;
                } else if (types.includes('administrative_area_level_1')) {
                  state = component.short_name;
                } else if (types.includes('postal_code')) {
                  zipCode = component.short_name;
                } else if (types.includes('country')) {
                  country = component.long_name;
                }
              });
              onChange(place.formatted_address);
              setPlaceDetail({
                ...place,
                city,
                state,
                zipCode,
                country,
                address_one_line: place.formatted_address,
              });
            }
          }
        );
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  return (
    <div className="my-4" id="location-input">
      <div
        style={{ fontFamily: 'Now' }}
        className="mb-2 font-medium text-updatedBlue"
      >
        Address
      </div>
      <Input
        size={size}
        disabled={disabled}
        className={`w-full  ${
          !isEditMode
            ? 'mb-5 rounded-none border-x-0 border-t-0 !bg-updatedMustard'
            : 'border border-gray-200 bg-updatedCreme'
        }`}
        placeholder="Address"
        onChange={(event) => {
          const newValue = event.target.value;
          onChange(newValue);
          setPlaceDetail(undefined);
          loadSuggestions(newValue);
        }}
        value={value}
      />
      {suggestions.length > 0 && (
        <div>
          <ul style={{ listStyleType: 'none', padding: '0' }} role="listbox">
            {suggestions.map((suggestion) => (
              <li
                key={suggestion.place_id}
                className="bg-opacity-15 my-1 cursor-pointer px-2 py-1 text-base hover:bg-opacity-30"
                tabIndex={-1}
                role="option"
                aria-selected="false"
                onClick={() => handleSuggestionSelected(suggestion)}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Node to put attribution details for Google Places API */}
      <div id="googlemaps-attribution-container"></div>
    </div>
  );
};

export default LocationInput;
