export const FormRule = {
  NAME: [
    {
      required: true,
      pattern: /^[a-zA-Z ]{3,50}$/,
      message: 'Please enter a valid name!',
      min: 3,
      max: 50,
    },
  ],
  DISABILITY_TYPE: [
    {
      required: true,
      message: 'Please enter a disability type!',
    },
  ],
  LINK: [
    {
      required: false,
      pattern: /^(http:\/\/|https:\/\/).*$/,
      message: 'Please enter a valid link!',
      max: 100,
    },
  ],
  FIRSTNAME: [
    {
      required: true,
      pattern: /^[a-zA-Z ]{3,20}$/,
      message: 'Please enter a valid first name!',
      min: 3,
      max: 50,
    },
  ],
  LASTNAME: [
    {
      required: true,
      pattern: /^[a-zA-Z ]{3,20}$/,
      message: 'Please enter a valid last name!',
      min: 3,
      max: 50,
    },
  ],
  COUNTRY: [
    {
      required: true,
      pattern: /^[a-zA-Z ]{2,50}$/,
      message: 'Please enter a valid country name!',
      min: 3,
      max: 50,
    },
  ],
  STATE: [
    {
      required: true,
      pattern: /^[a-zA-Z ]{2,50}$/,
      message: 'Please enter a valid state name!',
      min: 2,
      max: 2,
    },
  ],
  EMAIL: [
    {
      required: true,
      type: 'email',
      message: 'Please enter a valid email!',
      validator: (_, value) => {
        const lowercaseRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
        if (!lowercaseRegex.test(value) || /[A-Z]/.test(value)) {
          return Promise.reject(
            new Error(
              'Please enter a valid email address with lowercase letters only.'
            )
          );
        }
        return Promise.resolve();
      },
    },
  ],

  PASSWORD: [
    {
      required: true,
      message: 'Please enter password!',
    },
  ],

  OLD_PASSWORD: [
    {
      required: true,
      message: 'Please enter old password!',
    },
  ],

  NEW_PASSWORD: [
    {
      required: true,
      message: 'Please enter new password!',
    },
  ],
  RE_ENTER_PASSWORD: [
    {
      required: true,
      message: 'Please re-enter new password!',
    },
  ],

  SELECT: [
    {
      required: true,
      message: 'Please select some value!',
    },
  ],

  PHONENUMBER: [
    {
      required: true,
      message: 'Please enter your phone number!',
    },
    {
      min: 10,
      message: 'The phone number must have 10 digits!',
    },
  ],
  PHONENUMBER_NOT_REQUIRED: [
    {
      required: false,
    },
    {
      min: 10,
      message: 'The phone number must have 10 digits!',
    },
  ],

  ZIP_CODE: [
    {
      required: true,
      message: 'Please enter your zip code!',
    },
  ],
  CITY: [
    {
      required: true,
      message: 'Please enter a valid city name!',
      pattern: /^[a-zA-Z ]{3,50}$/,
    },
  ],

  AGE: [
    {
      required: true,
      message: 'Age is required!',
    },
  ],

  ADDRESS: [
    {
      required: true,
      message: 'Please enter address!',
    },
  ],

  none: [
    {
      required: false,
    },
  ],
  SCHOOL_NAME: [
    {
      required: true,
      message: 'Please enter a school name!',
      pattern: /^[a-zA-Z ]{3,50}$/,
    },
  ],
};
