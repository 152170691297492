import { Table } from 'antd';
import FormModal from 'components/shared/AntFormModal/AntFormModal';
import React from 'react';
import { Link } from 'react-router-dom';

const TutorListModal = ({
  tutorData,
  isTutorModalOpen,
  setIsTutorModalOpen,
  setIsLoading,
  handleCancel,
}) => {
  const columns = [
    {
      title: 'Email',
      dataIndex: 'tutor_email',
      key: 'tutor_email',
      align: 'center',
    },

    {
      title: 'View',
      dataIndex: 'view',
      key: 'view',
      align: 'center',
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <Link
            to={`/parent/student-find-tutors/${record.tutor_id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="flex h-9 w-32 flex-col items-center justify-center rounded-full border border-[#3056D3] text-[#3056D3]"
          >
            View Storefront
          </Link>
        </div>
      ),
    },
  ];

  return (
    <div>
      <FormModal
        title={<div className="text-xl font-bold">Tutors</div>}
        isModalOpen={isTutorModalOpen}
        setIsModalOpen={setIsTutorModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={handleCancel}
        className="!w-[1050px] sm:!w-[380px] md:!w-[600px]"
      >
        <Table
          columns={columns}
          dataSource={tutorData}
          pagination={false}
          scroll={{
            x: 400,
            y: 500,
          }}
        />
      </FormModal>
    </div>
  );
};

export default TutorListModal;
