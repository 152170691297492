import React, { useContext } from 'react';
import { Layout } from 'antd';
import Navbar from 'components/shared/Navbar/Navbar';
import { tutorNavbarItems } from 'enums';
import Calendar from 'components/shared/Calendar/Calendar';
import { TUTOR } from 'constants/roles';
import { userContext } from 'contexts/user';

const Dashboard = () => {
  const {
    user: { id: userId },
  } = useContext(userContext);
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={tutorNavbarItems} role={TUTOR} />
        <Calendar role={TUTOR} userId={userId} />
      </Layout>
    </div>
  );
};

export default Dashboard;
