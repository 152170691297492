import { Avatar } from 'antd';
import StudentDetailsLayout from 'components/admin/student-details-layout';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import { selectedUserContext } from 'contexts/selected-user';
import React, { useContext } from 'react';

const PaymentConfiguration = () => {
  const { selectedUser } = useContext(selectedUserContext);

  return (
    <StudentDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <div className="flex justify-center pb-5">
          <div className="flex flex-col items-center justify-center gap-1">
            <Avatar size={100} src={selectedUser.profile_picture} />
            <div className="text-2xl font-semibold leading-relaxed text-updatedBlue">
              {`${selectedUser.first_name} ${selectedUser.last_name}`}
            </div>
          </div>
        </div>

        <div className="mt-10 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Payment Configuration
          </div>
          <div className="mt-5 flex justify-center">
            <CustomButton
              label="Make Payment"
              className="h-12 w-52 items-center justify-center rounded-3xl bg-gray-800 text-sm font-semibold text-white hover:!bg-gray-700"
            />
          </div>
        </div>
      </div>
    </StudentDetailsLayout>
  );
};

export default PaymentConfiguration;
