import React, { useContext, useState } from 'react';
import { Modal as AntdModal, Checkbox, DatePicker, Select } from 'antd';
import { notificationsContext } from 'contexts/notifications';
import { Formik } from 'formik';
import * as yup from 'yup';
import DeleteActionButton from 'components/DeleteActionButton';
import { ReactComponent as Loader } from 'assets/images/loader.svg';
import { updateTutorEducation } from 'requests/education';
import moment from 'moment';
import degreeTypes from 'enums/degreeTypes';
import dayjs from 'dayjs';

const UseEditEducationModal = (tutorId, callback) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { api } = useContext(notificationsContext);
  const validationSchema = yup.object().shape({
    degree_title: yup.string().required(),
    institute: yup.string().required(),
    degree_type: yup.string().required(),
    start_date: yup.date().required(),
    is_ongoing: yup.boolean().notRequired(),
    end_date: yup.date().when('is_ongoing', {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(),
    }),
    document: yup.mixed().required(),
  });
  const [educationId, setEducationId] = useState(null);
  const [educationData, setEducationData] = useState(null);

  const showModal = (educationId, educationData) => {
    setEducationId(educationId);
    setEducationData({
      ...educationData,
      start_date: dayjs(moment(educationData.start_date)),
      end_date: !educationData.is_ongoing
        ? dayjs(moment(educationData.end_date))
        : null,
    });

    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async (values) => {
    try {
      const formData = new FormData();
      formData.append('degree_title', values.degree_title);
      formData.append('institute', values.institute);
      formData.append('degree_type', values.degree_type);

      formData.append('start_date', values.start_date);
      formData.append('is_ongoing', values.is_ongoing);
      if (!values.is_ongoing) formData.append('end_date', values.end_date);
      formData.append('document', values.document);

      await updateTutorEducation(tutorId, educationId, formData);
      api.success({
        message: 'Success',
        description: 'Qualification updated successfully',
        placement: 'top',
        duration: 4,
      });
      if (callback) callback();
    } catch (err) {
      api.error({
        message: 'Error',
        description:
          'Something went wrong while updating the qualification. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    } finally {
      handleCancel();
    }
  };

  const EditEducationModal = ({ ...props }) => {
    return (
      <AntdModal
        width={'max-content'}
        {...props}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            degree_title: educationData?.degree_title,
            institute: educationData?.institute,
            degree_type: educationData?.degree_type,
            start_date: educationData?.start_date,
            is_ongoing: educationData?.is_ongoing,
            end_date: educationData?.end_date,
            document: educationData?.document,
          }}
          validateOnMount={true}
          onSubmit={handleOk}
        >
          {({
            isValid,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
            handleChange,
          }) => (
            <div className="xs:w-full xs:p-2 md:w-[500px] md:p-7">
              <div className="text-2xl font-semibold text-updatedBlue">
                Edit Qualification
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Degree Title
                </div>
                <input
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 text-base font-medium text-gray-500 outline-none"
                  id="degree_title"
                  name="degree_title"
                  type="text"
                  placeholder="Enter Degree Title"
                  onChange={handleChange}
                  value={values.degree_title}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Institute
                </div>
                <input
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 text-base font-medium text-gray-500 outline-none"
                  id="institute"
                  name="institute"
                  type="text"
                  placeholder="Enter Institute"
                  onChange={handleChange}
                  value={values.institute}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Degree Type
                </div>
                <div id="languages2">
                  <Select
                    className="mt-3 h-14 w-full rounded-lg text-base font-medium text-gray-500 outline-none"
                    name="degree_type"
                    id="degree_type"
                    placeholder="Select Degree Type"
                    value={values.degree_type}
                    onChange={(value) => setFieldValue('degree_type', value)}
                  >
                    {degreeTypes.map((el) => {
                      return (
                        <Select.Option value={el}>
                          <span className="m-2">{el}</span>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Start Date
                </div>
                <DatePicker
                  inputRender={({ onChange, ...props }) => (
                    <input
                      {...props}
                      id="start_date"
                      name="start_date"
                      className="!text-base !text-gray-500 !placeholder-[#9ca3af] !outline-none"
                    />
                  )}
                  value={values.start_date}
                  onChange={(date) => {
                    setFieldValue('start_date', date);
                  }}
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 "
                  placeholder="Select Date"
                  style={{
                    fontSize: '1.5rem',
                  }}
                  format={'DD-MM-YYYY'}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  End Date
                </div>
                <DatePicker
                  disabled={values.is_ongoing}
                  inputRender={({ onChange, ...props }) => (
                    <input
                      {...props}
                      id="end_date"
                      name="end_date"
                      className="!text-base !text-gray-500 !placeholder-[#9ca3af] !outline-none"
                    />
                  )}
                  value={values.end_date}
                  onChange={(date) => {
                    setFieldValue('end_date', date);
                  }}
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 "
                  placeholder="Select Date"
                  style={{
                    fontSize: '1.5rem',
                  }}
                  format={'DD-MM-YYYY'}
                />
                <Checkbox
                  className="mt-2 !text-base !text-gray-500"
                  checked={values.is_ongoing}
                  onChange={() => {
                    setFieldValue('end_date', null);
                    setFieldValue('is_ongoing', !values.is_ongoing);
                  }}
                >
                  Ongoing
                </Checkbox>
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Upload Educational Document
                </div>
                {!values.document ? (
                  <div className="relative mt-3 h-14 overflow-hidden rounded-lg">
                    <input
                      accept="image/*,.pdf"
                      type="file"
                      className="h-full w-full"
                      onChange={(event) => {
                        setFieldValue('document', event.target.files[0]);
                      }}
                    />
                    <div className="flex h-full">
                      <div className="flex h-full w-32 items-center justify-center rounded-bl-lg rounded-tl-lg border border-neutral-200 bg-slate-100">
                        Choose file
                      </div>

                      <div className="flex h-full flex-1 items-center rounded-br-lg rounded-tr-lg border border-neutral-200 px-5">
                        No file Choosen
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 flex items-center justify-between gap-5">
                    <div className="flex-1 overflow-hidden text-base font-medium text-gray-500">
                      {values.document?.name ?? 'Uploaded Educational Document'}
                    </div>
                    <div>
                      <DeleteActionButton
                        onClick={() => {
                          setFieldValue('document', null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <button
                  className={`mt-12 h-12 w-full items-center justify-center rounded-3xl ${
                    isValid ? 'bg-updatedBlue' : 'bg-gray-500'
                  } text-white`}
                  onClick={handleSubmit}
                  disabled={!isValid || isSubmitting}
                >
                  {!isSubmitting ? (
                    'Update Qualification'
                  ) : (
                    <div className="flex w-full items-center justify-center">
                      <Loader color="white" className="spinner h-8 w-8" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </AntdModal>
    );
  };

  return { EditEducationModal, showModal };
};

export default UseEditEducationModal;
