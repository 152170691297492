import ForgotPasswordConponent from 'components/forgot-password';
import { STUDENT } from 'constants/roles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { sendResetPasswordToken } from 'requests';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const handleForgotPassword = async ({ email }) => {
    await sendResetPasswordToken(email);
    const query = btoa(JSON.stringify({ email }));
    navigate(`/student/verify-code?query=${query}`);
  };
  return (
    <ForgotPasswordConponent
      forgotPassword={handleForgotPassword}
      className="bg-updatedGreenish md:bg-contain"
      role={STUDENT}
    />
  );
};

export default ForgotPassword;
