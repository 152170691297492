import React from 'react';

const RoleCard = ({ roleName, roleIcon, onSelect, selected }) => {
  return (
    <div
      className={`flex cursor-pointer flex-col items-center justify-center rounded-2xl bg-[#FFFEF9] p-5 shadow-lg xs:w-[70%] sm:w-[80%] md:w-full ${
        selected ? 'border-4 border-updatedBlue' : 'border'
      } hover:shadow-2xl`}
      onClick={onSelect}
    >
      <img src={roleIcon} alt={roleName} className="mb-5" />
      <div className={`font-bold ${selected ? 'text-updatedBlue' : ''}`}>
        {roleName}
      </div>
    </div>
  );
};

export default RoleCard;
