import { createContext, useState } from 'react';

export const selectedUserContext = createContext();

export const SelectedUserContextProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState(
    JSON.parse(localStorage.getItem('selectedUser'))
  );

  return (
    <selectedUserContext.Provider value={{ selectedUser, setSelectedUser }}>
      {children}
    </selectedUserContext.Provider>
  );
};
