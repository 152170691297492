import React, { useContext, useState } from 'react';
import { tutorLayoutSideBarOptions } from 'constants/side-bar-options';
import { generatePath, useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';
import { userContext } from 'contexts/user';

const TutorGeneralProfileSidebar = ({ handleDeacticateAccountButton }) => {
  const navigate = useNavigate();
  const {
    user: { id: tutorId },
  } = useContext(userContext);
  const pathName = window.location.pathname;

  const [expanded, setExpanded] = useState(() => {
    if (generatePath(tutorLayoutSideBarOptions[0].path) === pathName) return '';
    else return 'detail-profiling';
  });

  return (
    <div className="top-6 mx-6 my-[24px] flex h-full  flex-col items-center justify-between rounded-2xl bg-updatedMustard py-16 md:sticky md:w-[450px]">
      <div className="mt-5 flex w-full flex-col md:h-[540px]">
        {tutorLayoutSideBarOptions.map(({ label, path, children, name }) => {
          const isCurrentPath =
            (path && generatePath(path, { id: tutorId }) === pathName) ||
            (children &&
              children?.find(
                ({ path }) => generatePath(path, { id: tutorId }) === pathName
              ));

          return (
            <div
              className={`overflow-hidden transition-all duration-300 ${
                expanded === name ? 'h-[500px]' : 'h-10'
              }`}
            >
              <div
                onClick={() => {
                  if (path && !isCurrentPath)
                    navigate(path.replace(':id', tutorId));
                  if (children && children.length > 0)
                    if (expanded === name) setExpanded('');
                    else setExpanded(name);
                }}
                className={cn(
                  'flex w-full cursor-pointer items-center justify-between px-6 py-[9px]',
                  {
                    'border-r-4 border-updatedBlue bg-updatedCreme text-updatedBlue hover:text-gray-700':
                      isCurrentPath,
                    'bg-updatedMustard text-gray-500 hover:text-gray-700':
                      !isCurrentPath,
                  }
                )}
              >
                <div>{label}</div>
                {children && children.length > 0 && (
                  <div
                    className={`transition-all duration-300 ${
                      expanded === name ? 'rotate-180' : 'rotate-0'
                    }`}
                  >
                    <DropdownArrow
                      className={cn('h-5 w-5', {
                        'text-updatedBlue': isCurrentPath,
                        'text-gray-500': !isCurrentPath,
                      })}
                    />
                  </div>
                )}
              </div>
              {children &&
                children.map(({ label, path }) => {
                  const isCurrentChildPath =
                    path && generatePath(path, { id: tutorId }) === pathName;

                  return (
                    <div
                      onClick={() => {
                        if (
                          path &&
                          generatePath(path, { id: tutorId }) !== pathName
                        )
                          navigate(path.replace(':id', tutorId));
                      }}
                      className={cn(
                        'ml-5 w-full cursor-pointer items-center justify-start py-[9px] pl-6 hover:text-gray-700',
                        {
                          ' text-updatedBlue': isCurrentChildPath,
                          ' text-gray-500': !isCurrentChildPath,
                        }
                      )}
                    >
                      {label}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <div className="w-full px-10">
        <div className="h-[0px] w-full border border-neutral-200" />
        <div
          onClick={handleDeacticateAccountButton}
          className="mt-10 flex w-full flex-1 cursor-pointer items-center justify-center rounded-md px-2 py-2 hover:bg-red-100"
        >
          <div className="pb-2 text-base font-medium leading-normal text-rose-500">
            Deactivate Account
          </div>
        </div>
      </div>
    </div>
  );
};

export default TutorGeneralProfileSidebar;
