import React from 'react';
import ParentGeneralProfileSidebar from './parent-general-profile-sidebar';

const ParentDetailsLayout = ({ children }) => {
  return (
    <div className="flex flex-row bg-violet-50">
      <ParentGeneralProfileSidebar />
      <div className="mx-8 mb-5 mt-6 flex items-start gap-2.5 rounded-2xl bg-updatedCreme p-20 shadow md:h-fit md:w-full">
        {children}
      </div>
    </div>
  );
};

export default ParentDetailsLayout;
