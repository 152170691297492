import React, { useContext, useEffect, useState } from 'react';
import { getTutorById } from 'requests/tutors';
import { notificationsContext } from 'contexts/notifications';
import { Checkbox } from 'antd';
import { addStudentStatistics } from 'requests';
import Layout from 'components/tutor/ProfileLayout';
import DefaultButton from 'components/DefaultButton';
import { userContext } from 'contexts/user';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const options = ['5-10 Years', '10-13 Years', '13-16 Years', '17-18 Years'];

const TutorStudentStatistics = () => {
  const {
    user: { id: tutorId, account_status },
  } = useContext(userContext);
  const { api } = useContext(notificationsContext);
  const [studentsStatistics, setStudentsStatistics] = useState([]);
  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setStudentsStatistics(data?.student_statistics ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateStudentsStatistics = async () => {
    try {
      await addStudentStatistics(tutorId, studentsStatistics);
      api.success({
        message: 'Success',
        description: 'Student statistics updated successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="flex min-h-[calc(100vh_-_273px)] w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Student Age Groups
          </div>
          <div>
            {account_status === 'approved' && (
              <button
                type="button"
                className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                onClick={() => {
                  const navigateString = viewStoreFront(tutorId, TUTOR);
                  navigate(navigateString);
                }}
              >
                View Storefront
              </button>
            )}
          </div>
        </div>

        <div className="mt-10 flex w-full flex-1 flex-wrap gap-5">
          {options.map((value) => {
            return (
              <Checkbox
                className="flex h-14 flex-row items-center gap-2 text-lg"
                checked={studentsStatistics.includes(value)}
                onChange={() => {
                  if (studentsStatistics.includes(value)) {
                    setStudentsStatistics((stats) => {
                      let statistics = [...stats];
                      const index = statistics.indexOf(value);
                      statistics.splice(index, 1);
                      return statistics;
                    });
                  } else {
                    setStudentsStatistics((statistics) => {
                      return [...statistics, value];
                    });
                  }
                }}
              >
                {value}
              </Checkbox>
            );
          })}
        </div>

        <div className="flex w-full justify-end">
          <DefaultButton onClick={handleUpdateStudentsStatistics}>
            Save Settings
          </DefaultButton>
        </div>
      </div>
    </Layout>
  );
};

export default TutorStudentStatistics;
