import React from 'react';
import { ReactComponent as DeleteSvg } from 'assets/images/delete.svg';

const DeleteActionButton = ({ ...props }) => {
  return (
    <button
      className="flex h-10 w-10 items-center justify-center rounded-md bg-updatedPink"
      {...props}
    >
      <DeleteSvg className="h-5 w-5" />
    </button>
  );
};

export default DeleteActionButton;
