import React from 'react';
import { Result } from 'antd';

const StripeFailed = () => {
  return (
    <Result
      status="500"
      title="Sorry, something went wrong!"
      className="mt-[12%]"
    />
  );
};

export default StripeFailed;
