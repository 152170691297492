/* eslint-disable default-case */
import React, { useContext, useEffect, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button,
  Modal,
  Form,
  Avatar,
  Upload,
  message,
  Select,
  Radio,
  Space,
} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import CustomButton from 'components/Button';
import AntFormDatePicker from 'components/shared/AntFormDatePicker/AntFormDatePicker';
import AntFormInput from 'components/shared/AntFormInput/AntFormInput';
import { CHILDREN_MODAL_TYPE } from 'constants/strings';
import { genderOptions } from 'enums';
import { FormRule } from 'enums/formRules';
import { LANGUAGES_ARRAY } from 'enums/languages';
import { addChildByParent } from 'requests';
import { userContext } from 'contexts/user';
import { notificationsContext } from 'contexts/notifications';
import LocationInput from 'components/shared/LocationInput/LocationInput';

const ChildrenManagementModal = ({
  isModalOpen,
  setIsModalOpen,
  childInfo,
  modalType,
  setRefreshTable,
}) => {
  const { user } = useContext(userContext);
  const { api } = useContext(notificationsContext);
  const parentId = user.id;

  const [form] = Form.useForm();
  const [profileImage, setProfileImage] = useState(null);
  const [addressValue, setAddressValue] = useState('');
  const [placeDetail, setPlaceDetail] = useState();

  useEffect(() => {
    if (placeDetail) {
      form.setFieldsValue({
        city: placeDetail?.city,
        state: placeDetail?.state,
        country: placeDetail?.country,
        zipcode: placeDetail?.zipCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeDetail]);

  const { DELETE, ADD } = CHILDREN_MODAL_TYPE;

  const onFormSubmit = async (values) => {
    try {
      const updatedData = {
        ...values,
        address_one_line: placeDetail?.formatted_address,
        profile_picture: profileImage,
      };
      await addChildByParent(parentId, updatedData);
      setIsModalOpen(false);
      setRefreshTable((prev) => !prev);
      setProfileImage(null);
      api.success({
        message: 'Success',
        description: 'Child added successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong',
        placement: 'top',
        duration: 4,
      });
    } finally {
      form.resetFields();
    }
  };
  const onChangeImage = ({ file }) => {
    setProfileImage(file);
  };

  let renderModal;
  switch (modalType) {
    case DELETE:
      renderModal = (
        <Modal
          open={isModalOpen}
          footer={false}
          width={350}
          onCancel={() => setIsModalOpen((prev) => !prev)}
        >
          <p className="text-xl font-semibold">Delete Child</p>
          <p className="mt-4 text-xs text-[#637381]">
            Are you sure you want to remove{' '}
            {`${childInfo.first_name} ${childInfo.last_name}`} as a child?
          </p>

          <div className="mb-2 mt-7 flex w-full justify-between">
            <Button
              type="primary"
              ghost
              className="h-10 w-36 rounded-full !border-[#3056D3] !text-[#3056D3]"
              onClick={() => setIsModalOpen((prev) => !prev)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="h-10 w-36 rounded-full bg-[#212B36] !text-[#FFFFFF] hover:!bg-[#2e3c4b]"
              onClick={() => setIsModalOpen((prev) => !prev)}
            >
              Delete
            </Button>
          </div>
        </Modal>
      );
      break;
    case ADD:
      renderModal = (
        <Modal
          open={isModalOpen}
          footer={false}
          width={700}
          onCancel={() => {
            form.resetFields();
            setIsModalOpen((prev) => !prev);
          }}
        >
          <div className="w-full px-7 py-5">
            <Form
              form={form}
              layout="vertical"
              onFinish={onFormSubmit}
              initialValues={{ is_student_disable: false }}
              className="my-7"
            >
              <div className="flex justify-center pb-5">
                <div className="flex flex-col items-center justify-center gap-1">
                  <p className="mb-7 text-3xl font-bold">Add child details</p>
                  <Avatar size={100} icon={<UserOutlined />} />
                </div>
              </div>
              <Form.Item name="picture">
                <div className="flex justify-center pb-5">
                  <Upload
                    maxCount={1}
                    className="upload-buttontext-white"
                    beforeUpload={(file) => {
                      const isImage =
                        file.type === 'image/png' ||
                        file.type === 'image/jpg' ||
                        file.type === 'image/jpeg';
                      if (!isImage) {
                        message.error(`${file.name} is not an image file`);
                        return Upload.LIST_IGNORE;
                      } else {
                        return false;
                      }
                    }}
                    onChange={onChangeImage}
                  >
                    <Button
                      type="primary"
                      ghost
                      className="flex h-8 w-36 items-center justify-center rounded-full !border-[#3056D3] !text-[#3056D3]"
                      icon={<UploadOutlined className="" />}
                    >
                      Upload picture
                    </Button>
                  </Upload>
                </div>
              </Form.Item>

              <AntFormInput
                name="first_name"
                rules={FormRule.FIRSTNAME}
                label={<div className="font-medium">First name</div>}
                placeholder="First name"
                className={`w-full`}
                size="large"
              />
              <AntFormInput
                name="last_name"
                rules={FormRule.LASTNAME}
                label={<div className="font-medium">Last name</div>}
                placeholder="Last name"
                className={`w-full`}
                size="large"
              />
              <AntFormDatePicker
                name="date_of_birth"
                type="date"
                rules={FormRule.none}
                label={<div className="font-medium">Date of Birth</div>}
                placeholder="Date of Birth"
                className={`w-full`}
                size="large"
              />
              <Form.Item name="gender" label="Gender">
                <Select
                  options={genderOptions}
                  className="w-full"
                  placeholder="Select gender"
                  size="large"
                />
              </Form.Item>
              <Form.Item name="languages" label="Languages">
                <Select
                  className="w-full"
                  mode="multiple"
                  placeholder="Select languages"
                >
                  {LANGUAGES_ARRAY.map((language) => (
                    <Select.Option value={language.name}>
                      {language.name} ({language.nativeName})
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="is_student_disable"
                label="Does the child have any disability?"
              >
                <Radio.Group>
                  <Space direction="vertical">
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>

              <Form.Item name="address">
                <LocationInput
                  name="address_one_line"
                  value={addressValue}
                  onChange={setAddressValue}
                  isEditMode={true}
                  disabled={false}
                  setPlaceDetail={setPlaceDetail}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="city" label="City">
                <AntFormInput
                  disabled
                  className="w-full"
                  placeholder="Select city"
                  size="large"
                  name={'city'}
                />
              </Form.Item>
              <Form.Item name="state" label="State">
                <AntFormInput
                  disabled
                  className="w-full"
                  placeholder="Select state"
                  size="large"
                  name={'state'}
                />
              </Form.Item>
              <Form.Item name="zipcode" label="Zipcode">
                <AntFormInput
                  disabled
                  name="zipcode"
                  placeholder="Enter Zip code"
                  className={`w-full`}
                  size="large"
                />
              </Form.Item>
              <Form.Item name="country" label="Country">
                <AntFormInput
                  disabled
                  name={'country'}
                  className="w-full"
                  placeholder="Enter country"
                  size="large"
                />
              </Form.Item>
              <div className={`flex justify-end gap-4`}>
                <CustomButton className="!h-10">Add Child</CustomButton>
              </div>
            </Form>
          </div>
        </Modal>
      );

      break;
  }

  return renderModal;
};

export default ChildrenManagementModal;
