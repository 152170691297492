import { Form } from 'antd';
import AntFormCheckbox from 'components/shared/AntFormCheckbox/AntFormCheckbox';
import AntFormDatePicker from 'components/shared/AntFormDatePicker/AntFormDatePicker';
import AntFormInput from 'components/shared/AntFormInput/AntFormInput';
import FormModal from 'components/shared/AntFormModal/AntFormModal';
import AntFormSelect from 'components/shared/AntFormSelect/AntFormSelect';
import AntFormSubmit from 'components/shared/AntFormSubmit/AntFormSubmit';
import dayjs from 'dayjs';
import { schoolGrades } from 'enums';
import { FormRule } from 'enums/formRules';
import moment from 'moment';
import React from 'react';

const EditSchoolModal = ({
  isEditSchoolModalOpen,
  setIsEditSchoolModalOpen,
  isLoading,
  setIsLoading,
  handleEditSchool,
  handleCancel,
  selectedSchool,
  onChangeCheckbox,
  isContinueSelected,
}) => {
  const updatedInitialValues = {
    school_name: selectedSchool && selectedSchool.school_name,
    grades: selectedSchool && selectedSchool.grades,
    city: selectedSchool && selectedSchool.city,
    state: selectedSchool && selectedSchool.state,
    attended_from:
      selectedSchool && selectedSchool.attended_from
        ? dayjs(
            moment(selectedSchool.attended_from).format('MMMM D YYYY'),
            'MMMM D YYYY'
          )
        : null,
    attended_till:
      selectedSchool && selectedSchool.attended_till !== 'Ongoing'
        ? dayjs(
            moment(selectedSchool.attended_till).format('MMMM D YYYY'),
            'MMMM D YYYY'
          )
        : dayjs(moment().format('MMMM D YYYY'), 'MMMM D YYYY'),
  };
  return (
    <div>
      {selectedSchool !== null && (
        <FormModal
          title={<div className="text-xl font-bold">Edit School</div>}
          isModalOpen={isEditSchoolModalOpen}
          setIsModalOpen={setIsEditSchoolModalOpen}
          setIsLoading={setIsLoading}
          handleCancel={handleCancel}
        >
          <Form
            className="mt-7"
            layout="vertical"
            onFinish={handleEditSchool}
            initialValues={updatedInitialValues}
          >
            <AntFormInput
              label={<div className="font-medium">School Name</div>}
              rules={FormRule.SCHOOL_NAME}
              name="school_name"
              placeholder="Enter your school name"
              className="w-full"
            />

            <AntFormSelect
              label={<div className="font-medium">Grades</div>}
              rules={FormRule.none}
              name="grades"
              showArrow={true}
              options={schoolGrades}
              placeholder="Select your grades (Checkboxes)"
              className=" w-full border border-gray-300"
              size="small"
              mode="multiple"
            />

            <AntFormInput
              label={<div className="font-medium">City</div>}
              rules={FormRule.CITY}
              name="city"
              placeholder="Choose your city"
              className="w-full"
            />
            <AntFormInput
              label={<div className="font-medium">State</div>}
              rules={FormRule.STATE}
              name="state"
              placeholder="Choose your state"
              className="w-full"
            />
            <AntFormDatePicker
              label={<div className="font-medium">Attended From</div>}
              rules={FormRule.none}
              name="attended_from"
              placeholder="Select Date"
              className={'w-full'}
              showArrow={true}
            />
            <AntFormDatePicker
              label={<div className="font-medium">Attended Till</div>}
              name="attended_till"
              rules={FormRule.none}
              placeholder="Select date"
              className="w-full"
              showArrow={true}
              disabled={isContinueSelected}
            />
            <AntFormCheckbox
              name="is_continue"
              initialValue={selectedSchool.is_continue}
              rules={FormRule.none}
              label="Continue"
              onChangeCheckbox={onChangeCheckbox}
            />
            <AntFormSubmit
              loading={isLoading}
              label={<div className="font-bold">Update</div>}
              disabled={isLoading}
              className="flex w-full items-center justify-center rounded-2xl bg-updatedBlue px-5 py-4 text-white"
            />
          </Form>
        </FormModal>
      )}
    </div>
  );
};

export default EditSchoolModal;
