import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const EditAndDeleteActionButtons = ({ onEdit, onDelete, record }) => {
  return (
    <div className="flex items-center justify-evenly">
      <CustomButton
        className="w-1 bg-updatedBlue text-white hover:bg-updatedBlue"
        icon={<EditOutlined />}
        onClick={() => onEdit(record)}
      />
      <CustomButton
        className="w-1 bg-updatedPink text-white hover:!bg-updatedPink"
        icon={<DeleteOutlined />}
        onClick={() => onDelete(record)}
      />
    </div>
  );
};

export default EditAndDeleteActionButtons;
