import dayjs from 'dayjs';

const getInitialValuesTutorGeneralProfile = (isTutorRecord) => {
  return {
    first_name: isTutorRecord ? isTutorRecord.first_name : null,
    last_name: isTutorRecord ? isTutorRecord.last_name : null,
    email: isTutorRecord ? isTutorRecord.email : null,
    phone_number: isTutorRecord ? isTutorRecord.phone_number : null,
    date_of_birth: isTutorRecord
      ? isTutorRecord.date_of_birth
        ? dayjs(isTutorRecord.date_of_birth)
        : null
      : null,
    gender: isTutorRecord ? isTutorRecord.gender : null,
    address_one_line: isTutorRecord ? isTutorRecord.address_one_line : null,
    city: isTutorRecord ? isTutorRecord.city : null,
    state: isTutorRecord ? isTutorRecord.state : null,
    zipcode: isTutorRecord ? isTutorRecord.zipcode : null,
    country: isTutorRecord ? isTutorRecord.country : null,
    profile_picture: isTutorRecord ? isTutorRecord.profile_picture : null,
  };
};

export default getInitialValuesTutorGeneralProfile;
