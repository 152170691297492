import axiosInstance from 'requests/config';

export const login = (email, password, role) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/sessions/login', {
        email,
        password,
        role,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginOther = (email, password) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/sessions/login', {
        email,
        password,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
