const { STUDENT, TUTOR, ADMIN } = require('constants/roles');

export const viewStoreFront = (id, role) => {
  if (role === STUDENT) {
    return `/student/find-tutors/${id}`;
  } else if (role === TUTOR) {
    return `/${role}/find-tutors/${id}`;
  } else if (role === ADMIN) {
    return `/admin/tutor/${id}/storefront`;
  }
};
