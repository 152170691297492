import React, { useContext, useState, useRef, useEffect } from 'react';
import Sidebar from 'components/tutor/ProfileSidebar';
import { Modal } from 'antd';
import { userContext } from 'contexts/user';
import { notificationsContext } from 'contexts/notifications';
import { deacticateAccount } from 'requests';
import { useNavigate } from 'react-router-dom';
import Navbar from 'components/shared/Navbar/Navbar';
import { TUTOR } from 'constants/roles';
import { tutorNavbarItems } from 'enums';

const Layout = ({ children }) => {
  const {
    user: { id: tutorId },
    logout,
  } = useContext(userContext);
  const { api } = useContext(notificationsContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const targetDivRef = useRef(null);

  useEffect(() => {
    if (targetDivRef.current && window.innerWidth <= 768) {
      targetDivRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [targetDivRef]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleDeacticateAccountButton = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await deacticateAccount(tutorId);
      logout();
      navigate('/tutor/login');
    } catch (err) {
      api.error({
        message: 'Error',
        description:
          'Something went wrong while deactivating the account. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <>
      <Modal
        width={'max-content'}
        onCancel={handleCancel}
        open={isModalVisible}
        footer={null}
      >
        <div className="p-7">
          <div className="text-2xl font-semibold text-updatedBlue">
            Deactivate Account
          </div>
          <div className="mt-8 w-full">
            <div className="text-base font-medium text-updatedBlue">
              Are you sure you want to deactivate this account?
            </div>
          </div>

          <div className="flex flex-row items-center justify-between gap-8">
            <button
              className={`mt-12 h-12 w-full flex-1 items-center justify-center rounded-3xl border-2 border-updatedBlue text-updatedBlue`}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              className={`mt-12 h-12 w-full flex-1 items-center justify-center rounded-3xl bg-gray-800 text-white`}
              onClick={handleOk}
            >
              Confirm
            </button>
          </div>
        </div>
      </Modal>
      <div className="min-h-screen bg-updatedGreenish bg-cover">
        <Navbar role={TUTOR} navItems={tutorNavbarItems} />
        <div className="flex gap-5 xs:flex-col md:flex-row">
          <Sidebar
            handleDeacticateAccountButton={handleDeacticateAccountButton}
          />
          <div
            ref={targetDivRef}
            className="mx-5 my-6 flex-1 rounded-2xl bg-updatedMustard shadow xs:p-5 md:p-20"
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
