import React from 'react';
import { Input, Form } from 'antd';
const { TextArea } = Input;

function AntFormTextArea({
  label,
  name,
  size = 'large',
  rows = 4,
  maxLength = 250,
  rules = {},
  placeholder,
  disabled,
  className = '',
  style = {},
  tooltip,
  showCount = false,
}) {
  return (
    <>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        tooltip={tooltip}
        normalize={(value) => value && value.replace(/\n/g, '\r\n')}
      >
        <TextArea
          rows={rows}
          size={size}
          maxLength={maxLength}
          style={style}
          className={`rounded-lg ${className ? className : ''}`}
          placeholder={placeholder}
          disabled={disabled}
          showCount={showCount}
        />
      </Form.Item>
    </>
  );
}

export default AntFormTextArea;
