const { default: axiosInstance } = require('./config');

export const getGeneralAvailability = async (tutorId) =>
  axiosInstance.get(`/tutors/${tutorId}/generalAvailabilities`);

export const postGeneralAvailability = async (tutorId, data) =>
  axiosInstance.post(`/tutors/${tutorId}/generalAvailabilities`, data);

export const deleteGeneralAvailability = async (
  tutorId,
  generalAvailabilityId
) =>
  axiosInstance.delete(
    `/tutors/${tutorId}/generalAvailabilities/${generalAvailabilityId}`
  );

export const updateGeneralAvailability = async (
  tutorId,
  generalAvailabilityId,
  data
) =>
  axiosInstance.put(
    `/tutors/${tutorId}/generalAvailabilities/${generalAvailabilityId}`,
    data
  );
