import React from 'react';
import TutorGeneralProfileSidebar from 'components/admin/tutor-general-profile-sidebar';

const TutorDetailsLayout = ({ children }) => {
  return (
    <div className="flex flex-row bg-violet-50">
      <TutorGeneralProfileSidebar />
      <div className="mx-8 mt-14 flex items-start justify-center gap-2.5 rounded-2xl bg-updatedCreme p-20 shadow md:h-fit md:w-full">
        {children}
      </div>
    </div>
  );
};

export default TutorDetailsLayout;
