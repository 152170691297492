import React, { useEffect, useState } from 'react';
import { ReactComponent as MathIcon } from 'assets/images/tutor/math.svg';
import moment from 'moment';
import { TUTOR } from 'constants/roles';

const SessionCard = ({ event, role }) => {
  const [timeZone, setTimeZone] = useState('');

  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  }, []);

  return (
    <div className="my-5 grid rounded-xl border bg-customCreme py-5 xs:grid-cols-1 xs:gap-5 sm:grid-cols-3 md:grid-cols-3 md:gap-0">
      <div className="flex flex-row items-center  gap-5 pl-5 xs:h-auto xs:border-b xs:pb-3 sm:pb-0 md:h-full md:border-r">
        <div className="xs:hidden lg:block">
          <MathIcon className="h-16 w-16 rounded-full" />
        </div>
        <div>
          {role !== TUTOR ? (
            <div>
              <span className="font-bold">Tutor Email</span>
              <div className="leading-6">{event?.tutor_email}</div>
              <span className="font-bold">Student ID</span>
              <div className="leading-6">{event?.student_id}</div>
            </div>
          ) : (
            <div>
              <span className="font-bold">Student ID</span>
              <div className="leading-6">{event?.student_id}</div>
              <span className="font-bold">Student name</span>
              <div className="leading-6">{event?.student_name}</div>
            </div>
          )}
          <div className="leading-6">
            <b>Agenda : </b>
            {event.agenda}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center rounded-r-none border-r pl-5">
        <div className="flex gap-5">
          <div className="font-bold">Date</div>
          <div className="text-gray-500">
            {moment(event.start_time).format('MMMM Do, YYYY')}
          </div>
        </div>
        <div className="flex gap-5">
          <div className="font-bold">Time</div>
          <div className="text-gray-500">
            {moment(event.start_time).format('LT')} {timeZone}
          </div>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center xs:gap-2 md:gap-5">
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={event.link} target="_blank">
          <div className="rounded-3xl bg-updatedBlue px-5 py-2 text-white">
            View In Calendar
          </div>
        </a>
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href={event.link} target="_blank">
          <div className="rounded-3xl border border-[#DA00A1] bg-updatedCreme px-5 py-2 text-[#DA00A1]">
            Session Notes
          </div>
        </a>
      </div>
    </div>
  );
};

export default SessionCard;
