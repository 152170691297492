import React from 'react';
import backgroundImg from '../../../assets/images/tutor/background.png';
import UpcomingSessionTabs from '../UpcomingSessionTabs/UpcomingSessionTabs';
import PastSessionList from '../PastSessionList/PastSessionList';

const Sessions = ({ role, userId }) => (
  <div className="bg-updatedGreenish">
    <div className="px-10 py-5">
      <div className="my-5 grid xs:grid-cols-1 md:grid-cols-12 md:gap-6">
        <div className="grid gap-6 rounded-xl xs:grid-cols-1 md:col-span-8 md:grid-cols-3">
          <div className="mb-5 rounded-xl bg-updatedMustard px-5 md:col-span-3">
            <UpcomingSessionTabs role={role} userId={userId} />
          </div>
        </div>
        <div className="col-span-4">
          <PastSessionList role={role} userId={userId} />
        </div>
      </div>
    </div>
  </div>
);

export default Sessions;
