import { Drawer } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import CustomButton from '../CustomButton/CustomButton';

const MobileNavbar = ({
  isMobileOptionsOpen,
  setIsMobileOptionsOpen,
  navItems,
  isImpersonating,
  selectedItem,
  handleItemClick,
  role,
  handleLogout,
}) => {
  return (
    <Drawer
      title="Dashboard"
      placement="right"
      onClose={() => {
        setIsMobileOptionsOpen(false);
      }}
      open={isMobileOptionsOpen}
      width={320}
    >
      {navItems.map((item) => (
        <Link
          key={item.key}
          to={`/${isImpersonating ? 'parent' : role}/${item.key}`}
          className={`mb-4 flex items-center gap-2 border-b-black ${
            selectedItem === item.key ? 'text-updatedBlue' : ''
          }`}
          onClick={() => handleItemClick(item)}
        >
          {item.logo} {item.label}
        </Link>
      ))}
      <div className="mt-16">
        <CustomButton
          label="Logout"
          size="large"
          shape="default"
          className="w-full bg-red-500 font-bold text-white"
          buttonWidth="w-full"
          onClick={handleLogout}
        />
      </div>
    </Drawer>
  );
};

export default MobileNavbar;
