import { TUTOR } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import moment from 'moment';
import React, { useContext, useState, useEffect } from 'react';
import { getPaid } from 'requests/stripe';

const PastSessionCard = ({ event, role, userId }) => {
  const [timeZone, setTimeZone] = useState('');
  useEffect(() => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone);
  }, []);

  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(event.status === 'paid');
  const { api } = useContext(notificationsContext);

  const onGetPaid = async () => {
    setLoading(true);
    try {
      await getPaid(userId, event.id);
      setPaid(true);
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong in getting paid.',
        placement: 'top',
        duration: 4,
      });
    }
    setLoading(false);
  };

  return (
    <div className="mt-5 flex items-center justify-center gap-5 border-b pb-5 xs:flex-col lg:flex-row">
      <div className="flex-[3]">
        <div className="mb-2 flex flex-col items-start justify-between">
          {role !== TUTOR ? (
            <div>
              <span className="font-bold">Tutor Email</span>
              <div className="leading-6">{event?.tutor_email}</div>
              <span className="font-bold">Student ID :</span>{' '}
              {event?.student_id}
            </div>
          ) : (
            <div>
              <div className="leading-6">
                <span className="font-bold">Student ID :</span>{' '}
                {event?.student_id}
              </div>

              <div className="leading-6">
                <span className="font-bold">Student name :</span>{' '}
                {event?.student_name}
              </div>
            </div>
          )}
          <div className="break-keep leading-6 text-gray-500">
            <b>Agenda : </b>
            {event.agenda}
          </div>
        </div>

        <div className="flex flex-row items-center gap-3">
          <p className="font-bold">Date</p>
          <p className="text-gray-500">
            {moment(event.start_time).format('MMMM Do, YYYY')}
          </p>
        </div>

        <div className="flex flex-row items-center gap-3 ">
          <p className="font-bold">Time</p>
          <p className="text-gray-500">
            {moment(event.start_time).format('LT')} {timeZone}
          </p>
        </div>
      </div>
      <div className="flex flex-[2] flex-col items-center justify-center gap-5">
        {/* Commented just for now */}
        {role === TUTOR && (
          <div className="flex items-center justify-end">
            <button
              onClick={onGetPaid}
              disabled={loading || paid}
              className="w-36 rounded-3xl bg-updatedBlue py-2 text-center text-white disabled:cursor-not-allowed disabled:opacity-50"
            >
              {loading ? 'Loading...' : paid ? 'Paid' : 'Get Paid'}
            </button>
          </div>
        )}
        <div className="flex items-center justify-end">
          <a href={event.link} target="_blank" rel="noreferrer">
            <div className="w-36 rounded-3xl border border-updatedPink bg-updatedCreme py-2 text-center text-updatedPink">
              Session Notes
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PastSessionCard;
