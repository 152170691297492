/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import { getTutorById } from 'requests/tutors';
import { useNavigate, useParams } from 'react-router-dom';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';

const BankDetails = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [isStripeConnected, setIsStripeConnected] = useState(false); // New state to track Stripe connection
  const { id: tutorId } = useParams();

  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);

      // Check if stripe_connect_account_id is present and update isStripeConnected
      if (data.stripe_connect_account_id) {
        setIsStripeConnected(true);
      } else {
        setIsStripeConnected(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TutorDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <img
          className="h-[120px] w-[120px] rounded-full"
          src={!!image ? image : profile}
          alt="image"
        />
        <div className="mt-5 text-2xl font-semibold text-updatedBlue">
          {name}
        </div>
        <Button
          className="mt-4"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>

        <div className="mt-16 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Bank Details
          </div>
          <Button className="mb-48 mt-14 !bg-gray-400" disabled={true}>
            {isStripeConnected ? 'Stripe Connected' : 'Stripe not Connected'}
          </Button>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default BankDetails;
