import React, { useContext, useState } from 'react';
import SessionList from '../SessionList/SessionList';
import { userContext } from 'contexts/user';
import { useNavigate } from 'react-router-dom';
import { TUTOR } from 'constants/roles';

const UpcomingSessionTabs = ({ role, userId }) => {
  const { getGoogleCalendarToken } = useContext(userContext);
  const [hasCalendarToken] = useState(!!getGoogleCalendarToken());
  const navigate = useNavigate();

  return (
    <div className="mt-4">
      <div className="text-xl font-semibold">Scheduled Sessions</div>
      {hasCalendarToken ? (
        <SessionList role={role} userId={userId} />
      ) : (
        <div className="mt-10 grid gap-5 xs:grid-cols-1 md:grid-cols-2">
          <div className="flex items-center justify-start text-xl font-semibold">
            Connect your Google Calendar to view your sessions
          </div>
          <div className="flex items-center xs:justify-center md:justify-end">
            <button
              onClick={() => {
                navigate(
                  role === TUTOR
                    ? '/tutor/connect-calendar'
                    : '/parent/connect-calendar'
                );
              }}
              className="m-5 w-44 rounded-3xl bg-updatedBlue py-2 text-white"
            >
              Connect Calendar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingSessionTabs;
