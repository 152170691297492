import React, { useContext } from 'react';
import backgroundImg from '../../../assets/images/tutor/background.png';
import UpcomingSessionTabs from '../UpcomingSessionTabs/UpcomingSessionTabs';
import PastSessionList from '../PastSessionList/PastSessionList';
import { userContext } from 'contexts/user';

const Calendar = ({ role, userId }) => {
  const { user } = useContext(userContext);

  const isStripeCustomerIdPresent = user.stripe_connect_account_id !== null;

  const {
    user: { google_appointment_link = '', account_status },
  } = useContext(userContext);

  const isAccountApproved = account_status === 'approved';

  return (
    <div className="bg-[#E4FDE1]">
      <div className="px-10 py-5">
        {/* Create Session button */}
        <div className="flex items-center xs:justify-center md:justify-end">
          {isAccountApproved && isStripeCustomerIdPresent ? (
            <a href={google_appointment_link} target="_blank" rel="noreferrer">
              <div className="w-48 rounded-3xl bg-updatedBlue py-2 text-center text-lg text-white">
                Create Session
              </div>
            </a>
          ) : (
            <div className="w-48 cursor-not-allowed rounded-3xl bg-[#9ca3af] py-2 text-center text-lg text-white">
              Create Session
            </div>
          )}
        </div>

        <div className="my-5 grid gap-6 xs:grid-cols-1 md:grid-cols-12">
          {/* Scheduled Sessions */}
          <div className="grid grid-cols-3 gap-6 rounded-xl md:col-span-8">
            <div className="col-span-3 rounded-xl  bg-updatedMustard px-5">
              <UpcomingSessionTabs role={role} userId={userId} />
            </div>
          </div>
          {/* Past Sessions */}
          <div className="md:col-span-4">
            <PastSessionList role={role} userId={userId} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
