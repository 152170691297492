import React, { useContext, useEffect, useState } from 'react';
import Layout from 'components/tutor/ProfileLayout';
import { userContext } from 'contexts/user';
import useGoogleAuthConsent from 'utils/oauthGoogle';
import { ReactComponent as GoogleLogo } from 'assets/images/google.svg';
import { notificationsContext } from 'contexts/notifications';
import { updateAppointmentLink } from 'requests';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import GoogleConsentModal from 'components/Modal/GoogleConsentModal';
import { ReactComponent as GoogleIcon } from 'assets/images/GoogleIcon.svg';
import { Button } from 'antd';

const TutorGeneralAvailability = () => {
  const { getGoogleCalendarToken } = useContext(userContext);
  const connectGoogle = useGoogleAuthConsent();
  const {
    user: { google_appointment_link, id: userId, account_status },
    updateUser,
  } = useContext(userContext);
  const navigate = useNavigate();

  const [appointmentLink, setAppointmentLink] = useState(
    google_appointment_link || ''
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { api } = useContext(notificationsContext);

  const checkCalendarConnection = () => {
    return !!getGoogleCalendarToken();
  };

  const [isCalendarConnected, setIsCalendarConnected] = useState(
    checkCalendarConnection
  );

  useEffect(() => {
    window.addEventListener('googleCalendarTokenChanged', () => {
      setIsCalendarConnected(checkCalendarConnection());
    });

    return () =>
      window.removeEventListener('googleCalendarTokenChanged', () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [linkError, setLinkError] = useState(false);
  const googleCalendarRegex =
    /^(https:\/\/calendar\.app\.google\/|https:\/\/calendar\.google\.com\/calendar\/appointments\/schedules)/;

  const updateGoogleAppointmentLink = async () => {
    // Check if the entered value has the expected format
    if (googleCalendarRegex.test(appointmentLink)) {
      try {
        await updateAppointmentLink(userId, appointmentLink);
        await updateUser();
        setLinkError(false); // Clear any previous error
        api.success({
          message: 'Success',
          description: 'Google Appointment Link Updated Successfully',
          placement: 'top',
          duration: 4,
        });
      } catch (err) {
        api.error({
          message: 'Error',
          description:
            'Something went wrong. Please try again after some time.',
          placement: 'top',
          duration: 4,
        });
      }
    } else {
      // Show an error if the link doesn't match the expected format
      setLinkError(true);
    }
  };

  return (
    <Layout>
      <div className="flex  w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Connect Calendar
          </div>

          <div>
            {account_status === 'approved' && (
              <button
                type="button"
                className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                onClick={() => {
                  const navigateString = viewStoreFront(userId, TUTOR);
                  navigate(navigateString);
                }}
              >
                View Storefront
              </button>
            )}
          </div>
        </div>
        <div className="mt-5 grid w-full border-t border-gray-500 border-opacity-25 pt-7 xs:grid-cols-1 xs:gap-10 md:gap-3 lg:grid-cols-2">
          {/* Integrate Google Account */}
          <div className="flex flex-col justify-center border-gray-500 border-opacity-25 xs:items-center xs:border-b xs:pb-10 sm:border-0 md:items-start md:border-r">
            <div className="text-lg font-semibold text-updatedBlue">
              Integration Google Account<span className="text-red-800">*</span>
            </div>
            <div className="mt-10">
              {isCalendarConnected ? (
                <div className="flex flex-row items-center gap-4">
                  <GoogleLogo />
                  <div className="flex flex-row items-center gap-2">
                    <div className="h-3 w-3 rounded-full bg-[#13C296]" />
                    <div>Connected</div>
                  </div>
                </div>
              ) : (
                <>
                  <GoogleConsentModal
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                    connectGoogle={connectGoogle}
                  />
                  <Button
                    type=""
                    ghost
                    shape="round"
                    className="flex w-auto border-black bg-updatedCreme p-5 text-updatedBlue"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <GoogleIcon height="35px" className="self-center" />
                    <p className="self-center text-sm text-updatedBlue">
                      Continue with Google
                    </p>
                  </Button>
                </>
              )}
            </div>
          </div>
          {/* Connect Google Calendar Information */}
          <div className="border-gray-500 border-opacity-25 pb-10 xs:border-b xs:pb-10 sm:border-0">
            <p className="mx-5">
              <span className="font-semibold">
                Guide to integrate Google Calendar
              </span>
              <div className="youtube-video mt-3">
                <iframe
                  className="h-60 w-full md:h-80 lg:h-60"
                  src="https://www.youtube.com/embed/iysmxdVLsTo"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                ></iframe>
              </div>
            </p>
          </div>
          {/* Google Appointment Link */}
          <div className="border-gray-500 border-opacity-25 xs:border-b xs:pb-5 sm:border-0 md:border-r md:border-t md:pt-7">
            <div className="text-lg font-semibold text-updatedBlue">
              Add Your Google Appointment Link
              <span className="text-red-800">*</span>
            </div>
            <div className="mt-7">Add Url</div>
            <textarea
              value={appointmentLink}
              onChange={(e) => setAppointmentLink(e.target.value)}
              placeholder="Enter Appointment Calendar link"
              className={`mt-3 h-[100px] rounded-lg border-2 border-neutral-200 bg-updatedCreme px-4 py-3.5 outline-none xs:w-full  ${
                linkError ? 'border-red-500' : ''
              }`}
            />
            {linkError && (
              <div className="mt-1 text-red-500">
                Please enter a valid Google Calendar link.
              </div>
            )}

            <div>
              <button
                onClick={() => updateGoogleAppointmentLink()}
                className={`mt-5 rounded-md ${
                  !!appointmentLink.length && !linkError
                    ? 'bg-updatedBlue'
                    : 'bg-updatedBlue'
                }   px-4 py-3 text-white`}
              >
                Save Link
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TutorGeneralAvailability;
