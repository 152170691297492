import { Loader } from '@googlemaps/js-api-loader';

let googleApiClient;

export default async function getGoogleMapsApiClient() {
  if (googleApiClient) {
    return googleApiClient;
  }

  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });

  googleApiClient = await loader.load();

  return googleApiClient;
}
