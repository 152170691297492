import React, { useContext, useEffect, useState } from 'react';
import { userContext } from 'contexts/user';
import useGoogleAuthConsent from 'utils/oauthGoogle';
import { ReactComponent as GoogleLogo } from 'assets/images/google.svg';
import { ReactComponent as GoogleIcon } from 'assets/images/GoogleIcon.svg';
import EditParentDetailsLayout from 'pages/parent-dashboard/edit-parent-details-layout';
import GoogleConsentModal from 'components/Modal/GoogleConsentModal';
import { Button } from 'antd';

const TutorGeneralAvailability = () => {
  const { getGoogleCalendarToken } = useContext(userContext);
  const connectGoogle = useGoogleAuthConsent();

  const checkCalendarConnection = () => {
    return !!getGoogleCalendarToken();
  };

  const [isCalendarConnected, setIsCalendarConnected] = useState(
    checkCalendarConnection
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    window.addEventListener('googleCalendarTokenChanged', () => {
      setIsCalendarConnected(checkCalendarConnection());
    });

    return () =>
      window.removeEventListener('googleCalendarTokenChanged', () => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <EditParentDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div
            style={{ fontFamily: 'Bogart' }}
            className="flex-1 text-2xl font-semibold text-updatedBlue"
          >
            Detailed Profile / Connect Calendar
          </div>
        </div>
        <div
          style={{ fontFamily: 'Now' }}
          className="mt-5 flex w-full gap-10 border-t border-gray-500 border-opacity-25 pt-7 xs:flex-col lg:flex-row"
        >
          <div className="h-60 flex-1 border-gray-500 border-opacity-25 pb-10 xs:border-b lg:border-b-0 lg:border-r">
            <div className="text-lg font-semibold text-updatedBlue">
              Integration Google Account
            </div>
            <div className="mt-10">
              {isCalendarConnected ? (
                <div className="flex flex-row items-center gap-4">
                  <GoogleLogo />
                  <div className="flex flex-row items-center gap-2">
                    <div className="h-3 w-3 rounded-full bg-[#13C296]" />
                    <div>Connected</div>
                  </div>
                </div>
              ) : (
                <>
                  <GoogleConsentModal
                    isModalOpen={isModalOpen}
                    handleCancel={handleCancel}
                    connectGoogle={connectGoogle}
                  />
                  <Button
                    type=""
                    ghost
                    shape="round"
                    className="flex w-auto border-black bg-updatedCreme p-5 text-updatedBlue"
                    onClick={() => setIsModalOpen(true)}
                  >
                    <GoogleIcon height="35px" className="self-center" />
                    <p
                      style={{ fontFamily: 'Now' }}
                      className="self-center text-sm text-updatedBlue"
                    >
                      Continue with Google
                    </p>
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="h-60 flex-1 ">
            <p className="mx-5">
              <span className="font-semibold">Note:</span> You can connect your
              Google Calendar to Skool’d to automatically sync your availability
              and appointments.
            </p>
            <div className="mx-5 mt-5">
              <div className="font-semibold">
                Steps to Connect Google Calendar:
              </div>
              <div>
                <div>
                  1. Click on "Integrate Google Calendar" button. It will open
                  google sign in popup.
                </div>
                <div>
                  2. Sign in with your google account which you want to connect
                </div>
                <div>
                  3. Click on "Allow" button to allow Skool'd to access your
                  Calendar and Events
                </div>
                <div>
                  4. You will be redirected to Skool'd. Now you can see your
                  connected google account.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditParentDetailsLayout>
  );
};

export default TutorGeneralAvailability;
