import axiosInstance from './config';

export const inviteUser = (
  { firstName, lastName, email, phoneNumber },
  role
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/invitations/inviteUserOrAdmin', {
        first_name: firstName,
        last_name: lastName,
        email: email,
        role,
        ...(!!phoneNumber && { phone_number: phoneNumber }),
      })
      .then(resolve)
      .catch(reject);
  });
};
