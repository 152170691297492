import React, { useContext } from 'react';
import StudentDetailsLayout from 'components/student/StudentDetailsLayout';
import { STUDENT } from 'constants/roles';
import { userContext } from 'contexts/user';
import SchoolProfilingDetails from 'pages/shared/SchoolProfilingDetails/SchoolProfilingDetails';
import { Layout } from 'antd';
import Navbar from 'components/shared/Navbar/Navbar';
import { studentNavbarItems } from 'enums';

const SchoolProfiling = () => {
  const { user } = useContext(userContext);

  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={studentNavbarItems} role={STUDENT} />
        <StudentDetailsLayout>
          <SchoolProfilingDetails studentId={user.id} mode={STUDENT} />
        </StudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default SchoolProfiling;
