import React from 'react';
import studentImage from 'assets/images/student/studentImage.jpg';
import OTPInput from 'react-otp-input';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { STRINGS } from 'constants/strings';
import { sendResetPasswordToken } from 'requests';
import cn from 'classnames';
import { PARENT, STUDENT, TUTOR } from 'constants/roles';
import tutorImage from '../assets/images/admin/tutor_sign_in.png';
import parentImage from '../assets/images/admin/parent_sign_in.png';

const VerifyCodeComponent = ({
  handleVerifyCode,
  className = '',
  email,
  role,
}) => {
  const [apiError, setApiError] = React.useState('');
  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .required(STRINGS.otp_required)
      .min(6, STRINGS.otp_invalid)
      .max(6, STRINGS.otp_invalid),
  });

  const handleResendCode = async () => {
    try {
      await sendResetPasswordToken(email);
    } catch (err) {
      alert("Can't send OTP");
    }
  };

  let handleVerifyCodeButton = async (values) => {
    setApiError('');
    try {
      await handleVerifyCode(values);
    } catch (err) {
      setApiError('Verification code does not match');
    }
  };
  if (!email) {
    <div>Invalid Url</div>;
  }

  const [username, domain] = email?.split('@');
  const protectedEmail =
    username.substring(0, 2) +
    username.substring(2).replace(/./g, '*') +
    domain;

  return (
    <div
      className={cn(
        'flex h-screen w-screen justify-center pt-10 md:items-center md:pt-0',
        className
      )}
    >
      <div className="flex aspect-[1] h-[600px] overflow-hidden rounded-3xl bg-updatedCreme shadow-custom-1 lg:aspect-[1.7]">
        <Formik
          initialValues={{
            otp: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleVerifyCodeButton}
          validateOnChange={false}
        >
          {({ handleChange, handleSubmit, values }) => (
            <div className="flex flex-1 flex-col items-center p-6 sm:p-14 md:p-[60px]">
              <div className="h-8 w-full text-3xl font-bold leading-9 text-updatedBlue">
                Verify code
              </div>
              <div className="mt-10 h-24 w-full">
                <div className="text-base font-normal leading-normal text-gray-500">
                  Please confirm your account by entering the authentication
                  code sent to you on {protectedEmail}
                </div>
              </div>
              <div className="mt-2 w-full">
                <OTPInput
                  containerStyle={{ justifyContent: 'space-between' }}
                  inputStyle={{
                    width: 50,
                    height: 50,
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 15,
                    paddingBottom: 15,
                    background: 'white',
                    borderRadius: 8,
                    border: '1px #E0E0E0 solid',
                  }}
                  value={values.otp}
                  onChange={handleChange('otp')}
                  numInputs={6}
                  renderInput={(props) => <input {...props} />}
                />
              </div>

              <button
                className={`mt-9 h-12 w-full items-center justify-center rounded-3xl ${
                  role === STUDENT ? 'bg-updatedBlue' : 'bg-updatedBlue'
                } text-center font-semibold text-white`}
                type="button"
                onClick={handleSubmit}
              >
                Next
              </button>
              <div className="mt-1 h-5 w-full">
                {!!apiError && <div className=" text-red-500">{apiError}</div>}
              </div>
              <Link
                className="mt-8 text-base font-normal text-updatedBlue underline"
                onClick={handleResendCode}
              >
                Resend Code?
              </Link>
            </div>
          )}
        </Formik>

        <img
          className="hidden h-full w-1/2 lg:block"
          src={
            role === TUTOR
              ? tutorImage
              : role === PARENT
                ? parentImage
                : studentImage
          }
          alt="login_image"
        />
      </div>
    </div>
  );
};

export default VerifyCodeComponent;
