const { default: axiosInstance } = require('./config');

export const getAllSubjects = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/subjects`)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addSubject = ({ title }) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/subjects`, { title })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editSubject = ({ id, title, is_hidden }) => {
  let values = {};
  if (title !== undefined) values = { title };
  if (is_hidden !== undefined) values = { ...values, is_hidden };

  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`/subjects/${id}`, values)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Other Subjects tab

export const getMappedSubjects = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/mappedSubjects`)
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addMappedSubject = ({ title }) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/mappedSubjects`, { title })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const editMappedSubject = ({ id, subject_id }) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`/mappedSubjects/${id}`, { subject_id })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
