import {
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import adminRoutes from 'routes/admin.routes';
import tutorRoutes from 'routes/tutor.routes';
import generalRoutes from 'routes/general.routes';
import parentRoutes from 'routes/parent.routes';
import studentRoutes from 'routes/student.routes';

const router = createBrowserRouter(
  createRoutesFromElements([
    generalRoutes,
    adminRoutes,
    tutorRoutes,
    parentRoutes,
    studentRoutes,
  ])
);

export default router;
