import { useContext, useState } from 'react';
import { Modal as AntdModal } from 'antd';
import { deleteTutorSubject } from 'requests/subjects';
import { notificationsContext } from 'contexts/notifications';

const UseDeleteSubjectModal = (tutorId, callback) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { api } = useContext(notificationsContext);
  const [subjectId, setSubjectId] = useState(null);

  const showModal = (subjectId) => {
    setSubjectId(subjectId);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async () => {
    try {
      await deleteTutorSubject(tutorId, subjectId);
      api.success({
        message: 'Success',
        description: 'Subject deleted successfully',
        placement: 'top',
        duration: 4,
      });
      if (callback) callback();
    } catch (err) {
      api.error({
        message: 'Error',
        description:
          'Something went wrong while adding the subject. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    } finally {
      handleCancel();
    }
  };

  const DeleteSubjectModal = ({ ...props }) => {
    return (
      <AntdModal
        width={'max-content'}
        {...props}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <div className="xs:w-full xs:p-2 md:w-[500px] md:p-7">
          <div className="text-2xl font-semibold text-updatedBlue">
            Delete Subject
          </div>
          <div className="mt-8 w-full">
            <div className="text-base font-medium text-updatedBlue">
              Are you sure you want to delete this subject?
            </div>
          </div>

          <div className="flex flex-row flex-wrap items-center justify-between gap-8">
            <button
              className={`mt-12 h-12 w-full flex-1 items-center justify-center rounded-3xl border-2 border-updatedBlue text-updatedBlue`}
              onClick={handleCancel}
            >
              Cancel
            </button>

            <button
              className={`mt-12 h-12 w-full flex-1 items-center justify-center rounded-3xl bg-updatedBlue text-white`}
              onClick={handleOk}
            >
              Delete
            </button>
          </div>
        </div>
      </AntdModal>
    );
  };

  return { DeleteSubjectModal, showModal };
};

export default UseDeleteSubjectModal;
