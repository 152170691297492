import React, { useContext } from 'react';
import ParentGeneralProfileForm from 'components/Parent/parent-general-profile-form';
import { userContext } from 'contexts/user';
import EditParentDetailsLayout from './edit-parent-details-layout';

const EditParentPersonalProfile = () => {
  const { user } = useContext(userContext);

  return (
    <EditParentDetailsLayout>
      <ParentGeneralProfileForm id={user.id} />
    </EditParentDetailsLayout>
  );
};

export default EditParentPersonalProfile;
