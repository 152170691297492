import React from 'react';

const StoreFrontListingTable = ({
  type = '', // The type of table can be experience, certificate, or education.
  tableTitle,
  listingData = [],
}) => {
  const renderTableHead = () => (
    <thead className="bg-gray-50 text-xs uppercase text-updatedBlue">
      <tr>
        <th scope="col" className="px-6 py-3">
          {type === 'experience'
            ? 'Job Title'
            : type === 'certificates'
              ? 'Certification Title'
              : 'Degree Title'}
        </th>
        <th scope="col" className="px-6 py-3">
          {type === 'experience'
            ? "Employer's Name"
            : type === 'certificates'
              ? 'Awarding Institute'
              : 'Degree Type'}
        </th>
        {type === 'education' && (
          <th scope="col" className="px-6 py-3">
            Awarding Institute
          </th>
        )}
        <th scope="col" className="px-6 py-3">
          {type === 'experience' ? '' : ''}
        </th>
      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      {listingData.map((rowData, index) => (
        <tr
          key={rowData.id}
          className={`${
            index < listingData.length - 1 ? 'border-b border-neutral-200' : ''
          } bg-updatedCreme`}
        >
          <td className="whitespace-nowrap px-6 py-4 font-medium text-updatedBlue">
            {rowData.title || rowData.degree_title || 'N/A'}
          </td>
          <td className="whitespace-nowrap px-6 py-4 font-medium text-updatedBlue">
            {rowData.employer_name ||
              rowData.awarding_institute ||
              rowData.degree_type ||
              'N/A'}
          </td>
          {type === 'education' && (
            <td className="whitespace-nowrap px-6 py-4 font-medium text-updatedBlue">
              {rowData.institute || 'N/A'}
            </td>
          )}
          <td className="whitespace-nowrap px-6 py-4 font-medium text-updatedBlue">
            {rowData.start_date?.split('-')[0] + '-'}
            {rowData.is_currently_working
              ? 'Present'
              : rowData.doesnt_expire
                ? 'Present'
                : rowData.is_ongoing
                  ? 'Present'
                  : rowData.end_date?.split('-')[0]}
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className="mb-5 rounded-lg border border-neutral-200 bg-updatedCreme p-5 shadow-md">
      <p className="text-3xl font-semibold">{tableTitle}</p>
      <div className="mt-7 overflow-x-auto">
        <table className="w-full text-left text-sm text-updatedBlue">
          {renderTableHead()}
          {renderTableBody()}
        </table>
      </div>
    </div>
  );
};

export default StoreFrontListingTable;
