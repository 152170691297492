/* eslint-disable jsx-a11y/img-redundant-alt */
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import { notificationsContext } from 'contexts/notifications';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getTutorById } from 'requests/tutors';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import DefaultButton from 'components/DefaultButton';
import EditActionButton from 'components/EditActionButton';
import DeleteActionButton from 'components/DeleteActionButton';
import { Table } from 'antd';
import moment from 'moment';
import { getTutorEducation } from 'requests/education';
import UseAddEducationModal from 'components/admin/AddEducationModal';
import UseEditEducationModal from 'components/admin/EditEducationModal';
import UseDeleteEducationModal from 'components/admin/DeleteEducationModal';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';

const Education = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const { id: tutorId } = useParams();

  const { api } = useContext(notificationsContext);
  const navigate = useNavigate();

  const getEducations = async () => {
    try {
      const {
        data: { data: educations },
      } = await getTutorEducation(tutorId);

      setEducations(
        educations.map((item, index) => ({
          ...item,
          key: index + 1,
        }))
      );
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  const { AddEducationModal, showModal } = UseAddEducationModal(
    tutorId,
    getEducations
  );
  const { EditEducationModal, showModal: showEditModal } =
    UseEditEducationModal(tutorId, getEducations);
  const { DeleteEducationModal, showModal: showDeleteModal } =
    UseDeleteEducationModal(tutorId, getEducations);
  const [educations, setEducations] = useState([]);

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTutorDetails();
    getEducations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Degree Title',
      dataIndex: 'degree_title',
      key: 'degree_title',
    },
    {
      title: 'Institute',
      dataIndex: 'institute',
      key: 'institute',
    },
    {
      title: 'Degree Type',
      dataIndex: 'degree_type',
      key: 'degree_type',
    },

    {
      title: 'Attachment',
      dataIndex: 'document',
      key: 'document',
      render: (_, { document }) => {
        return !document ? (
          'No Attachment'
        ) : (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={document} target="_blank">
            <div className="text-sm font-normal text-updatedBlue underline hover:text-blue-900">
              View
            </div>
          </a>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, { start_date }) => {
        return moment(start_date).format('LL');
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, { end_date, is_ongoing }) => {
        return moment(end_date).isValid() && !is_ongoing
          ? moment(end_date).format('LL')
          : 'Ongoing';
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, { id, ...education }) => {
        return (
          <div className="flex items-center justify-start gap-5">
            <EditActionButton
              onClick={() => {
                showEditModal(id, education);
              }}
            />
            <DeleteActionButton
              onClick={() => {
                showDeleteModal(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <EditEducationModal />
      <DeleteEducationModal />
      <AddEducationModal />
      <TutorDetailsLayout>
        <div className="flex w-full flex-col items-center">
          <img
            className="h-[120px] w-[120px] rounded-full"
            src={!!image ? image : profile}
            alt="image"
          />
          <div className="mt-6 text-2xl font-semibold text-updatedBlue">
            {name}
          </div>
          <Button
            className="mt-6"
            onClick={() => {
              const navigateString = viewStoreFront(tutorId, ADMIN);
              navigate(navigateString);
            }}
          >
            View Storefront
          </Button>

          <div className="mt-20 flex w-full items-center justify-between">
            <div className="text-2xl font-semibold text-updatedBlue">
              Detailed Profile / Education
            </div>
            <div>
              <DefaultButton onClick={showModal}>Add Education</DefaultButton>
            </div>
          </div>

          <Table
            className="mt-10 w-full"
            pagination={false}
            columns={columns}
            dataSource={educations}
          />
        </div>
      </TutorDetailsLayout>
    </>
  );
};

export default Education;
