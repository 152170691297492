// tutor screens
import TutorLogin from 'pages/tutor/login';
import TutorSignup from 'pages/tutor/signup';
import TutorForgotPassword from 'pages/tutor/forgot-password';
import TutorVerifyCode from 'pages/tutor/verify-code';
import TutorCreatePassword from 'pages/tutor/create-password';
import TutorDashboard from 'pages/tutor/dashboard';
import TutorGeneralProfile from 'pages/tutor/general-profile';
import TutorEducation from 'pages/tutor/education';
import TutorSubjectsExpertise from 'pages/tutor/subjects-expertise';
import TutorDisabilityTeachingExp from 'pages/tutor/disability-teaching-exp';
import TutorCertification from 'pages/tutor/certification';
import TutorPricing from 'pages/tutor/pricing';
import TutorGeneralAvailability from 'pages/tutor/general-availability';
import TutorLanguages from 'pages/tutor/languages';
import TutorBio from 'pages/tutor/bio';
import TutorWorkExperience from 'pages/tutor/work-experience';
import TutorBankDetails from 'pages/tutor/bank-details';
import TutorStudentStatistics from 'pages/tutor/students-statistics';
import TutorConnectCalendar from 'pages/tutor/connect-calendar';
import Payment from 'components/shared/Payment/Payment';
import ContentManagement from 'components/shared/ContentManagement/ContentManagement';
import Chat from 'pages/tutor/chat';
import Profile from 'components/shared/Profile/Profile';
import { Route } from 'react-router-dom';
import Dashboard from 'pages/tutor/dashboard';
import { TUTOR } from 'constants/roles';
import { NoAuthRoute, Protected } from 'routes';
import Storefront from 'pages/tutor/storefront';
import TutorStudentManagement from 'pages/tutor/student-management';
import TutorPayments from 'pages/tutor/payments';
import StreamAppChat from 'pages/student/stream-chat';

const tutorRoutes = (
  <Route key="tutor" path="tutor">
    <Route
      path="login"
      element={
        <NoAuthRoute>
          <TutorLogin />
        </NoAuthRoute>
      }
    />
    <Route
      path="forgot-password"
      element={
        <NoAuthRoute>
          <TutorForgotPassword />
        </NoAuthRoute>
      }
    />
    <Route
      path="verify-code"
      element={
        <NoAuthRoute>
          <TutorVerifyCode />
        </NoAuthRoute>
      }
    />
    <Route path="create-password" element={<TutorCreatePassword />} />

    <Route
      path="signup"
      element={
        <NoAuthRoute>
          <TutorSignup />
        </NoAuthRoute>
      }
    />
    <Route
      path="dashboard"
      element={
        <Protected routeRole={TUTOR}>
          <Dashboard />
        </Protected>
      }
    />
    <Route
      path="students-management"
      element={
        <Protected routeRole={TUTOR}>
          <TutorStudentManagement role={TUTOR} />
        </Protected>
      }
    />
    <Route
      path="payment"
      element={
        <Protected routeRole={TUTOR}>
          <Payment role={TUTOR} />
        </Protected>
      }
    />
    <Route
      path="content-management"
      element={
        <Protected routeRole={TUTOR}>
          <ContentManagement role={TUTOR} />
        </Protected>
      }
    />
    <Route
      path="chat"
      element={
        <Protected routeRole={TUTOR}>
          <Chat role={TUTOR} />
        </Protected>
      }
    />
    <Route
      path="profile"
      element={
        <Protected routeRole={TUTOR}>
          <Profile role={TUTOR} />
        </Protected>
      }
    />
    <Route
      path="general-profile"
      element={
        <Protected routeRole={TUTOR}>
          <TutorGeneralProfile />
        </Protected>
      }
    />
    <Route
      path="education"
      element={
        <Protected routeRole={TUTOR}>
          <TutorEducation />
        </Protected>
      }
    />
    <Route
      path="subjects-expertise"
      element={
        <Protected routeRole={TUTOR}>
          <TutorSubjectsExpertise />
        </Protected>
      }
    />
    <Route
      path="disability-teaching-exp"
      element={
        <Protected routeRole={TUTOR}>
          <TutorDisabilityTeachingExp />
        </Protected>
      }
    />
    <Route
      path="certification"
      element={
        <Protected routeRole={TUTOR}>
          <TutorCertification />
        </Protected>
      }
    />
    <Route
      path="pricing"
      element={
        <Protected routeRole={TUTOR}>
          <TutorPricing />
        </Protected>
      }
    />
    <Route
      path="students-statistics"
      element={
        <Protected routeRole={TUTOR}>
          <TutorStudentStatistics />
        </Protected>
      }
    />
    <Route
      path="general-availability"
      element={
        <Protected routeRole={TUTOR}>
          <TutorGeneralAvailability />
        </Protected>
      }
    />
    <Route
      path="connect-calendar"
      element={
        <Protected routeRole={TUTOR}>
          <TutorConnectCalendar />
        </Protected>
      }
    />
    <Route
      path="languages"
      element={
        <Protected routeRole={TUTOR}>
          <TutorLanguages />
        </Protected>
      }
    />
    <Route
      path="bio"
      element={
        <Protected routeRole={TUTOR}>
          <TutorBio />
        </Protected>
      }
    />
    <Route
      path="work-experience"
      element={
        <Protected routeRole={TUTOR}>
          <TutorWorkExperience />
        </Protected>
      }
    />
    <Route
      path="bank-details"
      element={
        <Protected routeRole={TUTOR}>
          <TutorBankDetails />
        </Protected>
      }
    />

    <Route
      path="payments"
      element={
        <Protected routeRole={TUTOR}>
          <TutorPayments />
        </Protected>
      }
    />

    <Route
      path="find-tutors/:id"
      element={
        <Protected routeRole={TUTOR}>
          <Storefront />
        </Protected>
      }
    />

    <Route
      path="/tutor/*"
      element={
        <Protected routeRole={TUTOR}>
          <TutorDashboard />
        </Protected>
      }
    />
    <Route
      path="chat/:id/:name"
      element={
        <Protected routeRole={TUTOR}>
          <StreamAppChat />
        </Protected>
      }
    />
  </Route>
);

export default tutorRoutes;
