import { Tag } from 'antd';
import { STATUSES } from 'constants/strings';
import React from 'react';

const {
  INVITED,
  SUSPEND,
  ACTIVE,
  DEACTIVATE,
  APPROVED,
  ON_HOLD,
  PENDING_APPROVAL,
  SIGNED_UP,
  ADDED_BY_PARENT,
} = STATUSES;

const AntdTags = ({ status }) => {
  return (
    <div>
      <Tag
        bordered={false}
        className={`${
          status === INVITED ||
          status === ACTIVE ||
          status === APPROVED ||
          status === ADDED_BY_PARENT
            ? 'rounded-full bg-green-100 text-green-600'
            : status === SUSPEND || status === DEACTIVATE
            ? 'rounded-full bg-red-100 text-red-500'
            : status === ON_HOLD ||
              status === PENDING_APPROVAL ||
              status === SIGNED_UP
            ? 'rounded-full bg-[#fffbe6] text-[#faad14]'
            : ''
        } `}
      >
        {status === INVITED && INVITED}
        {status === SUSPEND && SUSPEND}
        {status === ACTIVE && ACTIVE}
        {status === DEACTIVATE && DEACTIVATE}
        {status === APPROVED && APPROVED}
        {status === ON_HOLD && ON_HOLD}
        {status === PENDING_APPROVAL && PENDING_APPROVAL}
        {status === SIGNED_UP && SIGNED_UP}
        {status === ADDED_BY_PARENT && ADDED_BY_PARENT}
      </Tag>
    </div>
  );
};

export default AntdTags;
