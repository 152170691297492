import React, { useContext } from 'react';
import DisabilityProfilingDetails from 'pages/shared/DisabilityProfilingDetails/DisabilityProfilingDetails';
import { STUDENT } from 'constants/roles';
import Navbar from 'components/shared/Navbar/Navbar';
import { impersonateStudentNavbarItems } from 'enums';
import { Layout } from 'antd';
import { childUserContext } from 'contexts/child-user-context';
import ImpersonateStudentDetailsLayout from './impersonate-student-details-layout';

const ImpersonateDisabilityProfiling = () => {
  const { childUser } = useContext(childUserContext);
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={impersonateStudentNavbarItems} role={STUDENT} />
        <ImpersonateStudentDetailsLayout>
          <DisabilityProfilingDetails studentId={childUser} mode={STUDENT} />
        </ImpersonateStudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default ImpersonateDisabilityProfiling;
