import { TUTOR } from 'constants/roles';
import axiosInstance from 'requests/config';

export const getTutors = (limit = 10, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/admins/tutors?limit=${limit}&pageNumber=${pageNumber}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTutorById = (id) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/tutors/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateTutor = ({ id, formData }) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/tutors/${id}/updateTutorGeneralInfo`, formData, {
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const updateTutorAPI = (id, values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/tutors/${id}/updateTutorGeneralInfo`, values, {
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const updateTutorBio = (id, bio, video_link) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/tutors/${id}/updateBio`, { bio, video_link })
      .then(resolve)
      .catch(reject);
  });
};

export const updateTutorStatus = ({ tutorId, account_status }) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`tutors/${tutorId}/changeTutorStatus`, { account_status })
      .then(resolve)
      .catch(reject);
  });
};

export const updateTutorPricing = (tutorId, tutor_pricing) =>
  axiosInstance.post(`/tutors/${tutorId}/updatePricing`, {
    tutor_pricing,
  });
export const updateTutorTimezone = (tutorId, timezone) =>
  axiosInstance.post(`/tutors/${tutorId}/updateTimezone`, { timezone });

export const deacticateAccount = (tutorId) =>
  axiosInstance.post(`/tutors/${tutorId}/deactivateAccount`);

export const getTutorStorefrontById = async (id) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/tutors/${id}/tutorStoreFront/`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const updateAppointmentLink = (tutorId, google_appointment_link) =>
  axiosInstance.post(`/tutors/${tutorId}/updateAppointmentLink`, {
    google_appointment_link,
  });

export const signupTutor = (values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/registrations/signUp', { ...values, role: TUTOR })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const submitProfileForApproval = (id) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/tutors/${id}/submitStoreFront`)
      .then(resolve)
      .catch(reject);
  });
};

export const getStudents = async (id) =>
  axiosInstance.get(`/tutors/${id}/getStudentsRecords`);

export const getUserChats = async (id) =>
  axiosInstance.get(`/chats/${id}/getUserChats`);
