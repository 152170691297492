import ParentGeneralProfileForm from 'components/Parent/parent-general-profile-form';
import ParentDetailsLayout from 'components/admin/parent-details-layout';
import { useParams } from 'react-router-dom';
import React from 'react';

const ParentGeneralProfile = () => {
  const { id } = useParams();
  return (
    <ParentDetailsLayout>
      <ParentGeneralProfileForm id={id} />
    </ParentDetailsLayout>
  );
};

export default ParentGeneralProfile;
