import React, { useContext } from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { Avatar } from 'antd';
import { useNavigate } from 'react-router-dom';
import { childUserContext } from 'contexts/child-user-context';
import { userContext } from 'contexts/user';

const TutorProfileCard = ({
  id,
  name = 'N/A',
  profileImage = '',
  subjects = ['N/A'],
  experience = '4',
  education = ['N/A'],
  disabilityTeachings = 'No Experience',
  city = 'N/A',
  google_appointment_link = undefined,
}) => {
  const navigate = useNavigate();
  const { user } = useContext(userContext);

  const isStripeCustomerIdPresent = user.stripe_customer_id !== null;

  const { childUser } = useContext(childUserContext);

  return (
    <div className="my-5 grid gap-5 rounded-xl border border-neutral-200 bg-updatedCreme p-5 xs:grid-cols-2 md:grid-cols-9">
      <div className="col-span-3 flex items-center gap-5 border-neutral-200 px-2 xs:grid  xs:grid-cols-1 md:flex md:border-r">
        <div className="items-center justify-center xs:flex">
          <Avatar size={100} src={profileImage} />
        </div>
        <div className="flex flex-col">
          <p className="mb-2 font-semibold text-updatedBlue">{name}</p>
          <p className="text-lightGray">
            {subjects.length
              ? subjects.map(({ title }) => title).join(' | ')
              : 'No Experience'}
          </p>
          <p className="text-lightGray">
            Total Teaching Experience: {experience}
          </p>
        </div>
      </div>
      <div className="col-span-2 border-neutral-200 px-2 md:border-r">
        <p className="mb-3 font-semibold text-updatedBlue">Qualification</p>
        {education.length ? (
          <ul className="list-disc pl-6 text-lightGray">
            {education.map(({ degree_title, id, institute, degree_type }) => (
              <li key={id}>
                {degree_title}, {degree_type}, {institute}
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-lightGray">N/A</p>
        )}
      </div>
      <div className="col-span-2  border-neutral-200 px-2 md:border-r">
        <div className="mb-3">
          <p className="mb-1 font-semibold text-updatedBlue">
            Disability Teaching Ability
          </p>
          <p className="text-lightGray">
            {disabilityTeachings.length
              ? disabilityTeachings.map(({ type, id }) => type).join(', ')
              : 'No Experience'}
          </p>
        </div>
        <div className="mb-3">
          <p className="mb-1 font-semibold text-updatedBlue">City</p>
          <p className="text-lightGray">{city}</p>
        </div>
      </div>
      <div className="col-span-2 flex flex-col items-center justify-center gap-5 px-2">
        {google_appointment_link && isStripeCustomerIdPresent ? (
          <a target="_blank" href={google_appointment_link} rel="noreferrer">
            <div
              className="rounded-3xl bg-updatedBlue py-2 text-center
            text-white xs:w-36 lg:w-44"
            >
              Book a Session
            </div>
          </a>
        ) : (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a>
            <div className="cursor-not-allowed rounded-3xl bg-gray-500 py-2 text-center text-white xs:w-36 lg:w-44">
              Book a Session
            </div>
          </a>
        )}

        <CustomButton
          label="View Profile"
          className="border border-updatedPink text-updatedPink hover:!bg-updatedPink"
          shape="round"
          buttonWidth="xs:w-32 lg:w-36"
          onClick={() => {
            navigate(
              `/${
                childUser
                  ? `parent/student-find-tutors/${id}`
                  : `student/find-tutors/${id}`
              }`
            );
          }}
        />
      </div>
    </div>
  );
};

export default TutorProfileCard;
