import React from 'react';
import { Layout } from 'antd';
import { parentNavbarItems } from 'enums';
import Navbar from 'components/shared/Navbar/Navbar';
import { PARENT } from 'constants/roles';
import ChildrenManagement from 'pages/parent-dashboard/children-management';

const Dashboard = () => {
  return (
    <div>
      <Layout>
        <Navbar navItems={parentNavbarItems} role={PARENT} />
        <ChildrenManagement />
      </Layout>
    </div>
  );
};

export default Dashboard;
