import React from 'react';
import cn from 'classnames';

const DefaultButton = ({ disabled, children, className, ...props }) => {
  return (
    <button
      disabled={disabled}
      className={cn(
        className,
        `h-12 items-center justify-center rounded-3xl ${
          disabled ? 'bg-updatedBlue' : 'bg-updatedBlue'
        } px-6 text-sm font-medium  text-white`
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default DefaultButton;
