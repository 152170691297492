/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import backgroundImg from '../../assets/images/tutor/background.png';
import SearchBar from 'components/SearchBar/SearchBar';
import CustomButton from 'components/Button';
import { Button, Spin, Table } from 'antd';
import { getRowNumber } from 'utils/helperFunctions';
import ChildrenManagementModal from 'components/Modal/ChildrenManagementModal';
import { CHILDREN_MODAL_TYPE } from 'constants/strings';
import { getChildrenOfParent } from 'requests';
import { userContext } from 'contexts/user';
import { getSubscriptions } from 'requests/stripe';

const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const ChildrenManagement = () => {
  const navigate = useNavigate();
  const { user } = useContext(userContext);
  const parentId = user.id;

  // eslint-disable-next-line no-unused-vars
  const getUserSubscriptions = async () => {
    try {
      const {
        data: {
          data: { subscription, product },
        },
      } = await getSubscriptions(parentId);
    } catch (err) {
      console.log('stripe error');
    }
  };

  const isGoogleCalendarTokenPresent =
    localStorage.getItem('googleCalendarToken') !== null;
  const isStripeCustomerIdPresent = user.stripe_customer_id !== null;

  const [childrenData, setChildrenData] = useState([
    {
      id: 1,
      first_name: 'Clark',
      last_name: 'Kent',
      gender: 'Alpha Male',
      linked_tutors: 1,
      upcoming_sessions: 1,
    },
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [childInfo, setChildInfo] = useState({});
  const [modalType, setModalType] = useState('');
  const [parentSubscriptions, setParentSubscriptions] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  const [refreshTable, setRefreshTable] = useState(false);
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);

  const { DELETE, ADD } = CHILDREN_MODAL_TYPE;

  useEffect(() => {
    (async () => getAllChildren(tableParams))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => getAllChildren(tableParams))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTable, isPageChanged]);

  useEffect(() => {
    (async () => {
      await parentSubs();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const parentSubs = async () => {
    try {
      setIsLoading(true);
      const response = await getSubscriptions(parentId);
      setNumberOfChildren(
        response?.data?.data?.product?.metadata?.number_of_children
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const columns = [
    {
      title: 'Sr. No.',
      align: 'center',
      render: (text, record, index) => (
        <TableText>{getRowNumber(index, tableParams)}</TableText>
      ),
    },
    {
      title: 'ID',
      dataIndex: 'student_uuid',
      align: 'center',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      align: 'center',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      align: 'center',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      align: 'center',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Profile',
      dataIndex: 'profile',
      align: 'center',

      render: (_, record) => (
        <div className="flex items-center justify-center">
          {record.account_status === 'deactivate' ? (
            <p className="text-red-500">Deactivated</p>
          ) : (
            <Link
              to={`/parent/student-dashboard?key=${record.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="flex h-9 w-28 flex-col items-center justify-center rounded-full border border-updatedBlue text-updatedBlue hover:!text-updatedBlue"
            >
              Go to Profile
            </Link>
          )}
        </div>
      ),
    },
    // {
    //   title: 'Actions',
    //   key: 'actions',
    //   dataIndex: 'actions',
    //   align: 'center',
    //   render: (_, record) => (
    //     <Button
    //       type="primary"
    //       className="ml-2 bg-[#F87171] hover:!bg-[#fa8686]"
    //       icon={<TrashIcon className="h-6 w-6 text-white" />}
    //       onClick={() => {
    //         setIsModalOpen((prev) => !prev);
    //         setModalType(DELETE);
    //         setChildInfo(record);
    //       }}
    //     />
    //   ),
    // },
  ];

  const getAllChildren = async (tableParams) => {
    try {
      const {
        data: { users: childrenList, count: childrenCount },
      } = await getChildrenOfParent(
        parentId,
        tableParams.pagination.pageSize,
        tableParams.pagination.current
      );
      setChildrenData(childrenList);
      setChildrenCount(childrenList.length);
      setTableParams({
        pagination: {
          ...tableParams.pagination,
          total: childrenCount,
        },
      });
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams((prevParams) => ({
      pagination: {
        ...prevParams.pagination,
        current: pagination.current,
      },
    }));
    setIsPageChanged((prev) => !prev);
  };

  return (
    <div className="Tutorsue-400 h-screen bg-updatedGreenish">
      <ChildrenManagementModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        childInfo={childInfo}
        modalType={modalType}
        setRefreshTable={setRefreshTable}
      />
      <div className="mx-10 my-7 rounded-2xl bg-updatedMustard p-7">
        <p className="text-2xl font-semibold">Children Management</p>
        {/* Search bar and buttons */}
        <div className="mt-5 grid gap-3 xs:grid-cols-1 md:grid-cols-2">
          <div className="flex items-center xs:justify-center md:justify-start">
            <SearchBar />
          </div>
          <div className="flex flex-wrap items-center gap-3 xs:justify-center md:justify-end">
            <Button
              type="primary"
              ghost
              className="h-10 w-52 rounded-full border !border-updatedPink font-semibold !text-updatedPink"
              onClick={() => navigate('/parent/general-profile')}
            >
              My Profile
            </Button>
            {!isLoading && (
              <CustomButton
                onClick={() => {
                  setIsModalOpen((prev) => !prev);
                  setModalType(ADD);
                }}
                className={`!h-10 ${
                  isStripeCustomerIdPresent && isGoogleCalendarTokenPresent
                    ? // &&
                      // childrenCount < numberOfChildren
                      'bg-updatedBlue'
                    : '!bg-gray-400'
                }`}
                disabled={
                  !isStripeCustomerIdPresent || !isGoogleCalendarTokenPresent
                  // ||
                  // eslint-disable-next-line eqeqeq
                  // childrenCount == numberOfChildren
                }
              >
                Add child
              </CustomButton>
            )}
          </div>
        </div>

        <div
          id="ParentManagementTable"
          className="mt-5 flex justify-center overflow-scroll"
        >
          {isLoading ? (
            <Spin size="large" className="mt-10" />
          ) : (
            <Table
              columns={columns}
              dataSource={childrenData}
              pagination={{
                position: ['bottomCenter'],
                ...tableParams.pagination,
              }}
              className="w-full"
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildrenManagement;
