import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import SearchBar from 'components/SearchBar/SearchBar';
import Layout from 'components/admin/layout';
import { getRowNumber } from 'utils/helperFunctions';
import AntdTags from 'components/Tags/AntdTags';
import { STATUSES } from 'constants/strings';
import TableActionButtons from 'components/TableActionButtons/TableActionButtons';
import { getParents } from 'requests/parents';
import useInviteModal from 'components/Modal/InviteModal';
import { PARENT } from 'constants/roles';

const {
  INVITED,
  SUSPEND,
  ACTIVE,
  DEACTIVATE,
  APPROVED,
  ON_HOLD,
  PENDING_APPROVAL,
  SIGNED_UP,
} = STATUSES;
const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const ParentManagement = () => {
  const navigate = useNavigate();
  const [parentsData, setParentsData] = useState([]);
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  const [refreshTable, setRefreshTable] = useState(false);
  const { InviteModal, showModal } = useInviteModal(PARENT, setRefreshTable);

  const columns = [
    {
      title: 'Sr. No.',
      render: (text, record, index) => (
        <TableText>{getRowNumber(index, tableParams)}</TableText>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Phone No.',
      dataIndex: 'phone_number',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'No. of Kids',
      dataIndex: 'no_of_kids',
      render: (text) => <TableText>{text}</TableText>,
    },

    {
      title: 'Status',
      dataIndex: 'account_status',
      render: (status) => {
        if (status === INVITED) return <AntdTags status={INVITED} />;
        if (status === SUSPEND) return <AntdTags status={SUSPEND} />;
        if (status === ACTIVE) return <AntdTags status={ACTIVE} />;
        if (status === DEACTIVATE) return <AntdTags status={DEACTIVATE} />;
        if (status === APPROVED) return <AntdTags status={APPROVED} />;
        if (status === ON_HOLD) return <AntdTags status={ON_HOLD} />;
        if (status === PENDING_APPROVAL)
          return <AntdTags status={PENDING_APPROVAL} />;
        if (status === SIGNED_UP) return <AntdTags status={SIGNED_UP} />;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (_, record) => {
        return <TableActionButtons onView={onView} record={record} />;
      },
    },
  ];

  useEffect(() => {
    (async () => {
      await listParentsData(tableParams);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await listParentsData(tableParams);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageChanged, refreshTable]);

  const listParentsData = async (tableParams) => {
    try {
      // API call to get Parents list
      const {
        data: { users: parentsList, count: parentsCount },
      } = await getParents(
        tableParams.pagination.pageSize,
        tableParams.pagination.current
      );

      // Until we get no_of_kids and tutors from backend, these will act as placeholder
      const parentsListWithDummyData = [];
      parentsList.map((parent) =>
        parentsListWithDummyData.push({
          ...parent,
          no_of_kids: parent.children.length,
        })
      );

      setParentsData(parentsListWithDummyData);
      setTableParams({
        pagination: {
          ...tableParams.pagination,
          total: parentsCount,
        },
      });
    } catch (err) {
      throw new Error(err.response.data.error);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    setIsPageChanged((prev) => !prev);
  };

  const onView = async (record) => {
    navigate(`/admin/parent-management/${record.id}`);
  };

  return (
    <Layout>
      {InviteModal}
      <div className="p-5">
        <div className="flex w-full justify-end">
          {/* <SearchBar /> */}
          <Button onClick={showModal}>Add parent</Button>
        </div>

        <div id="ParentManagementTable" className="mt-5 flex overflow-scroll">
          <Table
            columns={columns}
            dataSource={parentsData}
            pagination={{
              position: ['bottomCenter'],
              ...tableParams.pagination,
            }}
            className="w-full"
            onChange={handleTableChange}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ParentManagement;
