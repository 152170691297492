import { Form, Input } from 'antd';
import React from 'react';
import InputMask from 'react-input-mask';

const AntFormInput = ({
  name,
  type = 'text',
  rules = [
    {
      required: false,
    },
  ],
  placeholder,
  className = 'xs:w-[250px] sm:w-[300px] lg:w-[450px] h-[40px] rounded-lg gap-2',
  disabled = false,
  icon,
  label,
  onChange,
  size,
  value,
  bordered = true,
}) => {
  const inputStyle = disabled ? { backgroundColor: 'transparent' } : {};

  return (
    <>
      <Form.Item name={name} type={type} rules={rules} label={label}>
        {type !== 'password' ? (
          type === 'phoneNumber' ? (
            <InputMask
              mask="(999)-999-9999"
              placeholder={placeholder}
              className={className}
              disabled={disabled}
              addonAfter={icon}
              onChange={onChange}
              style={inputStyle}
            />
          ) : (
            <Input
              placeholder={placeholder}
              className={className}
              disabled={disabled}
              addonAfter={icon}
              onChange={onChange}
              style={inputStyle}
              size={size}
              value={value}
              bordered={bordered}
            />
          )
        ) : (
          <Input.Password
            placeholder={placeholder}
            className={className}
            style={inputStyle}
            size={size}
          />
        )}
      </Form.Item>
    </>
  );
};

export default AntFormInput;
