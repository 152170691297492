import React from 'react';
import ImpersonateStudentProfileSidebar from './impersonate-student-profile-sidebar';
import { Layout } from 'antd';

const ImpersonateStudentDetailsLayout = ({ children }) => {
  return (
    <div>
      <Layout>
        <div className="flex gap-5 bg-updatedGreenish bg-cover xs:min-h-screen xs:flex-col md:flex-row">
          <ImpersonateStudentProfileSidebar />
          <div className="mx-5 my-6 flex-1 rounded-2xl bg-updatedMustard shadow xs:p-5 md:p-20">
            {children}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ImpersonateStudentDetailsLayout;
