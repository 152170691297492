/* eslint-disable no-unused-vars */
import { notificationsContext } from 'contexts/notifications';
import React, { useContext, useEffect, useState } from 'react';
import { getTutorById } from 'requests/tutors';
import DefaultButton from 'components/DefaultButton';
import UseAddSubjectModal from 'components/admin/AddSubjectModal';
import { getTutorSubjects } from 'requests/subjects';
import EditActionButton from 'components/EditActionButton';
import DeleteActionButton from 'components/DeleteActionButton';
import { Table } from 'antd';
import UseEditSubjectModal from 'components/admin/EditSubjectModal';
import UseDeleteSubjectModal from 'components/admin/DeleteSubjectModal';
import { userContext } from 'contexts/user';
import Layout from 'components/tutor/ProfileLayout';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const SubjectsExpertise = () => {
  // eslint-disable-next-line no-unused-vars
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const {
    user: { id: tutorId, account_status },
  } = useContext(userContext);

  const { api } = useContext(notificationsContext);

  const getTutorSubjectsExpertises = async () => {
    try {
      const {
        data: { data: subjectsExpertises },
      } = await getTutorSubjects(tutorId);

      setSubjectsExpertises(
        subjectsExpertises.map((item, index) => ({
          ...item,
          key: index + 1,
        }))
      );
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  const { AddSubjectModal, showModal } = UseAddSubjectModal(
    tutorId,
    getTutorSubjectsExpertises
  );
  const { EditSubjectModal, showModal: showEditModal } = UseEditSubjectModal(
    tutorId,
    getTutorSubjectsExpertises
  );
  const { DeleteSubjectModal, showModal: showDeleteModal } =
    UseDeleteSubjectModal(tutorId, getTutorSubjectsExpertises);
  const [subjectsExpertises, setSubjectsExpertises] = useState([]);
  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTutorDetails();
    getTutorSubjectsExpertises();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Subject Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Attachment',
      dataIndex: 'document',
      key: 'document',
      render: (_, { document }) => {
        return !document ? (
          'No Attachment'
        ) : (
          <a href={document} target="_blank" rel="noreferrer">
            <div className="text-sm font-normal text-updatedBlue underline hover:text-updatedBlue">
              View
            </div>
          </a>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, { id, title, document }) => {
        return (
          <div className="flex items-center justify-start gap-5">
            <EditActionButton
              onClick={() => {
                showEditModal(id, title, document);
              }}
            />
            <DeleteActionButton
              onClick={() => {
                showDeleteModal(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <DeleteSubjectModal />
      <EditSubjectModal />
      <AddSubjectModal />

      <Layout>
        <div className="flex min-h-[calc(100vh_-_273px)] w-full flex-col items-center">
          <div className="flex w-full items-center justify-between gap-8">
            <div className="flex-1 font-semibold text-updatedBlue xs:text-xl md:text-2xl">
              Detailed Profile / Subject(s) Expertise
            </div>
            <div className="flex flex-wrap items-center justify-start gap-1">
              <div>
                {account_status === 'approved' && (
                  <button
                    type="button"
                    className="flex h-12 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white xs:w-32 md:w-48"
                    onClick={() => {
                      const navigateString = viewStoreFront(tutorId, TUTOR);
                      navigate(navigateString);
                    }}
                  >
                    View Storefront
                  </button>
                )}
              </div>
              <div className="w-24 md:w-48">
                <DefaultButton className="!bg-[#212B36]" onClick={showModal}>
                  Add Subject
                </DefaultButton>
              </div>
            </div>
          </div>

          <Table
            className="mt-10 w-full flex-1"
            pagination={false}
            columns={columns}
            dataSource={subjectsExpertises}
            scroll={{
              x: 600,
              y: 1200,
            }}
          />
        </div>
      </Layout>
    </>
  );
};

export default SubjectsExpertise;
