import StreamChatModal from 'components/Modal/StreamChatModal';
import React, { useState } from 'react';

const StartChatCard = ({ cardTitle, tutorId, tutorName }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="mb-5 rounded-lg border border-neutral-200 bg-updatedCreme p-5  shadow-md">
      <StreamChatModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        name={tutorName}
        id={tutorId}
      />
      <p className="text-3xl font-semibold">{cardTitle}</p>
      <div className="mt-7">
        <button
          className="flex h-10 w-full cursor-pointer items-center justify-around rounded-md bg-updatedPink font-bold text-white"
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          Start Chat
        </button>
      </div>
    </div>
  );
};

export default StartChatCard;
