export const LANGUAGES = {
  en: {
    name: 'English',
    nativeName: 'English',
  },
  es: {
    name: 'Spanish',
    nativeName: 'español, castellano',
  },
  zh: {
    name: 'Chinese',
    nativeName: '中文 (Zhōngwén), 汉语, 漢語',
  },
};

export const LANGUAGES_ARRAY = Object.keys(LANGUAGES).map((key) => ({
  code: key,
  name: LANGUAGES[key].name,
  nativeName: LANGUAGES[key].nativeName,
}));
