import { DatePicker, Form } from 'antd';
import React from 'react';

const AntFormDatePicker = ({
  name,
  placeholder,
  bordered,
  rules,
  label,
  disabled,
  showArrow,
  className,
  size,
  suffixIcon,
}) => {
  const inputStyle = disabled ? { backgroundColor: 'transparent' } : {};
  const disabledDate = (current) => {
    // Calculate the date three years ago from today
    const threeYearsAgo = new Date();
    threeYearsAgo.setFullYear(threeYearsAgo.getFullYear() - 3);

    // Disable dates that are after today or before three years ago
    return current && current.valueOf() > threeYearsAgo.valueOf();
  };

  return (
    <>
      <Form.Item name={name} label={label} rules={rules}>
        <DatePicker
          bordered={bordered}
          placeholder={placeholder}
          disabled={disabled}
          disabledDate={disabledDate}
          showArrow={showArrow}
          className={className}
          size={size}
          suffixIcon={suffixIcon}
          style={inputStyle}
          format="MMMM D YYYY"
          inputReadOnly={true}
        />
      </Form.Item>
    </>
  );
};

export default AntFormDatePicker;
