import React, { useState } from 'react';
import StudentImage from 'assets/images/student/students_image.jpg';
import tutorImage from 'assets/images/tutor/tutors_picture.png';
import parentImage from 'assets/images/parent/parent_image.png';
import * as yup from 'yup';
import { Formik } from 'formik';
import { STRINGS } from 'constants/strings';
import cn from 'classnames';
import { PARENT, STUDENT, TUTOR } from 'constants/roles';

const ForgotPasswordConponent = ({ forgotPassword, className = '', role }) => {
  const [apiError, setApiError] = useState('');
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(STRINGS.email_required)
      .email(STRINGS.email_invalid),
  });

  const handleForgotPassword = async ({ email }) => {
    setApiError('');
    try {
      await forgotPassword({ email });
    } catch (err) {
      setApiError(err.response.data.message);
    }
  };

  return (
    <div
      className={cn(
        'flex h-screen w-screen justify-center pt-10 xs:px-2 sm:px-0 md:items-center md:pt-0 ',
        className
      )}
    >
      <div className="flex aspect-[1] h-[600px] overflow-hidden rounded-3xl bg-updatedCreme shadow-custom-1 lg:aspect-[1.7]">
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleForgotPassword}
          validateOnChange={false}
        >
          {({ handleChange, errors, handleBlur, touched, handleSubmit }) => (
            <div className="flex flex-1 flex-col items-center p-6 sm:p-14 md:p-[60px]">
              <div className="h-8 w-full text-3xl font-bold leading-9 text-updatedBlue">
                Forgot Password
              </div>
              <div className="mt-10 w-full">
                <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                  Email
                </div>
                <input
                  className="mt-3 h-14 w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-4 py-3.5"
                  placeholder="test@test.com"
                  type="text"
                  name="email"
                  id="email"
                  onChange={(props) => {
                    !!apiError && setApiError('');
                    handleChange(props);
                  }}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5 w-full">
                  {!!errors.email && !!touched.email && (
                    <div className="w-full text-red-500">{errors.email}</div>
                  )}
                </div>
              </div>

              <button
                className={`mt-6 h-12 w-full items-center justify-center rounded-3xl ${
                  role === STUDENT ? 'bg-updatedBlue' : 'bg-updatedBlue'
                } text-center font-semibold text-white`}
                type="button"
                onClick={handleSubmit}
              >
                Reset Password
              </button>
              <div className="mt-1 h-5 w-full">
                {!!apiError && <div className=" text-red-500">{apiError}</div>}
              </div>

              <button
                className="mt-9 h-12 w-full items-center justify-center rounded-3xl border border-updatedBlue text-center font-semibold text-updatedBlue"
                type="button"
                onClick={() => window.history.back()}
              >
                Back
              </button>
            </div>
          )}
        </Formik>
        <img
          className="hidden h-full w-1/2 lg:block"
          src={
            role === TUTOR
              ? tutorImage
              : role === PARENT
                ? parentImage
                : StudentImage
          }
          alt="login_image"
        />
      </div>
    </div>
  );
};

export default ForgotPasswordConponent;
