import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import PaymentIcon from '@mui/icons-material/PaymentOutlined';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SearchIcon from '@mui/icons-material/Search';

// Parent icons
import { UserGroupIcon, UserCircleIcon } from '@heroicons/react/24/outline';

export const tutorNavbarItems = [
  {
    key: 'calendar',
    label: 'Calendar',
    logo: <EventNoteOutlinedIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'students-management',
    label: 'Students Management',
    logo: <GroupOutlinedIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'chat',
    label: 'Chat',
    logo: <ChatOutlinedIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'payments',
    label: 'Payments',
    logo: <PaymentIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'general-profile',
    label: 'Profile',
    logo: <AccountCircleOutlinedIcon style={{ fontSize: 20 }} />,
  },
];

export const studentNavbarItems = [
  {
    key: 'dashboard',
    label: 'Sessions',
    logo: <EventNoteOutlinedIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'my-tutors',
    label: 'My Tutors',
    logo: <MenuBookIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'find-tutors',
    label: 'Find Tutors',
    logo: <SearchIcon style={{ fontSize: 20 }} />,
  },

  {
    key: 'general-profile',
    label: 'Profile',
    logo: <AccountCircleOutlinedIcon style={{ fontSize: 20 }} />,
  },
];

export const impersonateStudentNavbarItems = [
  {
    key: 'student-dashboard',
    label: 'Sessions',
    logo: <EventNoteOutlinedIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'my-tutors',
    label: 'My Tutors',
    logo: <MenuBookIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'student-find-tutors',
    label: 'Find Tutors',
    logo: <SearchIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'student-general-profile',
    label: 'Profile',
    logo: <AccountCircleOutlinedIcon style={{ fontSize: 20 }} />,
  },
];

export const parentNavbarItems = [
  // {
  //   key: 'calendar',
  //   label: 'Calendar',
  //   logo: <CalendarDaysIcon className="h-5 w-5" />,
  // },
  // {
  //   key: 'invoice',
  //   label: 'Invoice',
  //   logo: <DocumentChartBarIcon className="h-5 w-5" />,
  // },
  {
    key: 'dashboard',
    label: 'Children Management',
    logo: <UserGroupIcon className="h-5 w-5" />,
  },
  // {
  //   key: 'tutors-and-courses',
  //   label: 'Tutors & Courses',
  //   logo: <BookOpenIcon className="h-5 w-5" />,
  // },
  // {
  //   key: 'payment',
  //   label: 'Payment',
  //   logo: <CurrencyDollarIcon className="h-5 w-5" />,
  // },
  {
    key: 'tutor-chat',
    label: 'Chat',
    logo: <ChatOutlinedIcon className="h-5 w-5" />,
  },
  {
    key: 'my-network',
    label: 'My Network',
    logo: <Diversity3Icon style={{ fontSize: 20 }} />,
  },
  {
    key: 'payments',
    label: 'Payments',
    logo: <PaymentIcon style={{ fontSize: 20 }} />,
  },
  {
    key: 'general-profile',
    label: 'Profile',
    logo: <UserCircleIcon className="h-5 w-5" />,
  },
];

export const sessionItems = {
  SCHEDULED: 'scheduled',
  PENDING: 'pending',
  REQUESTED: 'requested',
};

export const studentStatusOptions = [
  {
    key: 'suspend',
    value: 'suspend',
    label: 'Suspend',
  },
  {
    key: 'active',
    value: 'active',
    label: 'Active',
  },
];

export const genderOptions = [
  {
    key: 'male',
    value: 'male',
    label: 'Male',
  },
  {
    key: 'female',
    value: 'female',
    label: 'Female',
  },
  {
    key: 'other',
    value: 'other',
    label: 'Other',
  },
];

export const allowedExtensions = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
};

export const acceptUploads = {
  picture: 'image/png, image/jpg, image/jpeg',
  pictureAndPDF: 'image/png, image/jpg, image/jpeg, application/pdf',
};

export const schoolGrades = [
  {
    key: 'KG',
    value: 'KG',
    label: 'K.G.',
  },
  ...Array.from({ length: 12 }, (_, index) => ({
    key: (index + 1).toString(),
    value: (index + 1).toString(),
    label: (index + 1).toString(),
  })),
];

export const educationOptions = [
  {
    key: 'High School',
    value: 'High School',
    label: 'High School',
  },
  {
    key: 'PhD',
    value: 'PhD',
    label: 'PhD',
  },
  {
    key: "Master's",
    value: "Master's",
    label: "Master's",
  },
  {
    key: "Bachelor's",
    value: "Bachelor's",
    label: "Bachelor's",
  },
];

export const reviewOptions = [
  {
    key: '5',
    value: '5',
    label: '5 - Star',
  },
  {
    key: '4',
    value: '4',
    label: '4 - Star',
  },
  {
    key: '3',
    value: '3',
    label: '3 - Star',
  },
  {
    key: '2',
    value: '2',
    label: '2 - Star',
  },
  {
    key: '1',
    value: '1',
    label: '1 - Star',
  },
];
