import React, { useContext, useState } from 'react';
import cn from 'classnames';
import { generatePath, useNavigate } from 'react-router-dom';
import { ReactComponent as DropdownArrow } from '../../assets/images/dropdown-arrow.svg';
import { studentDetailsSidebarOptions } from './sidebarOptions';
import { userContext } from 'contexts/user';
import DeactivateAccountModal from 'components/shared/DeactivateAccountModal/DeactivateAccountModal';
import { deactivateStudent } from 'requests/students';
import { notificationsContext } from 'contexts/notifications';
import { Message } from 'constants/message';

const StudentProfileSidebar = () => {
  const navigate = useNavigate();
  const { user, logout } = useContext(userContext);
  const { api } = useContext(notificationsContext);

  const studentId = user.id;
  const pathName = window.location.pathname;
  const [expanded, setExpanded] = useState(() => {
    if (
      generatePath(studentDetailsSidebarOptions[0].path, {
        id: studentId,
      }) === pathName
    )
      return '';
    else return 'detail-profiling';
  });
  const [isDeactivateAccountModalOpen, setIsDeactivateAccountModalOpen] =
    useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivateAccount = async () => {
    try {
      await deactivateStudent(studentId);
      api.success({
        message: 'Success',
        description: Message.deactivated,
        placement: 'top',
        duration: 4,
      });
      setIsDeactivateAccountModalOpen(false);
      logout();
      navigate('/student/login');
    } catch (error) {
      api.error({
        message: 'Error',
        description: Message.errorMessage,
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <div className="sticky top-6 mx-6 my-[24px] flex h-[calc(100vh_-_48px)] w-[450px] flex-col items-center bg-updatedCreme">
      <DeactivateAccountModal
        isDeactivateAccountModalOpen={isDeactivateAccountModalOpen}
        setIsDeactivateAccountModalOpen={setIsDeactivateAccountModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={() => setIsDeactivateAccountModalOpen(false)}
        handleDelete={handleDeactivateAccount}
      />
      <div className="mt-5 flex h-[510px] w-full flex-col">
        {studentDetailsSidebarOptions.map(({ label, path, children, name }) => {
          const isCurrentPath =
            (path && generatePath(path, { id: studentId }) === pathName) ||
            (children &&
              children?.find(
                ({ path }) => generatePath(path, { id: studentId }) === pathName
              ));

          return (
            <div
              className={`overflow-hidden transition-all duration-300 ${
                expanded === name ? 'h-[470px]' : 'h-10'
              }`}
            >
              <div
                onClick={() => {
                  if (path && !isCurrentPath)
                    navigate(path.replace(':id', studentId));
                  if (children && children.length > 0)
                    if (expanded === name) setExpanded('');
                    else setExpanded(name);
                }}
                className={cn(
                  'flex w-full cursor-pointer items-center justify-between px-6 py-[9px]',
                  {
                    'border-r-4 border-updatedBlue bg-violet-50 text-updatedBlue hover:text-gray-700':
                      isCurrentPath,
                    'bg-updatedCreme text-gray-500 hover:text-gray-700':
                      !isCurrentPath,
                  }
                )}
              >
                <div>{label}</div>
                {children && children.length > 0 && (
                  <div
                    className={`transition-all duration-300 ${
                      expanded === name ? 'rotate-180' : 'rotate-0'
                    }`}
                  >
                    <DropdownArrow
                      className={cn('h-5 w-5', {
                        'text-updatedBlue': isCurrentPath,
                        'text-gray-500': !isCurrentPath,
                      })}
                    />
                  </div>
                )}
              </div>
              {children &&
                children.map(({ label, path }) => {
                  const isCurrentChildPath =
                    path && generatePath(path, { id: studentId }) === pathName;

                  return (
                    <div
                      onClick={() => {
                        if (
                          path &&
                          generatePath(path, { id: studentId }) !== pathName
                        )
                          navigate(path.replace(':id', studentId));
                      }}
                      className={cn(
                        'ml-5 w-full cursor-pointer items-center justify-start py-[9px] pl-6 hover:text-gray-700',
                        {
                          ' text-updatedBlue': isCurrentChildPath,
                          ' text-gray-500': !isCurrentChildPath,
                        }
                      )}
                    >
                      {label}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      {/* 
      <div className="mx-10 my-2 h-[0px] w-[calc(100%_-_80px)] border border-neutral-200" />
      <div
        className="mt-5 cursor-pointer py-5 text-base font-medium leading-normal text-red-500"
        onClick={() => {
          setIsDeactivateAccountModalOpen(true);
        }}
      >
        Deactivate Account
      </div> */}
    </div>
  );
};

export default StudentProfileSidebar;
