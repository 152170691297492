import { Layout } from 'antd';
import Navbar from 'components/shared/Navbar/Navbar';
import GeneralProfileForm from 'components/student/GeneralProfileForm';
import StudentDetailsLayout from 'components/student/StudentDetailsLayout';
import { STUDENT } from 'constants/roles';
import { userContext } from 'contexts/user';
import { studentNavbarItems } from 'enums';
import React, { useContext } from 'react';

const StudentProfile = () => {
  const { user } = useContext(userContext);
  return (
    <div className="overflow-x-hidden bg-[url('assets/images/tutor/bg-general-profile.png')] bg-cover">
      <Layout>
        <Navbar navItems={studentNavbarItems} role={STUDENT} />
        <StudentDetailsLayout>
          <GeneralProfileForm id={user.id} />
        </StudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default StudentProfile;
