import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from 'requests';

import LoginComponent from 'components/login';
import { userContext } from 'contexts/user';
import { TUTOR } from 'constants/roles';

const Login = () => {
  const [apiError, setApiError] = useState('');

  const { login: loginContext } = useContext(userContext);
  const navigate = useNavigate();

  const handleLogin = async ({ email, password }) => {
    const response = await login(email, password);

    if (response?.data?.role === TUTOR) {
      loginContext(response);
      if (!!response?.data?.is_force_change_password) {
        navigate('/tutor/create-password', { replace: true });
      } else {
        navigate('/tutor/dashboard', { replace: true });
      }
    } else {
      setApiError('Invalid credentials');
    }
  };

  return (
    <LoginComponent
      login={handleLogin}
      forgotPasswordLink="/tutor/forgot-password"
      className="bg-updatedGreenish md:bg-contain"
      role={TUTOR}
      apiError={apiError}
      setApiError={setApiError}
    />
  );
};

export default Login;
