import ParentLogin from 'pages/parent/login';
import ParentSignup from 'pages/parent/signup';
import ParentForgotPassword from 'pages/parent/forgot-password';
import ParentVerifyCode from 'pages/parent/verify-code';
import ParentCreatePassword from 'pages/parent/create-password';
import ParentDashboard from 'pages/parent/dashboard';
import ParentPayments from 'pages/parent/payments';
import { Route } from 'react-router-dom';
import { Protected, NoAuthRoute, ImpersonateProtected } from 'routes';
import { PARENT } from 'constants/roles';
import EditParentPersonalProfile from 'pages/parent-dashboard/edit-parent-personal-profile';
import EditParentLanguageProficiency from 'pages/parent-dashboard/edit-parent-language-proficiency';
import EditParentPaymentConfiguration from 'pages/parent-dashboard/edit-parent-payment-configuration';
import ImpersonateDashboard from 'pages/student/impersonate-dashboard';
import ImpersonateStudentProfile from 'pages/student/impersonate-student-profile';
import ImpersonateSchoolProfiling from 'pages/student/impersonate-student-school-profiling';
import ImpersonateLanguages from 'pages/student/impersonate-student-languages';
import ImpersonateDisabilityProfiling from 'pages/student/impersonate-student-disability';
import ImpersonatePaymentConfiguration from 'pages/student/impersonate-student-payment';
import ParentConnectCalendar from 'pages/parent/connect-calendar';
import ImpersonateFindTutors from 'pages/student/impersonate-find-tutors';
import ImpersonateStorefront from 'pages/student/impersonate-store-front';
import StripeSucess from 'pages/shared/StripeSuccess/StripeSucess';
import StripeFailed from 'pages/shared/StripeFailed/StripeFailed';
import ImpersonateStudentTutorsAndCourses from 'pages/student/impersonate-student-tutors-and-courses';
import MetaAccounts from 'pages/parent/connect-social-media';
import MyNetwork from 'pages/parent/my-network';
import StreamAppChat from 'pages/student/stream-chat';
import ParentChat from 'pages/parent/parent-chat';

const parentRoutes = (
  <Route key="parent" path="parent">
    <Route
      path="login"
      element={
        <NoAuthRoute>
          <ParentLogin />
        </NoAuthRoute>
      }
    />
    <Route
      path="forgot-password"
      element={
        <NoAuthRoute>
          <ParentForgotPassword />
        </NoAuthRoute>
      }
    />
    <Route
      path="verify-code"
      element={
        <NoAuthRoute>
          <ParentVerifyCode />
        </NoAuthRoute>
      }
    />
    <Route path="create-password" element={<ParentCreatePassword />} />
    <Route
      path="signup"
      element={
        <NoAuthRoute>
          <ParentSignup />
        </NoAuthRoute>
      }
    />
    <Route
      path="dashboard"
      element={
        <Protected routeRole={PARENT}>
          <ParentDashboard />
        </Protected>
      }
    />

    <Route
      path="general-profile"
      element={
        <Protected routeRole={PARENT}>
          <EditParentPersonalProfile />
        </Protected>
      }
    />
    <Route
      path="connect-calendar"
      element={
        <Protected routeRole={PARENT}>
          <ParentConnectCalendar />
        </Protected>
      }
    />
    <Route
      path="connect-social-media"
      element={
        <Protected routeRole={PARENT}>
          <MetaAccounts />
        </Protected>
      }
    />
    <Route
      path="language-proficiency"
      element={
        <Protected routeRole={PARENT}>
          <EditParentLanguageProficiency />
        </Protected>
      }
    />
    <Route
      path="payment-configuration"
      element={
        <Protected routeRole={PARENT}>
          <EditParentPaymentConfiguration />
        </Protected>
      }
    />
    <Route
      path="my-network"
      element={
        <Protected routeRole={PARENT}>
          <MyNetwork />
        </Protected>
      }
    />
    <Route
      path="tutor-chat"
      element={
        <Protected routeRole={PARENT}>
          <ParentChat />
        </Protected>
      }
    />
    <Route
      path="payments"
      element={
        <Protected routeRole={PARENT}>
          <ParentPayments />
        </Protected>
      }
    />
    <Route
      path="payment-configuration/success"
      element={
        <Protected routeRole={PARENT}>
          <StripeSucess />
        </Protected>
      }
    />
    <Route
      path="payment-configuration/failed"
      element={
        <Protected routeRole={PARENT}>
          <StripeFailed />
        </Protected>
      }
    />

    <Route
      path="student-dashboard"
      element={
        <ImpersonateProtected>
          <ImpersonateDashboard />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-general-profile"
      element={
        <ImpersonateProtected>
          <ImpersonateStudentProfile />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-school-profiling"
      element={
        <ImpersonateProtected>
          <ImpersonateSchoolProfiling />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-languages"
      element={
        <ImpersonateProtected>
          <ImpersonateLanguages />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-disability-profiling"
      element={
        <ImpersonateProtected>
          <ImpersonateDisabilityProfiling />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-payment-configuration"
      element={
        <ImpersonateProtected>
          <ImpersonatePaymentConfiguration />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-find-tutors"
      element={
        <ImpersonateProtected>
          <ImpersonateFindTutors />
        </ImpersonateProtected>
      }
    />
    <Route
      path="student-find-tutors/:id"
      element={
        <ImpersonateProtected>
          <ImpersonateStorefront />
        </ImpersonateProtected>
      }
    />
    <Route
      path="my-tutors"
      element={
        <ImpersonateProtected>
          <ImpersonateStudentTutorsAndCourses />
        </ImpersonateProtected>
      }
    />
    <Route
      path="chat/:id/:name"
      element={
        <ImpersonateProtected>
          <StreamAppChat />
        </ImpersonateProtected>
      }
    />
  </Route>
);

export default parentRoutes;
