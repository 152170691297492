/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import { getTutorById } from 'requests/tutors';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationsContext } from 'contexts/notifications';
import { Select } from 'antd';
import { LANGUAGES_ARRAY } from 'enums/languages';
import { updateLanguages } from 'requests';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';

const TutorLanguages = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [languages, setLanguages] = useState([]);
  const { id: tutorId } = useParams();
  const { api } = useContext(notificationsContext);

  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
      setLanguages(data.languages ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateLanguages = async () => {
    try {
      await updateLanguages(tutorId, languages);
      api.success({
        message: 'Success',
        description: 'Languages updated successfully',
        placement: 'top',
        duration: 4,
      });
      getTutorDetails();
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TutorDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <img
          className="h-[120px] w-[120px] rounded-full"
          src={!!image ? image : profile}
          alt="image"
        />
        <div className="mt-5 text-2xl font-semibold text-updatedBlue">
          {name}
        </div>
        <Button
          className="mt-4"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>

        <div className="mt-10 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Languages
          </div>
          <div className="mt-8 text-xl text-updatedBlue">Languages</div>
          <div id="languages">
            <Select
              className="mt-10 w-full rounded-lg border border-neutral-200"
              value={languages}
              onChange={setLanguages}
              mode="multiple"
              optionLabelProp="label"
            >
              {LANGUAGES_ARRAY.map((language) => (
                <Select.Option value={language.name}>
                  {language.name} ({language.nativeName})
                </Select.Option>
              ))}
            </Select>
          </div>
          <div className="mt-40 flex justify-end">
            <Button onClick={handleUpdateLanguages}>Save Settings</Button>
          </div>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default TutorLanguages;
