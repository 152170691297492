import React from 'react';
import { Avatar, Rate } from 'antd';
import locationIcon from '../../assets/images/tutor/location.svg';
import StoreSectionLayout from './StoreSectionLayout';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';

const StoreFrontHeader = ({ storefrontData }) => {
  const navigate = useNavigate();
  const { user, totalWorkExperience } = storefrontData;
  const experience = (totalWorkExperience || '')
    .split(' ')
    .slice(0, 4)
    .join(' ');

  return (
    <div className="rounded-b-2xl bg-updatedGreenish bg-cover py-20">
      <div className="grid gap-10 text-white xs:grid-cols-1 md:mx-20 md:grid-cols-12">
        <div className="xs:col-span-1 xs:grid-cols-3 md:col-span-4 md:grid md:grid-cols-2">
          {/* Back Arrow */}
          <div className="flex items-start justify-center gap-5 pb-5 md:col-span-1">
            <KeyboardBackspaceIcon
              className="cursor-pointer text-updatedBlue hover:shadow-lg"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          {/* Avatar, Rating, and Location Information */}
          <div className="flex flex-col items-center justify-center   md:col-span-1">
            <Avatar size={150} src={user?.profile_picture} />
            <div className="flex items-center justify-center pt-2">
              <img className="h-6 w-6" src={locationIcon} alt="" />
              <p className="ml-4 font-semibold text-updatedBlue">
                {user?.city}
              </p>
            </div>
            <div className="flex items-center justify-center pt-2">
              <p className="mr-2 font-semibold text-updatedBlue">
                {user?.averageReview ? user?.averageReview.toFixed(1) : 0}
              </p>
              <Rate disabled allowHalf defaultValue={user?.averageReview} />
            </div>
          </div>
        </div>
        {/* User Information */}
        <div className=" xs:col-span-1 xs:justify-center md:col-span-8 md:justify-start">
          <div className="mb-5 flex flex-col xs:items-center xs:justify-center md:items-start md:justify-start">
            <p className="text-6xl font-semibold text-updatedBlue xs:text-center">
              {user?.first_name + ' ' + user?.last_name}
            </p>
          </div>
          <div className="flex flex-wrap items-center gap-8 xs:justify-center md:justify-start">
            <div className="flex flex-col gap-4">
              <p className="font-semibold text-updatedPink">Experience</p>
              <p className=" font-semibold text-updatedBlue">{experience}</p>
            </div>
            <span className="w-9 rotate-90 border border-gray-500 border-opacity-30 xs:hidden lg:block" />
            <div className="flex flex-col gap-4">
              <p className="font-bold text-updatedPink">Gender</p>
              <p className="font-semibold text-updatedBlue">
                {user?.gender ? user?.gender : 'N/A'}
              </p>
            </div>
          </div>
          <div className="mt-10 flex flex-col gap-4 xs:items-center xs:justify-center md:items-start md:justify-start">
            <p className="font-semibold text-updatedPink">Languages</p>
            <div className="flex flex-wrap items-center gap-5 xs:justify-center md:justify-start">
              {user?.languages.length ? (
                user?.languages?.map((language) => (
                  <StoreSectionLayout
                    className="rounded-md border border-updatedBlue px-5 py-2 font-medium text-updatedBlue"
                    languageName={language}
                  />
                ))
              ) : (
                <div className="font-semibold text-updatedBlue">N/A</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreFrontHeader;
