export const usCities = [
  'New York',
  'Los Angeles',
  'Chicago',
  'Houston',
  'Phoenix',
  'Philadelphia',
  'San Antonio',
  'San Diego',
  'Dallas',
  'San Jose',
  'Austin',
  'Jacksonville',
  'San Francisco',
  'Columbus',
  'Fort Worth',
  'Indianapolis',
  'Charlotte',
  'Seattle',
  'Denver',
  'Washington, D.C.',
  'Boston',
  'El Paso',
  'Nashville',
  'Detroit',
  'Oklahoma City',
  'Portland',
  'Las Vegas',
  'Memphis',
  'Louisville',
  'Baltimore',
  'Milwaukee',
  'Albuquerque',
  'Tucson',
  'Fresno',
  'Sacramento',
  'Kansas City',
  'Long Beach',
  'Mesa',
  'Atlanta',
  'Colorado Springs',
  'Virginia Beach',
  'Raleigh',
  'Omaha',
  'Miami',
  'Oakland',
  'Minneapolis',
  'Tulsa',
  'Wichita',
  'Arlington',
  'New Orleans',
  'Cleveland',
  'Bakersfield',
  'Tampa',
  'Aurora',
  'Honolulu',
  'Anaheim',
  'Santa Ana',
  'Corpus Christi',
  'Riverside',
  'St. Louis',
  'Lexington',
  'Pittsburgh',
  'Stockton',
  'Anchorage',
  'Cincinnati',
  'St. Paul',
  'Greensboro',
  'Toledo',
  'Newark',
  'Plano',
  'Henderson',
  'Lincoln',
  'Orlando',
  'Chula Vista',
  'Jersey City',
  'Chandler',
  'Fort Wayne',
  'Buffalo',
  'Durham',
  'St. Petersburg',
  'Irvine',
  'Laredo',
  'Lubbock',
  'Madison',
  'Gilbert',
  'Norfolk',
  'Reno',
  'Winston-Salem',
  'Glendale',
  'Hialeah',
  'Garland',
  'Scottsdale',
  'Irving',
  'Chesapeake',
  'North Las Vegas',
  'Fremont',
  'Baton Rouge',
  'Richmond',
  'Boise',
  'San Bernardino',
  'Spokane',
  'Des Moines',
  'Modesto',
  'Birmingham',
  'Tacoma',
  'Fontana',
  'Rochester',
  'Oxnard',
  'Moreno Valley',
  'Fayetteville',
  'Yonkers',
  'Huntington Beach',
  'Montgomery',
  'Amarillo',
  'Little Rock',
  'Akron',
  'Augusta',
  'Grand Rapids',
  'Shreveport',
  'Mobile',
  'Salt Lake City',
  'Huntsville',
  'Tallahassee',
  'Grand Prairie',
  'Overland Park',
  'Knoxville',
  'Worcester',
  'Brownsville',
  'Newport News',
  'Santa Clarita',
  'Port St. Lucie',
  'Providence',
  'Fort Lauderdale',
  'Oceanside',
  'Rancho Cucamonga',
  'Chattanooga',
  'Tempe',
  'Santa Rosa',
  'Garden Grove',
  'Cape Coral',
  'Pembroke Pines',
  'Elk Grove',
  'Salem',
  'Eugene',
  'Peoria',
  'McKinney',
  'Fort Collins',
  'Lancaster',
  'Cary',
  'Palmdale',
  'Hayward',
  'Salinas',
  'Springfield',
  'Macon',
  'Pasadena',
  'Pomona',
  'Hollywood',
  'Escondido',
  'Clarksville',
  'Joliet',
  'Rockford',
  'Torrance',
  'Naperville',
  'Paterson',
  'Savannah',
  'Bridgeport',
  'Mesquite',
  'Killeen',
  'Syracuse',
  'McAllen',
  'Bellevue',
  'Fullerton',
  'Orange',
  'Dayton',
  'Miramar',
  'Thornton',
  'West Valley City',
  'Olathe',
  'Hampton',
  'Warren',
  'Midland',
  'Waco',
  'Charleston',
  'Columbia',
  'Denton',
  'Carrollton',
  'Surprise',
  'Roseville',
  'Sterling Heights',
  'Sioux Falls',
  'Allentown',
  'Elizabeth',
  'Clearwater',
  'Provo',
  'Murrieta',
  'Waterbury',
  'Costa Mesa',
  'Everett',
  'Richardson',
  'Downey',
  'Manchester',
  'Arvada',
  'Ventura',
  'Pueblo',
  'Lowell',
  'Gainesville',
  'Inglewood',
  'Santa Clara',
  'Fairfield',
  'West Covina',
  'Billings',
  'Round Rock',
  'Pompano Beach',
  'North Charleston',
  'Norwalk',
  'West Palm Beach',
  'Boulder',
  'Broken Arrow',
  'Green Bay',
  'Santa Maria',
  'Wichita Falls',
  'Lakeland',
  'Clovis',
  'Lewisville',
  'Tyler',
  'El Cajon',
  'San Mateo',
  'Rialto',
  'Edison',
  'Davenport',
  'South Bend',
  'Las Cruces',
  'Woodbridge',
  'Sparks',
  'Lakewood',
  'Hillsboro',
  'Vista',
  'Renton',
  'Davie',
  'Greeley',
  'Mission Viejo',
  'Wilmington',
  'High Point',
  'Meridian',
  'Decatur',
  'Harlingen',
  'Longview',
  'Edinburg',
  'Farmington Hills',
  'Bend',
  'San Marcos',
  'Bryan',
  'Palm Coast',
  'Waukegan',
  'Murfreesboro',
  'Hemet',
  'Lawrence',
  'Hesperia',
  'Santa Monica',
  'Westminster',
  'Santa Barbara',
];
