import { Layout, Select } from 'antd';
import Button from 'components/Button';
import Navbar from 'components/shared/Navbar/Navbar';
import StudentDetailsLayout from 'components/student/StudentDetailsLayout';
import { STUDENT } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import { userContext } from 'contexts/user';
import { studentNavbarItems } from 'enums';
import { LANGUAGES_ARRAY } from 'enums/languages';
import React, { useContext, useEffect, useState } from 'react';
import { getStudentById, updateStudentLanguages } from 'requests/students';

const Languages = () => {
  const [languages, setLanguages] = useState([]);
  const [originalLanguages, setOriginalLanguages] = useState([]);
  const [languagesChanged, setLanguagesChanged] = useState(false);
  const { api } = useContext(notificationsContext);
  const { user } = useContext(userContext);
  const studentId = user.id;

  const getTutorDetails = async () => {
    try {
      const { data } = await getStudentById(studentId);
      setLanguages(data.languages ?? []);
      setOriginalLanguages(data.languages ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      await getTutorDetails();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const haveLanguagesChanged =
      JSON.stringify(languages) !== JSON.stringify(originalLanguages);
    setLanguagesChanged(haveLanguagesChanged);
  }, [languages, originalLanguages]);

  const handleLanguageUpdate = async () => {
    try {
      await updateStudentLanguages(studentId, languages);
      api.success({
        message: 'Success',
        description: 'Languages updated successfully!',
        placement: 'top',
        duration: 4,
      });
      setOriginalLanguages(languages);
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={studentNavbarItems} role={STUDENT} />
        <StudentDetailsLayout>
          <div className="flex w-full flex-col items-center">
            <div className="mt-10 w-full">
              <div className="flex items-center justify-between text-2xl font-semibold text-updatedBlue">
                <div>Detailed Profile / Languages</div>
                {languagesChanged && (
                  <Button onClick={handleLanguageUpdate}>Save Settings</Button>
                )}
              </div>
              <div className="mt-8 text-xl text-updatedBlue">Languages</div>
              <div id="languages">
                <Select
                  className="mt-10 w-full rounded-lg border border-neutral-200"
                  value={languages}
                  onChange={setLanguages}
                  mode="multiple"
                  optionLabelProp="label"
                >
                  {LANGUAGES_ARRAY.map((language) => (
                    <Select.Option value={language.name}>
                      {language.name} ({language.nativeName})
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </StudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default Languages;
