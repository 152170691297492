/* eslint-disable array-callback-return */
import { Avatar, Form, Table } from 'antd';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import EditAndDeleteActionButtons from 'components/shared/EditAndDeleteActionButtons/EditAndDeleteActionButtons';
import AddSchoolModal from 'components/student/AddSchoolModal';
import DeleteSchoolModal from 'components/student/DeleteSchoolModal';
import EditSchoolModal from 'components/student/EditSchoolModal';
import { ADMIN } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import { selectedUserContext } from 'contexts/selected-user';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  addSchool,
  deleteSchool,
  getSchools,
  updateSchool,
} from 'requests/school-profiling';

const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const SchoolProfilingDetails = ({ studentId, mode }) => {
  const [schoolData, setSchoolData] = useState([]);
  const [isContinueSelected, setIsContinueSelected] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [isAddSchoolModalOpen, setIsAddSchoolModalOpen] = useState(false);
  const [isDeleteSchoolModalOpen, setIsDeleteSchoolModalOpen] = useState(false);
  const [isEditSchoolModalOpen, setIsEditSchoolModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { selectedUser } = useContext(selectedUserContext);
  const [form] = Form.useForm();
  const { api } = useContext(notificationsContext);

  const columns = [
    {
      title: 'Sr. No.',
      render: (text, record, index) => <TableText>{index + 1}</TableText>,
    },
    {
      title: 'School Name',
      dataIndex: 'school_name',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Grades',
      dataIndex: 'grades',
      render: (text) => {
        return <TableText>{text.join(', ')}</TableText>;
      },
    },
    {
      title: 'City',
      dataIndex: 'city',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'State',
      dataIndex: 'state',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Attended From',
      dataIndex: 'attended_from',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Attended Till',
      dataIndex: 'attended_till',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      align: 'center',
      render: (_, record) => {
        return (
          <EditAndDeleteActionButtons
            record={record}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        );
      },
    },
  ];

  useEffect(() => {
    (async () => {
      await fetchSchoolData();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSchoolData = async () => {
    try {
      setIsLoading(true);
      const { data: schoolList } = await getSchools(studentId);
      schoolList.map((school) => {
        if (school.attended_from) {
          school.attended_from = moment(school.attended_from).format(
            'MMMM D YYYY'
          );
        }
        if (school.is_continue) {
          school.attended_till = 'Ongoing';
        } else if (school.attended_till) {
          school.attended_till = moment(school.attended_till).format(
            'MMMM D YYYY'
          );
        }
        if (school.is_continue) {
          setIsContinueSelected(true);
        }
      });
      setSchoolData(schoolList);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      api.error({
        message: 'Error',
        description: 'Error fetching the school details',
        placement: 'top',
        duration: 4,
      });
      throw new Error(error);
    }
  };

  const handleAddSchool = async (values) => {
    try {
      setIsLoading(true);
      if (isContinueSelected) {
        values = {
          ...values,
          attended_till: null,
          is_continue: isContinueSelected,
        };
      } else {
        values = {
          ...values,
          is_continue: false,
        };
      }

      const formattedValues = {
        ...values,
        attended_from: values.attended_from
          ? values.attended_from.format('YYYY-MM-DD')
          : null,
        attended_till: values.attended_till
          ? values.attended_till.format('YYYY-MM-DD')
          : null,
      };

      await addSchool(studentId, formattedValues);
      await fetchSchoolData();
      api.success({
        message: 'Success',
        description: 'School added successfully!',
        placement: 'top',
        duration: 4,
      });
      setIsAddSchoolModalOpen(false);
      setSelectedSchool(null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      api.error({
        message: 'Error',
        description: 'Error deleting school! Please try again after sometime!',
        placement: 'top',
        duration: 4,
      });
      throw new Error(error);
    }
  };

  const handleEditSchool = async (values) => {
    try {
      setIsLoading(true);
      if (isContinueSelected) {
        values = {
          ...values,
          attended_till: null,
          is_continue: isContinueSelected,
        };
      } else {
        values = {
          ...values,
          is_continue: false,
        };
      }

      const formattedValues = {
        ...values,
        attended_from: values.attended_from
          ? values.attended_from.format('YYYY-MM-DD')
          : null,
        attended_till: values.attended_till
          ? values.attended_till.format('YYYY-MM-DD')
          : null,
      };
      await updateSchool(studentId, selectedSchool.id, formattedValues);
      await fetchSchoolData();
      api.success({
        message: 'Success',
        description: 'School updated successfully!',
        placement: 'top',
        duration: 4,
      });
      setIsEditSchoolModalOpen(false);
      setSelectedSchool(null);
      setIsLoading(false);
    } catch (error) {
      api.error({
        message: 'Error',
        description: 'Error updating school! Please try again after sometime!',
        placement: 'top',
        duration: 4,
      });
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const handleAdd = () => {
    handleClearForm();
    setIsAddSchoolModalOpen(true);
  };

  const handleEdit = (record) => {
    setIsContinueSelected(record.is_continue);
    setSelectedSchool(record);
    setIsEditSchoolModalOpen(true);
  };

  const handleDelete = (record) => {
    setSelectedSchool(record);
    setIsDeleteSchoolModalOpen(true);
  };

  const onChangeCheckbox = (e) => {
    setIsContinueSelected(e.target.checked);
  };

  const handleDeleteSchool = async () => {
    try {
      setIsLoading(true);
      const { id: schoolProfilingId } = selectedSchool;
      await deleteSchool(studentId, schoolProfilingId);
      api.success({
        message: 'Success',
        description: 'School deleted successfully!',
        placement: 'top',
        duration: 4,
      });
      await fetchSchoolData();
      setIsDeleteSchoolModalOpen(false);
      setSelectedSchool(null);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      api.error({
        message: 'Error',
        description: 'Error deleting school! Please try again after sometime!',
        placement: 'top',
        duration: 4,
      });
      throw new Error(error);
    }
  };

  const handleClearForm = () => {
    form.resetFields();
  };

  return (
    <div>
      <DeleteSchoolModal
        isDeleteSchoolModalOpen={isDeleteSchoolModalOpen}
        setIsDeleteSchoolModalOpen={setIsDeleteSchoolModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={() => {
          setIsDeleteSchoolModalOpen(false);
        }}
        handleDelete={handleDeleteSchool}
      />
      <AddSchoolModal
        isAddSchoolModalOpen={isAddSchoolModalOpen}
        setIsAddSchoolModalOpen={setIsAddSchoolModalOpen}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleAddSchool={handleAddSchool}
        handleCancel={() => {
          setIsAddSchoolModalOpen(false);
        }}
        form={form}
        onChangeCheckbox={onChangeCheckbox}
        isContinueSelected={isContinueSelected}
      />
      <EditSchoolModal
        isEditSchoolModalOpen={isEditSchoolModalOpen}
        setIsEditSchoolModalOpen={setIsEditSchoolModalOpen}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleEditSchool={handleEditSchool}
        handleCancel={() => {
          setIsEditSchoolModalOpen(false);
        }}
        selectedSchool={selectedSchool}
        isContinueSelected={isContinueSelected}
        setIsContinueSelected={setIsContinueSelected}
        onChangeCheckbox={onChangeCheckbox}
      />
      <div className="flex w-full flex-col items-center">
        {/* Avatar and name */}
        {mode === ADMIN && (
          <div className="flex justify-center pb-5">
            <div className="flex flex-col items-center justify-center gap-1">
              <Avatar size={100} src={selectedUser.profile_picture} />
              <div
                style={{ fontFamily: 'Now' }}
                className="text-2xl font-semibold leading-relaxed text-updatedBlue"
              >
                {`${selectedUser.first_name} ${selectedUser.last_name}`}
              </div>
            </div>
          </div>
        )}
        {/* Header and button */}
        <div className="mt-10 flex w-full items-center justify-between">
          <div
            style={{ fontFamily: 'Bogart' }}
            className="font-semibold text-updatedBlue xs:text-xl md:text-2xl"
          >
            Detailed Profile / School Profiling
          </div>
          <CustomButton
            size="large"
            shape="round"
            className={`${
              mode === ADMIN ? '!bg-updatedBlue' : '!bg-updatedBlue'
            } text-white`}
            label={<p style={{ fontFamily: 'Now' }}>Add School</p>}
            onClick={handleAdd}
          >
            Add School
          </CustomButton>
        </div>
        {/* Data table */}
        <div
          id="ParentManagementTable"
          className="my-10 flex w-full justify-center overflow-scroll"
        >
          <Table
            style={{ fontFamily: 'Now' }}
            columns={columns}
            dataSource={schoolData}
            className="w-full"
            scroll={{
              x: 1000,
              y: 500,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SchoolProfilingDetails;
