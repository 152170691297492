import axios from 'axios';
import { getRefreshToken } from './oauthGoogle';

const axiosInstance = axios.create({
  baseURL: 'https://www.googleapis.com/calendar/v3',
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const access_token = localStorage.getItem('googleCalendarToken');
    config.headers = {
      Authorization: `Bearer ${access_token}`,
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refresh_token = localStorage.getItem('googleCalendarRefreshToken');
      const {
        data: { access_token },
      } = await getRefreshToken(refresh_token);
      localStorage.setItem('googleCalendarToken', access_token);

      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const getCalendarEvents = (props) => {
  props = props || {};

  const paramsToString = (params) => {
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  };
  let paramString = paramsToString(props) || '';
  paramString = paramString ? `?${paramString}` : '';

  return axiosInstance.get(`/calendars/primary/events${paramString}`);
};
