import { Modal } from 'antd';
import StreamAppChat from 'pages/student/stream-chat';
import React, { useState } from 'react';

const StreamChatModal = ({ isModalOpen, handleOk, handleCancel, id, name }) => {
  const [client, setClient] = useState('');
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
        setClient(null);
        client.disconnectUser();
      }}
      footer={null}
      className="!w-[900px] !min-w-[300px]"
    >
      <div className="h-[70vh]">
        <StreamAppChat
          id={id}
          name={name}
          setChatClient={setClient}
          chatClient={client}
        />
      </div>
    </Modal>
  );
};

export default StreamChatModal;
