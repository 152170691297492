import React, { useContext, useEffect, useState } from 'react';
import { notificationsContext } from 'contexts/notifications';
import { generatePath, useNavigate } from 'react-router-dom';
import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';
import cn from 'classnames';
import { deactivateParent, getParentById } from 'requests';
import { Message } from 'constants/message';
import { userContext } from 'contexts/user';
import { editParentDetailsSidebarOptions } from './side-bar-options';
import DeactivateAccountModal from 'components/shared/DeactivateAccountModal/DeactivateAccountModal';

const EditParentGeneralProfileSidebar = () => {
  const navigate = useNavigate();
  const pathName = window.location.pathname;
  // eslint-disable-next-line no-unused-vars
  const [studentData, setStudentData] = useState('');
  const { api } = useContext(notificationsContext);
  const { user, logout } = useContext(userContext);
  const parentId = user.id;
  const [expanded, setExpanded] = useState(() => {
    if (
      generatePath(editParentDetailsSidebarOptions[0].path, {
        id: parentId,
      }) === pathName
    )
      return '';
    else return 'detail-profiling';
  });
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      await getStudentStatus();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   This function fetches the student record by id.
  const getStudentStatus = async () => {
    try {
      setIsLoading(true);
      const { data: parentRecord } = await getParentById(parentId);
      setStudentData(parentRecord);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error[0].message);
    }
  };

  const handleDeactivateAccount = async () => {
    try {
      const response = await deactivateParent(parentId);
      api.success({
        message: 'Success',
        description: Message.deactivated,
        placement: 'top',
        duration: 4,
      });
      setIsModalOpen(false);
      logout();
      navigate('/parent/login');
    } catch (error) {
      console.log({ error });
      api.error({
        message: 'Error',
        description: Message.errorMessage,
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <>
      <DeactivateAccountModal
        isDeactivateAccountModalOpen={isModalOpen}
        setIsDeactivateAccountModalOpen={setIsModalOpen}
        setIsLoading={setIsLoading}
        handleDelete={handleDeactivateAccount}
      />
      <div className="top-6 my-[24px] flex flex-col items-center bg-updatedMustard xs:mx-10  xs:justify-center md:sticky md:h-[calc(100vh_-_48px)] md:w-[360px] md:justify-between">
        <div className="mt-5 flex w-full flex-col xs:h-44 md:h-[510px]">
          {editParentDetailsSidebarOptions.map(
            ({ label, path, children, name }) => {
              const isCurrentPath =
                (path && generatePath(path, { id: parentId }) === pathName) ||
                (children &&
                  children?.find(
                    ({ path }) =>
                      generatePath(path, { id: parentId }) === pathName
                  ));

              return (
                <div
                  className={`overflow-hidden transition-all duration-300 ${
                    expanded === name ? 'h-[470px]' : 'h-10'
                  }`}
                >
                  <div
                    onClick={() => {
                      if (path && !isCurrentPath)
                        navigate(path.replace(':id', parentId));
                      if (children && children.length > 0)
                        if (expanded === name) setExpanded('');
                        else setExpanded(name);
                    }}
                    style={{ fontFamily: 'Now' }}
                    className={cn(
                      'flex w-full cursor-pointer items-center justify-between px-6 py-[9px]',
                      {
                        'border-r-4 border-updatedBlue bg-updatedCreme text-updatedBlue hover:text-gray-700':
                          isCurrentPath,
                        'bg-updatedMustard text-updatedBlue hover:text-gray-700':
                          !isCurrentPath,
                      }
                    )}
                  >
                    <div>{label}</div>
                    {children && children.length > 0 && (
                      <div
                        className={`transition-all duration-300 ${
                          expanded === name ? 'rotate-180' : 'rotate-0'
                        }`}
                      >
                        <DropdownArrow
                          className={cn('h-5 w-5', {
                            'text-updatedBlue': isCurrentPath,
                            'text-gray-500': !isCurrentPath,
                          })}
                        />
                      </div>
                    )}
                  </div>
                  {children &&
                    children.map(({ label, path }) => {
                      const isCurrentChildPath =
                        path &&
                        generatePath(path, { id: parentId }) === pathName;

                      return (
                        <div
                          style={{ fontFamily: 'Now' }}
                          onClick={() => {
                            if (
                              path &&
                              generatePath(path, { id: parentId }) !== pathName
                            )
                              navigate(path.replace(':id', parentId));
                          }}
                          className={cn(
                            'ml-5 w-full cursor-pointer items-center justify-start py-[9px] pl-6 hover:text-gray-700',
                            {
                              ' text-updatedBlue': isCurrentChildPath,
                              ' text-updatedBlue': !isCurrentChildPath,
                            }
                          )}
                        >
                          {label}
                        </div>
                      );
                    })}
                </div>
              );
            }
          )}
        </div>

        <div className="mb-10 w-full px-10">
          <div className="my-2 h-[0px] w-full border border-neutral-200" />
          <div
            className="mt-5 flex cursor-pointer items-center justify-center py-5 text-base font-medium leading-normal text-red-500"
            onClick={() => setIsModalOpen((prev) => !prev)}
          >
            Deactivate Account
          </div>
        </div>
      </div>
    </>
  );
};

export default EditParentGeneralProfileSidebar;
