import { Layout, Table, Tag } from 'antd';
import Navbar from 'components/shared/Navbar/Navbar';
import React, { useContext, useEffect, useState } from 'react';
import backgroundImg from 'assets/images/tutor/background.png';

import { parentNavbarItems } from 'enums';
import { PARENT } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import { getAppointments } from 'requests/appointments';
import moment from 'moment';

const StudentManagement = () => {
  const [data, setData] = useState([]);
  const { api } = useContext(notificationsContext);

  const getPayments = async () => {
    try {
      const { data } = await getAppointments();
      setData(data.map((value, index) => ({ key: index + 1, ...value })));
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again later.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Student ID',
      dataIndex: 'student_id',
      key: 'student_id',
    },
    {
      title: 'Student Name',
      dataIndex: 'student_name',
      key: 'student_name',
    },
    {
      title: 'Tutor Name',
      dataIndex: 'tutor_name',
      key: 'tutor_name',
    },
    {
      title: 'Agenda',
      dataIndex: 'agenda',
      key: 'agenda',
    },
    {
      title: 'Appointment Date',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_, { start_time }) => {
        return moment(start_time).format('MMMM Do, YYYY LT');
      },
    },
    {
      title: 'Payment Amount',
      dataIndex: 'tutor_pricing',
      key: 'tutor_pricing',
      render: (_, { tutor_pricing }) => {
        return `$${tutor_pricing}`;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { status }) => {
        return (
          <Tag color={status === 'paid' ? 'green' : 'red'}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Tag>
        );
      },
    },
  ];

  return (
    <Layout>
      <Navbar role={PARENT} navItems={parentNavbarItems} />
      <div className="Tutorsue-400] min-h-[calc(100vh-64px)] bg-updatedGreenish">
        <div className="mx-12 my-7 min-h-[calc(100vh-120px)] rounded-2xl bg-updatedMustard px-10 pb-5 pt-10">
          <div className="mb-10 text-2xl font-semibold">Payments</div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{
              x: 1600,
              y: 500,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default StudentManagement;
