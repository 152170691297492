import React from 'react';
import { Form } from 'antd';
import FormInput from '../FormInput/FormInput';
import { SearchOutlined } from '@ant-design/icons';

const SearchBar = () => {
  return (
    <>
      <Form layout="inline">
        <div>
          <FormInput
            name="searchText"
            className="h-12 shrink grow basis-0 self-stretch rounded-xl text-base font-normal leading-normal text-gray-500 xs:w-60 sm:w-80 md:w-96"
            placeholder="Search"
            prefix={<SearchOutlined />}
          />
        </div>
      </Form>
    </>
  );
};

export default SearchBar;
