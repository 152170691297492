import { userContext } from 'contexts/user';
import React, { useContext } from 'react';
import { ReactComponent as FacebookLogo } from 'assets/images/facebook.svg';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { notificationsContext } from 'contexts/notifications';

const FacebookLoginButton = () => {
  const { user, updateUser } = useContext(userContext);
  const { api } = useContext(notificationsContext);

  const isFacebookConnected = user.facebook_access_token && user.facebook_id;

  const sendAuthorizationCodeToServer = async (code, user_id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FRONT_END_BASE_URL}/api/meta/getMetaAuthCode`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ code, user_id }),
        }
      );

      const data = await response.json();
      updateUser(data?.data);
    } catch (error) {
      console.error(
        'Error sending authorization code to server:',
        error.message
      );
    }
  };

  const removeMetaAccess = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FRONT_END_BASE_URL}/api/parents/removeMetaAccess/${user.id}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await response.json();
      updateUser(data?.data);
      api.success({
        message: 'Success',
        description: 'Meta Access Removed',
        placement: 'top',
        duration: 4,
      });
    } catch (error) {
      console.error(
        'Error sending authorization code to server:',
        error.message
      );
    }
  };

  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex w-full items-center justify-between gap-8">
        <div className="flex-1 text-2xl font-semibold text-updatedBlue">
          Detailed Profile / Connect Social Media
        </div>
      </div>
      <div className="mt-5 flex w-full gap-10 border-t border-gray-500 border-opacity-25 pt-7 xs:flex-col lg:flex-row">
        <div className="h-60 flex-1 border-gray-500 border-opacity-25 pb-10 xs:border-b lg:border-b-0 lg:border-r">
          <div className="text-lg font-semibold text-updatedBlue">
            Integrate Facebook Account
          </div>
          <div className="mt-10">
            {isFacebookConnected ? (
              <div className="flex flex-row items-center gap-2">
                <FacebookLogo width="48px" height="48px" />
                <div className="flex flex-row items-center gap-2">
                  <div className="h-3 w-3 rounded-full bg-updatedBlue" />
                  <div>{user.facebook_email}</div>
                </div>
                <button
                  className="ml-56 flex items-center justify-center rounded-lg bg-gray-600 px-5 py-3 text-center text-sm font-medium text-white"
                  onClick={removeMetaAccess}
                >
                  Logout
                </button>
              </div>
            ) : (
              <button className="flex items-center justify-center rounded-lg bg-[#1A77F2] px-5 py-3 text-center text-sm font-medium text-white">
                <div style={{ marginRight: '8px' }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </div>
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onSuccess={(response) => {
                    sendAuthorizationCodeToServer(
                      response.accessToken,
                      user.id
                    );
                  }}
                />
              </button>
            )}
            <div className="mt-10 max-w-xl rounded-lg border-gray-300">
              <p className="text-lg font-bold">Disclaimer:</p>
              <p className="text-justify">
                By logging in with Facebook on Skool'd Tutors, you grant us
                access to your basic Facebook information, including your
                Facebook ID and email address. We use this information to
                enhance your experience and to connect you with friends who are
                also using our platform. Rest assured, we prioritize your
                privacy and only access the limited information necessary for
                these purposes. If you have any concerns, please reach out to
                us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacebookLoginButton;
