export const editParentDetailsSidebarOptions = [
  {
    label: 'General Profile',
    name: 'general-profile',
    path: '/parent/general-profile',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',
    children: [
      {
        label: 'Language Proficiency',
        name: 'language-proficiency',
        path: '/parent/language-proficiency',
      },
      {
        label: 'Payment Configuration',
        name: 'payment-configuration',
        path: '/parent/payment-configuration',
      },
      {
        label: 'Connect Calendar',
        name: 'connect-calendar',
        path: '/parent/connect-calendar',
      },
      {
        label: 'Connect Social Media',
        name: 'connect-social-media',
        path: '/parent/connect-social-media',
      },
    ],
  },
];
