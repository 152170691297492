import React from 'react';
import backgroundImg from '../../../assets/images/tutor/background.png';
import Navbar from '../Navbar/Navbar';
import { Layout } from 'antd';
import { tutorNavbarItems } from 'enums';
import { TUTOR } from 'constants/roles';

const ContentManagement = () => {
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={tutorNavbarItems} role={TUTOR} />
        <div
          style={{
            backgroundImage: `url(${backgroundImg})`,
            backgroundSize: 'cover',
          }}
          className="h-screen"
        >
          Content Management Section
        </div>
      </Layout>
    </div>
  );
};

export default ContentManagement;
