import { useContext, useEffect, useState } from 'react';
import { Modal as AntdModal, Select, Input } from 'antd';
import { addTutorSubject, getSubjects } from 'requests/subjects';
import { notificationsContext } from 'contexts/notifications';
import { Formik } from 'formik';
import * as yup from 'yup';
import DeleteActionButton from 'components/DeleteActionButton';
import { ReactComponent as Loader } from 'assets/images/loader.svg';

const UseAddSubjectModal = (tutorId, callback) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subjects, setSubjects] = useState([]);
  const { api } = useContext(notificationsContext);

  const validationSchema = yup.object().shape({
    subject: yup
      .string()
      .oneOf(subjects.map(({ title }) => title))
      .required(),
    document: yup.mixed(),
  });

  const getAllSubjects = async () => {
    try {
      const {
        data: { data: subjects },
      } = await getSubjects();
      const filtered = subjects.filter((subject) => !subject?.is_hidden);
      setSubjects([...filtered, { title: 'Other...' }]);
    } catch (err) {
      api.error({
        message: 'Error',
        description:
          'Something went wrong while getting the subjects. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getAllSubjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async (values) => {
    try {
      const formData = new FormData();

      if (values.otherSubject) {
        formData.append('other_subject', values.otherSubject);
      } else {
        formData.append('title', values.subject);
      }

      if (values.document) {
        formData.append('document', values.document);
      }

      await addTutorSubject(tutorId, formData);
      api.success({
        message: 'Success',
        description: 'Subject added successfully',
        placement: 'top',
        duration: 4,
      });
      if (callback) callback();
    } catch (err) {
      if (err?.response?.data?.error === 'Subject already exists!')
        api.error({
          message: 'Error',
          description:
            'Subject already exists. Please try again with a different subject.',
          placement: 'top',
          duration: 4,
        });
      else
        api.error({
          message: 'Error',
          description:
            'Something went wrong while adding the subject. Please try again after some time.',
          placement: 'top',
          duration: 4,
        });
    } finally {
      handleCancel();
    }
  };

  const AddSubjectModal = ({ className, ...props }) => {
    return (
      <AntdModal
        width={'max-content'}
        {...props}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={{ subject: null, document: '' }}
          validateOnMount={true}
          onSubmit={handleOk}
        >
          {({ isValid, handleSubmit, setFieldValue, values, isSubmitting }) => (
            <div className="w-[500px] p-7">
              <div className="text-2xl font-semibold text-updatedBlue">
                New Subject Expertise
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Choose Subjects
                </div>
                <Select
                  className="mt-3 h-14 xs:w-[60%] md:w-full"
                  placeholder="Choose Subjects"
                  value={values.subject}
                  onChange={(value) => {
                    setFieldValue('subject', value);
                  }}
                >
                  {subjects.map(({ title }) => {
                    return (
                      <Select.Option value={title}>
                        <div className="text-medium flex h-10 items-center ">
                          {title}
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>

              {values.subject === 'Other...' && (
                <Input
                  placeholder="Enter new subject"
                  size="large"
                  onChange={(e) => {
                    setFieldValue('otherSubject', e.target.value);
                  }}
                  className="mt-3"
                />
              )}

              <div className="mt-8 xs:w-[60%] md:w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Upload Proof (optional)
                </div>
                {!values.document ? (
                  <div className="relative mt-3 h-14 overflow-hidden rounded-lg">
                    <input
                      accept="image/*,.pdf"
                      type="file"
                      className="h-full w-full"
                      onChange={(event) => {
                        setFieldValue('document', event.target.files[0]);
                      }}
                    />
                    <div className="flex h-full">
                      <div className="flex h-full w-32 items-center justify-center rounded-bl-lg rounded-tl-lg border border-neutral-200 bg-slate-100">
                        Choose file
                      </div>

                      <div className="flex h-full flex-1 items-center rounded-br-lg rounded-tr-lg border border-neutral-200 px-5">
                        No file Choosen
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 flex items-center justify-between gap-5">
                    <div className="flex-1 overflow-hidden text-base font-medium text-gray-500">
                      {values.document?.name}
                    </div>
                    <div>
                      <DeleteActionButton
                        onClick={() => {
                          setFieldValue('document', null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <button
                  className={`mt-12 h-12 items-center justify-center rounded-3xl xs:w-[60%] md:w-full ${
                    isValid ? '!bg-updatedBlue' : 'bg-gray-500'
                  } text-white`}
                  onClick={handleSubmit}
                  disabled={!isValid || isSubmitting}
                >
                  {!isSubmitting ? (
                    'Add Subject'
                  ) : (
                    <div className="flex items-center justify-center xs:w-[60%] md:w-full">
                      <Loader color="white" className="spinner h-8 w-8" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </AntdModal>
    );
  };

  return { AddSubjectModal, showModal };
};

export default UseAddSubjectModal;
