const getRowNumber = (index, tableParams) =>
  (tableParams.pagination.current - 1) * tableParams.pagination.pageSize +
  index +
  1;

const capitalizeFirstLetter = (inputString) => {
  if (typeof inputString === 'string' && inputString.length > 0) {
    return inputString.charAt(0).toUpperCase() + inputString.slice(1);
  } else {
    return inputString;
  }
};

module.exports = { getRowNumber, capitalizeFirstLetter };
