import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'components/shared/Navbar/Navbar';
import backgroundImg from '../../assets/images/tutor/background.png';
import { Form, Layout } from 'antd';
import { STUDENT } from 'constants/roles';
import { impersonateStudentNavbarItems } from 'enums';
import SearchBar from 'components/shared/SearchBar/SearchBar';
import BrowseTutors from 'components/student/BrowseTutors';
import { browseTutors } from 'requests/students';
import { userContext } from 'contexts/user';

const ImpersonateFindTutors = () => {
  const [isDisability, setIsDisability] = useState(false);
  const [isConnectedFriend, setIsConnectedFriend] = useState(false);
  const [tutorData, setTutorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { user } = useContext(userContext);

  //   Fetching all tutors on page load.
  useEffect(() => {
    (async () => {
      await getTutorsData();
    })();
  }, []);

  //   This function fetches the tutors without filters
  const getTutorsData = async () => {
    try {
      setIsLoading(true);
      //   Browsing tutors without filters.
      const { data: tutorResponse } = await browseTutors({});
      setTutorData(tutorResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  //   This function is used to search tutors.
  const handleSearchSubmit = async (values) => {
    try {
      setIsLoading(true);
      values = {
        ...values,
        disability_teach: isDisability,
        network_filter: isConnectedFriend,
        user_id: user.id,
      };
      const { data: tutorResponse } = await browseTutors(values);
      setTutorData(tutorResponse);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  return (
    <div id="find-tutors">
      <Layout>
        <Navbar navItems={impersonateStudentNavbarItems} role={STUDENT} />
        <div className="bg-updatedGreenish md:h-screen">
          <div className="px-10 py-7">
            <SearchBar
              isDisability={isDisability}
              setIsDisability={setIsDisability}
              isConnectedFriend={isConnectedFriend}
              setIsConnectedFriend={setIsConnectedFriend}
              handleSearchSubmit={handleSearchSubmit}
              form={form}
            />
          </div>
          {/* Browse Tutors */}
          <div className="mx-10 mb-7">
            <BrowseTutors isLoading={isLoading} tutorData={tutorData} />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ImpersonateFindTutors;
