import React from 'react';
import { Select } from 'antd';

const AntSelect = ({
  name,
  label,
  mode = '',
  rules = {},
  placeholder,
  options,
  className = '',
  disabled,
  dropdownStyle = '',
  style = {},
  showArrow = false,
  bordered = false,
  size = 'large',
  showSearch = false,
  defaultValue,
  handleOnChange,
}) => {
  return (
    <Select
      style={style}
      mode={mode}
      dropdownStyle={dropdownStyle ? dropdownStyle : ''}
      placeholder={placeholder}
      size={size}
      options={options}
      className={`rounded-lg ${className ? className : 'placeholder-white'}`}
      disabled={disabled}
      showArrow={showArrow}
      bordered={bordered}
      showSearch={showSearch}
      defaultValue={defaultValue}
      onChange={handleOnChange}
    />
  );
};

export default AntSelect;
