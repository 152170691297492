import { Avatar, Radio, Space, Table } from 'antd';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import EditAndDeleteActionButtons from 'components/shared/EditAndDeleteActionButtons/EditAndDeleteActionButtons';
import AddEditDisability from 'components/student/AddEditDisabilityModal';
import DeleteDisabilityModal from 'components/student/DeleteDisabilityModal';
import { notificationsContext } from 'contexts/notifications';
import React, { useContext, useEffect, useState } from 'react';
import {
  deleteStudentDisabilities,
  getStudentDisabilities,
  postStudentDisabilities,
  postStudentDisabilityCheck,
  updateStudentDisabilities,
} from 'requests/student-disabilities';
import { getStudentById } from 'requests/students';
import profile from '../../../assets/images/admin/profile.png';
import { ADMIN } from 'constants/roles';

const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const DisabilityProfilingDetails = ({ studentId, mode }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDisabilityModalOpen, setIsDisabilityModalOpen] = useState(false);
  const [selectedDisability, setSelectedDisability] = useState(null);
  const [disabilityModalMode, setDisabilityModalMode] = useState('add');
  const [isDisabilitySelected, setIsDisabilitySelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [name, setName] = useState('');
  const { api } = useContext(notificationsContext);
  const [studentDisabilities, setStudentDisabilities] = useState([]);

  const getStudentDetails = async () => {
    try {
      const { data } = await getStudentById(studentId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
      setIsDisabilitySelected(data?.is_student_disable ?? false);
    } catch (err) {
      console.log(err);
    }
  };

  const getStudentDisabilitiesProfiles = async () => {
    try {
      const {
        data: { data: disabilities },
      } = await getStudentDisabilities(studentId);
      setStudentDisabilities(
        disabilities.map((disability, index) => ({
          ...disability,
          key: index,
        }))
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getStudentDetails();
    getStudentDisabilitiesProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (e) => {
    const value = e.target.value;
    try {
      await postStudentDisabilityCheck(studentId, value);
      setIsDisabilitySelected(value);
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  const columns = [
    {
      title: 'Sr. No.',
      render: (text, record, index) => <TableText>{index + 1}</TableText>,
      width: '10%',
      align: 'center',
    },
    {
      title: 'Disability Type',
      dataIndex: 'disability_type',
      render: (text) => <TableText>{text}</TableText>,
      width: '20%',
      align: 'center',
    },
    {
      title: 'Additional Notes',
      dataIndex: 'additional_notes',
      render: (text) => <TableText>{text}</TableText>,
    },

    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      width: '10%',
      align: 'center',
      render: (_, record) => {
        return (
          <EditAndDeleteActionButtons
            record={record}
            onDelete={handleDeleteModal}
            onEdit={handleEditModal}
          />
        );
      },
    },
  ];

  const handleAddModal = () => {
    setDisabilityModalMode('add');
    setIsDisabilityModalOpen(true);
  };

  const handleEditModal = (record) => {
    setSelectedDisability(record);
    setDisabilityModalMode('edit');
    setIsDisabilityModalOpen(true);
  };

  const handleDeleteModal = (record) => {
    setSelectedDisability(record);
    setIsDeleteModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedDisability({});
    setIsDisabilityModalOpen(false);
  };

  const handleAddEditDisability = async (values) => {
    try {
      if (disabilityModalMode === 'add') {
        await postStudentDisabilities(studentId, values);
      } else {
        await updateStudentDisabilities(
          studentId,
          selectedDisability?.id,
          values
        );
      }
      api.success({
        message: 'Success',
        description: 'Student Disability deleted successfully',
        placement: 'top',
        duration: 4,
      });
      setIsDisabilityModalOpen(false);
      setSelectedDisability({});
      getStudentDisabilitiesProfiles();
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  const handleDeleteDisability = async () => {
    try {
      await deleteStudentDisabilities(studentId, selectedDisability?.id);
      api.success({
        message: 'Success',
        description: 'Student Disabilities updated successfully',
        placement: 'top',
        duration: 4,
      });
      setIsDeleteModalOpen(false);
      setSelectedDisability({});
      getStudentDisabilitiesProfiles();
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <div className="w-full">
      <AddEditDisability
        mode={disabilityModalMode}
        isDisabilityModalOpen={isDisabilityModalOpen}
        setIsDisabilityModalOpen={setIsDisabilityModalOpen}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleAddEditDisability={handleAddEditDisability}
        handleCancel={handleCloseModal}
        initialValues={disabilityModalMode === 'edit' && selectedDisability}
      />
      <DeleteDisabilityModal
        isDeleteDisabilityModalOpen={isDeleteModalOpen}
        setIsDeleteDisabilityModalOpen={setIsDeleteModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={() => {
          setIsDeleteModalOpen(false);
        }}
        handeDelete={handleDeleteDisability}
      />
      <div className="flex w-full flex-col items-center">
        {/* Avatar and name */}
        {mode === ADMIN && (
          <div className="flex justify-center pb-5">
            <div className="flex flex-col items-center justify-center gap-1">
              <Avatar size={100} src={!!image ? image : profile} />
              <div className="text-2xl font-semibold leading-relaxed text-updatedBlue">
                {name}
              </div>
            </div>
          </div>
        )}

        {/* Header and button */}
        <div className="mt-10 flex w-full items-center justify-between">
          <div className="font-semibold text-updatedBlue xs:text-xl md:text-2xl">
            Detailed Profile / Disability Profiling
          </div>
          <CustomButton
            size="large"
            shape="round"
            className={`${
              mode === ADMIN
                ? 'bg-customBlack'
                : 'bg-updatedBlue hover:!bg-updatedBlue'
            } text-white`}
            label="Add Disability"
            onClick={handleAddModal}
            disabled={!isDisabilitySelected}
          />
        </div>
        {/* Disablity Question */}
        <div className="my-10 flex w-full flex-col justify-start gap-2">
          <div className="font-medium">Have you any disability?</div>
          <Radio.Group onChange={onChange} value={isDisabilitySelected}>
            <Space direction="vertical">
              <Radio value={true}>Yes</Radio>
              <Radio value={false}>No</Radio>
            </Space>
          </Radio.Group>
          <div className="mt-5 text-sm text-gray-500">
            If you have any disability, let us know by clicking the "Add
            Disability" button in the top right corner.
          </div>
        </div>
        {/* Data table */}
        {isDisabilitySelected ? (
          <div
            id="ParentManagementTable"
            className="my-10 flex w-full justify-center overflow-scroll"
          >
            <Table
              columns={columns}
              dataSource={studentDisabilities}
              className="w-full"
              scroll={{
                x: 1000,
                y: 500,
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default DisabilityProfilingDetails;
