import { notificationsContext } from 'contexts/notifications';
import React, { useContext, useEffect, useState } from 'react';
import {
  generatePath,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import { getStudentById, updateStudentStatus } from 'requests/students';
import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';
import arrow from 'assets/images/admin/back.svg';
import { studentDetailsSidebarOptions } from '../../constants/side-bar-options';
import cn from 'classnames';
import AntSelect from 'components/shared/AntSelect/AntSelect';
import { studentStatusOptions } from 'enums';
import { Message } from 'constants/message';

const StudentGeneralProfileSidebar = () => {
  const navigate = useNavigate();
  const { id: studentId } = useParams();
  const pathName = window.location.pathname;
  const [studentData, setStudentData] = useState({});
  const { api } = useContext(notificationsContext);
  const [expanded, setExpanded] = useState(() => {
    if (
      generatePath(studentDetailsSidebarOptions[0].path, { id: studentId }) ===
      pathName
    )
      return '';
    else return 'detail-profiling';
  });
  const [isLoading, setIsLoading] = useState(false);

  const history = useLocation();

  useEffect(() => {
    (async () => {
      await getStudentStatus();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //   This function fetches the student record by id.
  const getStudentStatus = async () => {
    try {
      setIsLoading(true);
      let { data: studentRecord } = await getStudentById(studentId);
      setStudentData(studentRecord);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error[0].message);
    }
  };

  const handleStudentStatusChange = async (e) => {
    try {
      await updateStudentStatus(studentId, e);
      api.success({
        message: 'Success',
        description: Message[e],
        placement: 'top',
        duration: 4,
      });
    } catch (error) {
      api.error({
        message: 'Error',
        description: Message.errorStatus,
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <div className="sticky top-6 mx-6 my-[24px] flex h-[calc(100vh_-_48px)] w-[450px] flex-col items-center bg-updatedCreme">
      <button
        className="my-5 flex h-12 w-60 items-center gap-2 rounded-[34px] border border-updatedBlue pl-20 hover:border-blue-900 hover:shadow-md"
        onClick={() => {
          if (history.state === '/parent/children-management') {
            navigate('/parent/children-management');
          } else {
            navigate('/admin/student-management');
          }
        }}
      >
        <div className="relative h-4 w-4">
          <img className="mr-16 h-4 w-4 fill-current" src={arrow} alt="back" />
        </div>
        <button
          type="button"
          className="text-center text-base font-medium text-updatedBlue"
        >
          Back
        </button>
      </button>
      <div className="mt-5 flex h-[510px] w-full flex-col">
        {studentDetailsSidebarOptions.map(({ label, path, children, name }) => {
          const isCurrentPath =
            (path && generatePath(path, { id: studentId }) === pathName) ||
            (children &&
              children?.find(
                ({ path }) => generatePath(path, { id: studentId }) === pathName
              ));

          return (
            <div
              className={`overflow-hidden transition-all duration-300 ${
                expanded === name ? 'h-[470px]' : 'h-10'
              }`}
            >
              <div
                onClick={() => {
                  if (path && !isCurrentPath)
                    navigate(path.replace(':id', studentId));
                  if (children && children.length > 0)
                    if (expanded === name) setExpanded('');
                    else setExpanded(name);
                }}
                className={cn(
                  'flex w-full cursor-pointer items-center justify-between px-6 py-[9px]',
                  {
                    'border-r-4 border-updatedBlue bg-violet-50 text-updatedBlue hover:text-gray-700':
                      isCurrentPath,
                    'bg-updatedCreme text-gray-500 hover:text-gray-700':
                      !isCurrentPath,
                  }
                )}
              >
                <div>{label}</div>
                {children && children.length > 0 && (
                  <div
                    className={`transition-all duration-300 ${
                      expanded === name ? 'rotate-180' : 'rotate-0'
                    }`}
                  >
                    <DropdownArrow
                      className={cn('h-5 w-5', {
                        'text-updatedBlue': isCurrentPath,
                        'text-gray-500': !isCurrentPath,
                      })}
                    />
                  </div>
                )}
              </div>
              {children &&
                children.map(({ label, path }) => {
                  const isCurrentChildPath =
                    path && generatePath(path, { id: studentId }) === pathName;

                  return (
                    <div
                      onClick={() => {
                        if (
                          path &&
                          generatePath(path, { id: studentId }) !== pathName
                        )
                          navigate(path.replace(':id', studentId));
                      }}
                      className={cn(
                        'ml-5 w-full cursor-pointer items-center justify-start py-[9px] pl-6 hover:text-gray-700',
                        {
                          ' text-updatedBlue': isCurrentChildPath,
                          ' text-gray-500': !isCurrentChildPath,
                        }
                      )}
                    >
                      {label}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      {history.state !== '/parent/children-management' && !isLoading && (
        <AntSelect
          placeholder="Change Status"
          className="w-[80%] border border-gray-300"
          options={studentStatusOptions}
          showArrow={true}
          defaultValue={studentData.account_status}
          handleOnChange={handleStudentStatusChange}
        />
      )}

      <div className="mx-10 my-2 h-[0px] w-[calc(100%_-_80px)] border border-neutral-200" />
      {/* <div className="mt-5 cursor-pointer py-5 text-base font-medium leading-normal text-red-500">
        Delete Account
      </div> */}
    </div>
  );
};

export default StudentGeneralProfileSidebar;
