/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Field, Formik } from 'formik';
import { validationSchemaTutorGeneralProfile } from 'utils/validations';
import profile from 'assets/images/admin/profile.png';
import getInitialValuesTutorGeneralProfile from 'utils/tutorProfileInitialValues';
import { notificationsContext } from 'contexts/notifications';
import InputMask from 'react-input-mask';
import { FormSubmit } from 'utils/hooks/FormSubmit';
import { getTutorById, submitProfileForApproval } from 'requests';
import { userContext } from 'contexts/user';
import { ReactComponent as UploadImage } from 'assets/images/upload.svg';
import { DatePicker, Button } from 'antd';
import LocationInput from 'components/shared/LocationInput/LocationInput';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const TutorGeneralProfileForm = () => {
  const { api } = useContext(notificationsContext);
  let {
    user: { id, store_front_status, account_status },
  } = useContext(userContext);
  let { user, updateUser } = useContext(userContext);
  const [isShowButton, setIsShowButton] = useState(false);
  const [isShowEditButton, setIsShowEditButton] = useState(true);
  const [isEdit, setIsEdit] = useState(true);
  const [name, setName] = useState('');
  const [profileUrl, setProfileUrl] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [placeDetail, setPlaceDetail] = useState();
  const [addressValue, setAddressValue] = useState('');
  const [isShowViewStoreFrontButton, setIsShowViewStoreFrontButton] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewPictureUpload, setisNewPictureUpload] = useState(false);

  const [
    isShowSubmittedForApprovalButton,
    setIsShowSubmittedForApprovalButton,
  ] = useState(false);
  const [isShowSubmitForApproval, setIsShowSubmitForApproval] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (!store_front_status) setIsShowSubmitForApproval(true);

    if (store_front_status && account_status !== 'approved')
      setIsShowSubmittedForApprovalButton(true);

    if (store_front_status && account_status === 'approved')
      setIsShowViewStoreFrontButton(true);
  }, []);

  const tutorProfileValues = useMemo(() => {
    setName(`${user.first_name} ${user.last_name}`);
    setProfileUrl(user.profile_picture);
    return getInitialValuesTutorGeneralProfile(user);
  }, [user]);

  const getProfile = async (id, callback) => {
    try {
      const { data: tutor } = await getTutorById(id);
      updateUser(tutor);
      localStorage.setItem('user', JSON.stringify(tutor));
      callback(tutor);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setPlaceDetail({
      country: user.country,
      city: user.city,
      state: user.state,
      zipCode: user.zipcode,
      address_one_line: user.address_one_line,
    });
    setAddressValue(placeDetail?.address_one_line);
  }, [user]);

  const handleSubmit = async (values) => {
    setIsLoading(true);

    const filteredValues = Object.fromEntries(
      Object.entries(values).filter(([key, value]) => value !== null)
    );

    filteredValues.country = placeDetail?.country || '';
    filteredValues.city = placeDetail?.city || '';
    filteredValues.state = placeDetail?.state || '';
    filteredValues.zipcode = placeDetail?.zipCode || '';
    filteredValues.address_one_line = placeDetail?.formatted_address || '';
    const formData = new FormData();
    formData.append('profile_picture', profileImage);
    Object.keys(filteredValues).forEach((key) => {
      formData.append(key, filteredValues[key]);
    });
    await FormSubmit({
      formData,
      id,
      setIsShowButton,
      setIsShowEditButton,
      setIsEdit,
      api,
    });

    getProfile(id, (tutor) => {
      setIsLoading(false);
      setisNewPictureUpload(false);
      updateUser(tutor);
    });
  };

  const handleUploadImage = async (event) => {
    const newProfileImage = event.target.files[0];
    setProfileImage(newProfileImage);

    const imageUrl = URL.createObjectURL(newProfileImage);
    setProfileUrl(imageUrl);
    setisNewPictureUpload(true);
  };

  const submitForApproval = async () => {
    try {
      const response = await submitProfileForApproval(id);
      if (response?.data?.data?.store_front_status)
        setIsShowSubmittedForApprovalButton(true);
      if (response?.data?.data?.account_status)
        setIsShowSubmitForApproval(false);
      updateUser(response?.data?.data);
      localStorage.setItem('user', JSON.stringify(response?.data?.data));
      api.success({
        message: 'Success',
        description: 'Storefront successfully submitted for approval!',
        placement: 'top',
        duration: 5,
      });
    } catch (err) {
      if (err?.response?.data?.error === 'Incomplete profile') {
        api.warning({
          message: 'Error',
          description:
            'To submit for approval, ensure that both your General Profile and Detailed Profile are fully completed. This includes all sections, as well as linking your Stripe Account.',
          placement: 'top',
          duration: 5,
        });
      } else {
        api.error({
          message: 'Error',
          description: 'Something went wrong, please try again later!',
          placement: 'top',
          duration: 5,
        });
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={tutorProfileValues}
      validationSchema={validationSchemaTutorGeneralProfile}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {({
        handleChange,
        errors,
        handleBlur,
        touched,
        handleSubmit,
        values,
        setFieldValue,
      }) => (
        <div className=" flex w-full flex-col items-start justify-center gap-12">
          <div className="flex h-fit w-full items-center justify-center gap-2">
            <div className=" flex flex-col items-center justify-center gap-6">
              <div
                style={{ fontFamily: 'Bogart' }}
                className="font-semibold text-updatedBlue xs:mb-5 xs:text-xl md:mb-10 md:text-2xl"
              >
                General Profile
              </div>
              <div className="mb-5 flex flex-col items-center justify-center gap-10">
                <img
                  className="h-[120px] w-[120px] rounded-full"
                  src={profileUrl ?? profile}
                  alt="Profile"
                />
                <div className="flex flex-col items-center justify-center gap-4">
                  <div className="h-5 w-full font-semibold text-updatedBlue xs:text-xl md:text-2xl">
                    {name}
                  </div>
                </div>
              </div>
              {isShowButton && (
                <div className="flex items-start justify-center gap-6">
                  <div className="flex items-start justify-start gap-6">
                    <label className="inline-flex h-12 items-center justify-center gap-3 rounded-[34px] border border-updatedBlue px-4 py-2  text-updatedBlue">
                      <input
                        type="file"
                        id="profile_picture"
                        name="profile_picture"
                        onChange={async (event) => {
                          await handleUploadImage(event, async (value) => {
                            await setFieldValue('profile_picture', value);
                          });
                        }}
                      />
                      <UploadImage
                        className="h-7 w-7"
                        style={{ color: '#034078 !important' }}
                      />{' '}
                      Upload new picture
                    </label>
                  </div>
                </div>
              )}
              {isNewPictureUpload && (
                <p className="text-sm text-gray-500">
                  To upload the new picture, click the Save Settings button.
                </p>
              )}
              <div className="flex flex-row flex-wrap xs:justify-center xs:gap-3 md:justify-between md:gap-10 ">
                {isShowViewStoreFrontButton && (
                  <button
                    type="submit"
                    className="flex h-12 w-36 items-center justify-center rounded-3xl border border-updatedBlue bg-updatedCreme text-center text-sm font-medium text-updatedBlue"
                    onClick={() => {
                      const navigateString = viewStoreFront(id, TUTOR);
                      navigate(navigateString);
                    }}
                  >
                    View Storefront
                  </button>
                )}
                {isShowSubmittedForApprovalButton && (
                  <Button
                    type="primary"
                    disabled
                    className="flex h-12 w-36 items-center justify-center rounded-3xl text-center text-sm font-medium"
                    onClick={submitForApproval}
                  >
                    Pending approval
                  </Button>
                )}
                {isShowSubmitForApproval && (
                  <button
                    type="button"
                    className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                    onClick={submitForApproval}
                  >
                    Submit for approval
                  </button>
                )}

                <div>
                  {isLoading ? (
                    <div className="flex items-center justify-center">
                      <button
                        className="flex h-12 w-36 items-center justify-center rounded-3xl bg-gray-800 px-6 py-3 text-center text-sm font-medium text-white"
                        type="button"
                      >
                        Loading...
                      </button>
                    </div>
                  ) : (
                    <>
                      {isShowEditButton ? (
                        <button
                          type="button"
                          className="flex h-12 w-36 items-center justify-center rounded-3xl border border-gray-800 px-6 py-3 text-center text-sm font-medium text-updatedBlue"
                          onClick={() => {
                            setIsShowButton((prev) => !prev);
                            setIsShowEditButton((prev) => !prev);
                            setIsEdit((prev) => !prev);
                          }}
                        >
                          Edit Profile
                        </button>
                      ) : (
                        <button
                          className="flex h-12 w-36 items-center justify-center rounded-3xl bg-gray-800 px-6 py-3 text-center text-sm font-medium text-white"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Save Settings
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col items-start justify-start gap-8 self-stretch">
            <div className="flex flex-col items-start justify-start gap-2 self-stretch">
              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                First name
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch bg-updatedMustard pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="Frist Name"
                  type="text"
                  name="first_name"
                  id="first_name"
                  disabled={isEdit}
                  value={values.first_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.first_name && !!touched.first_name && (
                    <div className="w-full text-red-500">
                      {errors.first_name}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Last name
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="Last Name"
                  type="text"
                  name="last_name"
                  id="last_name"
                  disabled={isEdit}
                  value={values.last_name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.last_name && !!touched.last_name && (
                    <div className="w-full text-red-500">
                      {errors.last_name}
                    </div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Email
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="Email"
                  type="email"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  disabled
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.email && !!touched.email && (
                    <div className="w-full text-red-500">{errors.email}</div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Phone Number
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <Field name="phoneNumber">
                  {({ field }) => (
                    <InputMask
                      {...field}
                      className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                      mask="(999) 999-9999"
                      maskChar="_"
                      placeholder="(123) 456-7890"
                      name="phone_number"
                      id="phone_number"
                      disabled={isEdit}
                      value={values.phone_number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  )}
                </Field>
                <div className="mt-1 h-5">
                  {!!errors.phone_number && !!touched.phone_number && (
                    <div className="w-full text-red-500">
                      {errors.phone_number}
                    </div>
                  )}
                </div>
              </div>
              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Date of Birth
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <DatePicker
                  inputRender={({ onChange, ...props }) => (
                    <input
                      {...props}
                      id="date_of_birth"
                      name="date_of_birth"
                      className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2 !text-base !text-gray-500 !placeholder-[#9ca3af] !outline-none disabled:bg-transparent"
                    />
                  )}
                  disabledDate={(current) => {
                    return current && current.isAfter(new Date());
                  }}
                  value={values.date_of_birth}
                  disabled={isEdit}
                  onChange={(date) => {
                    setFieldValue('date_of_birth', date);
                  }}
                  className="mt-3 h-12 w-full rounded-lg border border-neutral-200 px-5 "
                  placeholder="Date of Birth"
                  style={{
                    fontSize: '1.5rem',
                  }}
                  format={'MMMM DD YYYY'}
                />
                <div className="mt-1 h-5">
                  {!!errors.date_of_birth && !!touched.date_of_birth && (
                    <div className="w-full text-red-500">
                      {errors.date_of_birth}
                    </div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Gender
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <select
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="Gender"
                  name="gender"
                  id="gender"
                  disabled={isEdit}
                  value={values.gender}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="" selected="true" disabled="disabled">
                    Select
                  </option>
                  <option disabled={isEdit} value="male">
                    Male
                  </option>
                  <option disabled={isEdit} value="female">
                    Female
                  </option>
                </select>
                <div className="mt-1">
                  {!!errors.gender && !!touched.gender && (
                    <div className="w-full text-red-500">{errors.gender}</div>
                  )}
                </div>
              </div>
              <div className="w-full">
                <LocationInput
                  name="address_one_line"
                  value={addressValue}
                  onChange={(val) => {
                    setAddressValue(val);
                  }}
                  isEditMode={!isEdit}
                  disabled={isEdit}
                  setPlaceDetail={setPlaceDetail}
                  size="large"
                />
                {/* <textarea
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  disabled={isEdit}
                  value={values.address_one_line}
                  onChange={handleChange}
                  onBlur={handleBlur}
                /> */}
                <div className="mt-1 h-5">
                  {!!errors.address_one_line && !!touched.address_one_line && (
                    <div className="w-full text-red-500">
                      {errors.address_one_line}
                    </div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                City
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="City"
                  type="text"
                  name="city"
                  id="city"
                  disabled={isEdit}
                  value={placeDetail?.city || ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.city && !!touched.city && (
                    <div className="w-full text-red-500">{errors.city}</div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                State
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="State"
                  type="text"
                  name="state"
                  id="state"
                  disabled={isEdit}
                  value={placeDetail ? placeDetail?.state : values.state}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.city && !!touched.city && (
                    <div className="w-full text-red-500">{errors.city}</div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Zip Code
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="Zip Code"
                  type="text"
                  name="zipcode"
                  id="zipcode"
                  disabled={isEdit}
                  value={placeDetail ? placeDetail?.zipCode : values.zipCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.city && !!touched.city && (
                    <div className="w-full text-red-500">{errors.city}</div>
                  )}
                </div>
              </div>

              <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                Country
              </div>
              <div className="inline-flex items-start justify-start gap-2.5 self-stretch pb-[15px] pt-px">
                <input
                  className="w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-2 py-2"
                  placeholder="Country"
                  type="text"
                  name="country"
                  id="country"
                  disabled={isEdit}
                  value={placeDetail ? placeDetail?.country : values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5">
                  {!!errors.country && !!touched.country && (
                    <div className="w-full text-red-500">{errors.country}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default TutorGeneralProfileForm;
