import { Checkbox, Form } from 'antd';
import React from 'react';

const AntFormCheckbox = ({ name, rules, label, onChangeCheckbox, initialValue }) => {
  return (
    <Form.Item name={name} rules={rules} className="my-0 pb-0">
      <Checkbox
        className="text-gray-500"
        onChange={onChangeCheckbox}
        defaultChecked={initialValue}
      >
        <div className="text-xs">{label}</div>
      </Checkbox>
    </Form.Item>
  );
};

export default AntFormCheckbox;
