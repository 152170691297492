import { useContext, useState } from 'react';
import { Modal as AntdModal } from 'antd';
import { notificationsContext } from 'contexts/notifications';
import { Formik } from 'formik';
import * as yup from 'yup';
import DeleteActionButton from 'components/DeleteActionButton';
import { ReactComponent as Loader } from 'assets/images/loader.svg';
import { createTutorDisabilityTeachingExp } from 'requests';

const useAddDisabilityTeachingExpModal = (tutorId, callback) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { api } = useContext(notificationsContext);
  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    document: yup.mixed().required(),
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async (values) => {
    try {
      const formData = new FormData();
      formData.append('type', values.type);
      formData.append('document', values.document);

      await createTutorDisabilityTeachingExp(tutorId, formData);
      api.success({
        message: 'Success',
        description: 'Disability teaching experience added successfully',
        placement: 'top',
        duration: 4,
      });
      if (callback) callback();
    } catch (err) {
      api.error({
        message: 'Error',
        description:
          'Something went wrong while adding the disability teaching experience. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    } finally {
      handleCancel();
    }
  };

  const AddDisabilityTeachingExpModal = ({ ...props }) => {
    return (
      <AntdModal
        width={'max-content'}
        {...props}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            type: null,
            document: null,
          }}
          validateOnMount={true}
          onSubmit={handleOk}
        >
          {({
            isValid,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
            handleChange,
          }) => (
            <div className="xs:w-full xs:p-2 md:w-[500px] md:p-7">
              <div className="text-2xl font-semibold text-updatedBlue">
                Add Disability Teaching Exp
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Disability Type
                </div>
                <input
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 text-base font-medium text-gray-500 outline-none"
                  id="type"
                  name="type"
                  type="text"
                  placeholder="Enter Disability Type"
                  onChange={handleChange}
                  value={values.type}
                />
              </div>

              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Upload Attachment
                </div>
                {!values.document ? (
                  <div className="relative mt-3 h-14 overflow-hidden rounded-lg">
                    <input
                      accept="image/*,.pdf"
                      type="file"
                      className="h-full w-full"
                      onChange={(event) => {
                        setFieldValue('document', event.target.files[0]);
                      }}
                    />
                    <div className="flex h-full">
                      <div className="flex h-full w-32 items-center justify-center rounded-bl-lg rounded-tl-lg border border-neutral-200 bg-slate-100">
                        Choose file
                      </div>

                      <div className="flex h-full flex-1 items-center rounded-br-lg rounded-tr-lg border border-neutral-200 px-5">
                        No file Choosen
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 flex items-center justify-between gap-5">
                    <div className="flex-1 overflow-hidden text-base font-medium text-gray-500">
                      {values.document?.name ?? 'Uploaded Document'}
                    </div>
                    <div>
                      <DeleteActionButton
                        onClick={() => {
                          setFieldValue('document', null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <button
                  className={`mt-12 h-12 w-full items-center justify-center rounded-3xl ${
                    isValid ? 'bg-updatedBlue' : 'bg-gray-500'
                  } text-white`}
                  onClick={handleSubmit}
                  disabled={!isValid || isSubmitting}
                >
                  {!isSubmitting ? (
                    'Add Disability Experience'
                  ) : (
                    <div className="flex w-full items-center justify-center">
                      <Loader color="white" className="spinner h-8 w-8" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </AntdModal>
    );
  };

  return { AddDisabilityTeachingExpModal, showModal };
};

export default useAddDisabilityTeachingExpModal;
