import { Layout } from 'antd';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import Navbar from 'components/shared/Navbar/Navbar';
import StudentDetailsLayout from 'components/student/StudentDetailsLayout';
import { STUDENT } from 'constants/roles';
import { studentNavbarItems } from 'enums';
import React from 'react';

const PaymentConfiguration = () => {
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={studentNavbarItems} role={STUDENT} />
        <StudentDetailsLayout>
          <div className="flex w-full flex-col items-center">
            <div className="mt-10 w-full">
              <div className="text-2xl font-semibold text-updatedBlue">
                Detailed Profile / Payment Configuration
              </div>
              <div className="mt-5 flex justify-center">
                <CustomButton
                  label="Make Payment"
                  className="h-12 w-52 items-center justify-center rounded-3xl bg-gray-800 text-sm font-semibold text-white hover:!bg-gray-700"
                />
              </div>
            </div>
          </div>
        </StudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default PaymentConfiguration;
