import React from 'react';
import { Layout } from 'antd';
import { studentNavbarItems } from 'enums';
import Navbar from 'components/shared/Navbar/Navbar';
import { STUDENT } from 'constants/roles';
import Sessions from 'components/shared/Sessions/Sessions';

const Dashboard = () => {
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={studentNavbarItems} role={STUDENT} />
        <Sessions role={STUDENT} />
      </Layout>
    </div>
  );
};

export default Dashboard;
