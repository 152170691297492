import CreatePasswordComponent from 'components/create-password';
import { STUDENT } from 'constants/roles';
import { userContext } from 'contexts/user';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { forceChangePassword, resetPassword } from 'requests';

const CreatePassword = () => {
  const navigate = useNavigate();
  const { logout } = useContext(userContext);

  const handleResetPassword = async (email, password, otp) => {
    const forcedChangePassword = localStorage.getItem(
      'is_force_change_password'
    );
    if (forcedChangePassword) {
      const savedEmail = JSON.parse(localStorage.getItem('user')).email;
      await forceChangePassword(savedEmail, password);
      localStorage.setItem('is_force_change_password', false);
      logout();
      navigate('/student/login');
    } else {
      await resetPassword(email, password, otp);
      navigate('/student/login');
    }
  };

  return (
    <CreatePasswordComponent
      resetPassword={handleResetPassword}
      className="bg-[url('assets/images/tutor/background.png')] bg-contain"
      role={STUDENT}
    />
  );
};

export default CreatePassword;
