import React, { useContext, useState } from 'react';

const PortfolioVideoCard = ({ cardTitle, link }) => {
  const getVideoId = (videoUrl) => {
    const youtubeMatch = videoUrl.match(
      /(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=))([^"&?\/\s]{11})/
    );
    if (youtubeMatch) {
      return { platform: 'youtube', id: youtubeMatch[1] };
    }

    const vimeoMatch = videoUrl.match(/(?:vimeo\.com\/)([^"&?\/\s]+)/);
    if (vimeoMatch) {
      return { platform: 'vimeo', id: vimeoMatch[1] };
    }

    return null;
  };

  const videoInfo = getVideoId(link);

  return (
    <div className="mb-5 rounded-lg border border-neutral-200 bg-updatedCreme p-5 shadow-md">
      <p className="text-3xl font-semibold">{cardTitle}</p>
      <div className="">
        <div className="flex items-center justify-between border-b border-neutral-200 py-5">
          {videoInfo && videoInfo.platform === 'youtube' && (
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${videoInfo.id}`}
              frameBorder="0"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          )}

          {videoInfo && videoInfo.platform === 'vimeo' && (
            <iframe
              src={`https://player.vimeo.com/video/${videoInfo.id}`}
              width="100%"
              height="315"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Vimeo Video"
            ></iframe>
          )}

          {/* Display N/A if link is not present or not supported */}
          {!videoInfo && <p>N/A</p>}
        </div>
      </div>
    </div>
  );
};

export default PortfolioVideoCard;
