import FormModal from 'components/shared/AntFormModal/AntFormModal';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import React from 'react';

const DeleteSchoolModal = ({
  isDeleteSchoolModalOpen,
  setIsDeleteSchoolModalOpen,
  setIsLoading,
  handleCancel,
  handleDelete,
}) => {
  return (
    <div>
      <FormModal
        title={<div className="text-xl font-bold">Delete School</div>}
        isModalOpen={isDeleteSchoolModalOpen}
        setIsModalOpen={setIsDeleteSchoolModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={handleCancel}
      >
        <div>
          <div className="text-gray-500">
            Are you sure you want to delete this school?
          </div>
          <div className="mb-5 mt-10 flex flex-wrap items-center justify-between gap-1">
            <CustomButton
              label="Cancel"
              shape="round"
              size="large"
              className="border border-updatedBlue bg-updatedCreme text-updatedBlue hover:!bg-updatedBlue"
              buttonWidth="w-28"
              onClick={() => {
                setIsDeleteSchoolModalOpen(false);
              }}
            />
            <CustomButton
              label="Delete"
              shape="round"
              size="large"
              className="bg-updatedBlue text-white"
              buttonWidth="w-28"
              onClick={handleDelete}
            />
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default DeleteSchoolModal;
