import React from 'react';
import TutorGeneralProfileForm from '../../../components/admin/tutor-general-profile-form';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import { useParams } from 'react-router-dom';

const GeneralProfile = () => {
  const { id } = useParams();
  return (
    <TutorDetailsLayout>
      <TutorGeneralProfileForm id={id} />
    </TutorDetailsLayout>
  );
};

export default GeneralProfile;
