import React, { useContext, useEffect, useState } from 'react';
import { Avatar } from 'antd';
import { selectedUserContext } from 'contexts/selected-user';
import EditParentDetailsLayout from './edit-parent-details-layout';
import { userContext } from 'contexts/user';
import {
  createCustomer,
  getSessionUrl,
  getSubscriptions,
} from 'requests/stripe';
import CurrentSubscriptionCard from 'components/shared/CurrentSubscriptionCard';

const EditParentPaymentConfiguration = () => {
  const { selectedUser } = useContext(selectedUserContext);
  const [sessionUrl, setSessionUrl] = useState('');
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  const {
    user: { id: userId, email, stripe_customer_id },
  } = useContext(userContext);
  const { updateUser } = useContext(userContext);

  useEffect(() => {
    updateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserSubscriptions = async () => {
    try {
      const {
        data: {
          data: { subscription, product },
        },
      } = await getSubscriptions(userId);
      setSubscriptionDetails({ subscription, product });
    } catch (err) {
      console.log('stripe error');
    }
  };

  useEffect(() => {
    getUserSubscriptions(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSession = async () => {
    try {
      const {
        data: {
          data: { url },
        },
      } = await getSessionUrl(userId);
      setSessionUrl(url);
    } catch (err) {
      console.log('stripe error');
    }
  };

  const onClickAddPayment = async () => {
    try {
      const {
        data: {
          data: { redirect_url },
        },
      } = await createCustomer(userId);
      window.location.href = redirect_url;
    } catch (err) {
      console.log('stripe error');
    }
  };

  useEffect(() => {
    if (stripe_customer_id) {
      getSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe_customer_id]);

  return (
    <EditParentDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <div className="flex justify-center pb-5">
          <div className="flex flex-col items-center justify-center gap-1">
            <Avatar size={100} src={selectedUser.profile_picture} />
            <div
              style={{ fontFamily: 'Now' }}
              className="font-semibold leading-relaxed text-updatedBlue xs:text-xl md:text-2xl"
            >
              {`${selectedUser.first_name} ${selectedUser.last_name}`}
            </div>
          </div>
        </div>

        <div className="mt-10 w-full">
          <div
            style={{ fontFamily: 'Bogart' }}
            className="font-semibold text-updatedBlue xs:text-xl md:text-2xl"
          >
            Detailed Profile / Payment Configuration
          </div>

          {!stripe_customer_id ? (
            <div className="mt-10 bg-gray-200 p-5">
              <stripe-pricing-table
                pricing-table-id={process.env.REACT_APP_STRIPE_PRICING_TABLE_ID}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
                customer-email={email}
              ></stripe-pricing-table>
            </div>
          ) : sessionUrl ? (
            <div className="my-10">
              <div className="my-10">
                <button
                  style={{ fontFamily: 'Now' }}
                  className="rounded-lg bg-updatedPink py-5 text-lg font-bold text-updatedCreme xs:w-full md:w-80"
                  onClick={onClickAddPayment}
                >
                  Add Payment Method
                </button>
              </div>
              <button
                style={{ fontFamily: 'Now' }}
                className="rounded-lg bg-updatedPink py-5 text-lg font-bold text-updatedCreme xs:w-full md:w-80"
                onClick={() => {
                  window.location.href = sessionUrl;
                }}
              >
                Manage your subscriptions
              </button>
            </div>
          ) : null}
        </div>
        {!!subscriptionDetails?.subscription &&
          !!subscriptionDetails?.product && (
            <div className="mt-10 w-full">
              <div className="font-semibold text-updatedLightBlue xs:text-xl md:text-2xl">
                Current Subscription
              </div>

              <CurrentSubscriptionCard {...subscriptionDetails} />
            </div>
          )}
      </div>
    </EditParentDetailsLayout>
  );
};

export default EditParentPaymentConfiguration;
