import React from 'react';
import { useParams } from 'react-router-dom';
import StudentDetailsLayout from 'components/admin/student-details-layout';

import DisabilityProfilingDetails from 'pages/shared/DisabilityProfilingDetails/DisabilityProfilingDetails';
import { ADMIN } from 'constants/roles';

const DisabilityProfiling = () => {
  const { id: studentId } = useParams();

  return (
    <StudentDetailsLayout>
      <DisabilityProfilingDetails studentId={studentId} mode={ADMIN} />
    </StudentDetailsLayout>
  );
};

export default DisabilityProfiling;
