import { notificationsContext } from 'contexts/notifications';
import React, { useContext, useEffect, useState } from 'react';
import DefaultButton from 'components/DefaultButton';
import EditActionButton from 'components/EditActionButton';
import DeleteActionButton from 'components/DeleteActionButton';
import { Table } from 'antd';
import moment from 'moment';
import { getWorkExperiences } from 'requests';
import useAddWorkExperienceModal from 'components/admin/AddWorkExperienceModal';
import useEditWorkExperienceModal from 'components/admin/EditWorkExperienceModal';
import useDeleteWorkExperienceModal from 'components/admin/DeleteWorkExperienceModal';
import { userContext } from 'contexts/user';
import Layout from 'components/tutor/ProfileLayout';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const WorkExperience = () => {
  const {
    user: { id: tutorId, account_status },
  } = useContext(userContext);
  const { api } = useContext(notificationsContext);

  const getTutorWorkExperiences = async () => {
    try {
      const {
        data: { data: WorkExperiences },
      } = await getWorkExperiences(tutorId);

      setWorkExperiences(
        WorkExperiences.map((item, index) => ({
          ...item,
          key: index + 1,
        }))
      );
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  const { AddWorkExperienceModal, showModal } = useAddWorkExperienceModal(
    tutorId,
    getTutorWorkExperiences
  );
  const { EditWorkExperienceModal, showModal: showEditModal } =
    useEditWorkExperienceModal(tutorId, getTutorWorkExperiences);
  const { DeleteWorkExperienceeModal, showModal: showDeleteModal } =
    useDeleteWorkExperienceModal(tutorId, getTutorWorkExperiences);
  const [WorkExperiences, setWorkExperiences] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getTutorWorkExperiences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Employer Name',
      dataIndex: 'employer_name',
      key: 'employer_name',
    },

    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, { start_date }) => {
        return moment(start_date).format('LL');
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, { end_date, is_currently_working }) => {
        return moment(end_date).isValid() && !is_currently_working
          ? moment(end_date).format('LL')
          : 'Currently working';
      },
    },
    {
      title: 'Attachment',
      dataIndex: 'document',
      key: 'document',
      render: (_, { document }) => {
        return !document ? (
          'No Attachment'
        ) : (
          <a href={document} target="_blank" rel="noreferrer">
            <div className="text-sm font-normal text-updatedBlue underline hover:text-updatedBlue">
              View
            </div>
          </a>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, { id, ...WorkExperience }) => {
        return (
          <div className="flex items-center justify-start gap-5">
            <EditActionButton
              onClick={() => {
                showEditModal(id, WorkExperience);
              }}
            />
            <DeleteActionButton
              onClick={() => {
                showDeleteModal(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <AddWorkExperienceModal />
      <EditWorkExperienceModal />
      <DeleteWorkExperienceeModal />
      <Layout>
        <div className="flex min-h-[calc(100vh_-_273px)] w-full flex-col items-center">
          <div className="flex w-full items-center justify-between gap-8">
            <div className="flex-1 font-semibold text-updatedBlue xs:text-xl md:text-2xl">
              Detailed Profile / Work Experience
            </div>
            <div className="flex flex-wrap items-center justify-start gap-1">
              <div>
                {account_status === 'approved' && (
                  <button
                    type="button"
                    className="flex h-12 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white xs:w-32 md:w-48"
                    onClick={() => {
                      const navigateString = viewStoreFront(tutorId, TUTOR);
                      navigate(navigateString);
                    }}
                  >
                    View Storefront
                  </button>
                )}
              </div>
              <div className="w-24 md:w-48">
                <DefaultButton className="!bg-[#212B36]" onClick={showModal}>
                  Add Work Experience
                </DefaultButton>
              </div>
            </div>
          </div>

          <Table
            className="mt-10 w-full"
            pagination={false}
            columns={columns}
            dataSource={WorkExperiences}
            scroll={{
              x: 1000,
              y: 1000,
            }}
          />
        </div>
      </Layout>
    </>
  );
};

export default WorkExperience;
