import { createContext, useState } from 'react';
import { getProfile } from 'requests/user';

export const childUserContext = createContext({
  childUser: null,
  setChildUser: () => {},
  childUserLogin: () => {},
  childUserLogout: () => {},
  isChildUserAuthenticated: false,
  updateUser: async (user) => {},
  childUserRole: null,
});

export const ChildUserContextProvider = ({ children }) => {
  const [childUser, setChildUser] = useState(
    localStorage.getItem('childUser') || null
  );
  const [isChildUserAuthenticated, setIsChildUserAuthenticated] = useState(
    localStorage.getItem('isChildUserAuthenticated') || false
  );
  const [childUserRole, setChildUserRole] = useState(
    localStorage.getItem('childUserRole') || null
  );

  const childUserLogin = ({ data: user, token, refreshToken }) => {
    localStorage.setItem('childUserToken', token);
    localStorage.setItem('childUserRefreshToken', refreshToken);
    localStorage.setItem('childUser', JSON.stringify(user));
    localStorage.setItem('childUserRole', user?.role);
    localStorage.setItem('isChildUserAuthenticated', true);
    localStorage.setItem(
      'is_child_force_change_password',
      user?.is_force_change_password
    );
    setChildUser(user);
    setIsChildUserAuthenticated(true);
    setChildUserRole(user?.role);
  };

  const childUserLogout = () => {
    localStorage.removeItem('childUserToken');
    localStorage.removeItem('childUserRefreshToken');
    localStorage.removeItem('childUser');
    localStorage.removeItem('childUserRole');
    localStorage.removeItem('isChildUserAuthenticated');
    localStorage.removeItem('is_child_force_change_password');
    setChildUser(null);
    setIsChildUserAuthenticated(false);
    setChildUserRole(null);
  };

  const updateUser = async (updatedUser) => {
    if (!updatedUser) {
      updatedUser = await getProfile(childUser.id);
    }
    localStorage.setItem('childUser', JSON.stringify(updatedUser));
    setChildUser(updatedUser);
  };

  return (
    <childUserContext.Provider
      value={{
        childUser,
        setChildUser,
        childUserLogin,
        childUserLogout,
        isChildUserAuthenticated,
        updateUser,
        childUserRole,
      }}
    >
      {children}
    </childUserContext.Provider>
  );
};
