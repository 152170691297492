import axiosInstance from './config';

export const getTutorCertifications = async (tutorId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/tutors/${tutorId}/certificates`)
      .then(resolve)
      .catch(reject);
  });
};

export const createTutorCertification = async (tutorId, formData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/tutors/${tutorId}/certificates`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const updateTutorCertification = async (
  tutorId,
  certificateId,
  formData
) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`/tutors/${tutorId}/certificates/${certificateId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const deleteTutorCertification = async (tutorId, certificateId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`/tutors/${tutorId}/certificates/${certificateId}`)
      .then(resolve)
      .catch(reject);
  });
};
