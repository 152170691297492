/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import { getTutorById, updateTutorTimezone } from 'requests/tutors';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationsContext } from 'contexts/notifications';
import momentTimezone from 'moment-timezone';
import { Select, TimePicker } from 'antd';
import {
  getGeneralAvailability,
  postGeneralAvailability,
  updateGeneralAvailability,
} from 'requests/general-availability';
import dayjs from 'dayjs';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';

const TutorGeneralAvailability = () => {
  const [userTimezone, setUserTimezone] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const { id: tutorId } = useParams();
  const { api } = useContext(notificationsContext);
  const [timezone, setTimezone] = useState('');
  const [userGeneralAvailability, setUserGeneralAvailability] = useState([]);
  const [generalAvailability, setGeneralAvailability] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
      setTimezone(data.timezone);
      setUserTimezone(data.timezone);
    } catch (err) {
      console.log(err);
    }
  };

  const getTutorGeneralAvailability = async () => {
    try {
      const {
        data: { data: generalAvailabilities },
      } = await getGeneralAvailability(tutorId);
      setUserGeneralAvailability(generalAvailabilities);
      setGeneralAvailability(generalAvailabilities);
    } catch (err) {
      console.log(err);
    }
  };

  const updateTime = () => {
    return generalAvailability.map(
      (availability) =>
        new Promise(async (resolve, reject) => {
          try {
            const index = userGeneralAvailability.findIndex(
              (userAvailability) => userAvailability.day === availability.day
            );
            if (index === -1) {
              await postGeneralAvailability(tutorId, availability);
              resolve();
            } else {
              if (
                userGeneralAvailability[index].start_time !==
                  availability.start_time ||
                userGeneralAvailability[index].end_time !==
                  availability.end_time
              ) {
                await updateGeneralAvailability(
                  tutorId,
                  userGeneralAvailability[index].id,
                  availability
                );
                resolve();
              } else {
                resolve();
              }
            }
          } catch (err) {
            reject(err);
          }
        })
    );
  };

  const updateTimezone = async () => {
    if (timezone === userTimezone) return;
    else return updateTutorTimezone(tutorId, timezone);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      await updateTimezone();
      await Promise.all(updateTime());
      await getTutorDetails();
      await getTutorGeneralAvailability();
      api.success({
        message: 'Success',
        description: 'General availability updated successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTutorDetails();
    getTutorGeneralAvailability();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange =
    (day) =>
    ([start_time, end_time]) => {
      const start_time_string = start_time.format('HH:mm');
      const end_time_string = end_time.format('HH:mm');
      const index = generalAvailability.findIndex(
        (availability) => availability.day === day
      );

      if (index === -1) {
        setGeneralAvailability([
          ...generalAvailability,
          { day, start_time: start_time_string, end_time: end_time_string },
        ]);
      } else {
        const newGeneralAvailability = [...generalAvailability];
        newGeneralAvailability[index] = {
          day,
          start_time: start_time_string,
          end_time: end_time_string,
        };
        setGeneralAvailability(newGeneralAvailability);
      }
    };

  const onValue = (day) => {
    const index = generalAvailability.findIndex(
      (availability) => availability.day === day
    );
    if (index === -1) return [];
    else
      return [
        dayjs(generalAvailability[index].start_time, 'HH:mm'),
        dayjs(generalAvailability[index].end_time, 'HH:mm'),
      ];
  };

  return (
    <TutorDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <img
          className="h-[120px] w-[120px] rounded-full"
          src={!!image ? image : profile}
          alt="image"
        />
        <div className="mt-5 text-2xl font-semibold text-updatedBlue">
          {name}
        </div>
        <Button
          className="mt-4"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>

        <div className="mt-10 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / General Availability
          </div>
          <div className="mt-5 text-xl text-updatedBlue">Timezone</div>
          <div id="languages">
            <Select
              className="mt-5 w-full rounded-lg border border-neutral-200"
              value={timezone}
              onChange={setTimezone}
              optionLabelProp="label"
            >
              {momentTimezone.tz.names().map((tz) => (
                <Select.Option value={tz}>{tz}</Select.Option>
              ))}
            </Select>
          </div>
          <div className="mt-10 text-xl text-updatedBlue">
            Add General Availability
          </div>
          <div className="mt-8 text-xl text-updatedBlue">
            <div className="ml-32 flex w-60 justify-between">
              <div>Start Time</div>
              <div>End Time</div>
            </div>
            <div
              className="mt-5 flex w-[400px] justify-between"
              id="picker-container"
            >
              <div>Monday</div>
              <TimePicker.RangePicker
                value={onValue('monday')}
                onChange={onChange('monday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
            <div className="mt-5 flex w-[400px] justify-between">
              <div>Tuesday</div>
              <TimePicker.RangePicker
                value={onValue('tuesday')}
                onChange={onChange('tuesday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
            <div className="mt-5 flex w-[400px] justify-between">
              <div>Wednesday</div>
              <TimePicker.RangePicker
                value={onValue('wednesday')}
                onChange={onChange('wednesday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
            <div className="mt-5 flex w-[400px] justify-between">
              <div>Thursday</div>
              <TimePicker.RangePicker
                value={onValue('thursday')}
                onChange={onChange('thursday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
            <div className="mt-5 flex w-[400px] justify-between">
              <div>Friday</div>
              <TimePicker.RangePicker
                value={onValue('friday')}
                onChange={onChange('friday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
            <div className="mt-5 flex w-[400px] justify-between">
              <div>Saturday</div>
              <TimePicker.RangePicker
                value={onValue('saturday')}
                onChange={onChange('saturday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
            <div className="mt-5 flex w-[400px] justify-between">
              <div>Sunday</div>
              <TimePicker.RangePicker
                value={onValue('sunday')}
                onChange={onChange('sunday')}
                allowClear={false}
                format={'HH:mm a'}
                use12Hours
              />
            </div>
          </div>
          <div className="mt-20 flex justify-end">
            <Button onClick={handleSave} disabled={loading}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default TutorGeneralAvailability;
