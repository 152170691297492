import React, { useContext, useState } from 'react';
import { notificationsContext } from 'contexts/notifications';
import { Select } from 'antd';
import { LANGUAGES_ARRAY } from 'enums/languages';
import { updateLanguages } from 'requests';
import { userContext } from 'contexts/user';
import Layout from 'components/tutor/ProfileLayout';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const TutorLanguages = () => {
  const {
    user: { id: tutorId, languages: userLanguages, account_status },
    updateUser,
  } = useContext(userContext);
  const [languages, setLanguages] = useState(userLanguages);
  const navigate = useNavigate();

  const { api } = useContext(notificationsContext);

  const handleUpdateLanguages = async () => {
    try {
      await updateLanguages(tutorId, languages);
      await updateUser();
      api.success({
        message: 'Success',
        description: 'Languages updated successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <Layout>
      <div className="flex min-h-[calc(100vh_-_273px)] w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 font-semibold text-updatedBlue xs:text-xl md:text-2xl">
            Detailed Profile / Languages
          </div>
          <div>
            {account_status === 'approved' && (
              <button
                type="button"
                className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                onClick={() => {
                  const navigateString = viewStoreFront(tutorId, TUTOR);
                  navigate(navigateString);
                }}
              >
                View Storefront
              </button>
            )}
          </div>
        </div>

        <div id="languages" className="w-full flex-1">
          <Select
            className="mt-10 w-full rounded-lg border border-neutral-200"
            value={languages}
            onChange={setLanguages}
            mode="multiple"
            optionLabelProp="label"
          >
            {LANGUAGES_ARRAY.map((language) => (
              <Select.Option value={language.name}>
                {language.name} ({language.nativeName})
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="flex w-full justify-end">
          <button
            className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue  text-center text-sm font-medium text-white"
            onClick={handleUpdateLanguages}
          >
            Save Settings
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default TutorLanguages;
