import StoreSectionLayout from 'components/tutor/StoreSectionLayout';
import React from 'react';

const StoreFrontTagTable = ({ type, tableTitle, tagData = [] }) => {
  const isExpertise = type === 'expertise';

  return (
    <div className="mb-5 rounded-lg border border-neutral-200 bg-updatedCreme p-5  shadow-md">
      <p className="text-3xl font-semibold">{tableTitle}</p>
      <div className="mt-7 flex flex-wrap gap-7 xs:justify-center md:justify-start">
        {tagData.length > 0
          ? tagData.map((tag, index) => (
              <StoreSectionLayout
                key={isExpertise ? tag.id : index}
                className="rounded-md border border-neutral-200 bg-violet-50 px-5 py-2 font-medium text-updatedBlue"
                languageName={isExpertise ? tag.title : tag}
              />
            ))
          : 'N/A'}
      </div>
    </div>
  );
};

export default StoreFrontTagTable;
