/* eslint-disable jsx-a11y/anchor-is-valid */
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Avatar, Dropdown } from 'antd';
import { Header } from 'antd/es/layout/layout';
import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import SkooldLogo from '../../../assets/images/tutor/skooldLogo.svg';
import SkooldLogo from '../../../assets/images/skooldLogo.jpg';
import { childUserContext } from 'contexts/child-user-context';
import { userContext } from 'contexts/user';
import { getStudentById } from 'requests/students';
import MobileNavbar from '../MobileNavbar/MobileNavbar';

const Navbar = ({ navItems, role }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [checkLogout, setCheckLogout] = useState('');
  const [childProfilePicture, setChildProfilePicture] = useState('');
  const [isImpersonating, setIsImpersonating] = useState(false);
  const [isMobileOptionsOpen, setIsMobileOptionsOpen] = useState(false);

  const { user, logout } = useContext(userContext);
  const { childUser } = useContext(childUserContext);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => await getStudentProfilePicture())();
    checkForImpersonation(location.pathname);
  });

  useEffect(() => {
    (async () => await getStudentProfilePicture())();
    checkForImpersonation(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childProfilePicture]);

  // Fetching the current key to show selected.
  useEffect(() => {
    const currentKey = location.pathname.split('/').pop();
    setSelectedItem(currentKey);
    setCheckLogout(location.pathname);
  }, [location]);

  const getStudentProfilePicture = async () => {
    try {
      if (checkForImpersonation(location.pathname)) {
        const {
          data: { profile_picture },
        } = await getStudentById(childUser);
        setChildProfilePicture(profile_picture);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.key);
    setIsMobileOptionsOpen(false);
  };

  const checkForImpersonation = (url) => {
    const regex = /^\/parent\/student-.*/;

    if (regex.test(url)) {
      setIsImpersonating(true);
      return true;
    } else {
      setIsImpersonating(false);
      return false;
    }
  };

  const handleLogout = () => {
    if (checkForImpersonation(checkLogout)) {
      localStorage.removeItem('childUser');
    } else {
      logout();
    }

    navigate('/');
  };

  const items = [
    {
      label: <Link onClick={handleLogout}>Logout</Link>,
      key: '0',
    },
  ];
  return (
    <Header className="sticky top-0 z-50 flex h-16 items-center justify-between bg-updatedBlue xs:px-6 md:px-12">
      {/* Logo */}
      <div className="flex cursor-pointer items-center">
        <img src={SkooldLogo} className="h-16 w-20" alt="skoold logo" />
        <div
          style={{ fontFamily: 'Bogart' }}
          className="text-lg font-bold text-[#ffbf46]"
        >
          Skool'd Tutors
        </div>
      </div>

      {/* Nav Items */}
      <div
        style={{ fontFamily: 'Bogart' }}
        className="text-[#ffbf46] xs:hidden xs:gap-3 md:flex md:gap-5 lg:gap-10"
      >
        {navItems.map((item) => (
          <Link
            key={item.key}
            to={`/${isImpersonating ? 'parent' : role}/${item.key}`}
            className={`mb-1 flex items-center gap-2 hover:!text-white ${
              selectedItem === item.key ? 'text-white' : ''
            }`}
            onClick={() => handleItemClick(item)}
          >
            {item.logo} {item.label}
          </Link>
        ))}
      </div>

      {/* Logout Dropdown */}
      <div className="items-center gap-3 xs:hidden md:flex">
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <a onClick={(e) => e.preventDefault()}>
            <div className="flex items-center gap-3">
              <Avatar
                size={'large'}
                src={
                  childProfilePicture === ''
                    ? user?.profile_picture
                    : childProfilePicture
                }
              />
              <DownOutlined />
            </div>
          </a>
        </Dropdown>
      </div>
      <MenuOutlined
        className="text-lg xs:flex md:hidden"
        onClick={() => {
          setIsMobileOptionsOpen(true);
        }}
      />
      <MobileNavbar
        isMobileOptionsOpen={isMobileOptionsOpen}
        setIsMobileOptionsOpen={setIsMobileOptionsOpen}
        navItems={navItems}
        isImpersonating={isImpersonating}
        selectedItem={selectedItem}
        handleItemClick={handleItemClick}
        role={role}
        handleLogout={handleLogout}
      />
    </Header>
  );
};

export default Navbar;
