import React, { useContext, useState } from 'react';
import { updateTutorBio } from 'requests/tutors';
import { notificationsContext } from 'contexts/notifications';
import { userContext } from 'contexts/user';
import Layout from 'components/tutor/ProfileLayout';
import DefaultButton from 'components/DefaultButton';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const TutorBio = () => {
  const {
    user: { id: tutorId, bio: userBio, account_status, video_link },
    updateUser,
  } = useContext(userContext);
  const [bio, setBio] = useState(userBio);
  const [videoLink, setVideoLink] = useState(video_link || '');
  const [isVideoLinkValid, setIsVideoLinkValid] = useState(true);
  const { api } = useContext(notificationsContext);
  const navigate = useNavigate();

  const handleUpdateBio = async () => {
    try {
      let isLinkValid = true;
      if (videoLink != '') {
        // Regular expression for URL
        const linkPattern =
          /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}/;
        isLinkValid = linkPattern.test(videoLink);
        setIsVideoLinkValid(isLinkValid);
      }

      if (isLinkValid) {
        await updateTutorBio(tutorId, bio, videoLink);
        await updateUser();
        api.success({
          message: 'Success',
          description: 'Bio updated successfully',
          placement: 'top',
          duration: 4,
        });
      }
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <Layout>
      <div className="flex h-[calc(100vh_-_273px)] w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Bio
          </div>
          <div>
            {account_status === 'approved' && (
              <button
                type="button"
                className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                onClick={() => {
                  const navigateString = viewStoreFront(tutorId, TUTOR);
                  navigate(navigateString);
                }}
              >
                View Storefront
              </button>
            )}
          </div>
        </div>

        <div className="mt-8 w-full text-xl text-updatedBlue">
          Bio<span className="text-red-800">*</span>
        </div>
        <div className="w-full flex-1">
          <textarea
            value={bio}
            onChange={(event) => setBio(event.target.value)}
            placeholder="Enter your bio here"
            className="mt-3 h-[300px] w-full rounded-lg border-2 border-neutral-200 bg-updatedCreme px-4 py-3.5 outline-none"
          />

          <div className="mt-8 text-xl text-updatedBlue">Video Link</div>
          <p className="mt-2 text-xs">
            Supported video platforms: YouTube and Vimeo only.
          </p>
          <Input
            size="large"
            placeholder="Enter video link here"
            value={videoLink}
            onChange={(e) => {
              setIsVideoLinkValid(true);
              setVideoLink(e.target.value);
            }}
            suffix={
              <Tooltip title="Supported video platforms: YouTube and Vimeo only.">
                <InfoCircleOutlined
                  style={{
                    color: 'rgba(0,0,0,.45)',
                  }}
                />
              </Tooltip>
            }
            className="mt-3 w-1/2"
          />
          {!isVideoLinkValid && (
            <p className="text-red-500">Video Link is not valid!</p>
          )}
        </div>

        <div className="flex w-full justify-end">
          <DefaultButton onClick={handleUpdateBio}>Save Settings</DefaultButton>
        </div>
      </div>
    </Layout>
  );
};

export default TutorBio;
