import { Form } from 'antd';
import AntFormInput from 'components/shared/AntFormInput/AntFormInput';
import FormModal from 'components/shared/AntFormModal/AntFormModal';
import AntFormSubmit from 'components/shared/AntFormSubmit/AntFormSubmit';
import AntFormTextArea from 'components/shared/AntFormTextArea/AntFormTextArea';
import { FormRule } from 'enums/formRules';
import React from 'react';

const AddEditDisability = ({
  mode = 'add',
  isDisabilityModalOpen,
  setIsDisabilityModalOpen,
  isLoading,
  setIsLoading,
  handleAddEditDisability,
  handleCancel,
  initialValues = {},
  form,
}) => {
  return (
    <div>
      <FormModal
        title={
          <div className="text-xl font-bold">
            {mode === 'add' ? 'Add Your Disability' : 'Edit Disability'}
          </div>
        }
        isModalOpen={isDisabilityModalOpen}
        setIsModalOpen={setIsDisabilityModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={handleCancel}
      >
        <Form
          className="mt-7"
          layout="vertical"
          onFinish={handleAddEditDisability}
          form={form}
          initialValues={initialValues}
        >
          <AntFormInput
            label={<div className="font-medium">Disability Type</div>}
            rules={FormRule.DISABILITY_TYPE}
            name="disability_type"
            placeholder="Select disability type"
            className="w-full border border-gray-300"
          />
          <AntFormTextArea
            label={<div className="font-medium">Additional Notes</div>}
            rules={FormRule.none}
            name="additional_notes"
            placeholder="Additional notes"
            className="w-full"
            showCount={true}
          />

          <AntFormSubmit
            loading={isLoading}
            label={
              <div className="font-bold">
                {mode === 'add' ? 'Add Disability' : 'Update Disability'}
              </div>
            }
            disabled={isLoading}
            className="flex w-full items-center justify-center rounded-2xl bg-updatedBlue px-5 py-4 text-white"
          />
        </Form>
      </FormModal>
    </div>
  );
};

export default AddEditDisability;
