import React, { useEffect, useState } from 'react';
import {
  PencilSquareIcon,
  EyeIcon,
  EyeSlashIcon,
} from '@heroicons/react/24/outline';
import CustomButton from 'components/Button';
import SearchBar from 'components/SearchBar/SearchBar';
import Layout from 'components/admin/layout';
import { getRowNumber } from 'utils/helperFunctions';
import { Table, Button, Spin } from 'antd';
import SubjectsModal from 'components/Modal/SubjectsModal';
import { SUBJECTS_MODAL_TYPE } from 'constants/strings';
import { getAllSubjects, editSubject } from 'requests/admin-subject-management';

const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const Subjects = () => {
  const [subjectsData, setSubjectsData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [refreshFlag, setRefreshFlag] = useState(false);
  const [subjectName, setSubjectName] = useState({ id: '', title: '' });

  const { ADD, EDIT } = SUBJECTS_MODAL_TYPE;

  useEffect(() => {
    (async () => getSubjects())();
  }, [refreshFlag]);

  const getSubjects = async () => {
    try {
      const subjects = await getAllSubjects();
      setSubjectsData(subjects);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw new Error(err.response.data.error);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  const handleHideSubject = async (id, is_hidden) => {
    try {
      await editSubject({ id, is_hidden });
      setRefreshFlag((prev) => !prev);
      setIsLoading(true);
    } catch (err) {}
  };

  const columns = [
    {
      title: 'Sr. No.',
      align: 'center',
      render: (text, record, index) => (
        <TableText>{getRowNumber(index, tableParams)}</TableText>
      ),
    },
    {
      title: 'Subject Name',
      dataIndex: 'title',
      align: 'center',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'No. of Tutors',
      dataIndex: 'totalTutorsCount',
      align: 'center',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Actions',
      key: 'actions',
      align: 'center',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div>
            <Button
              type="primary"
              className="bg-[#13C296] hover:!bg-[#16e2af]"
              icon={<PencilSquareIcon className="h-6 w-6 text-white" />}
              onClick={() => {
                setIsModalOpen((prev) => !prev);
                setModalType(EDIT);
                setSubjectName({ id: record.id, title: record.title });
              }}
            />
            <Button
              type="primary"
              className="ml-2 bg-[#3056D3]"
              icon={
                record.is_hidden ? (
                  <EyeSlashIcon className="h-6 w-6 text-white" />
                ) : (
                  <EyeIcon className="h-6 w-6 text-white" />
                )
              }
              onClick={() => {
                handleHideSubject(record.id, !record.is_hidden);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Layout>
      <SubjectsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalType={modalType}
        setRefreshFlag={setRefreshFlag}
        subjectName={subjectName}
        setSubjectName={setSubjectName}
      />
      <div className="p-5">
        <div className="flex w-full justify-end">
          {/* <SearchBar /> */}
          <CustomButton
            onClick={() => {
              setIsModalOpen((prev) => !prev);
              setModalType(ADD);
            }}
          >
            Add subject
          </CustomButton>
        </div>

        <div
          id="ParentManagementTable"
          className="mt-5 flex justify-center overflow-scroll"
        >
          {isLoading ? (
            <Spin size="large" className="mt-10" />
          ) : (
            <Table
              columns={columns}
              dataSource={subjectsData}
              pagination={{
                position: ['bottomCenter'],
                ...tableParams.pagination,
              }}
              className="w-full"
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Subjects;
