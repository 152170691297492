import { childUserContext } from 'contexts/child-user-context';
import { userContext } from 'contexts/user';
import { useContext, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

export const Protected = ({ children, routeRole }) => {
  const { isAuthenticated, role } = useContext(userContext);
  if (!isAuthenticated) {
    return <Navigate to={`/${routeRole}/login`} />;
  } else if (isAuthenticated && role !== routeRole) {
    return <Navigate to={`/${role}/dashboard`} />;
  }

  return children;
};

export const NoAuthRoute = ({ children }) => {
  const { isAuthenticated, role } = useContext(userContext);
  if (isAuthenticated) {
    return <Navigate to={`/${role}/dashboard`} />;
  }

  return children;
};

export const ImpersonateProtected = ({ children }) => {
  const [params] = useSearchParams();
  const childId = params.get('key');
  const { setChildUser, childUser } = useContext(childUserContext);

  useEffect(() => {
    if (childId) {
      setChildUser(childId);
      localStorage.setItem('childUser', childId);
    }
  });

  if (childUser || childId) {
    return children;
  }

  return <Navigate to={`/parent/dashboard`} />;
};
