import React, { useState } from 'react';
import backgroundImg from '../../../assets/images/tutor/background.png';
import studentIcon from '../../../assets/images/student/studentIcon-new.jpeg';
import tutorIcon from '../../../assets/images/tutor/tutorIcon-new.jpeg';
import parentIcon from '../../../assets/images/parent/parentIcon-new.jpeg';

import CustomButton from 'components/shared/CustomButton/CustomButton';
import RoleCard from '../RoleCard/RoleCard';
import { PARENT, STUDENT, TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const Welcome = () => {
  const [selectedRole, setSelectedRole] = useState(null);
  const isButtonDisabled = selectedRole === null;
  const navigate = useNavigate();

  const handleRoleSelect = (roleName) => {
    setSelectedRole(roleName);
  };

  const handleNextButtonClick = () => {
    navigate(`/${selectedRole.toLowerCase()}/login`);
  };

  return (
    <div className="flex items-center justify-center bg-[#E4FDE1] lg:h-screen">
      <div className="my-10 flex flex-col items-center justify-center rounded-2xl bg-[#FFBF46] px-5 py-10 text-updatedBlue xs:w-[80%] md:w-[59%]">
        <div className="flex justify-center font-bold xs:pt-2 xs:text-2xl md:pt-5 md:text-3xl lg:text-4xl">
          Welcome to Skool'd Tutors
        </div>
        <div className="mb-7 mt-5 xs:text-xl lg:text-2xl">Choose your Role</div>
        <div className="grid justify-items-center xs:w-[200px] xs:gap-3 md:w-[300px] md:grid-cols-1 md:gap-10 lg:w-[65%] lg:grid-cols-3">
          <RoleCard
            roleName="Student"
            roleIcon={studentIcon}
            onSelect={() => handleRoleSelect(STUDENT)}
            selected={selectedRole === STUDENT}
          />
          <RoleCard
            roleName="Tutor"
            roleIcon={tutorIcon}
            onSelect={() => handleRoleSelect(TUTOR)}
            selected={selectedRole === TUTOR}
          />
          <RoleCard
            roleName="Parent"
            roleIcon={parentIcon}
            onSelect={() => handleRoleSelect(PARENT)}
            selected={selectedRole === PARENT}
          />
        </div>
        <div className="pb-5 pt-10">
          <CustomButton
            label="Next"
            className={`rounded-2xl ${
              isButtonDisabled
                ? 'bg-gray-200'
                : 'bg-updatedBlue text-white hover:!bg-updatedBlue'
            } font-bold text-gray-500`}
            buttonWidth="w-44"
            onClick={handleNextButtonClick}
            disabled={isButtonDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
