import React, { useContext, useState } from 'react';
import Layout from 'components/tutor/ProfileLayout';
import { userContext } from 'contexts/user';
import { createAndConnectStripeAccount } from 'requests/stripe';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useEffect } from 'react';

const BankDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    user: { id: userId, account_status },
  } = useContext(userContext);
  const { user, updateUser } = useContext(userContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const stripeURLNavigate = async () => {
    try {
      setIsLoading(true);
      const {
        data: {
          data: { url },
        },
      } = await createAndConnectStripeAccount(userId);
      window.location.href = url;
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Layout>
      <div className="flex min-h-[calc(93vh_-_210px)] w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 font-semibold text-updatedBlue xs:text-xl md:text-2xl">
            Detailed Profile / Bank Details
          </div>
          <div>
            {account_status === 'approved' && (
              <button
                type="button"
                className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                onClick={() => {
                  const navigateString = viewStoreFront(userId, TUTOR);
                  navigate(navigateString);
                }}
              >
                View Storefront
              </button>
            )}
          </div>
        </div>
        <div className="flex h-48 items-center justify-center">
          <CustomButton
            label={
              user.stripe_connect_account_id
                ? 'Edit Stripe Account'
                : 'Connect with Stripe'
            }
            className={`!h-12 bg-updatedBlue font-bold text-white ${
              !isLoading && 'hover:!bg-updatedBlue'
            } `}
            shape="round"
            size="large"
            disabled={isLoading}
            onClick={stripeURLNavigate}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default BankDetails;
