import React from 'react';
import { sendResetPasswordToken } from 'requests';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordConponent from 'components/forgot-password';
import { PARENT } from 'constants/roles';

const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleForgotPassword = async ({ email }) => {
    await sendResetPasswordToken(email);
    const query = btoa(JSON.stringify({ email }));
    navigate(`/parent/verify-code?query=${query}`);
  };

  return (
    <ForgotPasswordConponent
      forgotPassword={handleForgotPassword}
      className="bg-updatedGreenish md:bg-contain"
      role={PARENT}
    />
  );
};

export default ForgotPassword;
