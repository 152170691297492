import { PARENT } from 'constants/roles';
import axiosInstance from 'requests/config';

export const getParents = (limit = 10, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/admins/parents?limit=${limit}&pageNumber=${pageNumber}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getParentById = (id) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/parents/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateParent = (id, values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/parents/${id}/updateParentGeneralInfo`, values, {
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const updateParentStatus = (parentId, account_status) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`parents/${parentId}/changeParentStatus`, { account_status })
      .then(resolve)
      .catch(reject);
  });
};

export const updateParentsLanguages = (parentId, languages) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/parents/${parentId}/addLanguages`, {
        languages,
      })
      .then(resolve)
      .catch(reject);
  });
};

export const deactivateParent = (parentId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`parents/${parentId}/deactivateAccount`)
      .then(resolve)
      .catch(reject);
  });
};

export const addChildByParent = (parentId, data) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/parents/${parentId}/addStudent`, data)
      .then(resolve)
      .catch(reject);
  });
};

export const getChildrenOfParent = (parentId, limit = 10, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(
        `parents/${parentId}/studentsListing?limit=${limit}&pageNumber=${pageNumber}`
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const impersonateChild = (parentId, studentId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/parents/${parentId}/impersonateStudent/${studentId}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const signupParent = (values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/registrations/signUp', { ...values, role: PARENT })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getParentFriends = async (parentId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/meta/${parentId}/getUserFriends`)
      .then(resolve)
      .catch(reject);
  });
};
