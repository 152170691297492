import { Layout, Table } from 'antd';
import Navbar from 'components/shared/Navbar/Navbar';
import React, { useContext, useEffect, useState } from 'react';
import backgroundImg from 'assets/images/tutor/background.png';
import { tutorNavbarItems } from 'enums';
import { TUTOR } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import { getStudents as getStudentsAPI } from 'requests/tutors';
import moment from 'moment';
import { userContext } from 'contexts/user';
import { useNavigate } from 'react-router-dom';
import StreamChatModal from 'components/Modal/StreamChatModal';

const StudentManagement = () => {
  const {
    user: { id: tutorId },
  } = useContext(userContext);
  const [data, setData] = useState([]);
  const { api } = useContext(notificationsContext);

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [parentId, setParentId] = useState('');
  // const [parentName, setParentName] = useState('');

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const getStudents = async () => {
    try {
      const {
        data: { data },
      } = await getStudentsAPI(tutorId);

      setData(data.map((item, index) => ({ ...item, key: index + 1 })));
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again later.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Student ID',
      dataIndex: 'student_id',
      key: 'student_id',
    },
    {
      title: 'Student Name',
      dataIndex: 'student_name',
      key: 'student_name',
      render: (_, { student_first_name, student_last_name }) => {
        return `${student_first_name} ${student_last_name}`;
      },
    },
    {
      title: 'Email Id',
      dataIndex: 'booked_by_email',
      key: 'booked_by_email',
    },
    {
      title: 'Agenda',
      dataIndex: 'agenda',
      key: 'agenda',
    },
    {
      title: 'Session Date',
      dataIndex: 'start_time',
      key: 'start_time',
      render: (_, { start_time }) => {
        return moment(start_time).format('MMMM Do, YYYY LT');
      },
    },
    // {
    //   title: 'Start Chat',
    //   dataIndex: 'start_chat',
    //   key: 'start_chat',

    //   render: (_, { parent_id, parent_first_name }) => (
    //     <button
    //       className="rounded-3xl bg-updatedBlue px-4 py-2 text-white"
    //       onClick={() => {
    //         setIsModalOpen(true);
    //         setParentId(parent_id);
    //         setParentName(parent_first_name);
    //       }}
    //     >
    //       Start Chat
    //     </button>
    //   ),
    // },
  ];

  return (
    <Layout>
      <Navbar role={TUTOR} navItems={tutorNavbarItems} />
      {/* <StreamChatModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        name={parentName}
        id={parentId}
      /> */}
      <div className="min-h-[calc(100vh-64px)] bg-updatedGreenish">
        <div className="mx-12 my-7 min-h-[calc(100vh-120px)] rounded-2xl bg-updatedMustard px-10 pb-5 pt-10">
          <div className="mb-10 text-2xl font-semibold">
            Sessions Information
          </div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{
              x: 1600,
              y: 500,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default StudentManagement;
