import React, { useContext, useEffect, useState } from 'react';
import Navbar from 'components/shared/Navbar/Navbar';
import { Layout, Table } from 'antd';
import { PARENT } from 'constants/roles';
import { parentNavbarItems } from 'enums';
import backgroundImg from 'assets/images/tutor/background.png';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import TutorListModal from 'pages/shared/TutorListModal/TutorListModal';
import { getParentFriends } from 'requests';
import { userContext } from 'contexts/user';
import { notificationsContext } from 'contexts/notifications';

const MyNetwork = () => {
  const [isTutorModalOpen, setIsTutorModalOpen] = useState(false);
  const [networkData, setNetworkData] = useState([]);
  const [tutorData, setTutorData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { api } = useContext(notificationsContext);
  const { user } = useContext(userContext);

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'parent_first_name',
      key: 'parent_first_name',
      align: 'center',
    },
    {
      title: 'Last Name',
      dataIndex: 'parent_last_name',
      key: 'parent_last_name',
      align: 'center',
    },
    {
      title: 'Email',
      dataIndex: 'parent_email',
      key: 'parent_email',
      align: 'center',
    },
    {
      title: 'Tutors',
      dataIndex: 'tutors',
      key: 'tutors',
      align: 'center',
      render: (_, record) => (
        <div className="flex items-center justify-center">
          <CustomButton
            label="View Tutors"
            className="bg-updatedPink text-white"
            onClick={() => {
              setTutorData(record.tutors);
              setIsTutorModalOpen(true);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      await fetchNetwork();
    })();
  }, []);

  // Function to fetch the network details.
  const fetchNetwork = async () => {
    try {
      setIsLoading(true);

      // Validation - No facebook account connected.
      if (!user.facebook_access_token) {
        api.error({
          message: 'Error',
          description: 'Connect your facebook account to access my network!',
          placement: 'top',
          duration: 4,
        });
        setIsLoading(false);
        return;
      }

      // Success case - Facebook account is connected.
      const response = await getParentFriends(user.id);
      if (response.status === 200) {
        setNetworkData(response.data.data);
        setIsLoading(false);
        return;
      }

      // Validation - response is not 200.
      api.error({
        message: 'Error',
        description: 'Something went wrong!',
        placement: 'top',
        duration: 4,
      });
      setNetworkData([]);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  return (
    <Layout>
      <Navbar role={PARENT} navItems={parentNavbarItems} />
      <div className="Tutorsue-400] min-h-[calc(100vh-64px)] bg-updatedGreenish">
        <div className="mx-12 my-7 min-h-[calc(100vh-120px)] rounded-2xl bg-updatedMustard px-10 pb-5 pt-10">
          <div className="mb-10 text-2xl font-semibold">
            My Network in Skool'd
          </div>
          <Table
            loading={isLoading}
            columns={columns}
            dataSource={networkData}
            pagination={false}
            scroll={{
              x: 1000,
              y: 500,
            }}
          />
          <TutorListModal
            tutorData={tutorData}
            isTutorModalOpen={isTutorModalOpen}
            setIsTutorModalOpen={setIsTutorModalOpen}
            setIsLoading={setIsLoading}
            handleCancel={() => setIsTutorModalOpen(false)}
          />
        </div>
      </div>
    </Layout>
  );
};

export default MyNetwork;
