import { updateTutor } from 'requests/tutors';
import { Message } from 'constants/message';

export const FormSubmit = async ({
  formData,
  id,
  setIsShowButton,
  setIsShowEditButton,
  setIsEdit,
  api,
}) => {
  try {
    await updateTutor({ id, formData });
    setIsShowButton((prev) => !prev);
    setIsShowEditButton((prev) => !prev);
    setIsEdit((prev) => !prev);
    api.success({
      message: 'Success',
      description: Message.tutorRecord,
      placement: 'top',
      duration: 4,
    });
  } catch (err) {
    api.error({
      message: 'Error',
      description: Message.errorMessage,
      placement: 'top',
      duration: 4,
    });
  }
};
