import React from 'react';

const StoreFrontBio = ({ type, tableTitle, tagData }) => {
  return (
    <div className="mb-5 rounded-lg border border-neutral-200 bg-updatedCreme  p-5 shadow-md">
      <p className="text-3xl font-semibold">{tableTitle}</p>
      <div className="mt-7 flex flex-wrap gap-7 xs:justify-center md:justify-start"></div>
      <p>{tagData}</p>
    </div>
  );
};

export default StoreFrontBio;
