import * as yup from 'yup';
import { STRINGS } from '../constants/strings';

export function validateEmail(email) {
  const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
}

export const phoneRegExp = /^\(\d{3}\) \d{3}-\d{4}$/;
export const validationSchemaTutorGeneralProfile = yup.object().shape({
  email: yup
    .string()
    .required(STRINGS.email_required)
    .email(STRINGS.email_invalid),
  first_name: yup
    .string()
    .required(STRINGS.firstNameRequired)
    .min(3, STRINGS.nameLength),
  last_name: yup
    .string()
    .required(STRINGS.lastNameRequired)
    .min(3, STRINGS.nameLength),
});
