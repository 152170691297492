import Welcome from 'pages/shared/Welcome/Welcome';
import { Route } from 'react-router-dom';
import { NoAuthRoute } from 'routes';

const generalRoutes = (
  <Route key="home" path="/">
    <Route
      path=""
      element={
        <NoAuthRoute>
          <Welcome />
        </NoAuthRoute>
      }
    />
  </Route>
);

export default generalRoutes;
