import PastSessionCard from 'components/shared/PastSessionCard/PastSessionCard';
import { STUDENT } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import { userContext } from 'contexts/user';
import React, { useContext, useEffect, useState } from 'react';
import { bulkInsertAppointments } from 'requests/appointments';
import { getStudentById } from 'requests/students';
import { getCalendarEvents } from 'utils/calendarApi';

const PastSessionList = ({ role, userId }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const { getGoogleCalendarToken } = useContext(userContext);
  const [calendarToken] = useState(getGoogleCalendarToken() || null);
  const { api } = useContext(notificationsContext);

  const getSessions = async () => {
    if (calendarToken) {
      try {
        setLoading(true);
        let {
          data: { nextPageToken, items = [] },
        } = await getCalendarEvents({
          timeMax: new Date().toISOString(),
        });
        while (nextPageToken) {
          const response = await getCalendarEvents({
            pageToken: nextPageToken,
            timeMax: new Date().toISOString(),
          });
          nextPageToken = response?.data?.nextPageToken;
          items = [...items, ...response.data.items];
        }

        let skooldEvents = items.filter(
          (item) =>
            item?.status === 'confirmed' &&
            new Date(item?.start?.dateTime) < new Date() &&
            item?.summary?.toLowerCase().includes('ssskoold')
        );

        if (role === STUDENT) {
          const {
            data: { student_uuid },
          } = await getStudentById(userId);
          skooldEvents = skooldEvents.filter((item) => {
            // eslint-disable-next-line no-unused-vars
            let [_bookedBy, _agenda, studentId] =
              item.description.split('<br>');
            if (!studentId) return false;
            studentId = studentId.split('\n')[1].trim();
            return studentId === student_uuid;
          });
        }

        const { data: sessions } = await bulkInsertAppointments(
          userId,
          skooldEvents
        );
        setEvents(sessions);
      } catch (err) {
        console.log(err);
        api.error({
          message: 'Error',
          description:
            'Something went wrong in getting past sessions. Please try again after some time.',
          placement: 'top',
          duration: 4,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen overflow-y-auto rounded-xl bg-updatedMustard px-5 py-5">
      <div className="border-b pb-5 text-lg font-bold">Past Sessions</div>
      <div className="my-5">
        {loading ? null : !!events.length ? (
          events.map((event) => (
            <PastSessionCard role={role} event={event} userId={userId} />
          ))
        ) : (
          <div className="mt-14 text-center text-3xl font-extrabold text-gray-300">
            No Past Sessions
          </div>
        )}
      </div>
    </div>
  );
};

export default PastSessionList;
