import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginOther } from 'requests';
import { STUDENT } from 'constants/roles';
import LoginComponent from 'components/login';
import { userContext } from 'contexts/user';
import loginImage from '../../assets/images/student/background.png';
import { notificationsContext } from 'contexts/notifications';
import { Message } from 'constants/message';

const Login = () => {
  const [apiError, setApiError] = useState('');

  const { login: loginContext } = useContext(userContext);
  const { api } = useContext(notificationsContext);

  const navigate = useNavigate();

  const handleLogin = async ({ email, password }) => {
    const response = await loginOther(email, password);
    const { account_status } = response?.data;

    if (response?.data?.role === STUDENT) {
      // Validation - If the account is deactivated.
      if (account_status === 'deactivate') {
        api.error({
          message: 'Error',
          description: Message.deactivated,
          placement: 'top',
          duration: 4,
        });
        return;
      }

      loginContext(response);
      if (!!response?.data?.is_force_change_password) {
        navigate('/student/create-password', { replace: true });
      } else {
        navigate('/student/dashboard', { replace: true });
      }
    } else {
      setApiError('Invalid credentials');
    }
  };

  return (
    <LoginComponent
      login={handleLogin}
      forgotPasswordLink="/student/forgot-password"
      className="bg-updatedGreenish"
      coverImage={loginImage}
      role={STUDENT}
      apiError={apiError}
      setApiError={setApiError}
    />
  );
};

export default Login;
