import React from 'react';
import StudentDetailsLayout from 'components/admin/student-details-layout';
import SchoolProfilingDetails from 'pages/shared/SchoolProfilingDetails/SchoolProfilingDetails';
import { useParams } from 'react-router-dom';
import { ADMIN } from 'constants/roles';

const SchoolProfiling = () => {
  const { id: studentId } = useParams();
  return (
    <StudentDetailsLayout>
      <SchoolProfilingDetails studentId={studentId} mode={ADMIN} />
    </StudentDetailsLayout>
  );
};

export default SchoolProfiling;
