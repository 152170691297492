import axiosInstance from './config';

export const getTutorDisabilityTeachingExp = (tutorId) =>
  axiosInstance.get(`/tutors/${tutorId}/disabilityTeachings`);

export const createTutorDisabilityTeachingExp = (tutorId, formData) =>
  axiosInstance.post(`/tutors/${tutorId}/disabilityTeachings`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateTutorDisabilityTeachingExp = (
  tutorId,
  disabilityTeachingExpId,
  formData
) =>
  axiosInstance.put(
    `/tutors/${tutorId}/disabilityTeachings/${disabilityTeachingExpId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );

export const deleteTutorDisabilityExp = (tutorId, disabilityTeachingExpId) =>
  axiosInstance.delete(
    `/tutors/${tutorId}/disabilityTeachings/${disabilityTeachingExpId}`
  );
