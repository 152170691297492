import { notification } from 'antd';
import { createContext } from 'react';

export const notificationsContext = createContext();

export const NotificationsContextProvider = ({ children }) => {
  const [api, contextHandler] = notification.useNotification();

  return (
    <notificationsContext.Provider value={{ api, contextHandler }}>
      {children}
    </notificationsContext.Provider>
  );
};
