import VerifyCodeComponent from 'components/verify-code';
import { STUDENT } from 'constants/roles';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyResetPasswordToken } from 'requests';

const VerifyCode = () => {
  const [params] = useSearchParams();
  const email = JSON.parse(atob(params.get('query'))).email;
  const navigate = useNavigate();

  const handleVerifyCode = async ({ otp }) => {
    await verifyResetPasswordToken(email, otp);
    const query = btoa(JSON.stringify({ otp, email }));
    navigate(`/student/create-password?query=${query}`);
  };
  return (
    <VerifyCodeComponent
      email={email}
      handleVerifyCode={handleVerifyCode}
      className="bg-[url('assets/images/tutor/background.png')] bg-contain"
      role={STUDENT}
    />
  );
};

export default VerifyCode;
