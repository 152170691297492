export const impersonateStudentDetailsSidebarOptions = [
  {
    label: 'General Profile',
    name: 'general-profile',
    path: '/parent/student-general-profile',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',

    children: [
      {
        label: 'School Profiling',
        name: 'school-profiling',
        path: '/parent/student-school-profiling',
      },
      {
        label: 'Languages',
        name: 'languages',
        path: '/parent/student-languages',
      },
      {
        label: 'Disability Profiling',
        name: 'disability-profiling',
        path: '/parent/student-disability-profiling',
      },
      {
        label: 'Payment Configuration',
        name: 'payment-configuration',
        path: '/parent/student-payment-configuration',
      },
    ],
  },
];
