import React from 'react';
import EditParentGeneralProfileSidebar from './edit-parent-general-profile-sidebar';
import backgroundImg from '../../assets/images/tutor/background.png';
import Navbar from 'components/shared/Navbar/Navbar';
import { parentNavbarItems } from 'enums';
import { PARENT } from 'constants/roles';
import { Layout } from 'antd';

const EditParentDetailsLayout = ({ children }) => {
  return (
    <div>
      <Layout>
        <Navbar navItems={parentNavbarItems} role={PARENT} />
        <div className="flex gap-5 bg-updatedGreenish xs:flex-col md:flex-row">
          <EditParentGeneralProfileSidebar />
          <div className="mx-5 my-6 flex-1 rounded-2xl bg-updatedMustard shadow xs:p-5 md:p-20">
            {children}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default EditParentDetailsLayout;
