import axiosInstance from './config';

export const getSchools = (studentId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/students/${studentId}/schoolProfilings`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteSchool = async (studentId, schoolProfilingId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`/students/${studentId}/schoolProfilings/${schoolProfilingId}`)
      .then(resolve)
      .catch(reject);
  });
};

export const addSchool = (studentId, formData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/students/${studentId}/schoolProfilings`, formData)
      .then(resolve)
      .catch(reject);
  });
};

export const updateSchool = (studentId, schoolProfilingId, formData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(
        `/students/${studentId}/schoolProfilings/${schoolProfilingId}`,
        formData
      )
      .then(resolve)
      .catch(reject);
  });
};
