import { Form } from 'antd';
import AntFormCheckbox from 'components/shared/AntFormCheckbox/AntFormCheckbox';
import AntFormDatePicker from 'components/shared/AntFormDatePicker/AntFormDatePicker';
import AntFormInput from 'components/shared/AntFormInput/AntFormInput';
import FormModal from 'components/shared/AntFormModal/AntFormModal';
import AntFormSelect from 'components/shared/AntFormSelect/AntFormSelect';
import AntFormSubmit from 'components/shared/AntFormSubmit/AntFormSubmit';
import { schoolGrades } from 'enums';
import { FormRule } from 'enums/formRules';
import React from 'react';

const AddSchoolModal = ({
  isAddSchoolModalOpen,
  setIsAddSchoolModalOpen,
  isLoading,
  setIsLoading,
  handleAddSchool,
  handleCancel,
  initialValues = {},
  form,
  onChangeCheckbox,
  isContinueSelected,
}) => {
  return (
    <div>
      <FormModal
        title={<div className="text-xl font-bold">Add New School</div>}
        isModalOpen={isAddSchoolModalOpen}
        setIsModalOpen={isAddSchoolModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={handleCancel}
      >
        <Form
          className="mt-7"
          layout="vertical"
          onFinish={handleAddSchool}
          form={form}
          initialValues={initialValues}
        >
          <AntFormInput
            label={<div className="font-medium">School Name</div>}
            rules={FormRule.SCHOOL_NAME}
            name="school_name"
            placeholder="Enter your school name"
            className="w-full"
          />
          <AntFormInput
            label={<div className="font-medium">City</div>}
            rules={FormRule.CITY}
            name="city"
            placeholder="Choose your city"
            className="w-full"
          />
          <AntFormInput
            label={<div className="font-medium">State</div>}
            rules={FormRule.STATE}
            name="state"
            placeholder="Choose your state"
            className="w-full"
          />
          <AntFormDatePicker
            label={<div className="font-medium">Attended From</div>}
            rules={FormRule.SELECT}
            name="attended_from"
            placeholder="Select date"
            className="w-full"
            showArrow={true}
          />
          <AntFormDatePicker
            label={<div className="font-medium">Attended Till</div>}
            name="attended_till"
            rules={isContinueSelected ? FormRule.none : FormRule.SELECT}
            placeholder="Select date"
            className="w-full"
            showArrow={true}
            disabled={isContinueSelected}
          />
          <AntFormCheckbox
            name="is_continue"
            rules={FormRule.none}
            label="Continue"
            onChangeCheckbox={onChangeCheckbox}
          />

          <AntFormSelect
            label={<div className="font-medium">Grades</div>}
            rules={FormRule.none}
            name="grades"
            showArrow={true}
            options={schoolGrades}
            placeholder="Select your grades (Checkboxes)"
            className=" w-full border border-gray-300"
            size="small"
            mode="multiple"
          />

          <AntFormSubmit
            loading={isLoading}
            label={<div className="font-bold">Add School</div>}
            disabled={isLoading}
            className="flex w-full items-center justify-center rounded-2xl bg-updatedBlue px-5 py-4 text-white"
          />
        </Form>
      </FormModal>
    </div>
  );
};

export default AddSchoolModal;
