import React from 'react';
import StudentDetailsLayout from 'components/admin/student-details-layout';
import GeneralProfileForm from 'components/student/GeneralProfileForm';
import { useParams } from 'react-router-dom';

const GeneralProfile = () => {
  const { id } = useParams();
  return (
    <StudentDetailsLayout>
      <GeneralProfileForm id={id} />
    </StudentDetailsLayout>
  );
};

export default GeneralProfile;
