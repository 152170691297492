import React from 'react';
import cn from 'classnames';

const Button = ({ children, className, ...props }) => {
  return (
    <button
      className={cn(
        className,
        ' h-12 w-52 items-center justify-center rounded-3xl bg-gray-800 text-sm font-semibold text-white'
      )}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
