import React from 'react';
import image from 'assets/images/admin/login.png';
import tutor_sign_in_image from 'assets/images/admin/tutor_sign_in.png';
import parent_sign_in_image from 'assets/images/admin/parent_sign_in.png';
import student_sign_in_image from 'assets/images/admin/student_sign_in.png';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { Formik } from 'formik';
import { STRINGS } from 'constants/strings';
import cn from 'classnames';
import { PARENT, STUDENT, TUTOR } from 'constants/roles';
import privacyPolicy from '../assets/privacy-policy.pdf';
import termsOfService from '../assets/terms_of_service.pdf';

const LoginComponent = ({
  login,
  forgotPasswordLink,
  className = '',
  coverImage = image,
  role,
  apiError,
  setApiError,
}) => {
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required(STRINGS.email_required)
      .email(STRINGS.email_invalid),
    password: yup
      .string()
      .min(8, STRINGS.password_min_length)
      .required(STRINGS.password_required),
  });

  const handleLogin = async ({ email, password }) => {
    setApiError('');
    try {
      await login({ email, password });
    } catch (err) {
      setApiError(err.response.data.error);
    }
  };

  return (
    <div
      className={cn(
        'flex h-screen w-screen justify-center pt-10 xs:px-2 sm:p-0 md:items-center md:pt-0',
        className
      )}
    >
      <div className="flex aspect-[1] h-[600px] overflow-hidden rounded-3xl bg-updatedCreme shadow-custom-1 lg:aspect-[1.7]">
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
          validateOnChange={false}
        >
          {({ handleChange, errors, handleBlur, touched, handleSubmit }) => (
            <div className="flex flex-1 flex-col items-center p-6 sm:p-14 md:p-[60px]">
              <div className="h-8 w-full text-3xl font-bold leading-9 text-updatedBlue">
                Sign In
              </div>
              <div className="mt-10 h-24 w-full">
                <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                  Email
                </div>
                <input
                  className="mt-3 h-14 w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-4 py-3.5"
                  placeholder="test@test.com"
                  type="text"
                  name="email"
                  id="email"
                  onChange={(props) => {
                    !!apiError && setApiError('');
                    handleChange(props);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className="mt-1 h-5 w-full">
                {!!errors.email && !!touched.email && (
                  <div className="w-full text-red-500">{errors.email}</div>
                )}
              </div>
              <div className="mt-4 h-24 w-full">
                <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                  Password
                </div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="mt-3 h-14 w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-4 py-3.5"
                  placeholder="********"
                  onChange={(props) => {
                    !!apiError && setApiError('');
                    handleChange(props);
                  }}
                  onBlur={handleBlur}
                />
              </div>
              <div className="mt-1 h-5 w-full">
                {!!errors.password && !!touched.password && (
                  <div className="w-full text-red-500">{errors.password}</div>
                )}
              </div>
              <button
                className={`mt-7 h-12 w-full items-center justify-center rounded-3xl ${
                  role === STUDENT ? '!bg-updatedBlue' : 'bg-updatedBlue'
                }  text-center font-semibold text-white`}
                type="button"
                onClick={handleSubmit}
              >
                Sign In
              </button>
              <div className="mt-1 h-5 w-full">
                {!!apiError && <div className=" text-red-500">{apiError}</div>}
              </div>
              <div className="flex w-full items-center justify-between">
                <Link className="mt-4 text-updatedBlue" to={forgotPasswordLink}>
                  Forgot Password?
                </Link>
                {(role === TUTOR || role === PARENT) && (
                  <div className="mt-4">
                    <div className="text-gray-400">Don't have an account?</div>
                    <Link
                      className="text-updatedBlue underline"
                      to={`/${role}/signup`}
                    >
                      Sign Up
                    </Link>
                  </div>
                )}
              </div>

              {(role === TUTOR || role === PARENT || role === STUDENT) && (
                <div className="mt-2 flex gap-1">
                  <div className="text-gray-400">Read our</div>
                  <a
                    className="text-updatedBlue underline"
                    href="https://www.skooldtutors.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy & Policy
                  </a>
                  <div className="text-gray-400">&</div>
                  <a
                    className="text-updatedBlue underline"
                    href="https://www.skooldtutors.com/terms-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of Service
                  </a>
                </div>
              )}
            </div>
          )}
        </Formik>
        <img
          className="hidden h-full w-1/2 lg:block"
          src={
            role === STUDENT
              ? student_sign_in_image
              : role === PARENT
                ? parent_sign_in_image
                : tutor_sign_in_image
          }
          alt="login_image"
        />
      </div>
    </div>
  );
};

export default LoginComponent;
