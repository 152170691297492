import React, { useContext } from 'react';
import { userContext } from 'contexts/user';

const OnlineSessionCard = ({ cardTitle, fee, googleCalendarLink }) => {
  const { user } = useContext(userContext);
  const isStripeCustomerIdPresent = user.stripe_customer_id !== null;

  return (
    <div className="mb-5 rounded-lg border border-neutral-200 bg-updatedCreme p-5  shadow-md">
      <p className="text-3xl font-semibold">{cardTitle}</p>
      <div className="mt-7">
        <div className="flex items-center justify-between border-b border-neutral-200 py-5">
          <p className="font-medium text-gray-500">Fee:</p>
          <div className="font-medium text-updatedBlue">{fee}</div>
        </div>
        {isStripeCustomerIdPresent ? (
          // eslint-disable-next-line react/jsx-no-target-blank
          <a href={googleCalendarLink} target="_blank">
            <div className="flex h-10 w-full cursor-pointer items-center justify-around rounded-md bg-updatedPink font-bold text-white">
              Book Session
            </div>
          </a>
        ) : (
          <div className="flex h-10 w-full cursor-default items-center justify-around rounded-md bg-gray-500 font-bold text-white">
            Book Session
          </div>
        )}
      </div>
    </div>
  );
};

export default OnlineSessionCard;
