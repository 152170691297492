import axiosInstance from 'requests/config';

export const sendResetPasswordToken = (email) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/sessions/sendResetPasswordToken', {
        email,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetPassword = (email, password, reset_password_token) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/sessions/resetPassword', {
        email,
        password,
        reset_password_token,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const forceChangePassword = (email, password) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/sessions/forceChangePassword', {
        email,
        password,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const verifyResetPasswordToken = (email, reset_password_token) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post('/sessions/verifyResetPasswordToken', {
        email,
        reset_password_token,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
