import axiosInstance from './config';

export const updateAdmin = (id, values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/admins/${id}/updateAdminProfile`, values, {
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const changeAdminPassword = (id, values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/admins/${id}/changePassword`, values)
      .then(resolve)
      .catch(reject);
  });
};
