import React, { useState } from 'react';
import studentImage from 'assets/images/student/students_image.jpg';
import * as yup from 'yup';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';
import { STRINGS } from 'constants/strings';
import cn from 'classnames';
import { PARENT, STUDENT, TUTOR } from 'constants/roles';
import tutorImage from 'assets/images/admin/tutor_sign_in.png';
import parentImage from 'assets/images/admin/parent_sign_in.png';

const CreatePasswordComponent = ({ resetPassword, className = '', role }) => {
  const [apiError, setApiError] = useState('');
  const [params] = useSearchParams();
  const query = params.get('query');
  const { otp, email } = query
    ? JSON.parse(atob(query))
    : { otp: '', email: '' };

  const validationSchema = yup.object().shape({
    password: yup
      .string()
      .required(STRINGS.password_required)
      .min(8, STRINGS.password_min_length),
    confirmPassword: yup
      .string()
      .required(STRINGS.confirm_password_required)
      .min(8, STRINGS.confirm_password_min_length)
      .oneOf([yup.ref('password')], STRINGS.confirm_password_not_match),
  });

  const handleResetPassword = async ({ password }) => {
    setApiError('');
    try {
      await resetPassword(email, password, otp);
    } catch (err) {
      setApiError(err.response.data.message);
    }
  };

  return (
    <div
      className={cn(
        'flex h-screen w-screen justify-center pt-10 md:items-center md:pt-0',
        className
      )}
    >
      <div className="flex aspect-[1] h-[600px] overflow-hidden rounded-3xl bg-updatedCreme shadow-custom-1 lg:aspect-[1.7]">
        <Formik
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleResetPassword}
          validateOnChange={false}
        >
          {({ handleChange, errors, handleBlur, touched, handleSubmit }) => (
            <div className="flex flex-1 flex-col items-center p-6 sm:p-14 md:p-[60px]">
              <div className="w-full text-3xl font-bold leading-9 text-updatedBlue">
                Create New Password
              </div>
              <div className="mt-5 w-full">
                <div className="text-base font-normal leading-normal text-gray-500 ">
                  To protect your account, make sure your password
                  <li>Is longer than 7 characters</li>
                  <li>Should contain numeric and alphabetic both</li>
                </div>
              </div>
              <div className="mt-7 w-full">
                <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                  Enter new password
                </div>
                <input
                  className="mt-3 h-14 w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-4 py-3.5"
                  placeholder="********"
                  type="password"
                  name="password"
                  id="password"
                  onChange={(props) => {
                    !!apiError && setApiError('');
                    handleChange(props);
                  }}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5 w-full">
                  {!!errors.password && !!touched.password && (
                    <div className="w-full text-red-500">{errors.password}</div>
                  )}
                </div>
              </div>
              <div className="mt-2 w-full">
                <div className="text-base font-medium leading-normal text-updatedBlue text-opacity-90">
                  Confirm pasword
                </div>
                <input
                  className="mt-3  h-14 w-full items-start justify-start rounded-lg border border-neutral-200 bg-updatedCreme px-4 py-3.5"
                  placeholder="********"
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={(props) => {
                    !!apiError && setApiError('');
                    handleChange(props);
                  }}
                  onBlur={handleBlur}
                />
                <div className="mt-1 h-5 w-full">
                  {!!errors.confirmPassword && !!touched.confirmPassword && (
                    <div className="w-full text-red-500">
                      {errors.confirmPassword}
                    </div>
                  )}
                </div>
              </div>
              <button
                className={`mt-3 h-12 w-full items-center justify-center rounded-3xl ${
                  role === STUDENT ? 'bg-updatedBlue' : 'bg-updatedBlue'
                } text-center font-semibold text-white`}
                type="button"
                onClick={handleSubmit}
              >
                Update Password
              </button>
              <div className="mt-1 h-5 w-full">
                {!!apiError && <div className=" text-red-500">{apiError}</div>}
              </div>
            </div>
          )}
        </Formik>
        <img
          className="hidden h-full w-1/2 lg:block"
          src={
            role === TUTOR
              ? tutorImage
              : role === PARENT
                ? parentImage
                : studentImage
          }
          alt="login_image"
        />
      </div>
    </div>
  );
};

export default CreatePasswordComponent;
