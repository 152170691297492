import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

function FormModal({
  isModalOpen,
  setIsModalOpen,
  children,
  title = '',
  handleCancel,
  bodyStyle,
  setIsLoading,
  closeIcon = <CloseOutlined className="text-xl text-updatedBlue" />,
  className = '!w-[350px] md:!w-[380px]',
}) {
  const closeModalHandler = () => {
    setIsModalOpen(false);
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        destroyOnClose={true}
        open={isModalOpen}
        closeIcon={closeIcon}
        title={<h3 className="text-start">{title}</h3>}
        footer={null}
        className={className}
        onCancel={handleCancel ? handleCancel : closeModalHandler}
        bodyStyle={bodyStyle}
      >
        {children}
      </Modal>
    </>
  );
}

export default FormModal;
