import EditParentDetailsLayout from 'pages/parent-dashboard/edit-parent-details-layout';
import FacebookLoginButton from './facebook-login';

const MetaAccountsConnect = () => {
  return (
    <EditParentDetailsLayout>
      <FacebookLoginButton />
    </EditParentDetailsLayout>
  );
};

export default MetaAccountsConnect;
