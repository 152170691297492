import React, { useContext, useEffect } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { savePayment, setupPayment } from 'requests/stripe';
import { userContext } from 'contexts/user';

const StripeSucess = () => {
  const [searchParams] = useSearchParams();
  const session_id = searchParams.get('session_id');
  const checkout = searchParams.get('checkout');
  const {
    user: { id },
  } = useContext(userContext);
  const navigate = useNavigate();

  const saveSessionId = async () => {
    try {
      await savePayment(id, session_id);
      navigate('/parent/payment-configuration');
    } catch (err) {
      console.log('stripe error');
    }
  };

  const savePaymentMethod = async () => {
    try {
      await setupPayment(id, checkout);
      navigate('/parent/payment-configuration');
    } catch (err) {
      console.log('stripe error');
    }
  };

  useEffect(() => {
    if (checkout) savePaymentMethod();
    if (session_id) saveSessionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Result
      icon={<SmileOutlined />}
      title="Great, your Stripe account is successfully connected!"
      className="mt-[15%]"
    />
  );
};

export default StripeSucess;
