import { ReactComponent as TutorManagement } from 'assets/images/admin/tutor-management.svg';
import { ReactComponent as ParentManagement } from 'assets/images/admin/parent-management.svg';
import { ReactComponent as StudentManagement } from 'assets/images/admin/student-management.svg';
import { ReactComponent as SubjectManagement } from 'assets/images/admin/subject-management.svg';
import { ReactComponent as MyProfile } from 'assets/images/admin/my-profile.svg';

export const sideBarOptions = [
  {
    label: 'Tutor Management',
    name: 'tutor-management',
    path: '/admin/dashboard',
    Image: TutorManagement,
  },
  {
    label: 'Parent Management',
    name: 'parent-management',
    path: '/admin/parent-management',
    Image: ParentManagement,
  },
  {
    label: 'Student Management',
    name: 'student-management',
    path: '/admin/student-management',
    Image: StudentManagement,
  },
  {
    label: 'Subject Management',
    name: 'subject-management',
    children: [
      {
        label: 'Subjects',
        name: 'subjects',
        path: '/admin/subject-management/subjects',
      },
      {
        label: 'Other Subjects',
        name: 'other-subjects',
        path: '/admin/subject-management/other-subjects',
      },
    ],
    Image: SubjectManagement,
  },
  // {
  //   label: 'Payment Center',
  //   name: 'payment-center',
  //   children: [
  //     {
  //       label: 'Subscription Plan',
  //       name: 'subscription-plan',
  //       path: '/admin/payment-center/subscription-plan',
  //     },
  //     {
  //       label: 'Net Revenue Details',
  //       name: 'net-revenue-details',
  //       path: '/admin/payment-center/net-revenue-details',
  //     },
  //   ],
  //   Image: PaymentCenter,
  // },
  {
    label: 'My Profile',
    name: 'my-profile',
    path: '/admin/my-profile',
    Image: MyProfile,
  },
];

export const tuturDetailsSideBarOptions = [
  {
    label: 'General Profile',
    name: 'general-profile',
    path: '/admin/tutor/:id',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',

    children: [
      {
        label: 'Bio',
        name: 'bio',
        path: '/admin/tutor/:id/bio',
      },
      {
        label: 'Subject(s) Expertise',
        name: 'subjects-expertise',
        path: '/admin/tutor/:id/subjects-expertise',
      },
      {
        label: 'Languages',
        name: 'languages',
        path: '/admin/tutor/:id/languages',
      },
      {
        label: 'Certification',
        name: 'certification',
        path: '/admin/tutor/:id/certification',
      },
      {
        label: 'Education',
        name: 'education',
        path: '/admin/tutor/:id/education',
      },
      {
        label: 'Work Experience',
        name: 'work-experience',
        path: '/admin/tutor/:id/work-experience',
      },
      {
        label: 'Student Age Groups',
        name: 'students-statistics',
        path: '/admin/tutor/:id/students-statistics',
      },
      {
        label: 'Disability Teaching Exp',
        name: 'disability-teaching-exp',
        path: '/admin/tutor/:id/disability-teaching-exp',
      },
      {
        label: 'Calendar Integration',
        name: 'calendar-integration',
        path: '/admin/tutor/:id/calendar-integration',
      },
      {
        label: 'Pricing',
        name: 'pricing',
        path: '/admin/tutor/:id/pricing',
      },
      {
        label: 'Bank Details',
        name: 'bank-details',
        path: '/admin/tutor/:id/bank-details',
      },
    ],
  },
];

export const studentDetailsSidebarOptions = [
  {
    label: 'General Profile',
    name: 'general-profile',
    path: '/admin/student/:id',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',

    children: [
      {
        label: 'School Profiling',
        name: 'school-profiling',
        path: '/admin/student/:id/school-profiling',
      },
      {
        label: 'Languages',
        name: 'languages',
        path: '/admin/student/:id/languages',
      },
      {
        label: 'Disability Profiling',
        name: 'disability-profiling',
        path: '/admin/student/:id/disability-profiling',
      },
      {
        label: 'Payment Configuration',
        name: 'payment-configuration',
        path: '/admin/student/:id/payment-configuration',
      },
    ],
  },
];

export const parentDetailsSidebarOptions = [
  {
    label: 'General Profile',
    name: 'general-profile',
    path: '/admin/parent-management/:id',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',
    children: [
      {
        label: 'Language Proficiency',
        name: 'language-proficiency',
        path: '/admin/parent-management/:id/language-proficiency',
      },
      {
        label: 'Payment Configuration',
        name: 'payment-configuration',
        path: '/admin/parent-management/:id/payment-configuration',
      },
    ],
  },
];

export const tutorLayoutSideBarOptions = [
  {
    label: (
      <p>
        General Profile<span className="text-red-800">*</span>
      </p>
    ),
    name: 'general-profile',
    path: '/tutor/general-profile',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',

    children: [
      {
        label: (
          <p>
            Bio<span className="text-red-800">*</span>
          </p>
        ),
        name: 'bio',
        path: '/tutor/bio',
      },
      {
        label: (
          <p>
            Subject(s) Expertise<span className="text-red-800">*</span>
          </p>
        ),
        name: 'subjects-expertise',
        path: '/tutor/subjects-expertise',
      },
      {
        label: (
          <p>
            Languages<span className="text-red-800">*</span>
          </p>
        ),
        name: 'languages',
        path: '/tutor/languages',
      },
      {
        label: 'Certification',
        name: 'certification',
        path: '/tutor/certification',
      },
      {
        label: (
          <p>
            Education<span className="text-red-800">*</span>
          </p>
        ),
        name: 'education',
        path: '/tutor/education',
      },
      {
        label: (
          <p>
            Work Experience<span className="text-red-800">*</span>
          </p>
        ),
        name: 'work-experience',
        path: '/tutor/work-experience',
      },
      {
        label: (
          <p>
            Student Age Groups<span className="text-red-800">*</span>
          </p>
        ),
        name: 'students-statistics',
        path: '/tutor/students-statistics',
      },
      {
        label: 'Disability Teaching Exp',
        name: 'disability-teaching-exp',
        path: '/tutor/disability-teaching-exp',
      },
      {
        label: (
          <p>
            Connect Calendar<span className="text-red-800">*</span>
          </p>
        ),
        name: 'connect-calendar',
        path: '/tutor/connect-calendar',
      },
      {
        label: (
          <p>
            Pricing<span className="text-red-800">*</span>
          </p>
        ),
        name: 'pricing',
        path: '/tutor/pricing',
      },
      {
        label: (
          <p>
            Bank Details<span className="text-red-800">*</span>
          </p>
        ),
        name: 'bank-details',
        path: '/tutor/bank-details',
      },
    ],
  },
];
