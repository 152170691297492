import { UploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Form, Upload, message } from 'antd';
import AntFormDatePicker from 'components/shared/AntFormDatePicker/AntFormDatePicker';
import AntFormInput from 'components/shared/AntFormInput/AntFormInput';
import AntFormSelect from 'components/shared/AntFormSelect/AntFormSelect';
import AntFormSubmit from 'components/shared/AntFormSubmit/AntFormSubmit';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import LocationInput from 'components/shared/LocationInput/LocationInput';
import { Message } from 'constants/message';
import { notificationsContext } from 'contexts/notifications';
import { selectedUserContext } from 'contexts/selected-user';
import dayjs from 'dayjs';
import { genderOptions } from 'enums';
import { FormRule } from 'enums/formRules';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { getTutorById, updateTutorAPI } from 'requests';
import { viewStoreFront } from 'utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ADMIN } from 'constants/roles';

const TutorGeneralProfileForm = ({ id }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [tutorData, setTutorData] = useState({});
  const [placeDetail, setPlaceDetail] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [addressValue, setAddressValue] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const { api } = useContext(notificationsContext);
  const { setSelectedUser } = useContext(selectedUserContext);
  const [antForm] = Form.useForm();
  const { id: tutorId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await getTutorRecord();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setting the location details if address is selected.
  useEffect(() => {
    if (placeDetail) {
      antForm.setFieldsValue({
        city: placeDetail?.city,
        state: placeDetail?.state,
        country: placeDetail?.country,
        zipcode: placeDetail?.zipCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeDetail]);

  // This function is used to edit the profile.
  const handleGeneralProfileEdit = async (values) => {
    try {
      const valuesWithPicture = {
        ...values,
        city: placeDetail?.city ? placeDetail.city : ' ',
        state: placeDetail?.state,
        zipcode: placeDetail?.zipCode ? placeDetail?.zipCode : ' ',
        country: placeDetail?.country,
        address_one_line: placeDetail?.formatted_address,
        profile_picture: profileImage,
      };
      let { data: updatedTutorData } = await updateTutorAPI(
        id,
        valuesWithPicture
      );

      setTutorData(updatedTutorData.data);
      toggleEditMode();
      api.success({
        message: 'Success',
        description: Message.tutorRecord,
        placement: 'top',
        duration: 4,
      });
    } catch (error) {
      api.error({
        message: 'Error',
        description: Message.errorMessage,
        placement: 'top',
        duration: 4,
      });
      throw new Error(error);
    }
  };

  const toggleEditMode = async () => {
    setIsEditMode(!isEditMode);
  };

  const handleCancel = () => {
    antForm.resetFields();
    setIsEditMode(false);
  };

  const getTutorRecord = async () => {
    try {
      setIsLoading(true);
      let { data: tutorRecord } = await getTutorById(id);
      // Converting the UTC Date to MMM D YYYY format.
      const formattedDateOfBirth = tutorRecord.date_of_birth
        ? moment(tutorRecord.date_of_birth).format('MMMM D YYYY')
        : null;
      // Creating a date object for Ant Design DatePicker component.
      const antDateOfBirth = formattedDateOfBirth
        ? dayjs(formattedDateOfBirth, 'MMMM D YYYY')
        : null;
      const newDataObj = {
        ...tutorRecord,
        date_of_birth: antDateOfBirth ? antDateOfBirth : null,
      };
      setAddressValue(newDataObj.address_one_line);
      setTutorData(newDataObj);
      // Setting the selected user in context to be used for other pages.
      setSelectedUser(newDataObj);
      localStorage.setItem('selectedUser', JSON.stringify(newDataObj));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const onChangeImage = ({ file }) => {
    setProfileImage(file);
  };

  return (
    <div className="w-full">
      <div className="flex justify-center pb-5">
        <div className="flex flex-col items-center justify-center gap-1">
          <Avatar size={100} src={tutorData.profile_picture} />
          <div className="text-2xl font-semibold leading-relaxed text-updatedBlue">
            {`${tutorData.first_name} ${tutorData.last_name}`}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center pb-5">
        {isEditMode ? (
          <Upload
            maxCount={1}
            className="upload-buttontext-white"
            beforeUpload={(file) => {
              const isImage =
                file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg';
              if (!isImage) {
                message.error(`${file.name} is not an image file`);
                return Upload.LIST_IGNORE;
              } else {
                return false;
              }
            }}
            onChange={onChangeImage}
          >
            <CustomButton
              label="Upload new picture"
              className="w-40 rounded-2xl border border-updatedBlue bg-updatedCreme text-updatedBlue hover:shadow-lg"
              icon={<UploadOutlined className="mr-1" />}
            />
          </Upload>
        ) : (
          <CustomButton
            label="Edit Profile"
            className="w-36 rounded-2xl border border-customBlack bg-updatedCreme text-updatedBlue hover:shadow-lg"
            onClick={toggleEditMode}
          />
        )}
        <Button
          className="border-2xl mt-5 w-36 rounded-2xl border-[#212B36] bg-[#212B36] text-white"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>
      </div>

      <div className="text-2xl font-semibold leading-relaxed text-updatedBlue">
        General Profile
      </div>
      {isLoading ? (
        <div className="flex h-20 items-center justify-center">Loading...</div>
      ) : (
        <>
          <Form
            layout="vertical"
            onFinish={handleGeneralProfileEdit}
            className="my-7"
            initialValues={tutorData}
            form={antForm}
          >
            <AntFormInput
              name="first_name"
              rules={FormRule.FIRSTNAME}
              label={<div className="font-medium">First name</div>}
              placeholder="First name"
              disabled={!isEditMode}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
            />
            <AntFormInput
              name="last_name"
              rules={FormRule.LASTNAME}
              label={<div className="font-medium">Last name</div>}
              placeholder="Last name"
              disabled={!isEditMode}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
            />
            <AntFormInput
              name="email"
              label={<div className="font-medium">Email</div>}
              placeholder="Email"
              disabled={true}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
            />
            <AntFormInput
              name="phone_number"
              type="phoneNumber"
              label={<div className="font-medium">Phone number</div>}
              placeholder="Phone number"
              disabled={!isEditMode}
              className={`h-8 w-full rounded-md border border-gray-200 ${
                !isEditMode &&
                'cursor-not-allowed rounded-none border-x-0 border-t-0 text-gray-400'
              } `}
            />
            <AntFormDatePicker
              name="date_of_birth"
              type="date"
              rules={FormRule.none}
              label={<div className="font-medium">Date of Birth</div>}
              placeholder="Date of Birth"
              disabled={!isEditMode}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
            />
            <AntFormSelect
              name="gender"
              rules={FormRule.none}
              label={<div className="font-medium">Gender</div>}
              placeholder="Select gender"
              options={genderOptions}
              disabled={!isEditMode}
              className={`w-full ${
                !isEditMode
                  ? 'rounded-none border-x-0 border-b border-t-0'
                  : 'border border-gray-200'
              } `}
            />
            <LocationInput
              name="address_one_line"
              value={addressValue}
              onChange={(val) => {
                setAddressValue(val);
              }}
              isEditMode={isEditMode}
              disabled={!isEditMode}
              setPlaceDetail={setPlaceDetail}
            />
            <AntFormInput
              name="city"
              rules={FormRule.none}
              label={<div className="font-medium">City</div>}
              placeholder="City"
              disabled={true}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
              value={placeDetail?.city || ''}
            />
            <AntFormInput
              name="state"
              rules={FormRule.none}
              label={<div className="font-medium">State</div>}
              placeholder="State"
              disabled={true}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
              value={placeDetail?.state || ''}
            />

            <AntFormInput
              name="zipcode"
              rules={FormRule.none}
              label={<div className="font-medium">Zip Code</div>}
              placeholder="Zip code"
              disabled={true}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
              value={placeDetail?.zipCode || ''}
            />

            <AntFormInput
              name="country"
              rules={FormRule.none}
              label={<div className="font-medium">Country</div>}
              placeholder="Country"
              disabled={true}
              className={`w-full ${
                !isEditMode && 'rounded-none border-x-0 border-t-0'
              } `}
              value={placeDetail?.country || ''}
            />

            <div
              className={`flex justify-end gap-4 ${!isEditMode && 'hidden'}`}
            >
              <CustomButton
                label="Cancel"
                className="w-36 rounded-2xl border border-red-500 bg-updatedCreme text-red-500"
                onClick={handleCancel}
              />
              <AntFormSubmit
                label="Save Settings"
                className="w-36 rounded-2xl border border-updatedBlue bg-updatedCreme text-updatedBlue"
              />
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default TutorGeneralProfileForm;
