// admin screens
import AdminLogin from 'pages/admin/login';
import AdminForgotPassword from 'pages/admin/forgot-password';
import AdminVerifyCode from 'pages/admin/verify-code';
import AdminCreatePassword from 'pages/admin/create-password';
import AdminDashboard from 'pages/admin/dashboard';
import AdminTutorGeneralProfile from 'pages/admin/tutor/general-profile';
import AdminTutorSubjects from 'pages/admin/subject-management/subjects';
import AdminTutorBio from 'pages/admin/tutor/bio';
import AdminTutorSubjectsExpertise from 'pages/admin/tutor/subjects-expertise';
import AdminTutorCertification from 'pages/admin/tutor/certification';
import AdminTutorLanguages from 'pages/admin/tutor/languages';
import AdminTutorWorkExperience from 'pages/admin/tutor/work-experience';
import AdminTutorEducation from 'pages/admin/tutor/education';
import AdminTutorDisabilityTeachingExp from 'pages/admin/tutor/disability-teaching-exp';
import AdminTutorStudentsStatistics from 'pages/admin/tutor/students-statistics';
import AdminTutorBankDetails from 'pages/admin/tutor/bank-details';
import AdminTutorPricing from 'pages/admin/tutor/pricing';
import AdminTutorGeneralAvailability from 'pages/admin/tutor/general-availability';
import StudentGeneralProfile from 'pages/admin/student/general-profile';
import StudentSchoolProfiling from 'pages/admin/student/school-profiling';
import StudentLanguages from 'pages/admin/student/languages';
import StudentDisabilityProfiling from 'pages/admin/student/disability-profiling';
import StudentPaymentConfiguration from 'pages/admin/student/payment-configuration';
import MyProfile from 'pages/admin/my-profile';
import ParentGeneralProfile from 'pages/admin/parent/parent-general-profile';
import ParentLanguageProficiency from 'pages/admin/parent/parent-language-proficiency';
import ParentPaymentConfiguration from 'pages/admin/parent/parent-payment-configuration';
import OtherSubjects from 'pages/admin/subject-management/other-subjects';
import ParentManagement from 'pages/admin/parent-management/parent-management';
import StudentManagement from 'pages/admin/student-management/student-management';
import { Route } from 'react-router-dom';
import { Protected, NoAuthRoute } from 'routes';
import { ADMIN } from 'constants/roles';
import Storefront from 'pages/tutor/storefront';
import AdminTutorCalendar from 'pages/admin/tutor/connect-calendar';

const adminRoutes = (
  <Route key="admin" path="admin">
    <Route
      path="login"
      element={
        <NoAuthRoute>
          <AdminLogin />
        </NoAuthRoute>
      }
    />
    <Route
      path="forgot-password"
      element={
        <NoAuthRoute>
          <AdminForgotPassword />
        </NoAuthRoute>
      }
    />
    <Route
      path="verify-code"
      element={
        <NoAuthRoute>
          <AdminVerifyCode />
        </NoAuthRoute>
      }
    />
    <Route path="create-password" element={<AdminCreatePassword />} />
    <Route
      path="dashboard"
      element={
        <Protected routeRole={ADMIN}>
          <AdminDashboard />
        </Protected>
      }
    />
    <Route
      path="parent-management"
      element={
        <Protected routeRole={ADMIN}>
          <ParentManagement />
        </Protected>
      }
    />
    <Route
      path="student-management"
      element={
        <Protected routeRole={ADMIN}>
          <StudentManagement />
        </Protected>
      }
    />
    <Route
      path="my-profile"
      element={
        <Protected routeRole={ADMIN}>
          <MyProfile />
        </Protected>
      }
    />
    <Route path="student">
      <Route
        path=":id"
        element={
          <Protected routeRole={ADMIN}>
            <StudentGeneralProfile />
          </Protected>
        }
      />
      <Route
        path=":id/school-profiling"
        element={
          <Protected routeRole={ADMIN}>
            <StudentSchoolProfiling />
          </Protected>
        }
      />
      <Route
        path=":id/languages"
        element={
          <Protected routeRole={ADMIN}>
            <StudentLanguages />
          </Protected>
        }
      />
      <Route
        path=":id/disability-profiling"
        element={
          <Protected routeRole={ADMIN}>
            <StudentDisabilityProfiling />
          </Protected>
        }
      />
      <Route
        path=":id/payment-configuration"
        element={
          <Protected routeRole={ADMIN}>
            <StudentPaymentConfiguration />
          </Protected>
        }
      />
    </Route>
    <Route path="parent-management">
      <Route
        path=":id"
        element={
          <Protected routeRole={ADMIN}>
            <ParentGeneralProfile />
          </Protected>
        }
      />
      <Route
        path=":id/language-proficiency"
        element={
          <Protected routeRole={ADMIN}>
            <ParentLanguageProficiency />
          </Protected>
        }
      />
      <Route
        path=":id/payment-configuration"
        element={
          <Protected routeRole={ADMIN}>
            <ParentPaymentConfiguration />
          </Protected>
        }
      />
    </Route>

    <Route path="tutor">
      <Route
        path=":id"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorGeneralProfile />
          </Protected>
        }
      />
      <Route
        path=":id/bio"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorBio />
          </Protected>
        }
      />
      <Route
        path=":id/subjects-expertise"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorSubjectsExpertise />
          </Protected>
        }
      />
      <Route
        path=":id/certification"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorCertification />
          </Protected>
        }
      />
      <Route
        path=":id/languages"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorLanguages />
          </Protected>
        }
      />
      <Route
        path=":id/work-experience"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorWorkExperience />
          </Protected>
        }
      />
      <Route
        path=":id/education"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorEducation />
          </Protected>
        }
      />
      <Route
        path=":id/disability-teaching-exp"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorDisabilityTeachingExp />
          </Protected>
        }
      />
      <Route
        path=":id/students-statistics"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorStudentsStatistics />
          </Protected>
        }
      />
      <Route
        path=":id/bank-details"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorBankDetails />
          </Protected>
        }
      />
      <Route
        path=":id/pricing"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorPricing />
          </Protected>
        }
      />
      <Route
        path=":id/calendar-integration"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorCalendar />
          </Protected>
        }
      />
      <Route
        path=":id/general-availability"
        element={
          <Protected routeRole={ADMIN}>
            <AdminTutorGeneralAvailability />
          </Protected>
        }
      />
      <Route
        path=":id/storefront"
        element={
          <Protected routeRole={ADMIN}>
            <Storefront />
          </Protected>
        }
      />
    </Route>
    <Route
      path="subject-management/subjects"
      element={
        <Protected routeRole={ADMIN}>
          <AdminTutorSubjects />
        </Protected>
      }
    />
    <Route
      path="subject-management/other-subjects"
      element={
        <Protected routeRole={ADMIN}>
          <OtherSubjects />
        </Protected>
      }
    />
  </Route>
);

export default adminRoutes;
