import React, { useContext, useEffect, useState } from 'react';
import './SearchBar.css';
import AntFormSelect from '../AntFormSelect/AntFormSelect';
import { FormRule } from 'enums/formRules';
import AntFormSubmit from '../AntFormSubmit/AntFormSubmit';
import { Divider, Form, Switch } from 'antd';
import AntFormInput from '../AntFormInput/AntFormInput';
import { educationOptions, reviewOptions } from 'enums';
import CustomButton from '../CustomButton/CustomButton';
import { getSubjects } from 'requests';
import { usCities } from 'enums/USCities';
import { userContext } from 'contexts/user';

const SearchBar = ({
  isDisability,
  setIsDisability,
  isConnectedFriend,
  setIsConnectedFriend,
  handleSearchSubmit,
  form,
}) => {
  const [subjects, setSubjects] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleReset = () => {
    setIsConnectedFriend(false);
    setIsDisability(false);
    form.resetFields();
    handleSearchSubmit();
  };

  const { user } = useContext(userContext);

  useEffect(() => {
    (async () => {
      await fetchSubjects();
    })();
  }, []);

  // This function fetches the subject list.
  const fetchSubjects = async () => {
    try {
      setIsLoading(true);
      const {
        data: { data: subjectResponse },
      } = await getSubjects();

      const updatedSubjects = subjectResponse.map(
        ({ id: key, title: value, ...rest }) => ({
          key,
          value,
        })
      );
      setSubjects(updatedSubjects);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  return (
    <div
      className="flex items-center bg-updatedMustard xs:rounded-xl md:rounded-full lg:h-12"
      id="search-bar"
    >
      <Form
        className="mt-6 w-full items-center justify-between px-2 xs:grid xs:grid-cols-1 md:flex"
        onFinish={handleSearchSubmit}
        form={form}
      >
        <div className="flex items-center justify-center">
          <div className=" items-center justify-center border-gray-200 xs:flex xs:h-10 xs:rounded-xl xs:border sm:h-auto md:border-none">
            <AntFormInput
              name="free_search"
              type="text"
              rules={FormRule.none}
              placeholder="Search..."
              className="rounded-lg border-none xs:pt-6 sm:pt-0"
              bordered={false}
            />
          </div>
          <Divider
            className="mb-5 h-7 bg-neutral-200 xs:hidden md:block"
            type="vertical"
          />
        </div>
        <div className="flex items-center justify-center ">
          <AntFormSelect
            name="min_review"
            rules={FormRule.none}
            placeholder="Minimum Rating"
            style={{ minWidth: '120px' }}
            showSearch={true}
            showArrow={true}
            bordered={false}
            options={reviewOptions}
          />
        </div>

        <div className="flex items-center justify-center ">
          <AntFormSelect
            name="city"
            rules={FormRule.none}
            placeholder="By City"
            style={{ minWidth: '120px' }}
            showSearch={true}
            showArrow={true}
            bordered={false}
            options={usCities.map((city, index) => ({
              label: city,
              value: city,
              key: index,
            }))}
          />
        </div>
        <div className="flex items-center justify-center xs:h-2 sm:h-0">
          <AntFormSelect
            name="degree_type"
            rules={FormRule.none}
            placeholder="By Education"
            style={{ width: '150px' }}
            options={educationOptions}
            showArrow={true}
            bordered={false}
          />
        </div>

        {isLoading ? (
          <div className="text-lightGray xs:mb-0 md:mb-5">Loading...</div>
        ) : (
          <div className="flex items-center justify-center xs:h-20 md:h-0">
            <AntFormSelect
              name="title"
              rules={FormRule.none}
              placeholder="By Subjects"
              style={{ width: '150px' }}
              showArrow={true}
              bordered={false}
              options={subjects}
            />
          </div>
        )}
        {/* Disability Teaching Ability Toogle */}
        <div className="flex items-center justify-center gap-3 xs:mb-6 xs:flex-wrap md:mb-6 lg:flex-nowrap">
          <div className="text-base text-lightGray">
            Disability Teaching Ability
          </div>
          <Switch
            checked={isDisability}
            className="bg-lightGray"
            onChange={() => {
              setIsDisability(!isDisability);
            }}
          />
        </div>
        <div className="flex items-center justify-center gap-3 xs:mb-6 xs:flex-wrap md:mb-6 lg:flex-nowrap">
          <div className="text-base text-lightGray">Connected with Friends</div>
          <Switch
            checked={isConnectedFriend}
            disabled={user.facebook_access_token === null}
            className="bg-lightGray"
            onChange={() => {
              setIsConnectedFriend(!isConnectedFriend);
            }}
          />
        </div>
        <Divider
          className="mb-5 h-7 bg-neutral-200 xs:hidden md:block"
          type="vertical"
        />
        <div className="gap-5  xs:grid xs:grid-cols-1 xs:flex-wrap xs:justify-items-center md:flex lg:flex-nowrap">
          <div>
            <CustomButton
              label="Reset"
              shape="round"
              className="bg-customBlack text-white hover:!bg-customBlack"
              buttonWidth="w-20"
              onClick={handleReset}
            />
          </div>
          <AntFormSubmit
            label="Search"
            type="primary"
            className="w-28 rounded-full bg-updatedBlue text-white hover:!bg-updatedBlue"
          />
        </div>
      </Form>
    </div>
  );
};

export default SearchBar;
