import { Form, Input } from 'antd';
import React from 'react';
import { PASSWORD } from '../../constants/formConstant';

const FormInput = ({
  name,
  type = 'text',
  rules = [
    {
      required: false,
    },
  ],
  placeholder,
  className,
  disabled = false,
  icon,
  label,
  onChange,
  prefix,
}) => (
  <>
    <Form.Item name={name} type={type} rules={rules} label={label}>
      {type !== PASSWORD ? (
        <Input
          placeholder={placeholder}
          className={className}
          disabled={disabled}
          addonAfter={icon}
          onChange={onChange}
          prefix={prefix}
        />
      ) : (
        <Input.Password placeholder={placeholder} className={className} />
      )}
    </Form.Item>
  </>
);

export default FormInput;
