import { Layout } from 'antd';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import Navbar from 'components/shared/Navbar/Navbar';
import { STUDENT } from 'constants/roles';
import { impersonateStudentNavbarItems } from 'enums';
import React from 'react';
import ImpersonateStudentDetailsLayout from './impersonate-student-details-layout';

const ImpersonatePaymentConfiguration = () => {
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={impersonateStudentNavbarItems} role={STUDENT} />
        <ImpersonateStudentDetailsLayout>
          <div className="flex w-full flex-col items-center">
            <div className="mt-10 w-full">
              <div className="font-semibold text-updatedBlue xs:text-xl md:text-2xl">
                Detailed Profile / Payment Configuration
              </div>
              <div className="mt-5 flex justify-center">
                <CustomButton
                  label="Make Payment"
                  className="h-12 w-52 items-center justify-center rounded-3xl bg-updatedBlue text-sm font-semibold text-white hover:!bg-updatedBlue"
                />
              </div>
            </div>
          </div>
        </ImpersonateStudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default ImpersonatePaymentConfiguration;
