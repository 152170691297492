import { useContext, useState } from 'react';
import { Modal as AntdModal, Checkbox, DatePicker } from 'antd';
import { notificationsContext } from 'contexts/notifications';
import { Formik } from 'formik';
import * as yup from 'yup';
import DeleteActionButton from 'components/DeleteActionButton';
import { ReactComponent as Loader } from 'assets/images/loader.svg';
import { createWorkExperiece } from 'requests';

const useAddWorkExperienceModal = (tutorId, callback) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { api } = useContext(notificationsContext);
  const validationSchema = yup.object().shape({
    title: yup.string().required(),
    department: yup.string().required(),
    employer_name: yup.string().required(),
    start_date: yup.date().required(),
    is_currently_working: yup.boolean().notRequired(),
    end_date: yup.date().when('is_currently_working', {
      is: true,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.required(),
    }),
    document: yup.mixed().notRequired(),
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = async (values) => {
    try {
      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('department', values.department);
      formData.append('employer_name', values.employer_name);
      formData.append('start_date', values.start_date);
      if (values.is_currently_working)
        formData.append('is_currently_working', values.is_currently_working);
      else formData.append('end_date', values.end_date);
      formData.append('document', values.document);

      await createWorkExperiece(tutorId, formData);
      api.success({
        message: 'Success',
        description: 'Work experience added successfully',
        placement: 'top',
        duration: 4,
      });
      if (callback) callback();
    } catch (err) {
      api.error({
        message: 'Error',
        description:
          'Something went wrong while adding the work experience. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    } finally {
      handleCancel();
    }
  };

  const AddWorkExperienceModal = ({ ...props }) => {
    return (
      <AntdModal
        width={'max-content'}
        {...props}
        onCancel={handleCancel}
        open={isModalOpen}
        footer={null}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            title: null,
            department: null,
            employer_name: null,
            start_date: null,
            end_date: null,
            is_currently_working: false,
            document: null,
          }}
          validateOnMount={true}
          onSubmit={handleOk}
        >
          {({
            isValid,
            handleSubmit,
            setFieldValue,
            values,
            isSubmitting,
            handleChange,
          }) => (
            <div className="p-7 xs:w-full md:w-[500px]">
              <div className="text-2xl font-semibold text-updatedBlue">
                Add Work Experience
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Title
                </div>
                <input
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 text-base font-medium text-gray-500 outline-none"
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Enter experience title"
                  onChange={handleChange}
                  value={values.title}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Department
                </div>
                <input
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 text-base font-medium text-gray-500 outline-none"
                  id="department"
                  name="department"
                  type="text"
                  placeholder="Enter department"
                  onChange={handleChange}
                  value={values.department}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Employer Name
                </div>
                <input
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 text-base font-medium text-gray-500 outline-none"
                  id="employer_name"
                  name="employer_name"
                  type="text"
                  placeholder="Enter employer name"
                  onChange={handleChange}
                  value={values.employer_name}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Start Date
                </div>
                <DatePicker
                  inputRender={({ onChange, ...props }) => (
                    <input
                      {...props}
                      id="start_date"
                      name="start_date"
                      className="!text-base !text-gray-500 !placeholder-[#9ca3af] !outline-none"
                    />
                  )}
                  value={values.start_date}
                  onChange={(date) => {
                    setFieldValue('start_date', date);
                  }}
                  disabledDate={(current) => {
                    return current && current.isAfter(new Date());
                  }}
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 "
                  placeholder="Select Date"
                  style={{
                    fontSize: '1.5rem',
                  }}
                  format={'DD-MM-YYYY'}
                />
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  End Date
                </div>
                <DatePicker
                  disabled={values.is_currently_working}
                  inputRender={({ onChange, ...props }) => (
                    <input
                      {...props}
                      id="end_date"
                      name="end_date"
                      className="!text-base !text-gray-500 !placeholder-[#9ca3af] !outline-none"
                    />
                  )}
                  value={values.end_date}
                  onChange={(date) => {
                    setFieldValue('end_date', date);
                  }}
                  className="mt-3 h-14 w-full rounded-lg border border-neutral-200 px-5 "
                  placeholder="Select Date"
                  style={{
                    fontSize: '1.5rem',
                  }}
                  format={'DD-MM-YYYY'}
                />
                <Checkbox
                  className="mt-2 !text-base !text-gray-500"
                  checked={values.is_currently_working}
                  onChange={() => {
                    setFieldValue('end_date', null);
                    setFieldValue(
                      'is_currently_working',
                      !values.is_currently_working
                    );
                  }}
                >
                  Currently working here
                </Checkbox>
              </div>
              <div className="mt-8 w-full">
                <div className="text-base font-medium text-updatedBlue">
                  Upload Work Experience Document (optional)
                </div>
                {!values.document ? (
                  <div className="relative mt-3 h-14 overflow-hidden rounded-lg">
                    <input
                      accept="image/*,.pdf"
                      type="file"
                      className="h-full w-full"
                      onChange={(event) => {
                        setFieldValue('document', event.target.files[0]);
                      }}
                    />
                    <div className="flex h-full">
                      <div className="flex h-full w-32 items-center justify-center rounded-bl-lg rounded-tl-lg border border-neutral-200 bg-slate-100">
                        Choose file
                      </div>

                      <div className="flex h-full flex-1 items-center rounded-br-lg rounded-tr-lg border border-neutral-200 px-5">
                        No file Choosen
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mt-3 flex items-center justify-between gap-5">
                    <div className="flex-1 overflow-hidden text-base font-medium text-gray-500">
                      {values.document?.name}
                    </div>
                    <div>
                      <DeleteActionButton
                        onClick={() => {
                          setFieldValue('document', null);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div>
                <button
                  className={`mt-12 h-12 w-full items-center justify-center rounded-3xl ${
                    isValid ? 'bg-updatedBlue' : 'bg-gray-500'
                  } text-white`}
                  onClick={handleSubmit}
                  disabled={!isValid || isSubmitting}
                >
                  {!isSubmitting ? (
                    'Add Work Experience'
                  ) : (
                    <div className="flex w-full items-center justify-center">
                      <Loader color="white" className="spinner h-8 w-8" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
        </Formik>
      </AntdModal>
    );
  };

  return { AddWorkExperienceModal, showModal };
};

export default useAddWorkExperienceModal;
