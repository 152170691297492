import React from 'react';

const StoreSectionLayout = ({className, languageName}) => {
  return (
    <p className={className}>
      {languageName}
    </p>
  );
};

export default StoreSectionLayout;
