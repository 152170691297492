/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import { getTutorById, updateTutorPricing } from 'requests/tutors';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationsContext } from 'contexts/notifications';
import { ReactComponent as DollarSign } from 'assets/images/dollar.svg';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';

const Pricing = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const { id: tutorId } = useParams();
  const { api } = useContext(notificationsContext);
  const [price, setPrice] = useState();

  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
      setPrice(data?.tutor_pricing);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdatePricing = async () => {
    await updateTutorPricing(tutorId, price);
    try {
      api.success({
        message: 'Success',
        description: 'Pricing updated successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TutorDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <img
          className="h-[120px] w-[120px] rounded-full"
          src={!!image ? image : profile}
          alt="image"
        />
        <div className="mt-5 text-2xl font-semibold text-updatedBlue">
          {name}
        </div>
        <Button
          className="mt-4"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>

        <div className="mt-10 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Pricing
          </div>
          <div className="mt-14 text-xl text-updatedBlue">
            Pricing Per Session
          </div>
          <div className="relative mt-5 flex">
            <div className="flex h-14 w-20 items-center justify-center rounded-l-md border-2 border-r-0 border-neutral-200 bg-slate-50 text-gray-500">
              USD
            </div>
            <input
              className="flex h-14 flex-1 rounded-r-md border-2 border-neutral-200 bg-slate-50 px-5 text-gray-500 outline-none"
              type="text"
              value={price}
              onChange={(e) => {
                let price = e.target.value.replace(/[^0-9.]/g, '');

                price = price.replace(/(\..*)\./g, '$1');
                price = price.replace(/(\.[\d]{2})./g, '$1');
                price = price.replace(/^0+(?=\d)/, '');

                (parseFloat(price) <= 99999999.99 || price === '') &&
                  setPrice(price);
              }}
            />
            <DollarSign className="absolute right-5 top-4 h-6 w-6" />
          </div>

          <div className="mt-40 flex justify-end">
            <Button onClick={handleUpdatePricing}>Save Settings</Button>
          </div>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default Pricing;
