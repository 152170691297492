import { userContext } from 'contexts/user';
import SessionCard from '../SessionCard/SessionCard';
import React, { useContext, useEffect, useState } from 'react';
import { getCalendarEvents } from 'utils/calendarApi';
import { notificationsContext } from 'contexts/notifications';
import { bulkInsertAppointments } from 'requests/appointments';
import { STUDENT } from 'constants/roles';
import { getStudentById } from 'requests/students';

const SessionList = ({ role, userId }) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const { getGoogleCalendarToken } = useContext(userContext);
  const [calendarToken] = useState(getGoogleCalendarToken() || null);
  const { api } = useContext(notificationsContext);

  const getSessions = async () => {
    if (calendarToken) {
      try {
        setLoading(true);
        let {
          data: { nextPageToken, items = [] },
        } = await getCalendarEvents({
          timeMin: new Date().toISOString(),
        });

        while (nextPageToken) {
          const response = await getCalendarEvents({
            pageToken: nextPageToken,
            timeMin: new Date().toISOString(),
          });
          nextPageToken = response?.data?.nextPageToken;
          items = [...items, ...response.data.items];
        }

        let skooldEvents = items.filter((item) => {
          return (
            item?.status === 'confirmed' &&
            new Date(item?.start?.dateTime) > new Date() &&
            item?.summary?.toLowerCase().includes('ssskoold')
          );
        });
        if (role === STUDENT) {
          const {
            data: { student_uuid },
          } = await getStudentById(userId);
          skooldEvents = skooldEvents.filter((item) => {
            // eslint-disable-next-line no-unused-vars
            let [_bookedBy, _agenda, studentId] =
              item.description.split('<br>');
            studentId = studentId.split('\n')[1].trim();
            return studentId === student_uuid;
          });
        }
        const { data: sessions } = await bulkInsertAppointments(
          userId,
          skooldEvents
        );
        setEvents(sessions);
      } catch (err) {
        api.error({
          message: 'Error',
          description:
            'Something went wrong in getting schedulled sessions. Please try again after some time.',
          placement: 'top',
          duration: 4,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getSessions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-screen overflow-y-auto">
      {loading ? null : !!events.length ? (
        events.map((event) => <SessionCard role={role} event={event} />)
      ) : (
        <div className="mt-20 text-center text-3xl font-extrabold text-gray-300">
          No Current Sessions
        </div>
      )}
    </div>
  );
};

export default SessionList;
