import React, { useContext, useEffect, useState } from 'react';
import { getTutorById, updateTutorPricing } from 'requests/tutors';
import { notificationsContext } from 'contexts/notifications';
import { ReactComponent as DollarSign } from 'assets/images/dollar.svg';
import Layout from 'components/tutor/ProfileLayout';
import { userContext } from 'contexts/user';
import DefaultButton from 'components/DefaultButton';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const Pricing = () => {
  const {
    user: { id: tutorId, account_status },
  } = useContext(userContext);
  const { api } = useContext(notificationsContext);
  const [price, setPrice] = useState();
  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setPrice(data?.tutor_pricing);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdatePricing = async () => {
    await updateTutorPricing(tutorId, price);
    try {
      api.success({
        message: 'Success',
        description: 'Pricing updated successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <div className="flex min-h-[calc(100vh_-_273px)] w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 font-semibold text-updatedBlue xs:text-xl md:text-2xl">
            Detailed Profile / Pricing
          </div>
          <div>
            {account_status === 'approved' && (
              <button
                type="button"
                className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                onClick={() => {
                  const navigateString = viewStoreFront(tutorId, TUTOR);
                  navigate(navigateString);
                }}
              >
                View Storefront
              </button>
            )}
          </div>
        </div>

        <div className="mt-5 flex w-full flex-1 flex-col">
          <div className="mt-14 text-updatedBlue xs:text-lg md:text-xl">
            Pricing Per Session
          </div>
          <div className="relative mt-5 flex w-full flex-1">
            <div className="flex h-14 items-center justify-start rounded-l-md border-2 border-r-0 border-neutral-200 bg-slate-50 text-gray-500">
              USD
            </div>
            <input
              className="flex h-14 w-full flex-1 rounded-r-md border-2 border-neutral-200 bg-slate-50 px-5 text-gray-500 outline-none"
              type="text"
              value={price}
              onChange={(e) => {
                let price = e.target.value.replace(/[^0-9.]/g, '');

                price = price.replace(/(\..*)\./g, '$1');
                price = price.replace(/(\.[\d]{2})./g, '$1');
                price = price.replace(/^0+(?=\d)/, '');

                (parseFloat(price) <= 99999999.99 || price === '') &&
                  setPrice(price);
              }}
            />
            <DollarSign className="absolute right-5 top-4 h-6 w-6" />
          </div>

          <div className="mt-40 flex justify-end">
            <DefaultButton onClick={handleUpdatePricing} disabled={!price}>
              Save Settings
            </DefaultButton>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Pricing;
