import axiosInstance from './config';

export const getSubjects = () => {
  return new Promise((resolve, reject) => {
    axiosInstance.get('/subjects').then(resolve).catch(reject);
  });
};

export const addTutorSubject = (id, formData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/tutors/${id}/subjectExpertises`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const getTutorSubjects = (id) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/tutors/${id}/subjectExpertises`)
      .then(resolve)
      .catch(reject);
  });
};

export const updateTutorSubject = (tutorId, subjectId, formData) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .put(`/tutors/${tutorId}/subjectExpertises/${subjectId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const deleteTutorSubject = (tutorId, subjectId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .delete(`/tutors/${tutorId}/subjectExpertises/${subjectId}`)
      .then(resolve)
      .catch(reject);
  });
};
