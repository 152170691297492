import React, { useContext, useEffect, useState } from 'react';
import * as Yup from 'yup';
import arrow from 'assets/images/admin/back.svg';
import { tuturDetailsSideBarOptions } from 'constants/side-bar-options';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';
import { getTutorById, updateTutorStatus } from 'requests/tutors';
import getInitialValuesTutorProfileSidebar from 'utils/tutorSideBarInitialValues';
import { Message } from 'constants/message';
import { notificationsContext } from 'contexts/notifications';
import StatusField from './status-field';

const TutorGeneralProfileSidebar = () => {
  const navigate = useNavigate();
  const { id: tutorId } = useParams();
  const pathName = window.location.pathname;
  const [value, setValues] = useState('');
  const { api } = useContext(notificationsContext);

  useEffect(() => {
    (async () => {
      await getTutorStatus();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTutorStatus = async () => {
    try {
      let { data: recordTutor } = await getTutorById(tutorId);
      setValues(recordTutor);
    } catch (error) {
      throw new Error(error[0].message);
    }
  };

  const [expanded, setExpanded] = useState(() => {
    if (
      generatePath(tuturDetailsSideBarOptions[0].path, { id: tutorId }) ===
      pathName
    )
      return '';
    else return 'detail-profiling';
  });

  const tutorSidebarValues = getInitialValuesTutorProfileSidebar(value);

  const validationSchema = Yup.object({
    account_status: Yup.string().required('Required'),
  });

  const onSubmit = (values) => {};

  const handleDropdownChange = async (e) => {
    try {
      const account_status = e.target.value;
      await updateTutorStatus({ tutorId, account_status });
      api.success({
        message: 'Success',
        description: Message[account_status],
        placement: 'top',
        duration: 4,
      });
      await getTutorStatus();
    } catch (err) {
      api.error({
        message: 'Error',
        description: Message.errorStatus,
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <div className="sticky top-6 mx-6 my-[24px] flex h-[calc(100vh_-_48px)] w-[450px] flex-col items-center bg-updatedCreme">
      <button
        className="my-5 flex h-12 w-60 items-center gap-2 rounded-[34px] border border-updatedBlue pl-20 hover:border-blue-900"
        onClick={() => {
          navigate('/admin/dashboard');
        }}
      >
        <div className="relative h-4 w-4">
          <img className="mr-16 h-4 w-4 fill-current" src={arrow} alt="back" />
        </div>
        <button
          type="button"
          className="text-center text-base font-medium text-updatedBlue"
        >
          Back
        </button>
      </button>

      <div className="mt-5 flex h-[540px] w-full flex-col">
        {tuturDetailsSideBarOptions.map(({ label, path, children, name }) => {
          const isCurrentPath =
            (path && generatePath(path, { id: tutorId }) === pathName) ||
            (children &&
              children?.find(
                ({ path }) => generatePath(path, { id: tutorId }) === pathName
              ));

          return (
            <div
              className={`overflow-hidden transition-all duration-300 ${
                expanded === name ? 'h-[500px]' : 'h-10'
              }`}
            >
              <div
                onClick={() => {
                  if (path && !isCurrentPath)
                    navigate(path.replace(':id', tutorId));
                  if (children && children.length > 0)
                    if (expanded === name) setExpanded('');
                    else setExpanded(name);
                }}
                className={cn(
                  'flex w-full cursor-pointer items-center justify-between px-6 py-[9px]',
                  {
                    'border-r-4 border-updatedBlue bg-violet-50 text-updatedBlue hover:text-gray-700':
                      isCurrentPath,
                    'bg-updatedCreme text-gray-500 hover:text-gray-700':
                      !isCurrentPath,
                  }
                )}
              >
                <div>{label}</div>
                {children && children.length > 0 && (
                  <div
                    className={`transition-all duration-300 ${
                      expanded === name ? 'rotate-180' : 'rotate-0'
                    }`}
                  >
                    <DropdownArrow
                      className={cn('h-5 w-5', {
                        'text-updatedBlue': isCurrentPath,
                        'text-gray-500': !isCurrentPath,
                      })}
                    />
                  </div>
                )}
              </div>
              {children &&
                children.map(({ label, path }) => {
                  const isCurrentChildPath =
                    path && generatePath(path, { id: tutorId }) === pathName;

                  return (
                    <div
                      onClick={() => {
                        if (
                          path &&
                          generatePath(path, { id: tutorId }) !== pathName
                        )
                          navigate(path.replace(':id', tutorId));
                      }}
                      className={cn(
                        'ml-5 w-full cursor-pointer items-center justify-start py-[9px] pl-6 hover:text-gray-700',
                        {
                          ' text-updatedBlue': isCurrentChildPath,
                          ' text-gray-500': !isCurrentChildPath,
                        }
                      )}
                    >
                      {label}
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
      <div className="mx-10 my-2 h-[0px] w-[calc(100%_-_80px)] border border-neutral-200" />

      {/*change status dropdown field */}
      <StatusField
        tutorSidebarValues={tutorSidebarValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        handleDropdownChange={handleDropdownChange}
      />

      <div className="mx-10 my-2 h-[0px] w-[calc(100%_-_80px)] border border-neutral-200" />
      <div className="mt-5 w-full flex-1 px-6">
        {/* <div className="pb-2 text-base font-medium leading-normal text-rose-500">
          Delete Profile
        </div> */}
      </div>
    </div>
  );
};

export default TutorGeneralProfileSidebar;
