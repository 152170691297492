import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import SearchBar from 'components/SearchBar/SearchBar';
import Layout from 'components/admin/layout';
import TableActionButtons from 'components/TableActionButtons/TableActionButtons';
import AntdTags from 'components/Tags/AntdTags';
import { STATUSES } from 'constants/strings';
import { getRowNumber } from 'utils/helperFunctions';
import { Table } from 'antd';
import { getStudents } from 'requests/students';
import { STUDENT } from 'constants/roles';
import { useNavigate } from 'react-router-dom';
import useInviteModal from 'components/Modal/InviteModal';
const { INVITED, SUSPEND, ACTIVE, DEACTIVATE, ADDED_BY_PARENT } = STATUSES;

const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const StudentManagement = () => {
  const [studentsData, setStudentsData] = useState([]);
  const [isPageChanged, setIsPageChanged] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  console.log(studentsData);
  const [refreshTable, setRefreshTable] = useState(false);
  const { InviteModal, showModal } = useInviteModal(STUDENT, setRefreshTable);
  const navigate = useNavigate();

  const columns = [
    {
      title: 'Sr. No.',
      render: (text, record, index) => (
        <TableText>{getRowNumber(index, tableParams)}</TableText>
      ),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Phone No.',
      dataIndex: 'phone_number',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: "Parent's name",
      dataIndex: 'parent_name',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Status',
      dataIndex: 'account_status',
      render: (status) => {
        if (status === INVITED) return <AntdTags status={INVITED} />;
        if (status === SUSPEND) return <AntdTags status={SUSPEND} />;
        if (status === ACTIVE) return <AntdTags status={ACTIVE} />;
        if (status === DEACTIVATE) return <AntdTags status={DEACTIVATE} />;
        if (status === ADDED_BY_PARENT)
          return <AntdTags status={ADDED_BY_PARENT} />;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (_, record) => {
        return <TableActionButtons record={record} onView={onView} />;
      },
    },
  ];

  // Fetching the list of students on page mount.
  useEffect(() => {
    (async () => {
      await listStudentsData(tableParams);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetching the list of students on page change.
  useEffect(() => {
    (async () => {
      await listStudentsData(tableParams);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageChanged, refreshTable]);

  // Function to fetch the list of students.
  const listStudentsData = async (tableParams) => {
    try {
      const {
        data: { count: studentsCount, users: studentsList },
      } = await getStudents(
        tableParams.pagination.pageSize,
        tableParams.pagination.current
      );
      setStudentsData(studentsList);
      setTableParams({
        pagination: {
          ...tableParams.pagination,
          total: studentsCount,
        },
      });
    } catch (error) {
      throw new Error(error.response.data.error);
    }
  };

  const handleTableChange = (pagination) => {
    setTableParams((prevParams) => ({
      pagination: {
        ...prevParams.pagination,
        current: pagination.current,
      },
    }));
    setIsPageChanged((prev) => !prev);
  };

  const onView = async (record) => {
    navigate(`/admin/student/${record.id}`);
  };

  return (
    <Layout>
      {InviteModal}
      <div className="p-5">
        <div className="flex w-full justify-end">
          {/* <SearchBar /> */}
          <Button onClick={showModal}>Add student</Button>
        </div>

        <div id="ParentManagementTable" className="mt-5 flex overflow-scroll">
          <Table
            columns={columns}
            dataSource={studentsData}
            pagination={{
              position: ['bottomCenter'],
              ...tableParams.pagination,
            }}
            className="w-full"
            onChange={handleTableChange}
          />
        </div>
      </div>
    </Layout>
  );
};

export default StudentManagement;
