import React, { useContext, useEffect, useState } from 'react';
import AntFormDatePicker from 'components/shared/AntFormDatePicker/AntFormDatePicker';
import AntFormInput from 'components/shared/AntFormInput/AntFormInput';
import AntFormSelect from 'components/shared/AntFormSelect/AntFormSelect';
import AntFormSubmit from 'components/shared/AntFormSubmit/AntFormSubmit';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import { genderOptions } from 'enums';
import moment from 'moment';
import dayjs from 'dayjs';
import { FormRule } from 'enums/formRules';
import { Avatar, ConfigProvider, Form, Spin, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { notificationsContext } from 'contexts/notifications';
import { Message } from 'constants/message';
import { getParentById, updateParent } from 'requests';
import { selectedUserContext } from 'contexts/selected-user';
import LocationInput from 'components/shared/LocationInput/LocationInput';

const ParentGeneralProfileForm = ({ id }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [parentData, setParentData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [addressValue, setAddressValue] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [placeDetail, setPlaceDetail] = useState();
  const [antForm] = Form.useForm();

  const { api } = useContext(notificationsContext);
  const { setSelectedUser } = useContext(selectedUserContext);

  useEffect(() => {
    (async () => {
      await getParentRecord();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setting the location details if address is selected.
  useEffect(() => {
    if (placeDetail) {
      antForm.setFieldsValue({
        city: placeDetail?.city,
        state: placeDetail?.state,
        country: placeDetail?.country,
        zipcode: placeDetail?.zipCode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeDetail]);

  // This function is used to edit the profile.
  const handleGeneralProfileEdit = async (values) => {
    try {
      const valuesWithPicture = {
        ...values,
        city: placeDetail?.city ? placeDetail.city : ' ',
        state: placeDetail?.state,
        zipcode: placeDetail?.zipCode ? placeDetail?.zipCode : ' ',
        country: placeDetail?.country,
        address_one_line: placeDetail?.formatted_address,
        profile_picture: profileImage,
      };

      let { data: updatedParentData } = await updateParent(
        id,
        valuesWithPicture
      );
      setParentData(updatedParentData.data);
      toggleEditMode();
      api.success({
        message: 'Success',
        description: Message.studentRecord,
        placement: 'top',
        duration: 4,
      });
    } catch (error) {
      api.error({
        message: 'Error',
        description: Message.errorMessage,
        placement: 'top',
        duration: 4,
      });
      throw new Error(error);
    }
  };

  const toggleEditMode = async () => {
    setIsEditMode(!isEditMode);
    await getParentRecord();
  };

  // This function is used to get student record.
  const getParentRecord = async () => {
    try {
      let { data: parentRecord } = await getParentById(id);
      const newDataObj = {
        ...parentRecord,
        date_of_birth: parentRecord.date_of_birth
          ? dayjs(
              moment(parentData.date_of_birth).format('MMMM D YYYY'),
              'MMMM D YYYY'
            )
          : '',
      };
      setAddressValue(newDataObj.address_one_line);
      setParentData(newDataObj);

      // Setting the selected user in context to be used for other pages.
      setSelectedUser(newDataObj);
      localStorage.setItem('selectedUser', JSON.stringify(newDataObj));

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      throw new Error(error);
    }
  };

  const onChangeImage = ({ file }) => {
    setProfileImage(file);
  };

  return (
    <div className="w-full">
      <div className="flex justify-center pb-5">
        {isLoading ? (
          <Spin size="large" />
        ) : (
          <div className="flex flex-col items-center justify-center gap-1">
            <Avatar size={100} src={parentData.profile_picture} />
            <div
              style={{ fontFamily: 'Now' }}
              className="font-semibold leading-relaxed text-updatedBlue xs:text-xl md:text-2xl"
            >
              {`${parentData.first_name} ${parentData.last_name}`}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center pb-5">
        {isEditMode ? (
          <Upload
            maxCount={1}
            className="upload-buttontext-white"
            beforeUpload={(file) => {
              const isImage =
                file.type === 'image/png' ||
                file.type === 'image/jpg' ||
                file.type === 'image/jpeg';
              if (!isImage) {
                message.error(`${file.name} is not an image file`);
                return Upload.LIST_IGNORE;
              } else {
                return false;
              }
            }}
            onChange={onChangeImage}
          >
            <CustomButton
              label="Upload new picture"
              className="w-40 rounded-2xl border border-updatedBlue bg-updatedCreme text-updatedBlue hover:!bg-updatedBlue hover:shadow-lg"
              icon={<UploadOutlined className="mr-1" />}
            />
          </Upload>
        ) : (
          <CustomButton
            label="Edit Profile"
            className="w-36 rounded-2xl border border-customBlack bg-updatedCreme text-updatedBlue hover:!bg-updatedBlue hover:shadow-lg"
            onClick={toggleEditMode}
          />
        )}
      </div>
      <div
        style={{ fontFamily: 'Bogart' }}
        className="font-semibold leading-relaxed text-updatedBlue xs:text-xl md:text-2xl"
      >
        General Profile
      </div>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Form
          layout="vertical"
          onFinish={handleGeneralProfileEdit}
          className="my-7"
          initialValues={parentData}
          form={antForm}
        >
          <AntFormInput
            name="first_name"
            rules={FormRule.FIRSTNAME}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                First name
              </div>
            }
            placeholder="First name"
            disabled={!isEditMode}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
          />
          <AntFormInput
            name="last_name"
            rules={FormRule.LASTNAME}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Last name
              </div>
            }
            placeholder="Last name"
            disabled={!isEditMode}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
          />
          <AntFormInput
            name="email"
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Email
              </div>
            }
            placeholder="Email"
            disabled={true}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
          />
          <AntFormInput
            name="phone_number"
            type="phoneNumber"
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Phone number
              </div>
            }
            placeholder="Phone number"
            disabled={!isEditMode}
            className={`h-8 w-full rounded-md border border-gray-200 ${
              !isEditMode &&
              'cursor-not-allowed rounded-none border-x-0 border-t-0 text-gray-400'
            } `}
          />
          <AntFormDatePicker
            name="date_of_birth"
            type="date"
            rules={FormRule.none}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Date of Birth
              </div>
            }
            placeholder="Date of Birth"
            disabled={!isEditMode}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
          />
          <AntFormSelect
            name="gender"
            rules={FormRule.none}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Gender
              </div>
            }
            placeholder="Select gender"
            options={genderOptions}
            disabled={!isEditMode}
            className={`w-full ${
              !isEditMode
                ? 'rounded-none border-x-0 border-b border-t-0'
                : 'border border-gray-200'
            } `}
          />
          <LocationInput
            name="address_one_line"
            value={addressValue}
            onChange={(val) => {
              setAddressValue(val);
            }}
            isEditMode={isEditMode}
            disabled={!isEditMode}
            setPlaceDetail={setPlaceDetail}
          />
          <AntFormInput
            name="city"
            rules={FormRule.none}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                City
              </div>
            }
            placeholder="City"
            disabled={true}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
            value={placeDetail?.city || ''}
          />
          <AntFormInput
            name="state"
            rules={FormRule.none}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                State
              </div>
            }
            placeholder="State"
            disabled={true}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
            value={placeDetail?.state || ''}
          />
          <AntFormInput
            name="zipcode"
            rules={FormRule.none}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Zip Code
              </div>
            }
            placeholder="Zip code"
            disabled={true}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
            value={placeDetail?.zipCode || ''}
          />
          <AntFormInput
            name="country"
            rules={FormRule.none}
            label={
              <div style={{ fontFamily: 'Now' }} className="font-medium">
                Country
              </div>
            }
            placeholder="Country"
            disabled={true}
            className={`w-full ${
              !isEditMode && 'rounded-none border-x-0 border-t-0'
            } `}
            value={placeDetail?.country || ''}
          />
          <div className={`flex justify-end gap-4 ${!isEditMode && 'hidden'}`}>
            <CustomButton
              label="Cancel"
              className="w-36 rounded-2xl border border-updatedPink bg-updatedCreme text-updatedPink hover:!bg-updatedPink"
              onClick={toggleEditMode}
            />
            <AntFormSubmit
              label="Save Settings"
              className="w-36 rounded-2xl border border-updatedBlue bg-updatedCreme text-updatedBlue hover:!bg-updatedBlue"
            />
          </div>
        </Form>
      )}
    </div>
  );
};

export default ParentGeneralProfileForm;
