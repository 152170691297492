import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import VerifyCodeComponent from 'components/verify-code';
import { verifyResetPasswordToken } from 'requests';
import { PARENT } from 'constants/roles';

const VerifyCode = () => {
  const [params] = useSearchParams();
  const email = JSON.parse(atob(params.get('query'))).email;
  const navigate = useNavigate();

  const handleVerifyCode = async ({ otp }) => {
    await verifyResetPasswordToken(email, otp);
    const query = btoa(JSON.stringify({ otp, email }));
    navigate(`/parent/create-password?query=${query}`);
  };

  return (
    <VerifyCodeComponent
      email={email}
      handleVerifyCode={handleVerifyCode}
      className="bg-updatedGreenish md:bg-contain"
      role={PARENT}
    />
  );
};

export default VerifyCode;
