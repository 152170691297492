import React from 'react';
import { Formik } from 'formik';
import accountStatusOption from '../../utils/dropDownOptions';

const StatusField = ({
  tutorSidebarValues,
  validationSchema,
  onSubmit,
  handleDropdownChange,
}) => {
  return (
    <div className="mt-5 flex w-full flex-[2] flex-col gap-2 px-6">
      <div className="w-full text-updatedBlue">Change Status</div>
      <Formik
        enableReinitialize={true}
        initialValues={tutorSidebarValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        validateOnChange={false}
      >
        {({ errors, handleBlur, touched, values }) => (
          <div>
            <select
              className="w-full items-center justify-between rounded-lg border border-neutral-200 bg-updatedCreme px-4 py-[15px]"
              name="account_status"
              id="account_status"
              value={values.account_status}
              onChange={async (e) => {
                await handleDropdownChange(e);
              }}
              onBlur={handleBlur}
            >
              <option selected>Select Option</option>
              {accountStatusOption.map((el) => {
                return (
                  <option key={el.value} value={el.value}>
                    {el.label}
                  </option>
                );
              })}
            </select>
            <div className="mt-1">
              {!!errors.account_status && !!touched.account_status && (
                <div className="w-full text-red-500">
                  {errors.account_status}
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default StatusField;
