import React from 'react';
import { Link } from 'react-router-dom';
import AntFormInput from '../AntFormInput/AntFormInput';
import AntFormSubmit from '../AntFormSubmit/AntFormSubmit';
import cn from 'classnames';
import { Form } from 'antd';
import { FormRule } from 'enums/formRules';
import { STUDENT, TUTOR } from 'constants/roles';
import privacyPolicy from '../../../assets/privacy-policy.pdf';
import termsOfService from '../../../assets/terms_of_service.pdf';
import tutor_sign_up_image from 'assets/images/tutor/tutor_sign_up.png';
import student_sign_up_image from 'assets/images/student/student-cover.png';

const Signup = ({
  handleSignup,
  coverImage,
  className,
  role,
  isLoading,
  errorMessage,
}) => {
  return (
    <div
      className={cn(
        'flex h-screen w-screen justify-center pt-10 xs:px-2 sm:px-0 md:items-center md:pt-0',
        className
      )}
    >
      <div className="flex aspect-[1] h-[600px] overflow-hidden rounded-3xl bg-updatedCreme shadow-custom-1 lg:aspect-[1.7]">
        <div className="flex flex-1 flex-col items-center p-6 sm:p-14 md:p-[60px]">
          <div className="h-8 w-full">
            <div className="mb-10 text-3xl font-bold leading-9 text-updatedBlue">
              {role === TUTOR
                ? "Tutor's"
                : role === STUDENT
                  ? "Student's"
                  : "Parent's"}{' '}
              Sign Up
            </div>
            <Form layout="vertical" onFinish={handleSignup}>
              <div className="grid grid-cols-2 gap-x-6 gap-y-0">
                <AntFormInput
                  label="First name"
                  rules={FormRule.FIRSTNAME}
                  name="first_name"
                  size="large"
                  placeholder="Enter first name"
                  className="w-full border border-gray-300"
                />
                <AntFormInput
                  label="Last name"
                  rules={FormRule.LASTNAME}
                  name="last_name"
                  size="large"
                  placeholder="Enter last name"
                  className="w-full border border-gray-300"
                />
                <AntFormInput
                  label="Email"
                  rules={FormRule.EMAIL}
                  name="email"
                  size="large"
                  placeholder="Enter email"
                  className="w-full border border-gray-300"
                />
                <AntFormInput
                  name="phone_number"
                  type="phoneNumber"
                  label="Phone number"
                  placeholder="Enter phone number"
                  className="h-10 w-full rounded-md border border-gray-300 text-gray-400"
                />
                <AntFormInput
                  type="password"
                  label="Password"
                  rules={FormRule.PASSWORD}
                  name="password"
                  size="large"
                  placeholder="Enter password"
                  className="w-full border border-gray-300"
                />
                <AntFormInput
                  type="password"
                  label="Confirm Password"
                  rules={FormRule.RE_ENTER_PASSWORD}
                  name="confirm_password"
                  size="large"
                  placeholder="Re-enter password"
                  className="w-full border border-gray-300"
                />
                <AntFormSubmit
                  loading={isLoading}
                  label="Sign Up"
                  disabled={isLoading}
                  className="flex w-full items-center justify-center rounded-2xl bg-updatedBlue px-5 py-4 text-white"
                />
              </div>
              <div className="text-red-500">{errorMessage}</div>
            </Form>
            <div className="mt-2 flex gap-1">
              <div className="text-gray-400">Already have an account?</div>
              <Link
                className="text-updatedBlue underline"
                to={`/${role}/login`}
              >
                Sign In
              </Link>
            </div>
            <div className="mt-2 flex gap-1">
              <div className="text-gray-400">Read our</div>
              <a
                className="text-updatedBlue underline"
                href="https://www.skooldtutors.com/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy & Policy
              </a>
              <div className="text-gray-400">&</div>
              <a
                className="text-updatedBlue underline"
                href="https://www.skooldtutors.com/terms-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </div>
          </div>
        </div>
        <div className="hidden p-5 lg:block">
          <img
            className="h-full w-full object-cover"
            src={role === STUDENT ? student_sign_up_image : tutor_sign_up_image}
            alt="signup_image"
          />
        </div>
      </div>
    </div>
  );
};

export default Signup;
