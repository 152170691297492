import React from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from 'requests';
import CreatePasswordComponent from 'components/create-password';

const CreatePassword = () => {
  const navigate = useNavigate();

  const handleResetPassword = async (email, password, otp) => {
    await resetPassword(email, password, otp);
    navigate('/admin/login');
  };

  return (
    <CreatePasswordComponent
      resetPassword={handleResetPassword}
      className="bg-updatedCreme"
    />
  );
};

export default CreatePassword;
