import React from 'react';

const TableActionButtons = ({ onView, record }) => {
  return (
    <button
      className="rounded-3xl border border-[#3056D3] bg-updatedCreme px-5 py-1 text-[12px] text-[#3056D3] hover:shadow-lg"
      type="button"
      onClick={() => onView(record)}
    >
      View / Edit
    </button>
  );
};

export default TableActionButtons;
