import React from 'react';
import TutorProfileCard from 'components/shared/TutorProfileCard/TutorProfileCard';
import { Spin } from 'antd';

const BrowseTutors = ({ isLoading, tutorData }) => {
  return (
    <div className="h-[85vh] overflow-y-auto rounded-xl bg-updatedMustard py-7 xs:px-5 md:px-10">
      <div className="flex justify-center font-semibold xs:text-lg md:text-xl">
        Browse Tutors
      </div>
      <div className="mt-5">
        {isLoading ? (
          <div className="flex h-28 items-center justify-center">
            <Spin />
          </div>
        ) : tutorData.length ? (
          tutorData.map((tutor) => (
            <TutorProfileCard
              key={tutor.id}
              id={tutor.id}
              name={tutor.first_name + ' ' + tutor.last_name}
              profileImage={tutor.profile_picture}
              subjects={tutor.subjectExpertises}
              experience={(tutor.totalWorkExperience || '')
                .split(' ')
                .slice(0, 4)
                .join(' ')}
              education={tutor.education}
              disabilityTeachings={tutor.disabilityTeachings}
              city={tutor.city}
              google_appointment_link={tutor.google_appointment_link}
            />
          ))
        ) : (
          <div className="flex h-28 items-center justify-center">
            No results found, please make sure you're entering the right tutor
            name.
          </div>
        )}
      </div>
    </div>
  );
};

export default BrowseTutors;
