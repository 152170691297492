import axiosInstance from './config';

export const getTutorEducation = (tutorId) =>
  axiosInstance.get(`/tutors/${tutorId}/educations`);

export const createTutorEducation = (tutorId, formData) =>
  axiosInstance.post(`/tutors/${tutorId}/educations`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateTutorEducation = (tutorId, educationId, formData) =>
  axiosInstance.put(`/tutors/${tutorId}/educations/${educationId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const deleteTutorEducation = (tutorId, educationId) =>
  axiosInstance.delete(`/tutors/${tutorId}/educations/${educationId}`);
