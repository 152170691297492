import React, { useContext, useState } from 'react';
import Layout from 'components/admin/layout';
import AntFormInput from '../../components/shared/AntFormInput/AntFormInput';
import { FormRule } from '../../enums/formRules';
import { Form, Spin } from 'antd';
import CustomButton from '../../components/shared/CustomButton/CustomButton';
import AntFormSubmit from '../../components/shared/AntFormSubmit/AntFormSubmit';
import { Message } from '../../constants/message';
import { notificationsContext } from '../../contexts/notifications';
import { userContext } from 'contexts/user';
import { changeAdminPassword, updateAdmin } from 'requests/admin';
import ChangePasswordModal from 'components/shared/ChangePasswordModal/ChangePasswordModal';

const MyProfile = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { api } = useContext(notificationsContext);
  const { user, setUser } = useContext(userContext);
  const { id, first_name, last_name, email, phone_number } = user;

  // Creating initial values object from context data.
  const initialValues = { first_name, last_name, email, phone_number };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  // eslint-disable-next-line no-unused-vars
  const onChangeImage = ({ file }) => {
    setProfileImage(file);
  };

  const handleGeneralProfileEdit = async (values) => {
    try {
      setIsLoading(true);
      const valuesWithPicture = {
        ...values,
        profile_picture: profileImage,
      };
      const response = await updateAdmin(id, valuesWithPicture);
      setUser(response?.data?.data);
      localStorage.setItem('user', JSON.stringify(response?.data?.data));
      toggleEditMode();
      api.success({
        message: 'Success',
        description: 'Profile Updated!',
        placement: 'top',
        duration: 4,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      api.error({
        message: 'Error',
        description: Message.errorMessage,
        placement: 'top',
        duration: 4,
      });
      throw new Error(error);
    }
  };

  const handleChangePassword = async (values) => {
    try {
      setIsLoading(true);
      setErrorMessage('');
      const { old_password, new_password, confirm_new_password } = values;

      // Validation - Password length check.
      if (new_password.length < 8) {
        setErrorMessage(Message.passwordLength);
        setIsLoading(false);
        return;
      }

      // Validation - Password match check.
      if (new_password !== confirm_new_password) {
        setErrorMessage(Message.passwordMatch);
        setIsLoading(false);
        return;
      }

      await changeAdminPassword(id, {
        old_password,
        new_password,
      });
      api.success({
        message: 'Success',
        description: Message.passwordChanged,
        placement: 'top',
        duration: 4,
      });
      setIsLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.error);
      setIsLoading(false);
    }
  };
  return (
    <Layout>
      <ChangePasswordModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handleChangePassword={handleChangePassword}
        handleCancel={() => {
          setIsModalOpen(false);
        }}
        errorMessage={errorMessage}
      />
      <div className="mx-8 mt-8 flex flex-wrap rounded-2xl bg-updatedCreme p-20 py-4 shadow">
        <div className="flex w-full flex-col items-center">
          <div className="flex flex-col items-center gap-6">
            {/* <Avatar size={150} src={profile_picture} /> */}
            {/* <Upload
              maxCount={1}
              className="upload-buttontext-white"
              beforeUpload={(file) => {
                const isImage =
                  file.type === 'image/png' ||
                  file.type === 'image/jpg' ||
                  file.type === 'image/jpeg';
                if (!isImage) {
                  message.error(`${file.name} is not an image file`);
                  return Upload.LIST_IGNORE;
                } else {
                  return false;
                }
              }}
              onChange={onChangeImage}
            >
              {isEditMode && (
                <CustomButton
                  label="Upload new picture"
                  className="w-40 rounded-2xl border border-updatedBlue bg-updatedCreme text-updatedBlue hover:shadow-lg"
                  icon={<UploadOutlined className="mr-1" />}
                />
              )}
            </Upload> */}

            <p className="text-2xl font-semibold text-updatedBlue">
              {`${first_name}  ${last_name}`}
            </p>
            <button
              onClick={() => {
                setIsModalOpen(true);
              }}
              className="rounded-full border border-gray-800 px-8 py-2 text-sm font-medium text-updatedBlue"
            >
              Change password
            </button>
          </div>
        </div>
        <div className="w-full">
          {isLoading ? (
            <div className="flex h-44 items-center justify-center">
              <Spin />
            </div>
          ) : (
            <Form
              layout="vertical"
              className="my-7"
              onFinish={handleGeneralProfileEdit}
              initialValues={initialValues}
            >
              <div className="mt-10 flex w-full justify-between gap-12">
                <p className="flex-col text-2xl font-semibold text-updatedBlue">
                  General Profile
                </p>
                <AntFormSubmit
                  label="Save Changes"
                  className={`w-36 rounded-full border bg-updatedBlue text-sm font-medium text-white ${
                    !isEditMode && 'hidden'
                  }`}
                  // onClick={toggleEditMode}
                />
                <CustomButton
                  label="Edit Profile"
                  className={`w-36 rounded-full border border-updatedBlue text-sm font-medium text-updatedBlue ${
                    isEditMode && 'hidden'
                  }`}
                  onClick={toggleEditMode}
                />
              </div>
              <AntFormInput
                name="first_name"
                rules={FormRule.FIRSTNAME}
                label={<div className="font-medium">First name</div>}
                placeholder="First name"
                disabled={!isEditMode}
                className={`w-full ${
                  !isEditMode && 'rounded-none border-x-0 border-t-0'
                } `}
              />
              <AntFormInput
                name="last_name"
                rules={FormRule.LASTNAME}
                label={<div className="font-medium">Last name</div>}
                placeholder="Last name"
                disabled={!isEditMode}
                className={`w-full ${
                  !isEditMode && 'rounded-none border-x-0 border-t-0'
                } `}
              />
              <AntFormInput
                name="email"
                label={<div className="font-medium">Email</div>}
                placeholder="Email"
                disabled={true}
                className={`w-full ${
                  !isEditMode && 'rounded-none border-x-0 border-t-0'
                } `}
              />
              <AntFormInput
                name="phone_number"
                type="phoneNumber"
                label={<div className="font-medium">Phone number</div>}
                placeholder="Phone number"
                disabled={!isEditMode}
                className={`h-8 w-full rounded-md border border-gray-200 ${
                  !isEditMode &&
                  'cursor-not-allowed rounded-none border-x-0 border-t-0 text-gray-400'
                } `}
              />
            </Form>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default MyProfile;
