// student screens
import StudentLogin from 'pages/student/login';
import StudentForgotPassword from 'pages/student/forgot-password';
import StudentDashboard from 'pages/student/dashboard';
import StudentVerifyCode from 'pages/student/verify-code';
import StudentCreatePassword from 'pages/student/create-password';
import StudentProfile from 'pages/student/student-profile';
import SchoolProfiling from 'pages/student/school-profiling';
import Languages from 'pages/student/languages';
import DisabilityProfiling from 'pages/student/disability-profiling';
import PaymentConfiguration from 'pages/student/payment-configuration';
import { Route } from 'react-router-dom';
import { NoAuthRoute, Protected } from 'routes';
import { STUDENT } from 'constants/roles';
import FindTutors from 'pages/student/find-tutors';
import Storefront from 'pages/tutor/storefront';
import StreamChat from 'pages/student/stream-chat';

const studentRoutes = (
  <Route key="student" path="student">
    <Route
      path="login"
      element={
        <NoAuthRoute>
          <StudentLogin />
        </NoAuthRoute>
      }
    />
    <Route
      path="forgot-password"
      element={
        <NoAuthRoute>
          <StudentForgotPassword />
        </NoAuthRoute>
      }
    />
    <Route
      path="verify-code"
      element={
        <NoAuthRoute>
          <StudentVerifyCode />
        </NoAuthRoute>
      }
    />
    <Route path="create-password" element={<StudentCreatePassword />} />
    {/* <Route
      path="signup"
      element={
        <NoAuthRoute>
          <StudentSignup />
        </NoAuthRoute>
      }
    /> */}
    <Route
      path="dashboard"
      element={
        <Protected routeRole={STUDENT}>
          <StudentDashboard />
        </Protected>
      }
    />

    <Route
      path="tutor-and-courses"
      element={
        <Protected routeRole={STUDENT}>
          <h1>Tutor and Courses</h1>
        </Protected>
      }
    />
    <Route
      path="find-tutors"
      element={
        <Protected routeRole={STUDENT}>
          <FindTutors />
        </Protected>
      }
    />
    <Route
      path="general-profile"
      element={
        <Protected routeRole={STUDENT}>
          <StudentProfile />
        </Protected>
      }
    />
    <Route
      path="school-profiling"
      element={
        <Protected routeRole={STUDENT}>
          <SchoolProfiling />
        </Protected>
      }
    />
    <Route
      path="languages"
      element={
        <Protected routeRole={STUDENT}>
          <Languages />
        </Protected>
      }
    />
    <Route
      path="disability-profiling"
      element={
        <Protected routeRole={STUDENT}>
          <DisabilityProfiling />
        </Protected>
      }
    />
    <Route
      path="payment-configuration"
      element={
        <Protected routeRole={STUDENT}>
          <PaymentConfiguration />
        </Protected>
      }
    />
    <Route
      path="/student/"
      element={
        <Protected routeRole={STUDENT}>
          <StudentDashboard />
        </Protected>
      }
    />
    <Route
      path="/student/find-tutors/:id"
      element={
        <Protected routeRole={STUDENT}>
          <Storefront />
        </Protected>
      }
    />
    <Route
      path="/student/chat/:id"
      element={
        <Protected routeRole={STUDENT}>
          <StreamChat />
        </Protected>
      }
    />
  </Route>
);

export default studentRoutes;
