export const studentDetailsSidebarOptions = [
  {
    label: 'General Profile',
    name: 'general-profile',
    path: '/student/general-profile',
  },
  {
    label: 'Detailed Profiling',
    name: 'detail-profiling',

    children: [
      {
        label: 'School Profiling',
        name: 'school-profiling',
        path: '/student/school-profiling',
      },
      {
        label: 'Languages',
        name: 'languages',
        path: '/student/languages',
      },
      {
        label: 'Disability Profiling',
        name: 'disability-profiling',
        path: '/student/disability-profiling',
      },
      {
        label: 'Payment Configuration',
        name: 'payment-configuration',
        path: '/student/payment-configuration',
      },
    ],
  },
];
