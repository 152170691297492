import { Badge, Layout, Table, Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import backgroundImg from 'assets/images/tutor/background.png';
import Navbar from 'components/shared/Navbar/Navbar';
import { parentNavbarItems } from 'enums';
import { PARENT } from 'constants/roles';
import { notificationsContext } from 'contexts/notifications';
import { getUserChats as getUserChats } from 'requests/tutors';
import { userContext } from 'contexts/user';
import StreamChatModal from 'components/Modal/StreamChatModal';
import { StreamChat } from 'stream-chat';

const ParentChat = () => {
  const {
    user: { id: parentId },
  } = useContext(userContext);
  const { user } = useContext(userContext);
  const [data, setData] = useState([]);
  const { api } = useContext(notificationsContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tutorId, setTutorId] = useState('');
  const [tutorName, setTutorName] = useState('');
  const [displayBadge, setDisplayBadge] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getChats = async () => {
    try {
      const {
        data: { data },
      } = await getUserChats(parentId);

      const chatClient = StreamChat.getInstance(
        process.env.REACT_APP_STREAM_API_KEY
      );
      await chatClient.connectUser(
        {
          id: user.id.toString(),
          name: user.first_name,
          stream_chat_token: user.stream_chat_token,
        },
        user.stream_chat_token
      );

      const updatedData = await Promise.all(
        data.map(async (item, index) => {
          const channelName = item.channel_name;

          const channel = chatClient.channel('messaging', channelName);
          await channel.watch();

          const unreadCount = channel.countUnread();

          return {
            ...item,
            key: index + 1,
            unreadCount: unreadCount,
            channel: channel,
          };
        })
      );

      await chatClient.disconnectUser();

      setData(updatedData);
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again later.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Tutor Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, { Tutor }) => {
        return `${Tutor?.first_name} ${Tutor?.last_name}`;
      },
    },
    {
      title: 'email',
      dataIndex: 'email',
      key: 'email',
      render: (_, { Tutor }) => {
        return `${Tutor.email}`;
      },
    },
    {
      title: 'Start Chat',
      dataIndex: 'start_chat',
      key: 'start_chat',
      render: (_, { Tutor, unreadCount }) => (
        <Button
          className="h-10 w-28 rounded-3xl bg-updatedBlue px-2 text-white"
          size="default"
          shape="round"
          onClick={() => {
            setIsModalOpen(true);
            setTutorId(Tutor?.id);
            setTutorName(Tutor?.first_name);
            setDisplayBadge(false);
          }}
        >
          Start Chat
          {unreadCount > 0 && displayBadge && (
            <Badge
              offset={[3, -28]}
              count={unreadCount}
              overflowCount={99}
              className="bg-red-500"
            />
          )}
        </Button>
      ),
    },
  ];

  return (
    <Layout>
      <Navbar role={PARENT} navItems={parentNavbarItems} />
      <StreamChatModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        name={tutorName}
        id={tutorId}
      />
      <div className="Tutorsue-400] min-h-[calc(100vh-64px)] bg-updatedGreenish">
        <div className="mx-12 my-7 min-h-[calc(100vh-120px)] rounded-2xl bg-updatedMustard px-10 pb-5 pt-10">
          <div className="mb-10 text-2xl font-semibold">Chat</div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{
              x: 1600,
              y: 500,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ParentChat;
