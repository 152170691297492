export const Message = {
  tutorRecord: 'Tutor record updated',
  errorMessage: 'Something went wrong. Please try again after some time.',
  statusUpdated: 'Status updated successfully',
  errorStatus: 'You can not set invited status',
  suspend: 'Profile has been suspended successfully',
  invited: 'Profile has been invited successfully',
  active: 'Profile has been activated successfully',
  approved: 'Profile has been approved successfully',
  'on hold': 'Profile has been set to on hold successfully',
  'pending approval': 'Profile has been set to pending approval successfully',
  studentRecord: 'Profile updated!',
  invalidImageFormat: 'Invalid image format!',
  deactivated: 'Account deactivated!',
  passwordMatch: 'New password must match confirm new password!',
  passwordChanged: 'Password changed!',
  passwordLength: 'New password must be at least 8 characters long!',
  phoneNumberError: 'Phone Number not valid!',
  profileImageError: 'Profile image not valid!',
};
