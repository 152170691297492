import React, { useContext } from 'react';
import { Layout } from 'antd';
import { impersonateStudentNavbarItems } from 'enums';
import Navbar from 'components/shared/Navbar/Navbar';
import { STUDENT } from 'constants/roles';
import Sessions from 'components/shared/Sessions/Sessions';
import { childUserContext } from 'contexts/child-user-context';
import { useSearchParams } from 'react-router-dom';

const ImpersonateDashboard = () => {
  const { childUser } = useContext(childUserContext);

  const [params] = useSearchParams();
  const paramsId = params.get('key');

  return (
    <div className="bg-blue-400">
      <Layout>
        <Navbar navItems={impersonateStudentNavbarItems} role={STUDENT} />
        {!!childUser && (
          <Sessions role={STUDENT} userId={paramsId ? paramsId : childUser} />
        )}
      </Layout>
    </div>
  );
};

export default ImpersonateDashboard;
