import React from 'react';
import { ReactComponent as EditSvg } from 'assets/images/edit.svg';

const EditActionButton = ({ ...props }) => {
  return (
    <button
      className="flex h-10 w-10 items-center justify-center rounded-md bg-updatedBlue"
      {...props}
    >
      <EditSvg className="h-5 w-5" />
    </button>
  );
};

export default EditActionButton;
