import { notificationsContext } from 'contexts/notifications';
import React, { useContext, useEffect, useState } from 'react';
import DefaultButton from 'components/DefaultButton';
import EditActionButton from 'components/EditActionButton';
import DeleteActionButton from 'components/DeleteActionButton';
import { Table } from 'antd';
import moment from 'moment';
import { getTutorEducation } from 'requests/education';
import UseAddEducationModal from 'components/admin/AddEducationModal';
import UseEditEducationModal from 'components/admin/EditEducationModal';
import UseDeleteEducationModal from 'components/admin/DeleteEducationModal';
import Layout from 'components/tutor/ProfileLayout';
import { userContext } from 'contexts/user';
import { viewStoreFront } from 'utils';
import { TUTOR } from 'constants/roles';
import { useNavigate } from 'react-router-dom';

const Education = () => {
  const {
    user: { id: tutorId, account_status },
  } = useContext(userContext);

  const { api } = useContext(notificationsContext);
  const navigate = useNavigate();

  const getEducations = async () => {
    try {
      const {
        data: { data: educations },
      } = await getTutorEducation(tutorId);

      setEducations(
        educations.map((item, index) => ({
          ...item,
          key: index + 1,
        }))
      );
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  const { AddEducationModal, showModal } = UseAddEducationModal(
    tutorId,
    getEducations
  );
  const { EditEducationModal, showModal: showEditModal } =
    UseEditEducationModal(tutorId, getEducations);
  const { DeleteEducationModal, showModal: showDeleteModal } =
    UseDeleteEducationModal(tutorId, getEducations);
  const [educations, setEducations] = useState([]);

  useEffect(() => {
    getEducations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Degree Title',
      dataIndex: 'degree_title',
      key: 'degree_title',
    },
    {
      title: 'Institute',
      dataIndex: 'institute',
      key: 'institute',
    },
    {
      title: 'Degree Type',
      dataIndex: 'degree_type',
      key: 'degree_type',
    },

    {
      title: 'Attachment',
      dataIndex: 'document',
      key: 'document',
      render: (_, { document }) => {
        return !document ? (
          'No Attachment'
        ) : (
          <a href={document} target="_blank" rel="noreferrer">
            <div className="text-sm font-normal text-updatedBlue underline hover:text-blue-900">
              View
            </div>
          </a>
        );
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_, { start_date }) => {
        return moment(start_date).format('LL');
      },
    },
    {
      title: 'End Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_, { end_date, is_ongoing }) => {
        return moment(end_date).isValid() && !is_ongoing
          ? moment(end_date).format('LL')
          : 'Ongoing';
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, { id, ...education }) => {
        return (
          <div className="flex items-center justify-start gap-5">
            <EditActionButton
              onClick={() => {
                showEditModal(id, education);
              }}
            />
            <DeleteActionButton
              onClick={() => {
                showDeleteModal(id);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <EditEducationModal />
      <DeleteEducationModal />
      <AddEducationModal />

      <Layout>
        <div className="flex min-h-[calc(100vh_-_273px)] w-full flex-col items-center">
          <div className="flex w-full items-center justify-between gap-8">
            <div className="flex-1 font-semibold text-updatedBlue xs:text-xl md:text-2xl">
              Detailed Profile / Education
            </div>
            <div className="flex flex-wrap  items-center justify-start gap-3">
              <div>
                {account_status === 'approved' && (
                  <button
                    type="button"
                    className="flex h-12 w-40 items-center justify-center rounded-3xl bg-updatedBlue text-center text-sm font-medium text-white"
                    onClick={() => {
                      const navigateString = viewStoreFront(tutorId, TUTOR);
                      navigate(navigateString);
                    }}
                  >
                    View Storefront
                  </button>
                )}
              </div>
              <div>
                <DefaultButton className="!bg-[#212B36]" onClick={showModal}>
                  Add Education
                </DefaultButton>
              </div>
            </div>
          </div>

          <Table
            className="mt-10 w-full"
            pagination={false}
            columns={columns}
            dataSource={educations}
            scroll={{
              x: 1000,
              y: 1000,
            }}
          />
        </div>
      </Layout>
    </>
  );
};

export default Education;
