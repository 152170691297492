/* eslint-disable default-case */
import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal, Input, Button, Spin } from 'antd';
import { SUBJECTS_MODAL_TYPE } from 'constants/strings';
import {
  addMappedSubject,
  addSubject,
  editSubject,
} from 'requests/admin-subject-management';
import { notificationsContext } from 'contexts/notifications';
import { successNotification } from 'utils/successNotificationStyle';

const SubjectsModal = ({
  isModalOpen,
  setIsModalOpen,
  modalType,
  setRefreshFlag,
  subjectName,
  setSubjectName,
}) => {
  const [form] = Form.useForm();
  const { api } = useContext(notificationsContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({ title: subjectName.title });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subjectName]);

  const { ADD, EDIT, ADD_OTHER_SUBJECTS } = SUBJECTS_MODAL_TYPE;

  const handleFinish = async (value) => {
    try {
      const editValue = {
        id: subjectName.id,
        title: value.title,
      };
      setIsLoading((prev) => !prev);
      if (modalType === ADD) {
        await addSubject(value);
        api.success({
          message: 'Subject Added Successfully!',
          placement: 'top',
          duration: 4,
          style: successNotification,
        });
        setSubjectName({ title: '' });
      }
      if (modalType === EDIT) {
        await editSubject(editValue);
        api.success({
          message: 'Subject updated successfully!',
          placement: 'top',
          duration: 4,
          style: successNotification,
        });
        setSubjectName({ title: '' });
      }
      if (modalType === ADD_OTHER_SUBJECTS) {
        await addMappedSubject(editValue);
        api.success({
          message: 'Subject updated successfully!',
          placement: 'top',
          duration: 4,
          style: successNotification,
        });
      }

      setRefreshFlag((prev) => !prev);
    } catch (err) {
      if (
        err.response.data?.error === 'title Parameter Missing.' ||
        err.response.data?.error === 'Missing Parameters'
      ) {
        api.error({
          message: 'Error',
          description: 'Subject name is empty!',
          placement: 'top',
          duration: 4,
        });
      } else if (
        err.response?.data?.error?.errors[0]?.message === 'title must be unique'
      ) {
        api.error({
          message: 'Error',
          description: 'Subject already exists!',
          placement: 'top',
          duration: 4,
        });
      }
    } finally {
      setIsLoading((prev) => !prev);
      setIsModalOpen((prev) => !prev);
    }
  };

  const validateAlphabets = (_, value) => {
    if (value && !/^[A-Za-z]+$/.test(value)) {
      return Promise.reject(new Error('Only alphabets are allowed.'));
    }
    return Promise.resolve();
  };

  let renderModal;
  switch (modalType) {
    case EDIT:
    case ADD_OTHER_SUBJECTS:
    case ADD:
      renderModal = (
        <Modal
          centered
          open={isModalOpen}
          onCancel={() => {
            setIsModalOpen((prev) => !prev);
            form.resetFields();
          }}
          footer={null}
          width={350}
        >
          <h1 className="text-2xl font-semibold">
            {modalType === ADD || modalType === ADD_OTHER_SUBJECTS
              ? 'Add Subject'
              : 'Edit subject'}
          </h1>

          <Form
            form={form}
            onFinish={handleFinish}
            initialValues={subjectName}
            layout="vertical"
            className="mt-7 flex w-full flex-col items-center"
          >
            <Form.Item
              name="title"
              label={
                <p className="font-semibold">
                  {modalType === ADD || modalType === ADD_OTHER_SUBJECTS
                    ? 'Subject name'
                    : 'Change name'}
                </p>
              }
              rules={[
                { required: true, message: 'Please enter subject name.' },
              ]}
              className="w-full"
            >
              <Input size="large" placeholder="Enter subject name" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              className="h-10 w-52 items-center justify-center rounded-3xl bg-gray-800 text-sm font-semibold text-white hover:!bg-gray-700"
            >
              {isLoading ? (
                <Spin />
              ) : modalType === ADD || modalType === ADD_OTHER_SUBJECTS ? (
                'Add Subject'
              ) : (
                'Save Changes'
              )}
            </Button>
          </Form>
        </Modal>
      );
      break;
  }

  return renderModal;
};

export default SubjectsModal;
