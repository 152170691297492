import React, { useState, useEffect } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import { getTutorById } from 'requests';
import { useParams } from 'react-router-dom';

const AdminTutorCalendar = () => {
  const [calendarLink, setCalendarLink] = useState('');
  const { id: tutorId } = useParams();

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setCalendarLink(data?.google_appointment_link);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <TutorDetailsLayout>
      <div className="flex  w-full flex-col items-center">
        <div className="flex w-full items-center justify-between gap-8">
          <div className="flex-1 text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Connect Calendar
          </div>
        </div>
        <div className="grid w-full grid-cols-2 border-gray-500 border-opacity-25 pt-7">
          {/* Google Appointment Link */}
          <div className="col-span-1 pt-7">
            <div className="mb-5 mt-2 font-semibold text-updatedBlue">
              Calendar Link
            </div>
            <textarea
              className="w-full text-gray-500"
              value={calendarLink}
              placeholder="Calendar Link not added."
              disabled
            />
          </div>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default AdminTutorCalendar;
