import { useGoogleLogin } from '@react-oauth/google';
import { userContext } from 'contexts/user';
import { useContext } from 'react';
import axios from 'axios';
import { notificationsContext } from 'contexts/notifications';

export const getToken = (code) =>
  axios.post(
    'https://oauth2.googleapis.com/token',
    {
      code,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      grant_type: 'authorization_code',
      redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

export const getRefreshToken = (refreshToken) =>
  axios.post(
    'https://oauth2.googleapis.com/token',
    {
      refresh_token: refreshToken,
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
      grant_type: 'refresh_token',
    },
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );

const useGoogleAuthConsent = () => {
  const { setGoogleCalendarToken, setGoogleCalendarRefreshToken, user } =
    useContext(userContext);
  const { api } = useContext(notificationsContext);
  const login = useGoogleLogin({
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    onSuccess: (credentials) => {
      getToken(credentials.code)
        .then(({ data: { access_token, refresh_token } }) => {
          getUserInfo(access_token)
            .then((response) => {
              const userEmail = response.data.email;
              if (userEmail === user?.email) {
                setGoogleCalendarToken(access_token);
                setGoogleCalendarRefreshToken(refresh_token);

                api.success({
                  message: 'Google Calendar connected successfully.',
                  placement: 'top',
                });
              } else {
                api.error({
                  message: `Please use the correct email you used to sign in with the application i.e. ${user?.email}`,
                  placement: 'top',
                });
              }
            })
            .catch((error) => {
              console.error('Error fetching user information', error);
              api.error({
                message: 'Something went wrong. Please try again later.',
                placement: 'top',
              });
            });
        })
        .catch(() => {
          api.error({
            message: 'Something went wrong. Please try again later.',
            placement: 'top',
          });
        });
    },
    scope:
      'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar openid',
    accessType: 'offline',
    prompt: 'consent',
    flow: 'auth-code',
  });
  return login;
};

const getUserInfo = (accessToken) => {
  return axios.get('https://www.googleapis.com/oauth2/v1/userinfo', {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export default useGoogleAuthConsent;
