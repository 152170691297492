import React from 'react';

const CurrentSubscriptionCard = ({ subscription, product }) => {
  return (
    <div className="mt-10 flex h-72 flex-col rounded-xl bg-updatedGreenish p-5 xs:w-full md:w-56">
      <div className="font-bold text-updatedLightBlue xs:text-lg md:text-xl">
        {product.name} - ${subscription.items.data[0].price.unit_amount / 100}
      </div>
      <div className="mt-3 text-base text-updatedLightBlue">
        {product.description}
      </div>
      {!!product?.metadata?.number_of_children && (
        <div className="mt-3 text-updatedLightBlue">
          Max number of children : {product?.metadata?.number_of_children}
        </div>
      )}
    </div>
  );
};

export default CurrentSubscriptionCard;
