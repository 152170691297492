import { Form, Select } from 'antd';
import React from 'react';

const AntFormSelect = ({
  name,
  label,
  mode = '',
  rules = {},
  placeholder,
  options,
  className = '',
  disabled,
  dropdownStyle = '',
  style = {},
  showArrow = false,
  bordered = false,
  size = 'middle',
  showSearch = false,
}) => {
  return (
    <>
      <Form.Item name={name} label={label} rules={rules}>
        <Select
          style={style}
          mode={mode}
          dropdownStyle={dropdownStyle ? dropdownStyle : ''}
          placeholder={placeholder}
          size={size}
          options={options}
          className={`rounded-lg ${
            className ? className : 'placeholder-white'
          }`}
          disabled={disabled}
          showArrow={showArrow}
          bordered={bordered}
          showSearch={showSearch}
        />
      </Form.Item>
    </>
  );
};

export default AntFormSelect;
