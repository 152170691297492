export const STRINGS = {
  email_required: 'Email is required',
  email_invalid: 'Email is invalid',
  password_required: 'Password is required',
  password_min_length: 'Password must be at least 8 characters',
  confirm_password_required: 'Confirm password is required',
  confirm_password_min_length: 'Confirm password must be at least 8 characters',
  confirm_password_not_match: 'Confirm password does not match',
  otp_required: 'OTP is required',
  otp_invalid: 'OTP is invalid',
  firstNameRequired: 'First name is required',
  lastNameRequired: 'Last name is required',
  nameLength: 'Their must be at least 3 characters',
};

export const STATUSES = {
  INVITED: 'invited',
  SUSPEND: 'suspend',
  ACTIVE: 'active',
  DEACTIVATE: 'deactivate',
  PENDING_APPROVAL: 'pending approval',
  APPROVED: 'approved',
  ON_HOLD: 'on hold',
  SIGNED_UP: 'signed up',
  ADDED_BY_PARENT: 'added by parent',
};

export const SUBJECTS_MODAL_TYPE = {
  ADD: 'Add',
  EDIT: 'Edit',
  DELETE: 'Delete',
  ADD_OTHER_SUBJECTS: 'AddOtherSubjects',
};

export const CHILDREN_MODAL_TYPE = {
  ADD: 'Add',
  DELETE: 'Delete',
};
