import React from 'react';
import TutorGeneralProfileForm from 'components/tutor/GeneralProfileForm';
import Layout from 'components/tutor/ProfileLayout';

const GeneralProfile = () => {
  return (
    <Layout>
      <TutorGeneralProfileForm />
    </Layout>
  );
};

export default GeneralProfile;
