import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Select } from 'antd';
import Button from 'components/Button';
import { notificationsContext } from 'contexts/notifications';
import { selectedUserContext } from 'contexts/selected-user';
import { LANGUAGES_ARRAY } from 'enums/languages';
import { getParentById, updateParentsLanguages } from 'requests';
import { userContext } from 'contexts/user';
import EditParentDetailsLayout from './edit-parent-details-layout';

const EditParentLanguageProficiency = () => {
  const [languages, setLanguages] = useState([]);
  const [originalLanguages, setOriginalLanguages] = useState([]);
  const [languagesChanged, setLanguagesChanged] = useState(false);
  const { api } = useContext(notificationsContext);
  const { user } = useContext(userContext);
  const { selectedUser } = useContext(selectedUserContext);

  const parentId = user.id;

  useEffect(() => {
    (async () => {
      await getTutorDetails();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const haveLanguagesChanged =
      JSON.stringify(languages) !== JSON.stringify(originalLanguages);
    setLanguagesChanged(haveLanguagesChanged);
  }, [languages, originalLanguages]);

  const getTutorDetails = async () => {
    try {
      const { data } = await getParentById(parentId);
      setLanguages(data.languages ?? []);
      setOriginalLanguages(data.languages ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleLanguageUpdate = async () => {
    try {
      await updateParentsLanguages(parentId, languages);
      api.success({
        message: 'Success',
        description: 'Languages updated successfully!',
        placement: 'top',
        duration: 4,
      });
      setOriginalLanguages(languages);
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  return (
    <EditParentDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <div className="flex justify-center pb-5">
          <div className="flex flex-col items-center justify-center gap-1">
            <Avatar size={100} src={selectedUser.profile_picture} />
            <div
              style={{ fontFamily: 'Now' }}
              className="font-semibold leading-relaxed text-updatedBlue xs:text-xl md:text-2xl"
            >
              {`${selectedUser.first_name} ${selectedUser.last_name}`}
            </div>
          </div>
        </div>

        <div className="mt-10 w-full">
          <div
            style={{ fontFamily: 'Bogart' }}
            className="flex items-center justify-between font-semibold text-updatedBlue xs:text-xl md:text-2xl"
          >
            <div>Detailed Profile / Languages</div>
            {languagesChanged && (
              <Button onClick={handleLanguageUpdate}>Save Settings</Button>
            )}
          </div>
          <div
            style={{ fontFamily: 'Now' }}
            className="mt-8 text-updatedBlue xs:text-lg md:text-xl"
          >
            Languages
          </div>
          <div id="languages">
            <Select
              className="mt-10 w-full rounded-lg border border-neutral-200"
              value={languages}
              onChange={setLanguages}
              mode="multiple"
              optionLabelProp="label"
            >
              {LANGUAGES_ARRAY.map((language) => (
                <Select.Option
                  value={language.name}
                  style={{ fontFamily: 'Now' }}
                >
                  {language.name} ({language.nativeName})
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </EditParentDetailsLayout>
  );
};

export default EditParentLanguageProficiency;
