import React, { useEffect, useState } from 'react';
import StoreFrontHeader from '../../components/tutor/StoreFrontHeader';
import StoreFrontTagTable from 'components/shared/StoreFrontTagTable/StoreFrontTagTable';
import StoreFrontListingTable from 'components/shared/StoreFrontListingTable/StoreFrontListingTable';
import OnlineSessionCard from 'components/shared/OnlineSessionCard/OnlineSessionCard';
import { useParams } from 'react-router-dom';
import { getTutorStorefrontById } from 'requests';
import { Spin } from 'antd';
import StoreFrontBio from 'components/shared/StoreFrontBio/StoreFrontBio';
import PortfolioVideoCard from 'components/shared/PortfolioVideoCard/PortfolioVideoCard';

const Storefront = () => {
  const [storefrontData, setStorefrontData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { id } = useParams();

  // Fetching the storefront details on page load.
  useEffect(() => {
    (async () => {
      await getTutorStorefrontDetails(id);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This function fetches the tutor storefront details with id.
  const getTutorStorefrontDetails = async (id) => {
    try {
      setIsLoading(true);

      // Validation to check ids.
      if (isNaN(id)) {
        setErrorMessage("User doesn't exist");
        setIsLoading(false);
        return;
      }
      const { data: storefrontResponse } = await getTutorStorefrontById(id);
      setStorefrontData(storefrontResponse);
      setIsLoading(false);
    } catch (error) {
      setErrorMessage(error?.response?.data?.error);
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <div className="bg-updatedCreme">
      {isLoading ? (
        <div className="flex h-screen items-center justify-center">
          <Spin />
        </div>
      ) : errorMessage ? (
        <div className="flex h-screen items-center justify-center">
          {errorMessage}
        </div>
      ) : (
        <>
          {/*header layout*/}
          <StoreFrontHeader storefrontData={storefrontData} />
          <div className="my-10 grid xs:mx-5 xs:grid-cols-1 xs:gap-0 md:mx-16 md:grid-cols-7 md:gap-10 lg:gap-24">
            <div className="md:col-span-4">
              {/* Bio */}
              <div className="w-full">
                <StoreFrontBio
                  type="bio"
                  tableTitle="Bio"
                  tagData={
                    storefrontData?.user?.bio
                      ? storefrontData?.user?.bio
                      : 'N/A'
                  }
                />
              </div>
              {/* Expertise */}
              <div className="w-full">
                <StoreFrontTagTable
                  type="expertise"
                  tableTitle="Expertise"
                  tagData={storefrontData?.user?.subjectExpertises}
                />
              </div>
              {/* Work Experience */}
              <div className="w-full">
                <StoreFrontListingTable
                  type="experience"
                  tableTitle={`Work Experience (${
                    storefrontData?.totalWorkExperience?.split(' ')[0] +
                    ' ' +
                    storefrontData?.totalWorkExperience?.split(' ')[1]
                  })`}
                  listingData={storefrontData?.user?.workExperiences}
                  className="bg-red-300"
                />
              </div>
              {/* Demographics */}
              <div className="w-full">
                <StoreFrontTagTable
                  type="demographics"
                  tableTitle="Demographics of Age Group"
                  tagData={storefrontData?.user?.student_statistics}
                />
              </div>
              {/* Certifications */}
              <div className="w-full">
                <StoreFrontListingTable
                  type="certificates"
                  tableTitle="Certifications"
                  listingData={storefrontData?.user?.certificates}
                />
              </div>
              {/* Education */}
              <div className="w-full">
                <StoreFrontListingTable
                  type="education"
                  tableTitle="Education"
                  listingData={storefrontData?.user?.education}
                />
              </div>
            </div>
            {/* Online Session */}
            <div className="md:col-span-3">
              {/* video */}
              {storefrontData?.user?.video_link && (
                <div className="w-full">
                  <PortfolioVideoCard
                    cardTitle="Portfolio Video"
                    link={storefrontData?.user?.video_link}
                  />
                </div>
              )}
              <div className="w-full">
                <OnlineSessionCard
                  cardTitle="Online session"
                  fee={`$${storefrontData?.user?.tutor_pricing}`}
                  googleCalendarLink={
                    storefrontData?.user?.google_appointment_link
                  }
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Storefront;
