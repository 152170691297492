import FormModal from 'components/shared/AntFormModal/AntFormModal';
import CustomButton from 'components/shared/CustomButton/CustomButton';
import React from 'react';

const DeactivateAccountModal = ({
  isDeactivateAccountModalOpen,
  setIsDeactivateAccountModalOpen,
  setIsLoading,
  handleCancel,
  handleDelete,
}) => {
  return (
    <div>
      <FormModal
        title={
          <div style={{ fontFamily: 'Now' }} className="text-xl font-bold">
            Deactivate account
          </div>
        }
        isModalOpen={isDeactivateAccountModalOpen}
        setIsModalOpen={setIsDeactivateAccountModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={handleCancel}
      >
        <div>
          <div style={{ fontFamily: 'Now' }} className="text-gray-500">
            Are you sure you want to deactivate account?
          </div>
          <div className="mb-5 mt-10 flex flex-wrap items-center xs:justify-center xs:gap-3 md:justify-between md:gap-1">
            <CustomButton
              label="No"
              shape="round"
              size="large"
              className="border border-updatedBlue bg-updatedCreme text-updatedBlue"
              buttonWidth="w-28"
              onClick={() => {
                setIsDeactivateAccountModalOpen(false);
              }}
            />
            <CustomButton
              label="Yes"
              shape="round"
              size="large"
              className="bg-updatedBlue text-white"
              buttonWidth="w-28"
              onClick={handleDelete}
            />
          </div>
        </div>
      </FormModal>
    </div>
  );
};

export default DeactivateAccountModal;
