import React, { useEffect, useState, useContext } from 'react';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  Channel,
  Window,
  ChannelHeader,
  MessageList,
  MessageInput,
  Thread,
  LoadingIndicator,
} from 'stream-chat-react';
import { EmojiPicker } from 'stream-chat-react/emojis';
import 'stream-chat-react/dist/css/v2/index.css';
import { userContext } from 'contexts/user';

const StreamAppChat = ({ id, name, setChatClient, chatClient }) => {
  const { user } = useContext(userContext);

  const apiKey = process.env.REACT_APP_STREAM_API_KEY;
  const [channel, setChannel] = useState(null);

  const getChannelName = (user1, user2) => {
    const sortedIds = [user1.id.toString(), user2.id.toString()].sort();
    return `${
      process.env.REACT_APP_STREAM_CHAT_CHANNEL_PREFIX
    }_${sortedIds.join('_')}`;
  };

  useEffect(() => {
    async function init() {
      const chatClient = StreamChat.getInstance(apiKey);
      const currentUser = {
        id: user.id.toString(),
        name: user.first_name,
        stream_chat_token: user.stream_chat_token,
      };
      const otherUser = {
        id: id.toString(),
        name: name,
      };
      await chatClient.connectUser(currentUser, currentUser.stream_chat_token);
      await chatClient.upsertUsers([
        {
          id: otherUser.id,
          name: otherUser.name,
        },
      ]);
      const channelName = getChannelName(currentUser, otherUser);
      const channel = chatClient.channel('messaging', channelName, {
        name: `chat between ${currentUser.name} & ${otherUser.name}`,
        members: [currentUser.id, otherUser.id],
      });
      await channel.watch();

      const postData = {
        channel_name: channelName,
        current_user_id: currentUser.id,
        other_user_id: otherUser.id,
      };
      try {
        await fetch(`${process.env.REACT_APP_FRONT_END_BASE_URL}/api/chats`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });
      } catch (error) {
        console.error('Error while hitting the API:', error);
      }

      setChannel(channel);
      setChatClient(chatClient);
    }

    init();
  });

  if (!channel || !chatClient) return <LoadingIndicator />;

  return (
    <Chat client={chatClient} theme="messaging light">
      <Channel EmojiPicker={EmojiPicker} channel={channel}>
        <Window>
          <ChannelHeader />
          <MessageList />
          <MessageInput EmojiPickerIcon={true} />
        </Window>
        <Thread />
      </Channel>
    </Chat>
  );
};

export default StreamAppChat;
