import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from 'requests';
import { ADMIN, SUPERADMIN } from 'constants/roles';
import LoginComponent from 'components/login';
import { userContext } from 'contexts/user';

const Login = () => {
  const [apiError, setApiError] = useState('');

  const { login: loginContext } = useContext(userContext);
  const navigate = useNavigate();

  const handleLogin = async ({ email, password }) => {
    const response = await login(email, password, ADMIN);

    if (response?.data?.role === ADMIN || SUPERADMIN) {
      loginContext(response);
      navigate('/admin/dashboard', { replace: true });
    } else {
      setApiError('Invalid credentials');
    }
  };

  return (
    <LoginComponent
      forgotPasswordLink="/admin/forgot-password"
      login={handleLogin}
      className="bg-updatedCreme"
      apiError={apiError}
      setApiError={setApiError}
    />
  );
};

export default Login;
