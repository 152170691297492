import React, { useEffect, useState } from 'react';
import CustomButton from 'components/Button';
import SearchBar from 'components/SearchBar/SearchBar';
import Layout from 'components/admin/layout';
import { getRowNumber } from 'utils/helperFunctions';
import { Table, Button, Spin, Select } from 'antd';
import {
  editMappedSubject,
  getAllSubjects,
  getMappedSubjects,
} from 'requests/admin-subject-management';
import SubjectsModal from 'components/Modal/SubjectsModal';
import { SUBJECTS_MODAL_TYPE } from 'constants/strings';

const TableText = ({ children }) => (
  <p className="text-tableItemsGray">{children}</p>
);

const OtherSubjects = () => {
  const [subjectsData, setSubjectsData] = useState([
    {
      others: 'Thermodynamics',
      mapping: 'Physics',
      otherSubjectId: 1,
      mappedSubjectId: 3,
    },
  ]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 8,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshTable, setRefreshTable] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectList, setSelectList] = useState([]);

  const { ADD_OTHER_SUBJECTS } = SUBJECTS_MODAL_TYPE;

  useEffect(() => {
    (async () => getAllMappedSubjects())();
    (async () => getSubjectsForSelect())();
  }, []);

  useEffect(() => {
    (async () => getAllMappedSubjects())();
  }, [refreshTable]);

  const columns = [
    {
      title: 'Sr. No.',
      render: (text, record, index) => (
        <TableText>{getRowNumber(index, tableParams)}</TableText>
      ),
    },
    {
      title: 'Others',
      dataIndex: 'others',
      render: (text) => <TableText>{text}</TableText>,
    },
    {
      title: 'Mapping',
      dataIndex: 'mapping',
      render: (text, record) => {
        if (isEditable) {
          return (
            <div id="EditableSelect">
              <Select
                defaultValue={record.mappedSubjectId || null}
                size="small"
                className="w-[50%]"
                placeholder="Select subject"
                onChange={(val) =>
                  updateMappedSubject(val, record.otherSubjectId)
                }
                options={selectList}
              />
            </div>
          );
        } else {
          return <TableText>{text}</TableText>;
        }
      },
    },
  ];

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  const getSubjectsForSelect = async () => {
    try {
      const subjects = await getAllSubjects();
      // Filtering out subjects that are not hidden
      const modifiedSubjectsList = subjects
        .filter((subject) => !subject?.is_hidden)
        .map((subject) => ({
          value: subject.id,
          label: subject.title,
        }));
      setSelectList(modifiedSubjectsList);
    } catch (err) {
      throw new Error(err.response.data.error);
    }
  };

  const getAllMappedSubjects = async () => {
    try {
      const response = await getMappedSubjects();
      if (response.length > 0) {
        const modifiedData = response.map((element) => ({
          others: element.title,
          mapping: element?.subject?.title,
          otherSubjectId: element.id,
          mappedSubjectId: element?.subject?.id,
        }));
        setSubjectsData(modifiedData);
      }
    } catch (err) {
      throw new Error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateMappedSubject = async (mappedSubjectId, otherSubjectId) => {
    try {
      const editValues = {
        id: otherSubjectId,
        subject_id: mappedSubjectId,
      };
      await editMappedSubject(editValues);
    } catch (err) {
      throw new Error(err.response.data.error);
    }
  };

  return (
    <Layout>
      <SubjectsModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        modalType={ADD_OTHER_SUBJECTS}
        setRefreshFlag={setRefreshTable}
        subjectName={{ id: '', title: '' }}
      />
      <div className="p-5">
        <div className="flex w-full justify-end">
          {/* <SearchBar /> */}
          <div>
            <Button
              type="primary"
              shape="round"
              ghost={!isEditable}
              className={`mr-5 h-10 w-36 ${isEditable && 'bg-[#3056D3]'}`}
              onClick={() => {
                setIsEditable((prev) => !prev);
                if (isEditable) setRefreshTable((prev) => !prev);
              }}
            >
              {isEditable ? 'Save' : 'Edit'}
            </Button>
            <CustomButton
              className="!h-10 !w-52"
              onClick={() => {
                setIsModalOpen((prev) => !prev);
              }}
            >
              Add subject
            </CustomButton>
          </div>
        </div>

        <div
          id="ParentManagementTable"
          className="mt-5 flex justify-center overflow-scroll"
        >
          {isLoading ? (
            <Spin size="large" className="mt-10" />
          ) : (
            <Table
              columns={columns}
              dataSource={subjectsData}
              pagination={{
                position: ['bottomCenter'],
                ...tableParams.pagination,
              }}
              className="w-full"
              onChange={handleTableChange}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default OtherSubjects;
