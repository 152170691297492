const { default: axiosInstance } = require('./config');

export const createAndConnectStripeAccount = async (userId) =>
  axiosInstance.post(`/stripe/${userId}/account`);

export const createCustomer = async (userId) =>
  axiosInstance.post(`/stripe/${userId}/customer`);

export const savePayment = async (userId, session_id) =>
  axiosInstance.post(`/stripe/${userId}/account/payment`, { session_id });

export const getPaymentMethods = async (userId) =>
  axiosInstance.get(`/stripe/${userId}/paymentMethods`);

export const deletePaymentMethod = async (userId, paymentMethodId) =>
  axiosInstance.delete(`/stripe/${userId}/paymentMethods/${paymentMethodId}`);

export const getSessionUrl = async (userId) =>
  axiosInstance.get(`/stripe/${userId}/customer/session`);

export const getSubscriptions = async (userId) =>
  axiosInstance.get(`/stripe/${userId}/customer/subscriptions`);

export const getPaid = async (userId, appointmentId) =>
  axiosInstance.post(`/stripe/${userId}/account/getPaid/${appointmentId}`);

export const setupPayment = async (userId, session_id) =>
  axiosInstance.post(`/stripe/${userId}/account/setupPayment`, { session_id });
