import React, { useState, useContext } from 'react';
import { Form, Modal, Input } from 'antd';
import InputMask from 'react-input-mask';
import Button from 'components/Button';
import { inviteUser } from 'requests';
import { notificationsContext } from 'contexts/notifications';
import { successNotification } from 'utils/successNotificationStyle';

const ModalInputLabel = ({ children }) => (
  <p className="font-semibold">{children}</p>
);

const useInviteModal = (role, setRefreshTable) => {
  const [form] = Form.useForm();
  const { api } = useContext(notificationsContext);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const validatePhoneNumber = (_, value) => {
    if (value && value.replace(/\D/g, '').length !== 10) {
      return Promise.reject('Phone number must be 10 digits');
    }
    return Promise.resolve();
  };

  const onFinish = async (values) => {
    try {
      await inviteUser(values, role);
      setRefreshTable((prev) => !prev);
      setIsModalVisible(false);
      form.resetFields();

      api.success({
        message:
          'Congratulations! The invitation request is sent successfully.',
        placement: 'top',
        duration: 4,
        style: successNotification,
      });
    } catch (err) {
      if (err?.response?.data?.error === 'Email Already Exists.')
        api.error({
          message: 'Error',
          description:
            'Email already exists or Invitation request has already been sent to the User.',
          placement: 'top',
          duration: 4,
        });
      else if (
        err?.response?.data?.error === 'User with phone number already exists.'
      )
        api.error({
          message: 'Error',
          description: 'User with phone number already exists.',
          placement: 'top',
          duration: 4,
        });
      else
        api.error({
          message: 'Error',
          description:
            'Something went wrong. Please try again after some time.',
          placement: 'top',
          duration: 4,
        });
    }
  };

  const InviteModal = (
    <>
      <Modal
        open={isModalVisible}
        onCancel={hideModal}
        footer={false}
        width={700}
      >
        <div className="p-4">
          <p className="text-2xl font-semibold">
            Add {role.toLowerCase()} details
          </p>
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            className="mt-5 flex w-full flex-col items-center"
          >
            <Form.Item
              name="firstName"
              label={<ModalInputLabel>First Name</ModalInputLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
                {
                  max: 20,
                  message: 'Must be no longer than 20 characters.',
                },
              ]}
              className="w-full"
            >
              <Input size="large" placeholder="Enter First Name" />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={<ModalInputLabel>Last Name</ModalInputLabel>}
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
                {
                  max: 20,
                  message: 'Must be no longer than 20 characters.',
                },
              ]}
              className="w-full"
            >
              <Input size="large" placeholder="Enter Last Name" />
            </Form.Item>
            <Form.Item
              name="email"
              label={<ModalInputLabel>Email</ModalInputLabel>}
              rules={[
                {
                  type: 'email',
                  message: 'Please enter a valid email address',
                },
                {
                  required: true,
                  message: 'Please input your email!',
                },
              ]}
              className="w-full"
            >
              <Input size="large" placeholder="Enter your Email" />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              label={<ModalInputLabel>Phone Number</ModalInputLabel>}
              rules={[
                {
                  min: 10,
                  message: 'The phone number must have 10 digits!',
                },
                {
                  validator: validatePhoneNumber,
                },
              ]}
              className="w-full"
            >
              <InputMask mask="(999) 999-9999" placeholder="Enter Phone Number">
                {(inputProps) => <Input {...inputProps} size="large" />}
              </InputMask>
            </Form.Item>
            <Form.Item>
              <Button className="!mt-4 !h-10 !w-64" htmlType="submit">
                Send Invitation
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );

  return { InviteModal, showModal };
};

export default useInviteModal;
