import React from 'react';
import FormModal from '../AntFormModal/AntFormModal';
import { Form } from 'antd';
import AntFormInput from '../AntFormInput/AntFormInput';
import { FormRule } from 'enums/formRules';
import AntFormSubmit from '../AntFormSubmit/AntFormSubmit';
import CustomButton from '../CustomButton/CustomButton';

const ChangePasswordModal = ({
  isModalOpen,
  setIsModalOpen,
  isLoading,
  setIsLoading,
  handleChangePassword,
  handleCancel,
  errorMessage,
}) => {
  return (
    <div>
      <FormModal
        title={<div className="text-xl font-bold">Change Password</div>}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setIsLoading={setIsLoading}
        handleCancel={handleCancel}
        className="!w-[350px] md:!w-[600px]"
      >
        <Form
          className="mt-7"
          layout="vertical"
          onFinish={handleChangePassword}
        >
          <AntFormInput
            type="password"
            label={<div className="font-medium">Current Password</div>}
            rules={FormRule.PASSWORD}
            name="old_password"
            placeholder="Enter your current password"
            className="w-full"
          />
          <AntFormInput
            type="password"
            label={<div className="font-medium">New Password</div>}
            rules={FormRule.PASSWORD}
            name="new_password"
            placeholder="Enter your new password"
            className="w-full"
          />
          <AntFormInput
            type="password"
            label={<div className="font-medium">Confirm New Password</div>}
            rules={FormRule.PASSWORD}
            name="confirm_new_password"
            placeholder="Re-enter your new password"
            className="w-full"
          />
          {<div className="mb-5 text-red-500">{errorMessage}</div>}
          <div className="flex justify-center gap-3">
            <AntFormSubmit
              loading={isLoading}
              label={<div className="font-bold">Update</div>}
              disabled={isLoading}
              className="flex w-48 items-center justify-center rounded-2xl bg-customBlack px-5 py-4 text-white"
            />
            <CustomButton
              label="Cancel"
              className="mt-[2px] flex items-center justify-center border border-customBlack text-updatedBlue "
              shape="round"
              buttonWidth="w-40"
              onClick={() => {
                setIsModalOpen(false);
              }}
            />
          </div>
        </Form>
      </FormModal>
    </div>
  );
};

export default ChangePasswordModal;
