import { createContext, useEffect, useState } from 'react';
import { getProfile } from 'requests/user';

export const userContext = createContext({
  user: null,
  login: () => {},
  logout: () => {},
  isAuthenticated: false,
  updateUser: async (user) => {},
  role: null,
  setGoogleCalendarToken: (value) => {},
  getGoogleCalendarToken: () => {},
  setGoogleCalendarRefreshToken: (value) => {},
  getGoogleCalendarRefreshToken: () => {},
});

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null
  );
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') || false
  );
  const [role, setRole] = useState(localStorage.getItem('role') || null);

  const login = ({ data: user, token, refreshToken }) => {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('role', user?.role);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem(
      'is_force_change_password',
      user?.is_force_change_password
    );
    setUser(user);
    setIsAuthenticated(true);
    setRole(user?.role);
  };

  const logout = () => {
    //commenting, might be used in future i.e. clearing calendar token in context on logout
    // localStorage.removeItem('token');
    // localStorage.removeItem('refreshToken');
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('is_force_change_password');
    setUser(null);
    setIsAuthenticated(false);
    setRole(null);
    // setGoogleCalendarToken(null);
    // setGoogleCalendarRefreshToken(null);
  };

  // set user and isAuthenticated from localStorage with useEffect
  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    const user = JSON.parse(localStorage.getItem('user'));
    const role = localStorage.getItem('role');
    const isAuthenticated = localStorage.getItem('isAuthenticated');

    if (token && refreshToken && user && role && isAuthenticated) {
      setUser(user);
      setIsAuthenticated(isAuthenticated);
      setRole(role);
    } else {
      logout();
    }
  }, []);

  const updateUser = async (updatedUser) => {
    if (!updatedUser) {
      updatedUser = await getProfile(user.id);
    }
    localStorage.setItem('user', JSON.stringify(updatedUser));
    setUser(updatedUser);
  };

  const setGoogleCalendarToken = (value) => {
    if (!value) {
      localStorage.removeItem('googleCalendarToken');
    } else {
      localStorage.setItem('googleCalendarToken', value);
    }
    window.dispatchEvent(new Event('googleCalendarTokenChanged'));
  };

  const getGoogleCalendarToken = () => {
    return localStorage.getItem('googleCalendarToken');
  };

  const setGoogleCalendarRefreshToken = (value) => {
    if (!value) {
      localStorage.removeItem('googleCalendarRefreshToken');
    } else {
      localStorage.setItem('googleCalendarRefreshToken', value);
    }
  };

  const getGoogleCalendarRefreshToken = () => {
    return localStorage.getItem('googleCalendarRefreshToken');
  };

  return (
    <userContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        isAuthenticated,
        updateUser,
        role,
        setGoogleCalendarToken,
        getGoogleCalendarToken,
        setGoogleCalendarRefreshToken,
        getGoogleCalendarRefreshToken,
      }}
    >
      {children}
    </userContext.Provider>
  );
};
