import React, { useContext, useState } from 'react';
import ParentSignup from '../../components/shared/Signup/Signup';
import coverImage from '../../assets/images/tutor/tutor-cover.png';
import { Message } from 'constants/message';
import { PARENT } from 'constants/roles';
import { signupParent } from 'requests';
import { notificationsContext } from 'contexts/notifications';
import { userContext } from 'contexts/user';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const { api } = useContext(notificationsContext);
  const { login: loginContext } = useContext(userContext);
  const navigate = useNavigate();

  const handleParentSignup = async (values) => {
    try {
      setIsLoading(true);
      const { confirm_password, password, phone_number } = values;

      // Validation - password should be at least 8 characters long.
      if (password.length < 8) {
        setErrorMessage(Message.passwordLength);
        setIsLoading(false);
        return;
      }

      // Validation - password should match confirm password
      if (password !== confirm_password) {
        setErrorMessage(Message.passwordMatch);
        setIsLoading(false);
        return;
      }

      if (!phone_number) {
        delete values.phone_number;
      }

      setErrorMessage(null);

      // Sign up API call
      const response = await signupParent(values);

      // Storing Signup details in context and local storage.
      loginContext(response);
      if (!!response?.data?.is_force_change_password) {
        navigate('/parent/create-password', { replace: true });
      } else {
        navigate('/parent/dashboard', { replace: true });
      }
      api.success({
        message: 'Success',
        description: 'Congratulations! You are successfully signed up',
        placement: 'top',
        duration: 4,
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      error?.response?.data?.error === 'User with this email already exists' ||
      error?.response?.data?.error ===
        'User with this phone number already exists'
        ? api.error({
            message: 'Error',
            description: error?.response?.data?.error,
            placement: 'top',
            duration: 4,
          })
        : api.error({
            message: 'Error',
            description:
              'Something went wrong. Please try again after some time.',
            placement: 'top',
            duration: 4,
          });
      setErrorMessage(error?.response?.data?.error);
      console.error(error);
    }
  };

  return (
    <ParentSignup
      handleSignup={handleParentSignup}
      coverImage={coverImage}
      className="bg-updatedGreenish md:bg-contain"
      role={PARENT}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  );
};

export default Signup;
