import { Layout, Table } from 'antd';
import Navbar from 'components/shared/Navbar/Navbar';
import React, { useContext, useEffect, useState } from 'react';
import backgroundImg from 'assets/images/tutor/background.png';

import { impersonateStudentNavbarItems } from 'enums';
import { PARENT } from 'constants/roles';
import { childUserContext } from 'contexts/child-user-context';
import { notificationsContext } from 'contexts/notifications';
import { getTutorAndCourses as getTutorAndCoursesAPI } from 'requests/students';
import { useNavigate } from 'react-router-dom';
import StreamChatModal from 'components/Modal/StreamChatModal';

const ImpersonateStudentTutorsAndCourses = () => {
  const { childUser } = useContext(childUserContext);
  const [data, setData] = useState([]);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [tutorId, setTutorId] = useState('');
  // const [tutorName, setTutorName] = useState('');

  const { api } = useContext(notificationsContext);
  const navigate = useNavigate();

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const getTutorAndCourses = async () => {
    try {
      const { data } = await getTutorAndCoursesAPI(childUser);
      setData(data.map((item, index) => ({ ...item, key: index + 1 })));
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again later.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorAndCourses();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Tutor Name',
      dataIndex: 'tutor_name',
      key: 'tutor_name',
    },
    {
      title: 'Total Sessions held',
      dataIndex: 'total_appointment_count',
      key: 'total_appointment_count',
    },
    {
      title: 'View Profile',
      dataIndex: 'tutor_profile',
      key: 'tutor_profile',

      render: (_, { tutor_id }) => (
        <button
          className="rounded-3xl bg-updatedBlue px-4 py-2 text-white"
          onClick={() => {
            navigate(`/parent/student-find-tutors/${tutor_id}`);
          }}
        >
          View Profile
        </button>
      ),
    },
    // {
    //   title: 'Start Chat',
    //   dataIndex: 'start_chat',
    //   key: 'start_chat',

    //   render: (_, { tutor_id, tutor_name }) => (
    //     <button
    //       className="rounded-3xl bg-updatedBlue px-4 py-2 text-white"
    //       onClick={() => {
    //         setIsModalOpen(true);
    //         setTutorId(tutor_id);
    //         setTutorName(tutor_name);
    //       }}
    //     >
    //       Start Chat
    //     </button>
    //   ),
    // },
  ];

  return (
    <Layout>
      <Navbar navItems={impersonateStudentNavbarItems} role={PARENT} />
      {/* <StreamChatModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        name={tutorName}
        id={tutorId}
      /> */}
      <div className="min-h-[calc(100vh-64px)] bg-updatedGreenish">
        <div className="mx-12 my-7 rounded-2xl bg-updatedMustard px-10 pb-5 pt-10">
          <div className="mb-10 font-semibold xs:text-lg md:text-xl">
            My Enrolled Subjects
          </div>
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{
              x: 500,
              y: 1000,
            }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ImpersonateStudentTutorsAndCourses;
