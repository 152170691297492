/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import { getTutorById } from 'requests/tutors';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationsContext } from 'contexts/notifications';
import { Checkbox } from 'antd';
import { addStudentStatistics } from 'requests';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';

const options = ['5-10 Years', '10-13 Years', '13-16 Years', '17-18 Years'];

const TutorStudentStatistics = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const { id: tutorId } = useParams();
  const { api } = useContext(notificationsContext);
  const [studentsStatistics, setStudentsStatistics] = useState([]);

  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
      setStudentsStatistics(data?.student_statistics ?? []);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateStudentsStatistics = async () => {
    try {
      await addStudentStatistics(tutorId, studentsStatistics);
      api.success({
        message: 'Success',
        description: 'Student statistics updated successfully',
        placement: 'top',
        duration: 4,
      });
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TutorDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <img
          className="h-[120px] w-[120px] rounded-full"
          src={!!image ? image : profile}
          alt="image"
        />
        <div className="mt-5 text-2xl font-semibold text-updatedBlue">
          {name}
        </div>
        <Button
          className="mt-4"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>

        <div className="mt-14 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Student Age Groups
          </div>

          <div className="mt-10 flex flex-wrap gap-10">
            {options.map((value) => {
              return (
                <Checkbox
                  className="flex h-14 flex-row items-center gap-2 text-lg"
                  checked={studentsStatistics.includes(value)}
                  onChange={() => {
                    if (studentsStatistics.includes(value)) {
                      setStudentsStatistics((stats) => {
                        let statistics = [...stats];
                        const index = statistics.indexOf(value);
                        statistics.splice(index, 1);
                        return statistics;
                      });
                    } else {
                      setStudentsStatistics((statistics) => {
                        return [...statistics, value];
                      });
                    }
                  }}
                >
                  {value}
                </Checkbox>
              );
            })}
          </div>

          <div className="mt-48 flex justify-end">
            <Button onClick={handleUpdateStudentsStatistics}>
              Save Settings
            </Button>
          </div>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default TutorStudentStatistics;
