import { Modal, Rate } from 'antd';
import React, { useState, useContext } from 'react';
import axios from 'axios';
import { notificationsContext } from 'contexts/notifications';

const GiveReviewModal = ({
  isModalOpen,
  handleOk,
  handleCancel,
  tutorId,
  parentId,
  parentReview,
}) => {
  const [review, setReview] = useState(0);
  const { api } = useContext(notificationsContext);

  const handleDone = async () => {
    try {
      if (review < 1 || review > 5) {
        console.error(
          'Invalid review. Please select a number between 1 and 5.'
        );
        return;
      }

      const response = await axios.post('/api/reviews', {
        tutor_id: tutorId,
        parent_id: parentId,
        review: review,
      });
      if (response.status == 200) {
        api.success({
          message: 'Success',
          description: 'Review Submitted Successfully.',
          placement: 'top',
          duration: 4,
        });
      }
      const returnedReview = response.data.review;
      setReview(returnedReview);

      handleOk();
    } catch (error) {
      console.error('Error posting review:', error);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      onCancel={() => {
        handleCancel();
      }}
      footer={null}
      className="!w-[500px] !min-w-[100px] "
    >
      <div className="mt-16 h-[20vh] text-center">
        <Rate
          className="text-6xl"
          defaultValue={parentReview}
          // value={review}
          onChange={(value) => setReview(value)}
        />
        <button
          onClick={handleDone}
          disabled={review === 0}
          className={`mt-16 flex h-10 w-full cursor-pointer items-center justify-around rounded-md ${
            review === 0 ? 'bg-gray-300' : 'bg-updatedBlue'
          } font-bold text-white`}
        >
          Done
        </button>
      </div>
    </Modal>
  );
};

export default GiveReviewModal;
