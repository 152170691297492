/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react';
import TutorDetailsLayout from 'components/admin/tutor-details-layout';
import profile from 'assets/images/admin/profile.png';
import Button from 'components/Button';
import { getTutorById, updateTutorBio } from 'requests/tutors';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationsContext } from 'contexts/notifications';
import { viewStoreFront } from 'utils';
import { ADMIN } from 'constants/roles';
import { Input, Spin, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const TutorBio = () => {
  const [bio, setBio] = useState('');
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [videoLink, setVideoLink] = useState('Loading...');
  const [isVideoLinkValid, setIsVideoLinkValid] = useState(true);
  const { id: tutorId } = useParams();
  const { api } = useContext(notificationsContext);

  const navigate = useNavigate();

  const getTutorDetails = async () => {
    try {
      const { data } = await getTutorById(tutorId);
      setBio(data.bio ?? '');
      setImage(data.profile_picture ?? '');
      setName(`${data.first_name} ${data.last_name}`);
      setVideoLink(data.video_link);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateBio = async () => {
    try {
      let isLinkValid = true;
      if (videoLink != '') {
        // Regular expression for URL
        const linkPattern =
          /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?\/[a-zA-Z0-9]{2,}/;
        isLinkValid = linkPattern.test(videoLink);
        setIsVideoLinkValid(isLinkValid);
      }

      if (isLinkValid) {
        await updateTutorBio(tutorId, bio, videoLink);
        api.success({
          message: 'Success',
          description: 'Bio updated successfully',
          placement: 'top',
          duration: 4,
        });
      }
    } catch (err) {
      api.error({
        message: 'Error',
        description: 'Something went wrong. Please try again after some time.',
        placement: 'top',
        duration: 4,
      });
    }
  };

  useEffect(() => {
    getTutorDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TutorDetailsLayout>
      <div className="flex w-full flex-col items-center">
        <img
          className="h-[120px] w-[120px] rounded-full"
          src={!!image ? image : profile}
          alt="image"
        />
        <div className="mt-5 text-2xl font-semibold text-updatedBlue">
          {name}
        </div>
        <Button
          className="mt-4"
          onClick={() => {
            const navigateString = viewStoreFront(tutorId, ADMIN);
            navigate(navigateString);
          }}
        >
          View Storefront
        </Button>

        <div className="mt-10 w-full">
          <div className="text-2xl font-semibold text-updatedBlue">
            Detailed Profile / Bio
          </div>
          <div className="mt-8 text-xl text-updatedBlue">Bio</div>
          <textarea
            value={bio}
            onChange={(event) => setBio(event.target.value)}
            placeholder="Enter your bio here"
            className="mt-3 h-[300px] w-full rounded-lg border-2 border-neutral-200 bg-updatedCreme px-4 py-3.5 outline-none"
          />

          <div className="mt-8 text-xl text-updatedBlue">Video Link</div>
          <p className="mt-2 text-xs">
            Supported video platforms: YouTube and Vimeo only.
          </p>
          <Input
            size="large"
            placeholder="Enter video link here"
            value={videoLink}
            onChange={(e) => {
              setIsVideoLinkValid(true);
              setVideoLink(e.target.value);
            }}
            suffix={
              <Tooltip title="Supported video platforms: YouTube and Vimeo only.">
                <InfoCircleOutlined
                  style={{
                    color: 'rgba(0,0,0,.45)',
                  }}
                />
              </Tooltip>
            }
            className="mt-3 w-1/2"
          />
          {!isVideoLinkValid && (
            <p className="text-red-500">Video Link is not valid!</p>
          )}

          <div className="mt-10 flex justify-end">
            <Button onClick={handleUpdateBio}>Save Settings</Button>
          </div>
        </div>
      </div>
    </TutorDetailsLayout>
  );
};

export default TutorBio;
