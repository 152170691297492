import axiosInstance from './config';

export const getStudents = (limit = 10, pageNumber = 1) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/admins/students?limit=${limit}&pageNumber=${pageNumber}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getStudentById = (id) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/students/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateStudent = (id, values) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/students/${id}/updateStudentGeneralInfo`, values, {
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data;',
        },
      })
      .then(resolve)
      .catch(reject);
  });
};

export const updateStudentStatus = (studentId, account_status) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`students/${studentId}/changeStudentStatus`, { account_status })
      .then(resolve)
      .catch(reject);
  });
};

export const deactivateStudent = (studentId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`students/${studentId}/deactivateAccount`)
      .then(resolve)
      .catch(reject);
  });
};

export const updateStudentLanguages = (studentId, languages) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/students/${studentId}/addLanguages`, {
        languages,
      })
      .then(resolve)
      .catch(reject);
  });
};

export const browseTutors = ({
  free_search,
  city,
  degree_type,
  title,
  disability_teach,
  network_filter,
  user_id,
  min_review,
}) => {
  const queryParams = {};

  // Optional Params
  if (free_search) queryParams.free_search = free_search;
  if (city) queryParams.city = city;
  if (degree_type) queryParams.degree_type = degree_type;
  if (title) queryParams.title = title;
  if (disability_teach) queryParams.disability_teach = disability_teach;
  if (network_filter) queryParams.network_filter = network_filter;
  if (user_id) queryParams.user_id = user_id;
  if (min_review) queryParams.min_review = min_review;
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(`/students/browseTutors`, {
        params: queryParams,
      })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTutorAndCourses = async (studentId) =>
  axiosInstance.get(`/students/${studentId}/getTutorAndCourses`);
