const { default: axiosInstance } = require('./config');

export const postStudentDisabilityCheck = (studentId, is_student_disable) =>
  axiosInstance.post(
    `/students/${studentId}/studentDisabilities/updateStudentDisabilityCheck`,
    {
      is_student_disable,
    }
  );

export const getStudentDisabilities = (studentId) =>
  axiosInstance.get(`/students/${studentId}/studentDisabilities`);

export const postStudentDisabilities = (studentId, data) =>
  axiosInstance.post(`/students/${studentId}/studentDisabilities`, data);

export const updateStudentDisabilities = (studentId, disabilityId, data) =>
  axiosInstance.put(
    `/students/${studentId}/studentDisabilities/${disabilityId}`,
    data
  );

export const deleteStudentDisabilities = (studentId, disabilityId) =>
  axiosInstance.delete(
    `/students/${studentId}/studentDisabilities/${disabilityId}`
  );
