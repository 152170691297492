import { Button, Modal } from 'antd';
import React from 'react';

const GoogleConsentModal = ({ isModalOpen, handleCancel, connectGoogle }) => {
  return (
    <Modal
      title="Privacy Disclosure"
      open={isModalOpen}
      footer={
        <Button
          type="primary"
          shape="round"
          className="bg-updatedBlue"
          onClick={() => {
            connectGoogle();
            handleCancel();
          }}
          style={{ fontFamily: 'Now' }}
        >
          Accept & Continue
        </Button>
      }
      onCancel={handleCancel}
    >
      <div style={{ fontFamily: 'Now' }}>
        <p>
          <strong>Skool'd Tutors</strong> respects your privacy and is committed
          to protecting your personal information. By signing in with your
          Google account, you agree that <strong>Skool'd Tutors</strong> may
          access and use information from your Google Calendar in accordance
          with the Google API Services User Data Policy, including the Limited
          Use requirements.
        </p>
        <br></br>

        <p>
          <strong>Skool'd Tutors</strong> ensures that any information obtained
          from Google APIs will be used solely for the intended purpose within
          the app and will not be transferred or used by any other third-party
          applications. For more details, please review the Google API Services
          User Data Policy at{' '}
          <a
            className="text-blue-800"
            href="https://developers.google.com/terms/api-services-user-data-policy"
          >
            Google API Services User Data Policy
          </a>
          .
        </p>
        <br></br>
        <p>
          Thank you for trusting <strong>Skool'd Tutors</strong>. If you have
          any questions or concerns regarding your data privacy, please contact
          us at <a href="mailto:info@skooldtutors.com">info@skooldtutors.com</a>
          .
        </p>
      </div>
    </Modal>
  );
};

export default GoogleConsentModal;
