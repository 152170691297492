import React, { useContext } from 'react';
import StudentDetailsLayout from 'components/student/StudentDetailsLayout';
import DisabilityProfilingDetails from 'pages/shared/DisabilityProfilingDetails/DisabilityProfilingDetails';
import { userContext } from 'contexts/user';
import { STUDENT } from 'constants/roles';
import Navbar from 'components/shared/Navbar/Navbar';
import { studentNavbarItems } from 'enums';
import { Layout } from 'antd';

const DisabilityProfiling = () => {
  const { user } = useContext(userContext);
  return (
    <div className="overflow-x-hidden bg-blue-400">
      <Layout>
        <Navbar navItems={studentNavbarItems} role={STUDENT} />
        <StudentDetailsLayout>
          <DisabilityProfilingDetails studentId={user.id} mode={STUDENT} />
        </StudentDetailsLayout>
      </Layout>
    </div>
  );
};

export default DisabilityProfiling;
