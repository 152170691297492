import React, { useContext, useState } from 'react';
import { sideBarOptions } from 'constants/side-bar-options';
import { ReactComponent as Logo } from 'assets/images/admin/logo.svg';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DropdownArrow } from 'assets/images/dropdown-arrow.svg';
import { userContext } from 'contexts/user';
import { ReactComponent as Logout } from 'assets/images/admin/logout.svg';
import CustomButton from 'components/shared/CustomButton/CustomButton';

const Layout = ({ children }) => {
  const { user, logout } = useContext(userContext);
  const navigate = useNavigate();
  const pathName = window.location.pathname;

  // setState for expanded view if current path is a child path
  const [expanded, setExpanded] = useState(() => {
    // finding if the currentpath exists in the sideBarOptions which have children
    const option = sideBarOptions.find(({ children }) => {
      return children && children.find(({ path }) => path === pathName);
    });
    if (option) return option.name;
    return '';
  });

  const [title] = useState(() => {
    // finding if the currentpath exists in the sideBarOptions
    const option = sideBarOptions.find(
      ({ path, children }) =>
        (path && path === pathName) ||
        children?.find(({ path }) => path === pathName)
    );

    // if the current path has no children, return the label
    if (option?.path) return option.label;
    // else if the current path has children, return the label of the parent and the child
    else {
      const childOption = option?.children?.find(
        ({ path }) => path === pathName
      );
      if (childOption) return `${option.label} | ${childOption.label}`;
      return '';
    }
  });

  const handleLogout = () => {
    logout();
    navigate('/admin/login');
  };

  return (
    <div className="flex flex-row bg-violet-50">
      <div className="sticky top-0 flex h-screen flex-col bg-updatedCreme">
        <div className="mb-3 px-12">
          <Logo className="h-40 w-56" />
          <div className="h-px w-56 border border-neutral-200"></div>
        </div>
        <div className="flex flex-col gap-3">
          {sideBarOptions.map(({ label, Image, name, path, children }) => {
            const isCurrentPath = path && path === pathName;
            const isCurrentChildPath =
              children && !!children?.find(({ path }) => path === pathName);

            return (
              <div
                className={`overflow-hidden transition-all duration-300 ${
                  expanded === name ? 'h-28' : 'h-10'
                }`}
              >
                <div
                  onClick={() => {
                    if (path && !isCurrentPath) navigate(path);
                    if (children && children.length > 0)
                      if (expanded === name) setExpanded('');
                      else setExpanded(name);
                  }}
                  className={cn(
                    'flex h-10 select-none flex-row items-center gap-5 pl-8 pr-8',
                    {
                      'bg-violet-50': isCurrentPath || isCurrentChildPath,
                      'border-r-4 border-updatedBlue':
                        isCurrentPath || isCurrentChildPath,
                      'bg-updatedCreme': !isCurrentPath && !isCurrentChildPath,
                      'cursor-pointer': !isCurrentPath && !isCurrentChildPath,
                    }
                  )}
                >
                  <div>
                    <Image
                      className={cn('h-7 w-7', {
                        'text-updatedBlue': isCurrentPath || isCurrentChildPath,
                        'text-gray-500': !isCurrentPath && !isCurrentChildPath,
                      })}
                    />
                  </div>
                  <div
                    className={cn('flex-1 text-lg', {
                      'text-updatedBlue': isCurrentPath || isCurrentChildPath,
                      'text-gray-500': !isCurrentPath && !isCurrentChildPath,
                    })}
                  >
                    {label}
                  </div>
                  {children && children.length > 0 && (
                    <div
                      className={`transition-all duration-300 ${
                        expanded === name ? 'rotate-180' : 'rotate-0'
                      }`}
                    >
                      <DropdownArrow
                        className={cn('h-5 w-5', {
                          'text-updatedBlue':
                            isCurrentPath || isCurrentChildPath,
                          'text-gray-500':
                            !isCurrentPath && !isCurrentChildPath,
                        })}
                      />
                    </div>
                  )}
                </div>
                {children &&
                  children.length > 0 &&
                  children.map(({ label, path }) => {
                    return (
                      <div
                        onClick={() => {
                          if (path && path !== pathName) navigate(path);
                        }}
                        className={cn(
                          'flex h-10 select-none flex-row items-center gap-5 pl-8 pr-12',
                          {
                            'cursor-pointer': path !== pathName,
                            'border-r-4 border-updatedBlue': path === pathName,
                          }
                        )}
                      >
                        <div
                          className={cn('pl-12 text-lg', {
                            'text-updatedBlue': path === pathName,
                            'text-gray-500': path !== pathName,
                          })}
                        >
                          {label}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>

        <div className="flex flex-1 flex-col-reverse gap-5 pb-8 pl-8 pr-4">
          <div className="flex flex-row items-center gap-3">
            {/* <Avatar size={50} src={profile_picture} /> */}
            <div>
              <div className="text-base font-medium text-gray-500">
                {user?.first_name} {user?.last_name}
              </div>
              <div className="text-sm font-normal leading-tight text-gray-500">
                {user?.email}
              </div>
            </div>
          </div>
          <div
            onClick={handleLogout}
            className="mb-5 flex cursor-pointer select-none flex-row items-center gap-3 pl-3"
          >
            <Logout className={'h-6 w-6 text-gray-500'} />
            <div className={'flex-1 text-lg text-gray-500'}>Logout</div>
          </div>
          <div className="mx-4 h-px w-56 border border-neutral-200"></div>
          <a
            href="https://dashboard.stripe.com/login"
            target="_blank"
            rel="noreferrer"
          >
            <CustomButton
              label="Go to Stripe"
              className="flex h-12 w-60 items-center justify-center rounded-3xl bg-gray-800 text-sm font-semibold text-white hover:!bg-gray-600"
              shape="round"
              buttonWidth="w-40"
            />
          </a>
        </div>
      </div>
      <div className="flex-1">
        <div className="flex flex-row items-center gap-5 border-b border-neutral-200 bg-updatedCreme px-8 py-4">
          <div className="flex-1 text-xl font-semibold leading-relaxed text-updatedBlue">
            {title}
          </div>
          {/* Commmenting for now */}
          {/* <div className="flex h-12 w-12 items-center justify-center rounded-lg border border-neutral-200 bg-violet-50">
            <Notifications className={'h-7 w-7'} />
          </div> */}
          {/* <Avatar size={50} src={profile_picture} /> */}
        </div>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
